import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Register from './components/pages/auth/Register'; 
import Main_Routing from "./Main_Routing"; 
import Topmenu from "./components/layouts/Topmenu"
import Footer from "./components/layouts/Footer"
import Login from './components/pages/auth/Login';
import AuthRouting from './components/pages/auth/Auth_Routing' ; 

class App extends React.Component 
{
  constructor() 
  {  
    super();
    this.state = {
      paths : '',
    }
  }
  
  // "homepage":"/devops",
  // basename={'/react_app'} 
   
  render() {   
    return (
      <div>   
          {/* <Router  basename={'/devops'} >  */}
 
          <Router > 
          <Topmenu />
            <ScrollToTop> 
              <Switch > 
                {/* <Route path="/" component={Login} />   */}
                <Route exact path="/register" component={Register} />
                <Route exact path="/*" component={Main_Routing} />    
              </Switch>
            </ScrollToTop> 
          </Router> 

      </div>
    ); 
  } 
}
export default App;

 