import React from 'react';
import Topmenu from '../../layouts/Topmenu';
import MetaTags from 'react-meta-tags';
import Footer from '../../layouts/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class academy_crypto_basics_you_should_know extends React.Component 
{
  constructor() {
  super();
}

render(){
  return (
    <>
      <div id="Content">
        <MetaTags>
          <title>Cryptocurrency Insights</title> 
          <meta name="description" content="Discover the latest and events in the fintech and crypto eco-system" />
          <meta name="keyword" content=" Crypto insights, defi trends, defi markets , cryptocurrency market trends, decentralised  market insights" />
          <meta property="og:title" content="Cryptocurrency Insights" />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        <div className="content_wrapper clearfix">
          <div className="sections_group">
          <div class="entry-content">
      <div class="about_page ">
          <div class="container">
            <div class="col-md-12">
                <div class="row">
                  <div class="academy_detail_lessons">
                      <div class="row">
                         <div className="col-md-2"></div>
 
                        <div class="col-md-8">
                         <div class="academy_details_page"> 
                          <div class="row">
                            <div class="col-md-9">
                               <h5 class="course_title">How to Get Started with Cryptocurrency ? </h5>
                               <p class="course_tag"><span>Course #3</span> </p>
                            </div>
                            <div class="col-md-3">
                               <p class="back_button"><a href="/academy/all"><i class="fas fa-arrow-circle-left" aria-hidden="true"></i> Back to Courses</a></p>
                            </div>
                         </div>
                         <h6 class="course_overview"><i>This Course will help you with factors and tips to get started or consider while you begin with cryptocurrency.</i></h6>
                         <div class="course_cover">
                            <h5>Cover </h5>
                            <ul>
                               <li><a href="#thetransactions">Why this topic?</a></li>
                               <li><a href="#factorsthatinfluencecryptocurrencies">Tips to get started.</a></li>
                               <li><a href="#risksinvolvedincryptocurrencyinvestment">Risk Factors.</a></li> 
                            </ul> 
                         </div>

                         
                         <div class="course_lesson_data" id="thetransactions">
                          <h5>1. Why this topic?</h5>
            <p>By this time now you must be familiar with cryptocurrencies and know that there are more cryptocurrencies beside bitcoin like ethereum,ripple, litecoin, Arrano token. </p>
            <p>No doubt that with cryptocurrencies one can make a lot of money and add fortune to his wealth. But as you know that cryptocurrencies trade is volatile and risky. Many people across the world are into crypto trading to make a profit.</p>
            <p>There's no easy risk-free way to make money and no magical way to earn Cryptocurrencies, it requires work, patience and wisdom and also being able to lose what you put in. </p>
            <p>Here are a few of the reasons that people intend to trade in Cryptocurrencies despite their volatility. You can make a nice amount of profit if you anticipate the market really well. </p>
            <p>Unlike the traditional market, Crypto trading is available 24/7</p>


               <div class="course_lesson_data" id="factorsthatinfluencecryptocurrencies"> 
            <h5>2.  Tips to get started.  </h5>
            <ul class="contents-in-dots">
                <li><b>Safety First </b></li>
            </ul> 
            <p>No doubt Blockchain is an extremely secure technology but you must be always aware about where and how you are trading and storing your funds. </p>
            <p>- Your Wallet password or Private Keys are the gateway to enter and that can be misused </p>
            <p>- Save your backup phrases and password in a secure email or local place that is highly secure and reliable like Gmail or a Notepad </p>
             <p>- Use 2FA authenticator wherever required</p>
            


                  <ul class="contents-in-dots">
                <li><b>Get a Wallet </b></li>
            </ul>
                <p>To Store, trade or Send and Receive you need a Wallet. Unlike the fiat currency in wallet, Crypto funds never leave the blockchain and wallet holds the Keys(Private keys) that are cryptographically associated with your funds. So to have control over the funds one must have their unique private keys or wallet. </p>
                <p>Registering with an Exchange is the easiest way to have a Crypto wallet, wherein the platform has inbuilt wallets of specific cryptocurrencies. But, every exchange does not support all cryptocurrencies as they provide storage of crypto’s which are tradable on their platform and so do the wallet apps. </p>
                <p>So before you begin with any exchange, wallets have checks at the supported cryptos, or check for the crypto you want  to buy or store. </p>
                <p>Decentralized wallets(Private wallets) come with Keys and a 12 Seed Phrase code that acts as an identifier (username) everytime you wish to recover the account or login into another device. </p>

            <p><b>Also know </b></p>
            <ul class="contents-in-dots">
                <li>Every wallet will hold a unique receiver address or wallet address , just like your account number to receive funds. </li>
                <li>Every Crypto will have a different receiver address </li>
                <li>Only Decentralized wallets will have a one common address for all tokens as they all belong to same blockchain network
                   <br/> For Ex : Metamask Wallet is a Ethereum Wallets that stores token that are only built on Ethereum Blockchain Network and has one common wallet address.
                  </li>
                <li>Wallets are generally categorized into Hot wallet and Cold wallet </li>
                <li>Hot wallets are one which are somehow connected to the Internet , like Desktop or Mobile apps and exchange based wallets. As they are remote these wallets  are soft in security but high in convenience </li>
                <li>Cold wallets are not connected to the internet , they are Hardware Wallets and are usually a device ( pendrive , disc etc) . These are more secured but a little inconvenient to access. </li>
                <li>Most experts recommend to prefer the Cold wallets.</li>
            </ul>
            <p><b>Pick and Exchange </b></p>
            <p>To Begin with cryptocurrency you will need to Obtain it ( Buy / receive). Bitcoin by far is most easy to buy due its popularity and market acquisition, however some altcoin like Eth, Ripple and others can also be bought directly using Credit/debit cards. </p>
            <p>Well, if you like to Buy or Sell ( Or Trade ) you have multiple ways to do it. </p>
            <ul class="contents-in-dots">
                <li><b>Peer to Peer : </b> Commonly known as P2P , is a well known form of trading Crypto to Fiat to Crypto . there are platforms who help you place ads or organize meetups to exchange funds. This option is known to be risky , but the platform reputation and user experience over it should be considered.
                <br/><br/>In this exchange the Seller of crypto will pledge funds with the mediator and place ad of desired volume with desired price. The ad will be shown to active Buyers who opted to buy the volume with equal or higher price. 
                <br/><br/>Seller Bank details will be shown to the buyer to make the direct deposit. Once the seller confirms the receiving of money ( fiat ) the mediator will then send the respective crypto to the buyer. </li>
            </ul>
            

            <ul class="contents-in-dots">
                <li><b>Exchanges : </b> Centralized Exchange platforms are a good choice for traders to get started with. Some exchanges like Biannce , coinbase .. allow you to buy and  sell you crypto directly with your bank transactions . 
                  <br/><br/>You will also have options for multiple pairs of trading, which means you can exchange Crypto to Crypto in the market.
                  <br/><br/>As said before these centralized exchanges come with liite currencies supported and also the traders need deposit/store funds into their wallets to make successful trade. 
                  <br/><br/>This means the safety of your funds depends on the security of the exchange Platforms and any hack may cause in loss of your funds. </li>
            </ul>
            
            <p>Criteria to check for an exchange</p>
            <ul class="contents-in-dots"> 
              <li>How Reputed is the exchange </li>
              <li>Number of crypto’s supported </li>
              <li>Trading Volume and no.of users</li>
              <li>Supports direct withdrawal or P2P or Crypto to Crypto</li>
              <li>Technology or any certifications available </li>
              <li>Check for online reviews </li>
              <li>Check for Support system</li>
              <li>Decentralized Exchanges : Commonly known as DEX , decentralized exchanges are a differently technical function platforms ( website or apps ).
                <br/><br/>In Decentralized exchange you don't have to deposit your Funds into the wallet of the exchange platform, the custody of your funds is with you and you can simply connect your wallets to palace trade orders or Swap tokens to other tokens. 
                <br/><br/>DeX platforms have become more popular after the rise of a term called Decentralized financing the Smart Contract technology evolution. 
                <br/><br/>This type of exchange is widely recognised as the safest way to trade in crypto and also offer high liquidity compared to Centralized exchanges.  </li>

            </ul>
            

            <p><b>Trade </b></p>
            <p>Generally, there are three ways to conduct a trade:</p>
            <p><b> Limit trade : </b>This allows you to set a price you want to buy or sell a token at and specify the amount of tokens you want. When the price of the token hits your price target, the buy or sell order is automatically filled. </p>
            <p><b>Market trade:  </b>Some exchanges like Binance and Bittrex allow you to buy or sell at the market rate. This is the quickest way to buy or sell a token immediately. </p>
            <p><b>Stop limit : </b>This way of trading basically enables you to automatically trigger and order at a set price (or a better one), up until a set limit. This also works the other way around if you place a stop limit sell order.</p>
                          </div>

                      
                              <ul class="contents-in-dots">
                <li><b>Manage Portfolio </b></li> 
            </ul>
            <p>As you dive more into trading in Cryptocurrencies you will have to maintain a record Of all your investments , profits, lossed just like we do with stocks. </p>
            <p>A Portfolio is basically a consolidated overview of Cryptocurrency investments, that provides you with friendly reports and transaction lists. There are a handful of apps for portfolio management across all platforms like Blokofi, Delta and Arrano Portfolio Management. </p>
            <ul class="contents-in-dots">
              <li><b>Stay Updated </b></li> 
            </ul>
            <p>Cryptocurrency is an ongoing process and currently in the development stage we can say. There are developments everyday updates with regards to its technical, social and regulatory views. For traders and cryptio enthusiasts it's very certain to keep a look at updates for news and insights.  </p>
            <p>You can opt to read some popular news portals like Cointelegraph, CoinDesk , Coinpedia. Forums like Bitcoin talk and Reddit are useful to connect with like minded people. Coinmarketcap , Coinchecko and Arrano analytics can be a regular check for price behaviour updates. </p>
                          
  </div> 
                            
                          <div class="course_lesson_data" id="risksinvolvedincryptocurrencyinvestment">
                             <h5>3. Risk Factors</h5>
            <p><b>Risks involved in Cryptocurrency Investment.</b></p>
            <p><b>What is Risk in Investment?</b></p>
             <p><b>Risk can be defined as a calculated move or a degree of uncertainty taken to gain higher returns on investments which can also be subjected to financial loss. </b></p>
             <p><b>Without identifying or considering risks, it is difficult to achieve your goal or objective. A proper strategy and understanding of risks should always be planned and implemented to dodge higher risks.</b></p>
             <p>Though Cryptocurrencies have buzzed the whole economic-financial sector with its popularity and benefits, they are still bound to risks and obstacles which need immediate attention before you invest your money in Cryptocurrency Exchange.</p>

             <p>There are so many people rushing towards its investments without any proper knowledge of Crypto investments.</p>
             <p>Here we list some risk factors that one should consider before investing in Cryptocurrencies.</p>
             <ul class="contents-in-number">
               <li>Cryptocurrency Market is <b>Fluctuating and volatile</b> like any other stock market. Its activity is quite unpredictable and uncertain. The investment market can be rippling back and forth.No guarantee is assured for higher returns on the amount that is invested. It is variable and can sometimes lead to incredibly high returns or massive loss. Don't get attracted to the bubbling hub of the market wherein people expect a sudden rise in cryptocurrencies and bid all their assets when without cross-checking the market risks involved in it.</li>
               <li>Cryptocurrencies have evolved over a decade and require much more attention in terms of its investment plans. Any haste towards its trading or exchanges can lead to an unattainable loss. It is always better to keep in check with its investment statistics and make smaller investments to avoid big risks.</li>
               <li> It is evolved from the <b>decentralized system</b> of financial management. Though the government is not in- charge of maintaining its records, certain restrictions can be implemented toward its trade exchange leading to disrupting activities in its trading programs. In some countries, it is operating without any regulations and is operating smoothly and,  yet, it is still not widely accepted Currency.</li>
               <li>It is still a <b>technology-reliant virtual currency</b> that means they are vulnerable to hacking and malware attacks. There is no physical collateral to back up its value or money if once lost due to hacking or shut down the system because of malware attack.</li>
               <li><b>Loss of Private key-</b> Cryptocurrencies are stored in digital-wallet hence a private key is used to regulate its transaction, if the key (code or password) is lost or is misused by any external entity, there is a high chance of theft and loss of assets.</li>
               <li><b>Fraud in mining pools - </b>new coins can be obtained or created by a process called crypto mining which requires solving complex cryptographic codes on the network which generates blocks. The benefit can be reaped by not recording these blocks on the network from the group involved in collective mining of the respective currency.</li>
               <li>Do not fall prey to an <b>exchange platform</b> which promises high returns, always run a background check on such sites before you go for investments. Only check for the official websites advised by the experts.</li>
               <li><b>Loss of confidence in cryptocurrencies</b> in large aspects can lead to its price fall in the market. Therefore it is always advised to invest in small assets and diligently keep an eye on the investment scale.</li>
             </ul>

                            </div>
                            
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default academy_crypto_basics_you_should_know;