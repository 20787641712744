import React from 'react'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags' 
import * as myConstClass from '../../Constants'
import ContentLoader from "react-content-loader"; 
class Market extends React.Component {

  constructor()
  {
    super();
    this.state = {
        website_base_url:myConstClass.website_base_url,
        defulatModalState: false,
        getMarketsList:"",
        isHovering: false,
        headers: {
          "Accept":"application/json"
        }
    }
    this.handleMouseHover = this.handleMouseHover.bind(this);
  }

  componentDidMount()
  { 
    this.getMarketsList();
  }

  //https://api.0x.org/swap/v1/tokens - address
  //https://api.0x.org/swap/v1/prices?sellToken=DAI
  //https://gas.api.0x.org/source/median?output=eth_gas_station
  //https://api.0x.org/swap/v1/price?affiliateAddress=0x86003b044f70dac0abc80ac8957305b6370893ed&buyToken=ETH&includePriceComparisons=false&sellAmount=33333333000000000000000000&sellToken=0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2&skipValidation=false&slippagePercentage=0.01
  //https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=ethereum,usd-coin,dai
  // //https://api.0x.org/swap/v1/price?affiliateAddress=0x86003b044f70dac0abc80ac8957305b6370893ed&buyToken="+first_token_symbol+"&includePriceComparisons=false&sellAmount="+receiveTokenValue+"&sellToken="+secondTokenAddress+"&skipValidation=false&slippagePercentage=0.01
  
  getMarketsList()
  {
    fetch("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum%2Cdai%2Cwrapped-bitcoin%2Cwrapped-ethereum%2Cchainlink%2Cyearn-finance%2Cuniswap%2Ctether%2Cusd-coin%2Caave%2Cbinance-usd%2Csynthetix-network-token%2Cbalancer%2Comg-network%2Cmaker%2Ccompound%2Cuma%2Cpaxos-standard%2Crenbtc%2Cbasic-attention-token%2C0x%2Cren%2Ctrue-usd%2Ckyber-network%2Csushi%2Campleforth%2Cband-protocol%2Cnumeraire%2Cenjin-coin%2Caragon%2Cstatus%2Cdecentraland%2Ccurve-dao-token%2Caave-old%2Cbancor-network%2Cpax-gold%2Csusd%2Cbzx-protocol%2Cmstable-usd%2Cxdai-stake%2Clivepeer%2Ccream%2Ctbtc%2Ckeep-network%2Cseth%2Cpickle-finance%2Cakropolis%2Cmeta%2Cgemini-dollar%2Cfoam%2Cbased-money%2Cswerve%2Cbarnbridge%2Crenzec%2Cdonut%2Caugur&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=24h", {
      method:'GET',
      headers: this.state.headers
    }).then(res => res.json())
    .then(
      (result) => {
          this.setState({
            getMarketsList:result
          }) 
     });

  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }


  render()
  {
    return (
      <> 
      <div id="Content">
      <MetaTags>
          <title>Markets - Decentralized Exchange | DEX </title>
          <link rel="canonical" href="https://arrano.network/" />
          <meta name="description" content=" Arrano Network is a powerful and user-friendly decentralized exchange developed by the Arrano Developers community. Arrano DEX..." />
          <meta name="keyword" content="Arrano Network, Arrano DEX, ANO Token, Arrano Tokens, Arrano DeFi, Arrano airdrop , ANO Defi token , Arrano Network Community , Arrano Launchpad, Arrano Exchange Arrano Defi Project , ANO governance token" />
          <meta property="og:title" content="Markets - Decentralized Exchange | DEX" />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>

      {/* bitcoin,ethereum,dai,wrapped-bitcoin,wrapped-ethereum,chainlink,yearn-finance,uniswap */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 className="mt-3">DeFi Tokens</h4>
                <p>Live market price and token details.</p>
              </div>
            </div>
            <div className="row">
            <div className="col-md-12">  
              
            

              <div className="row">
                {
                  this.state.getMarketsList ?
                  <React.Fragment>
                   {
                      this.state.getMarketsList.map((item, i) => 
                      <div className="col-md-3" key={i}>
                        <Link to={"/market/"+item.symbol.toUpperCase()}>
                          <div className="main_market_display">
                            <div className="media">
                              <div className="media-body">
                                <h4 style={{textTransform:"uppercase"}}>{item.symbol}</h4>
                                <h6>$ {item.current_price}</h6>
                                {
                                  item.price_change_percentage_24h != 'undefined' ?
                                  <React.Fragment>
                                    {
                                      item.price_change_percentage_24h > 0 ?
                                      <p className="green">{(parseFloat(item.price_change_percentage_24h)).toFixed(2)}% in 24Hrs</p>
                                      :
                                      <p className="red">{(parseFloat(item.price_change_percentage_24h)).toFixed(2)}% in 24Hrs</p>
                                    }
                                  </React.Fragment>
                                  :
                                  null
                                }
                                
                              </div>
                              <img src={item.image} alt={item.symbol} className="ml-3" />
                            </div>
                          </div>
                        </Link>
                      </div>
                      )
                   }
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div className="row">
                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="main_market_display">
                    <div className="media">
                      <div className="media-body">
                        <div className="row">
                          <div className="col-md-8">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="30%" height="10px" />
                              <rect x="0" y="15" rx="0" width="50%" height="20px" />
                              <rect x="0" y="40" rx="0" width="60%" height="10px" />
                            </ContentLoader>
                          </div>
                          <div className="col-md-4">
                            <ContentLoader speed={1}
                              backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                              <rect x="0" y="0" rx="0" width="100%" height="50px" />
                              
                            </ContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  </React.Fragment>
                }
                
                
                
                </div>
              </div>
            </div>
          </div>
        </div> 
        </>
    );
  }
}

export default Market;
