import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import Moment from 'react-moment'
import {api_base_url, get_config, p2p_currency, equi_p2p_currency,cookieDomainExtension, getUsdSymbol} from '../../Constants'
import Axios from 'axios'
import Popupmodal from '../../../components/layouts/popupmodal'
import JsCookie from 'js-cookie'
import moment from 'moment'
import { useHistory } from "react-router-dom"
import Timer from 'react-compound-timer';
// p2p_trading/cancel_your_buy_order/:id

export default function P2P_sell_order({userAgent, ref_link, prev_url})
{
  const router = useHistory()
  var post_slug
  post_slug = window.location.pathname.substring(1)
  post_slug = post_slug.substring(post_slug.lastIndexOf("/"))
  post_slug = post_slug.replace(/\//,"")

  const [sell_request_row_id, set_sell_request_row_id] = useState(post_slug)
  const [sell_request, set_sell_request] = useState(0)
  const [transactions_list, set_transactions_list] = useState([])
  const [cancel_modal, set_cancel_modal] = useState(false)
  const [confirm_modal, set_confirm_modal] = useState(false)
  const [transaction_list, set_transaction_list] = useState([])
  const [alert_message, set_alert_message] = useState(false)
  const [modal_data, set_modal_data] = useState({ icon: "", title: "", content: "" })
  const [present_date_n_time, set_present_date_n_time] = useState("")
  const [txn_trading_row_id, set_txn_trading_row_id] = useState("")
  const [payment_details_modal_status, set_payment_details_modal_status] = useState("")
  const [payment_details, set_payment_details] = useState("")
  const [payment_confirm_status, set_payment_confirm_status] = useState(false)
  const [payment_confirm_usdt_value, set_payment_confirm_usdt_value] = useState(0)
  const [payment_confirm_inr_value, set_payment_confirm_inr_value] = useState(0)
  
  useEffect(() => 
  { 
    individualDetails()
    console.log(transaction_list)
  }, [])

  const individualDetails = async ()=>
  {
    const res_data = await Axios.get(api_base_url+"p2p_trading/sell_individual_view/"+sell_request_row_id, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_data.data.status)
    {
      set_sell_request(res_data.data.message.sell_request)
      set_present_date_n_time(res_data.data.message.sell_request.present_date_n_time)
      set_transaction_list(res_data.data.message.transactions)
      set_txn_trading_row_id(res_data)
      console.log(res_data)
    }
    else
    {
      router.push("/p2p")
    }
  }

  const cancelOrder = async ()=>
  {
    set_modal_data({icon:"", title:"", content:""})
    const res_data = await Axios.get(api_base_url+"p2p_trading/cancel_your_sale_order/"+sell_request_row_id, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_data.data.status)
    {
      set_cancel_modal(!cancel_modal)
      set_modal_data({icon:true, title:"Order Cancelled", content:res_data.data.message.alert_message})
      individualDetails()
    }
    else
    {
      set_cancel_modal(!cancel_modal)
      set_modal_data({icon:true, title:"Oops!", content:res_data.data.message.alert_message})
    }
  }

  const AcceptOrder = async ()=>
  {
    if(!payment_confirm_status)
    {
      return false
    }
    const reqObj={
      sell_request_row_id:sell_request_row_id,
       txn_trading_row_id:txn_trading_row_id
    }
    set_modal_data({icon:"", title:"", content:""})
    const res_data = await Axios.post(api_base_url+"p2p_trading/sell_order_confirm_payment/",reqObj, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_data.data.status)
    {
      console.log("res data",res_data)
      set_modal_data({icon:true, title:"Order Confirmed", content:res_data.data.message.alert_message})
      individualDetails()
      set_confirm_modal(false)
    }
  }
  
  const confirmModal = (txn_trading_row_id, pass_usdt_value, pass_inr_value)=>
  {
    set_confirm_modal(true)
    set_txn_trading_row_id(txn_trading_row_id)
    set_payment_confirm_usdt_value(pass_usdt_value)
    set_payment_confirm_inr_value(pass_inr_value)
    set_payment_confirm_status(false)
  }

  const paymentDetails = async (item)=>
  {
    set_payment_details_modal_status(true)
    set_payment_details(item)
  }

  const clearPaymentDetails = async ()=>
  {
    set_payment_details_modal_status(false)
    set_payment_details("")
  }

    return (
      <>
      <MetaTags>
        <title>P2P Sell Order Details</title>
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>
      <div id="Content" className="p2p_orders_details profile p2p_orders">
        <div className="container">
          <div className="row">
          
            {/* <div className="col-md-4">
              <div className="order_quick_links">
                <div className="order_quick_header">
                  <h6>Sell Order Details </h6>
                  <p>Total Selling Order</p>
                </div>
                <div className="order-details">
                  <h6>1.  {sell_request.completed_sell_usdt_value} {getUsdSymbol(parseInt(sell_request.network_type_row_id))} - <span className="completed">Completed</span></h6>
                </div>
                <div className="order-details">
                  <h6>2.  {sell_request.assigned_usdt_value} {getUsdSymbol(parseInt(sell_request.network_type_row_id))} - <span className="pending">Assigned</span></h6>
                </div>
                <div className="order-details">
                  <h6>3.  {sell_request.pending_sell_usdt_value} {getUsdSymbol(parseInt(sell_request.network_type_row_id))} - <span className="pending">Not Assigned</span></h6>
                </div>
                <div className="order-details">
                  <h6>4.  {sell_request.cancelled_sell_usdt_value} {getUsdSymbol(parseInt(sell_request.network_type_row_id))} - <span className="cancelled">Cancelled</span></h6>
                </div>
              </div>
            </div> */}
         
         <div className="col-md-12">
      
         <div className="buy_sell_orders_back">
              <Link to={((sell_request.sell_status == 2) || (sell_request.sell_status == 3)) ? '/p2p?active_tab=2' : '/p2p?active_tab=1'} >
                <img src="/assets/images/left-arrow.png" width="27px"/>  Back to Order's
              </Link>
              
              </div>
              </div>

            <div className="col-md-8 mx-auto"> 
            
              <h3 className="buy-sell-title"><img src="/assets/images/buy-order.svg"/> Sell Order Details:  {sell_request.sell_request_id}</h3>
            <div className="single_order_details">
             
              <h5>You are Selling <span>{parseFloat(sell_request.request_sell_usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))}</span>  <span>for {sell_request.receiving_amount} {equi_p2p_currency}</span></h5>
              <h6>Placed on {moment(sell_request.date_n_time).format("LLLL")} (1 {getUsdSymbol(parseInt(sell_request.network_type_row_id))} = {parseFloat(sell_request.sell_rate_for_one_usdt)} INR)</h6>
              {/* <h6 className="order_id_details">Order ID :  {sell_request.sell_request_id}</h6> */}
              
              {
                sell_request.transaction_hash ?
                <h6 className="order_id_details" style={{color:"white"}}>
                 Deposited Trans Hash :  
                 {
                   parseInt(sell_request.network_type_row_id) === 1 ?
                   <a href={"https://etherscan.io/tx/"+sell_request.transaction_hash} target="_blank" style={{color:"white"}}>
                     {sell_request.transaction_hash.slice(0,4)}...{sell_request.transaction_hash.slice((sell_request.transaction_hash.length-4),sell_request.transaction_hash.length)}
                   </a>
                   :
                   parseInt(sell_request.network_type_row_id) === 2 ?
                   <a href={"https://bscscan.com/tx/"+sell_request.transaction_hash} target="_blank" style={{color:"white"}}>
                     {sell_request.transaction_hash.slice(0,4)}...{sell_request.transaction_hash.slice((sell_request.transaction_hash.length-4),sell_request.transaction_hash.length)}
                   </a>
                   :
                   "asdf"
                 }
                </h6>
                :
                ""
              }
              
              <div className="row">
                <div className="col-md-3 col-6">
                  <h4>{parseFloat(sell_request.completed_sell_usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))}</h4>
                  <p>Completed</p>
                </div>
                <div className="col-md-3 col-6">
                  <h4>{parseFloat(sell_request.pending_sell_usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))}</h4>
                  <p>Not Assigned</p>
                </div>
                <div className="col-md-3 col-6">
                  <h4>{parseFloat(sell_request.assigned_usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))}</h4>
                  <p>Assigned</p>
                </div>
                <div className="col-md-3 col-6">
                  <h4>{parseFloat(sell_request.cancelled_sell_usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))}</h4>
                  <p>Cancelled</p>
                </div>
                <div className="col-md-12">
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-7 mx-auto">
                
              
                {/* 
                0:in process, 1:doposited by buyer, 2:approved by seller-Txn complete
                3:seller confirmation timeout, 4:Txn cancelled by software, 5:Txn cancelled by admin
                */}
             
                {
                  transaction_list.length > 0 ?
                  transaction_list.map((item, i)=>
                  <>
                    {
                    parseInt(item.assinged_status) === 0 ? 
                    <div className="order_transaction">
                      <div className="transaction_deposited">
                        <div className="transaction_header">
                          <h4>#{i+1} Receiving Payment of {parseFloat(item.total_amount)} INR (For {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))}) </h4>
                          <h6>Transaction ID : {item.assinged_unique_id}</h6>
                          <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                          <h4 className="mt-4 mb-4">Payment in Process</h4>
                          <img src="/assets/images/timer-img.svg"/>
                          <h4 className="mt-4">
                          <Timer initialTime={new Date(item.buyer_added_date_n_time).getTime()-new Date(present_date_n_time).getTime()} direction="backward" 
                            checkpoints={[
                              {
                                time: 0,
                                callback: () =>individualDetails(),
                              }
                            ]} >
                            {() => ( <React.Fragment> <Timer.Minutes />: <Timer.Seconds />  </React.Fragment> )} 
                          </Timer> Min</h4>
                        </div>
                       
                      
                        <p className="seller-confirmation">Waiting for payment from buyer</p>
                        
                      </div>
                    </div>
                    :
                    parseInt(item.assinged_status) === 1 ? 
                    <div className="order_transaction">
                      <div className="transaction_pay_with">
                        <div className="transaction_header">
                          <h4>#{i+1} Payment Deposited {parseFloat(item.total_amount)} INR (For {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))})</h4>
                          <h5>Transaction ID : {item.assinged_unique_id}</h5>
                          <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                          <h6>Buyer has sent {parseFloat(item.total_amount)} INR to your&nbsp; 
                            {
                              parseInt(sell_request.recieve_payment_type) == 1 ?
                              <span className="green-code" onClick={()=>paymentDetails(sell_request)} >Bank Account</span>
                              :
                              <span className="green-code" onClick={()=>paymentDetails(sell_request)} >UPI ID</span> 
                            }
                          </h6>
                        </div>

                        <div className="confirm-buyer-details">
                         <p>Buyer Name <span>{item.buyer_name}</span></p>
                         <p>Deposited Ref ID <span>{item.deposited_bank_ref_id}</span></p> 
                        </div>
                        {/* <i class="fas fa-exclamation-triangle"></i> */}
                        <h4 className="blue-code">Confirm payment from buyer</h4>
                   
                        {/* <div className="text-center">
                          <Timer initialTime={new Date(item.deposited_date_n_time_added_hour).getTime()-new Date(present_date_n_time).getTime()} direction="backward" 
                          checkpoints={[
                            {
                               callback: () =>individualDetails(),
                            }
                        ]}
                           >
                            {() => ( <React.Fragment> <Timer.Minutes />: <Timer.Seconds />  </React.Fragment> )} 
                          </Timer>
                        </div> */}
                                      
                
                
                        {/* <p style={{paddingBottom: '15px'}}>This is the transaction ID from the buyer <br/><a>{item.deposited_bank_ref_id}</a></p> */}
                        <div className="row">
                          {/* <div className="col-lg-2">
                            
                          </div> */}
                          <div className="col-lg-8 col-md-12 col-xs-12 col-12 col-sm-12 mr-auto ml-auto">
                            <button className="yes_confirmed" onClick={()=>confirmModal(item.id, parseFloat(item.usdt_value), parseFloat(item.total_amount))}>Yes, I received Payment</button>
                          </div>

                          <div className="paywith-txn">
                              <img src="/assets/images/timer-img.svg" style={{marginTop:"10px"}} />

                              <h6>Confirm before <span className="green-code green-border">
                            <Timer initialTime={new Date(item.deposited_date_n_time_added_hour).getTime()-new Date(present_date_n_time).getTime()} direction="backward" 
                          checkpoints={[
                            {
                               time: 0,
                               callback: () =>individualDetails(),
                            }
                        ]}
                           >
                            {() => ( <React.Fragment> <Timer.Minutes />: <Timer.Seconds />  </React.Fragment> )} 
                          </Timer>
                          </span> Min to avoid dispute in transaction</h6> 
                                      </div>
                          
                        </div>
                      </div>
                    </div>
                    :
                    parseInt(item.assinged_status) === 2 ? 
                    <div className="order_transaction">
                      <div className="transaction_completed">
                          <div className="transaction_header">
                            <h4> #{i+1} Payment of {parseFloat(item.total_amount)} INR (For {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))})</h4>
                            <h5>Transaction ID : {item.assinged_unique_id}</h5>
                            <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                          </div>
                          <img src="/assets/images/complete-icon.svg" width="50" />
                          <h3 className="completed-btn">Transaction Completed</h3>
                          <p>Buyer has completed the payment</p>
                      </div>
                    </div>
                    :
                    parseInt(item.assinged_status) === 3 ? 
                      <div className="order_transaction">
                        <div className="transaction_dispute">
                          <div className="transaction_header">
                            <h4>#{i+1} Payment of {parseFloat(item.total_amount)} INR (For {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))})</h4>
                            <h6>Transaction ID : {item.assinged_unique_id}</h6>
                            <h6>Matched on {moment(item.date_n_time).format("LLLL")}</h6>
                            <h6>Buyer has sent {parseFloat(item.total_amount)} INR to your&nbsp; 
                              {
                                parseInt(sell_request.recieve_payment_type) == 1 ?
                                <span className="green-code" onClick={()=>paymentDetails(sell_request)} >Bank Account</span>
                                :
                                <span className="green-code" onClick={()=>paymentDetails(sell_request)} >UPI ID</span> 
                              }
                            </h6>
                          </div>
                          <div className="despute-buyer-details">
                            <p>Buyer Name <span>{item.buyer_name}</span></p>
                            <p>Deposited Ref ID <span>{item.deposited_bank_ref_id}</span></p> 
                          </div>
                          <img src="/assets/images/cancelled.svg" width="50" />
                          <h5>Order in Dispute</h5>
                          {/* <p>You rejected the payment from buyer</p> */}
                          {/* <p style={{paddingBottom: '15px'}}>This is the transaction ID from the buyer <br/><a>{sell_request.deposited_bank_ref_id}</a></p> */}
                          <div className="row">
                            {/* <div className="col-lg-2">
                              
                            </div> */}
                            <div className="col-lg-8 col-md-12 col-xs-12 col-12 col-sm-12 mr-auto ml-auto">
                              <button className="yes_confirmed" onClick={()=>confirmModal(item.id, parseFloat(item.usdt_value), parseFloat(item.total_amount))} >Yes, I received Payment</button>
                            </div>
                          </div>
                          <p>The order is in dispute, ahead of your pending confirmation</p>
                        </div>
                      </div>
                    :
                    (parseInt(item.assinged_status) === 4) ? 
                    <div className="order_transaction">
                      <div className="transaction_cancelled">
                        <div className="transaction_header">
                          <h4>#{i+1} Payment of {parseFloat(item.total_amount)} INR (For {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))})</h4>
                          <h5>Transaction ID : {item.assinged_unique_id}</h5>
                          <h6>Matched on {moment(item.date_n_time).format("LLLL")}</h6>
                        </div>
                        <img src="/assets/images/buyer-cancel.svg" width="50" />
                        <h6 className="order-cancel">Order Cancelled</h6>
                        <p>Order was cancelled due to payment not submitted</p>
                      </div>
                    </div>
                    :
                    (parseInt(item.assinged_status) === 5) ? 
                    <div className="order_transaction">
                      <div className="transaction_cancelled">
                        <div className="transaction_header">
                          <h4>#{i+1} Payment of {parseFloat(item.total_amount)} INR (For {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))})</h4>
                          <h5>Transaction ID : {item.assinged_unique_id}</h5>
                          <h6>Matched on {moment(item.date_n_time).format("LLLL")}</h6>
                        </div>
                        <img src="/assets/images/buyer-cancel.svg" width="50"/>
                        <h6 className="order-cancel">Order Cancelled</h6>
                        <p>Order was cancelled by admin on Dispute</p>
                      </div>
                    </div>
                    :
                    null
                    }
                  </>
                  )
                  :
                  null
                }

                {
                  parseFloat(sell_request.all_assigned_cancelled) < parseFloat(sell_request.request_sell_usdt_value) ?
                  <div className="matching_direct_seller">
                    {/* <h2>Finding Buyers for your Order</h2> */}
                    <h2>Looking For Buyer</h2>
                    {/* <h4>Please hold while we match your order</h4> */}
                    <img src="/assets/images/orders-loader.svg" />
                    <h4>You will receive email when buyer matched.</h4>
                  </div>
                  :
                  parseFloat(sell_request.cancelled_sell_usdt_value) == parseFloat(sell_request.request_sell_usdt_value) ?
                  <div className="order_transaction">
                    <div className="transaction_cancelled">
                      <div className="transaction_header">
                        <h4>{parseFloat(sell_request.cancelled_sell_usdt_value)} {getUsdSymbol(parseInt(sell_request.network_type_row_id))} of {parseFloat(sell_request.total_amount)} INR</h4>
                      </div>
                      <img src="/assets/images/buyer-cancel.svg" width="50"/>
                      <h6 className="order-cancel">Order Cancelled</h6>
                      <p>Order was cancelled</p>
                    </div>
                  </div>
                  :
                  null
                }

                

              </div>
            </div>
          </div>
          {/* // :
                  // parseInt(sell_request.sell_status) ===  1 ?
                  // <div className="order_pending">
                  //   <h2>Sell Order Pending</h2>
                  //   <h5>({sell_request.completed_percentage}% complete) {parseFloat(sell_request.completed_sell_usdt_value)} USDT of {sell_request.completed_sell_usdt_value*sell_request.sell_rate_for_one_usdt} INR</h5>
                  //   <h6>Placed on {moment(sell_request.date_n_time).format("LLLL")}</h6> 
                  //   <h6>Order ID : {sell_request.sell_request_id}</h6>
                  
                  // </div>
                  // :
                  // parseInt(sell_request.sell_status) ===  2 ?
                  // <div className="order_complete">
                  //   <h2>Sell Order Completed</h2>
                  //   <h5>({sell_request.completed_percentage}% complete) {parseFloat(sell_request.request_sell_usdt_value)} USDT of {parseFloat(sell_request.total_amount)} INR</h5>
                  //   <h6>Placed on {moment(sell_request.date_n_time).format("LLLL")}</h6>
                  //   <h6>Order ID : {sell_request.sell_request_id}</h6>
                  // </div>
                  // :
                  // parseInt(sell_request.sell_status) ===  3 ?
                  // <div className="order_cancelled">
                  //   <h2>Order Cancelled</h2>
                  //   <h5>(100% cancelled) {parseFloat(sell_request.cancelled_sell_usdt_value)} USDT of {parseFloat(sell_request.total_amount)} INR</h5>
                  //   <h6>Placed on {moment(sell_request.date_n_time).format("LLLL")}</h6>
                  //   <h6>Order ID : {sell_request.sell_request_id}</h6>
                  // </div> */}
           {/* <h4>Placed on {moment(sell_request.date_n_time).format("LLLL")} </h4>
                    <h4>Order ID : {sell_request.sell_request_id}</h4> */}
                    {/* {
                      parseInt(sell_request.pending_sell_usdt_value)>0?
                      <button type="button" className="btn_place_order" name="cancel_order" onClick={()=>set_cancel_modal(!cancel_modal)}>Cancel Order</button>
                      :
                      sell_request.request_sell_usdt_value == sell_request.cancelled_sell_usdt_value ?
                      <span className="red"> Order Cancelled</span>
                      :
                      null
                    } */}
          {/* 
          
          <div className="col-md-4 text-right"> 
            <Link to={((sell_request.sell_status == 2) || (sell_request.sell_status == 3)) ? '/p2p?active_tab=2' : '/p2p?active_tab=1'} className="btn btn-primary mt-3">
              <i className="la la-arrow-left"></i>Back
            </Link>
          </div> */}
        </div>
      </div>
    </div>

    <div className={"modal  modal_main_popup" + (cancel_modal ? " show d-block" : " d-none")}>
      <div className="modal-dialog claim_auto modal-sm">
        <div className="modal-content ">
          <div className="modal-body ">
            <button type="button" className="close" data-dismiss="modal" onClick={()=>set_cancel_modal(!cancel_modal)}>&times;</button>
            <h2 style={{fontSize:'40px'}} ><i className="fa fa-info-circle" aria-hidden="true"></i></h2> 
            <p>Do you want to Cancel this order?</p>
            <button type="button" className="btn order_cancel_accept" onClick={()=>cancelOrder()}>Cancel Order</button>
          </div>
        </div>
      </div>
    </div>

    {modal_data.title ? <Popupmodal name={modal_data} /> : null}

    <div className={"modal  modal-design modal_main_popup" + (confirm_modal ? " show d-block" : " d-none")}>
      <div className="modal-dialog modal-md">
        <div className="modal-content ">
        <div className="modal-header">
          <h4 className="modal-title">Attention !</h4>
          <button type="button" className="close" data-dismiss="modal" onClick={()=>set_confirm_modal(!confirm_modal)}>&times;</button>
        </div> 
          <div className="modal-body ">
            {/* <h2 style={{fontSize:'40px'}} ><i className="fa fa-info-circle" aria-hidden="true"></i></h2>  */}
            {/* <p> Order Confirmation</p> */}
            
            <p>Have you checked the payment of {payment_confirm_inr_value} INR in your  { parseInt(sell_request.recieve_payment_type) == 1 ? "Bank Account":"UPI ID"} ? On this confirmation, {payment_confirm_usdt_value} {getUsdSymbol(parseInt(sell_request.network_type_row_id))} will be sent to the buyer</p>
            <div className="checkbox-check">
                <label className="form-check-label">
                <input type="checkbox" checked={payment_confirm_status ? true:false} onChange={()=>set_payment_confirm_status(!payment_confirm_status)}/> Yes I m sure
                </label>
            </div>
            <div className="modal-btns">
            <button type="button" className="btn goback-btn" data-dismiss="modal" onClick={()=>set_confirm_modal(!confirm_modal)}>Go Back</button>
            <button type="button" className={"btn confirm-order "+(!payment_confirm_status ? " btn-opacity":"")} onClick={()=>AcceptOrder()}>Yes I Received</button>
            </div>
          </div>
        </div>
      </div>
    </div>
 
<div className={"modal modal-design "+(payment_details_modal_status ? " show d-block":" d-none")}>
  <div className="modal-dialog modal-md">
    <div className="modal-content">
    <div className="modal-header">
        <h4 className="modal-title">Payment Details</h4>
        <button type="button" class="close" data-dismiss="modal" onClick={()=>clearPaymentDetails()}>&times;</button>
      </div> 
      <div className="modal-body">
        <div className="impsdetails">
          {
            parseInt(payment_details.recieve_payment_type) == 1 ?
            <>
              <div className="impsdetails">
                <h4>IMPS</h4>
                <h5>Name : {payment_details.bank_holder_name}</h5>
                <h5>Account Number : {payment_details.bank_acc_number}</h5>
                <h5>IFSC : {payment_details.bank_ifsc_code}</h5>
                <h5>Bank : {payment_details.bank_name}</h5>
              </div> 
            </>
            :
            <>
                <div className="impsdetails">
                  <h4>UPI</h4>
                  <h5>UPI: {payment_details.upi_id}</h5>
                </div>
            </>
          }
        </div>      
      </div>
    </div>
  </div>
</div>

      </>
    )
  
}
