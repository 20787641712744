import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Cookies from 'js-cookie';
import { api_base_url, website_base_url, get_config, cookieDomainExtension, accountLogout}  from '../../Constants'; 
import ContentLoader from "react-content-loader";
import MetaTags from 'react-meta-tags'; 
 
class Connections extends React.Component {

constructor() {
    super();
    this.state = {
        referralsList: null,
        total_connections: null,
        filteredReferrals:null,
        user_name:Cookies.get('local_set_user_name'),
        email_verify_status: 1,
        headers: {
          "Accept":"Application/json",
          "Content-Type":"Application/json"
        },
        ano_wallet_balance: null,
        total_referrals_count: null,
        search: "",
        offset: 0,
        referralDataStatus:false,
        data: [],
        elements: [],
        perPage: 20,
        sl_no:0,
        page_number:1,
        currentPage: 0,
        token:Cookies.get('token')
    }
}
 
logout(){
  this.props.history.push('/auth/login');
}

componentDidMount()
{   
    this.referralsOverview()
    this.getReferralsList(this.state.page_number)
}


getReferralsList(page_number)
{
  this.setState({ referralDataStatus: false })
  const { perPage } = this.state
  var cust_page = page_number ? (page_number-1):0
  console.log(cust_page)
  var asdfd = cust_page*perPage
  this.setState({page_number:page_number})
  this.setState({sl_no:cust_page*perPage})
  const config = get_config(this.state.token)
  fetch(api_base_url+"connections/connection_list/"+asdfd+"/"+perPage, {
    method:'GET',
    headers: config.headers
  }).then(res => res.json()).then((result) => 
  {
    this.setState({ referralDataStatus: true })
    if(result.tokenStatus)
    {  
      if(result.status === true) 
      {
        this.setState({referralsList: result.message, total_connections:result.total_counts})
      }
    }
    else
    {
      this.logout()
    }
  })
}

referralsOverview()
{
  
  const config = get_config(this.state.token)
  fetch(api_base_url+"dashboard/overview", {
    method:'GET',
    headers: config.headers
  }).then(res => res.json()) .then((result) => 
  {
    if(result.tokenStatus === true)
    {
      if(result.status === true) 
      {
        this.setState({ 
          total_connections: result.message.total_connections,
          ano_wallet_balance: result.message.ano_wallet_balance
        })
      }
    }
    else
    {
      accountLogout()
      this.props.history.push('/auth/login');
    }
  })
}


  myReferrlaLink() {
    var copyText = document.getElementById("referral-link");
    copyText.select();
    document.execCommand("Copy");
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied";
  }

  
  

  renderReferralsList()
  { 
   
    var page_number = (this.state.page_number-1)*this.state.perPage
    return (
      <React.Fragment>
        {
                this.state.referralDataStatus ?
                <div className="table-responsive">
                <table className="table table_connections">
                <thead>
                  <tr>
                    <th className="mobile_hide" style={{ width: "10px" }}>Sl.No</th>
                    <th>Date</th>
                    <th>Name(ID)</th>
                    <th>Country</th>
                    <th>Rewards</th>
                    <th>Email+Device Verified Status </th>
                   
                  </tr>
                </thead>
                {
                  this.state.referralsList ?
                <tbody>
                
                  {
                    this.state.referralsList.map((item, i) =>{
                      var my_sl = this.state.sl_no
                   return <tr key={i}>
                        <td className="mobile_hide">{my_sl+i+1}</td>
                        <td>{item.date_n_time}</td>
                        <td><span>{item.full_name}</span> <span className="name-color">[{item.user_name}]</span> </td>
                        <td><img src={'/assets/flags/'+item.country_flag}  title="Luxembourg" className="flag-imgs"/> <span className="mobile_hide">{item.country_name}</span></td>
                        <td>
                          {
                            item.ano_value ? 
                            <span>{item.ano_value} ANO</span>
                            :
                            <span>-</span>
                          }
                          
                        </td>
                        <td className="verified_status">
                          {
                            parseInt(item.email_verify_status) ? 
                            <span className="green"><img src="/assets/images/mail-green.svg" width="30" className="mr-2 mail_verified_status" /></span>
                            :
                            <span className="red"><img src="/assets/images/mail-red.svg" width="30" className="mr-2 mail_verified_status" /></span>
                          }
                          {
                            item.device_unqiue_id ? 
                            <span className="green"><img src="/assets/images/mobile-green.svg" width="19" className="mobile_verified_status"/></span>
                            :
                            <span className="red"><img src="/assets/images/mobile-red.svg" width="19" className="mobile_verified_status"/></span>
                          } 
                          <span></span>
                        </td> 
                  </tr>
                    })
                  }
                  
                </tbody>
                 :
                 <tbody>
                   <tr >
                   <td colSpan="5">Sorry No Related Data Found.</td>
                   </tr>

                 </tbody>
              }
              </table> 
              </div>
                :
                <div className="table-responsive">
                <table className="table table-hover table_connections">
                <thead>
                  <tr>
                    <th className="mobile_hide" style={{ width: "10px" }}>Sl.No</th>
                    <th>Date</th>
                    <th>Name(ID)</th>
                    <th>Country</th>
                    <th>Rewards</th>
                    <th>Email+Device<br/>
                    <small>Verified Status</small></th>
                  </tr>
                </thead>
                <tbody>
                    <tr key={'1'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'2'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'3'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'4'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'5'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'6'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'7'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'8'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                </tbody>
                </table> 
              </div>
            }

        {
          this.state.total_connections ?
            <React.Fragment>
              {
                this.state.total_connections > this.state.perPage ?
                <div style={{textAlign: 'center', marginBottom: '15px'}}>
                    <Pagination
                      prevPageText='« Prev'
                      nextPageText='Next »'
                      firstPageText='First'
                      lastPageText='Last'
                      itemclassName='page-item'
                      activePage={this.state.page_number}
                      itemsCountPerPage={this.state.perPage}
                      totalItemsCount={this.state.total_connections}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber) => this.getReferralsList(pageNumber)}
                    />
                </div>
                :
                null
              }
            </React.Fragment>   
          :
          null
        }       
        
      </React.Fragment>
    );
  }
 
  render()
  {
    var filteredReferrals = null;
    
    var usersGet = this.state.referralsList;
    var searchValue = this.state.search;
    if((searchValue !== ''))
    {
        var filteredReferrals = usersGet.filter(function(item) {
            return ((item.username.indexOf(searchValue) != -1) || (item.full_name.indexOf(searchValue) != -1) || (item.email_id.indexOf(searchValue) != -1)  || (item.mobile_number.indexOf(searchValue) != -1));
        })
    }
    else
    {
        var filteredReferrals = usersGet;
    }
   
    return (
      <> 
      <div id="Content" className="connections">
      <MetaTags>
          <title>Connections : Your community for lifetime.</title>
          <meta property="og:image" content="/favicon.png" />
      </MetaTags>
      <div className="banner_connection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="title-bg-gredient"></div>
              <div className="set-banner-content">
              <h3>Together We are Going Further.</h3>
              <p>It's all about having as many smart and more people in the community to grow Big, the Tokens to price high, The Projects Deploy in all dimensions. Do you know any ? Let them Join the Community and go where the world is going!</p>
              <div className="wallet_balance">
                {/* <p>Total Earned</p> */}
                {
                    this.state.ano_wallet_balance ? 
                    <h3><span>Total Earned</span> {this.state.ano_wallet_balance} ANO</h3>
                    :
                    <h3><span>Total Earned</span> 0.00 ANO</h3>
                }

                
              </div>
              <button type="button" className="btn btn-refferal" data-toggle="modal" data-target="#myModal">View My Refferal Link</button>
              {/* <div className="alert alert-info alert-dismissible connection_alert">
                <span className="verify_email_tag"><i className="fas fa-trophy"></i> Invite and Earn 100 ANO/verified user.</span>
              </div> */}
              {
                this.state.email_verify_status == 0 ?
                <div className="alert alert-info alert-dismissible connection_alert">
                  <i className="fa fa-bell" aria-hidden="true" /> Tokens are accessible only if email is verified. <Link to="/user/profile?ref=verify-email" className="verify_email_tag">Verify Email</Link>
                </div>
                :
                null
              }
              </div>
            </div>
            <div className="col-lg-2 hide-on-tablet" />
            <div className="col-lg-4 col-md-6">
              <div className="connection-bg-img">
                <div className="bg-gredient-color"></div>
              </div>
              <img src="/assets/images/connection-bg.png" />
            </div>
          </div>
          
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          		    
          <div className="modal modal-design pr-0" id="myModal">
            <div className="modal-dialog">
              <div className="modal-content">
                {/* Modal Header */}
                <div className="modal-header">
                  <h5 className="modal-title mb-0">Invite Your Friends</h5>
                  <button type="button" className="close" data-dismiss="modal">×</button>
                </div>
                {/* Modal body */}
                <div className="modal-body mt-3">
                  <img src="/assets/images/share_a.png" className="position_a_img" />
                  <p>By inviting your friends to Arrano Network, you'll be helping them invest their Super where the world is going.</p>
                  <p>So far, you've referred {this.state.total_connections} friend with your unique url. </p>
                  {/*34*/}
                  <div className="input-group">
                    
                    <input type="text" className="form-control" id="referral-link" value={website_base_url+'register?ref='+this.state.user_name} readOnly />
                    <span className="input-group-addon">
                      <button type="button" id="myTooltip" onClick={()=>{this.myReferrlaLink()}}>
                        Copy link 
                      </button>
                    </span>
                  </div>
                  <p style={{marginTop: '25px', marginBottom: 0}}>Invite Via:</p>
                  <p className="social_referral">
                    <a target="_blank" href="https://www.facebook.com/sharer.php?u=./r?ref=arrano&t=Hello Blockchain Mates! 
      Decentralized exchange with low fees high liquidity and P2P included is the best treat for Defi Lovers rewarded by ANO Tokens.">
                      <img src="/assets/images/social/facebook.svg" />
                    </a>
                    <a target="_blank" href="http://twitter.com/intent/tweet/?text=Hello Blockchain Mates! 
      Decentralized exchange with low fees high liquidity and P2P included is the best treat for Defi Lovers rewarded by ANO Tokens.&url=./r?ref=arrano">
                      <img src="/assets/images/social/twitter.svg" />
                    </a>
                    <a target="_blank" href="whatsapp://send?text=Hello Blockchain Mates! 
      Decentralized exchange with low fees high liquidity and P2P included is the best treat for Defi Lovers rewarded by ANO Tokens../r?ref=arrano">
                      <img src="/assets/images/social/whatsapp.svg" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="mt-3">  My Connection   
                          {
                            this.state.total_connections ? 
                            <span> - {this.state.total_connections}</span>
                            :
                            <span> - 0</span>
                          } 
                  </h5>
                </div>
              </div>
              {this.renderReferralsList()}
              
            </div>
          </div>
           
        </div>
      </div>
    </div> 
    </>
    );
  }
}

export default withRouter(Connections);
