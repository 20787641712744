import React, { Component } from 'react';  
import * as myConstClass from '../Constants';
import Web3 from 'web3';
import Footer from '../layouts/Footer';


class Eth_wallet extends Component
{
    constructor()
    {
      super()
      this.state = {
        headers: {
          "Accept":"Application/json",
          "Content-Type":"Application/json"
        },
        api_base_url:myConstClass.api_base_url,
        givenProvider:myConstClass.givenProvider,
        metaMaskNetworkAccpeted:myConstClass.metaMaskNetworkAccpeted,
        website_base_url:myConstClass.website_base_url,
        alert_message:null,
        create_eth_secrete_key:'2435@#@#@±±±±!!!!678543213456764321§34567543213456785432134567',
        loader:false
      }
    }


    submitCreateNewWallet()
    {
        let parentThis = this
        var create_eth_secrete_key = this.state.create_eth_secrete_key
        var website_base_url = this.state.website_base_url
        var web3 = new Web3(new Web3.providers.HttpProvider(website_base_url))
        var account = web3.eth.accounts.create('1', create_eth_secrete_key)
        if(account !== '') 
        {   
            var requestObject = {
                address: account.address,
                privateKey: account.privateKey
            }

            var privateKey = account.privateKey
            var password = '123123'
            var encrypted_json = web3.eth.accounts.encrypt(privateKey, password) 
        }
    }

    componentDidMount() 
    {
        
    }

    render()
    {    
        return(
            <> 
                <div style={{padding:"150px"}}>
                    <button className="btn btn-primary" type="button" onClick={()=>{this.submitCreateNewWallet()}}>Create New Wallet</button>
                </div>
                <Footer />
            </>
        )
    }
}

export default Eth_wallet

