import React,{useState} from 'react';  
export default function Popupmodal(props)
{ 
    const {icon, title, content, otherData } = props.name  
    console.log("title", title)
    const [showmodal, setShowModal] = useState(true) 
    const modalClose = () => {
      setShowModal(!showmodal)   
    }

   
    return(   
      <div className="app_modal">
        <div className={"modal modal-design modal_main_popup show"+ (showmodal ? "  d-block" : " d-none")} >
          <div className="modal-dialog modal-sm">
       
            <div className="modal-content ">
            <div className="modal-header">
          {title ? <h5 className="mb-0 modal-title">{title}</h5> : null}
          <button type="button" class="close" data-dismiss="modal" onClick={()=>modalClose()}>&times;</button>
        </div> 
              <div className="modal-body">
                {/* <button type="button" className="close" data-dismiss="modal" onClick={()=>modalClose()}>&times;</button> */}
                {/* <h2 style={{fontSize:'40px'}} ><i className="fa fa-info-circle" aria-hidden="true"></i></h2>  */}
                {/* {icon ? <img src={icon} /> : null} */}
                
                {content ? <p className="mb-0">{content}</p>  : null}
              
              </div>
            </div>
          </div>
        </div>
    </div>
    )
}
