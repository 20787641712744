import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';  
import Eth_wallet from './components/pages/Eth_wallet'; 
import Protected from './components/pages/auth/Protected'; 
import Register from './components/pages/auth/Register';  
import Swap_ANDX from './components/pages/swap/Swap_ANDX';
import Staking from './components/pages/Staking';
import Swaptransactions from './components/pages/swap/Swap_transactions_list';  
import Dashboard_Routing from './components/pages/dashboard/Dashboard_Routing' 
import Academy_Routing from './components/pages/academy/Academy_Routing';  
import AuthRouting from './components/pages/auth/Auth_Routing' ; 
import Roadmap from './components/pages/Roadmap'; 
import Market_Routing from './components/pages/markets/Market_Routing';  
import P2p_routing from './components/pages/p2p/P2p_routing';  
import Home from './components/pages/Home';  
import Login from './components/pages/auth/Login';

import LazyComponent from './components/LazyComponent';   
const Claim_now = lazy(() => import('./components/pages/Claim_now'))
const Updates = lazy(() => import('./components/pages/about-token'))
const Privacypolicy = lazy(() => import('./components/pages/Privacypolicy')) 
const Error404 = lazy(() => import('./components/Error404')) 

class Main_Routing extends React.Component 
{
  constructor()
  {  
    super(); 
    this.state = {
      paths : ''
    }
  } 
  render() {  
    return (
      <div>     
              <Suspense fallback={LazyComponent}>
                <Switch > 
                  <Route  path="/register" component={Register} />     
                  <Route  path="/claim-ano" component={Claim_now} /> 
                  <Route  path="/tokens" component={Updates} /> 
                  <Route  path="/privacy-policy" component={Privacypolicy} />  
                  <Route  path="/eth-wallet" component={Eth_wallet} /> 
                  <Route  path="/roadmap"  component={Roadmap} /> 
      
                  <Route  path="/academy" component={Academy_Routing} />
                  <Route path="/user" component={Dashboard_Routing} /> 
                  <Route path="/auth" component={AuthRouting} />
                  <Route path="/market" component={Market_Routing} /> 
                  <Route path="/staking" component={Staking} />   

                  <Route path="/swap-ANDX"  component={Swap_ANDX} /> 
                  <Route path="/p2p"  component={P2p_routing} />
                  <Route path="/swap-transactions"  component={Swaptransactions} />   
                  <Route path="/" component={Login} />
                  
                  <Route path="*"><Error404 /></Route>
                </Switch>  
              </Suspense> 
      </div>
    );
  } 
} 
export default Main_Routing;

 