// import { createStore ,applyMiddleware ,combineReducers } from 'redux'
// import reducer from './Reducer.js';
// // import Auth_Reducer from "./reducers/Auth_Reducers";
// import thunk from 'redux-thunk';

// const rootReducer = combineReducers({ reducer, Auth_Reducer });

// const appStore = createStore(rootReducer, applyMiddleware(thunk));

// export default appStore;
 
import { createStore } from 'redux'
import reducer from './Reducer.js';

const appStore = createStore(reducer);

export default appStore;
