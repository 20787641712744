import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import MetaTags from 'react-meta-tags'; 
import Cookies from 'js-cookie';
import { api_base_url, get_config, loginCookies, businessModal, cookieDomainExtension} from '../../Constants' 
import Web3 from 'web3'
import Axios from 'axios'

class Register extends React.Component {
  
  
  constructor() 
  {
    super()
    this.state = {
      headers: {
        "Accept":"Application/json",
        "Content-Type":"Application/json"
      },
      pathList:businessModal,       
      alert_message:null,
      country_id:"",
      user_name:"",
      full_name:"",
      email_id:"",
      mobile_number:"",
      password:"",
      path_row_id:"",
      referral_id:"",
      referred_username:"",
      wallet_address:"",
      error_password:"",
      error_user_name:"",
      error_full_name:"",
      error_email_id:"",
      error_path_row_id:"",
      error_referral_id:"",
      error_mobile_number:"",
      error_country_id:"",
      modal_alert_message:{ status:false, title: "", description: "" },
      url_param:queryString.parse(window.location.search),
      loader:false,
      password_show_status:false,
      tp_wallet_address:""
    
    }
   
  }

  getPathList()
  {
    const config = get_config("")
    fetch(api_base_url+"register/path_list", {
      method:'GET',
      headers: config.headers,
    }).then(res => res.json()).then( (result) => 
    {
      if(result.status === true) 
      {
        this.setState({ pathList: result.message })
      }
    })
  }

  connectToWallet()
  {
    var parentThis = this
    var checkNetworks = ["private", "main"]
    if(window.ethereum) 
    {
      window.ethereum.enable().then(function (res) 
      {
        let web3 = new Web3(Web3.givenProvider || parentThis.state.state.givenProvider)
        web3.eth.net.getNetworkType().then(function (networkName) 
        {
          if(checkNetworks.includes(networkName)) 
          {
            web3.eth.requestAccounts().then(function (accounts) 
            {
              var first_address = accounts[0]
              if((typeof first_address != 'undefined')) 
              {
                parentThis.setState({ wallet_address : first_address}) 
                Axios.post(api_base_url+'login/login_with_wallet_address', {wallet_address:first_address}, get_config(""))
                .then(res => {  
                    console.log(res.data)
                    if(res.data.status == true)
                    {
                      loginCookies(res.data.message)
                      parentThis.props.history.push('/user/dashboard')
                    }
                    else
                    {
                        // Cookies.set("temp_wallet_address", parentThis.state.wallet_address,{domain:cookieDomainExtension})
                       // history.push({pathname : "/register"})
                    } 
                })
              }
              return true
            })
          }
          else 
          {
            console.log('Please connect to Main or BNB wallet.')
            parentThis.setState({modal_alert_message : { status:true, title: "Connection Error", description: 'Please connect to Main or BNB wallet.' }})
          }
        })
      })
    }
    else 
    {
      console.log('You are connected to an unsupported network.')
      parentThis.setState({modal_alert_message : { status:true, title: "Connection Error", description: 'You are connected to an unsupported network.' }})
    }
  }

  getCountryList()
  {
    const config = get_config("")
    fetch(api_base_url+"register/country_list", {
      method:'GET',
      headers: config.headers,
    }).then(res => res.json())
    .then(
      (result) => {
        if(result.status === true) 
        {
          this.setState({ 
              countryList: result.message
          })
        }
    })
  }

  saveRegisteredUser()
  {
    // return false;
    let formIsValid = true;

    if(this.state.user_name === '') 
    {
      this.setState({ error_user_name:"The Username field is required." })
      formIsValid = false
    }
    else if(this.state.user_name.length < 4) 
    {
      this.setState({ error_user_name:"The Username field must be at least 4 characters in length." })
      formIsValid = false
    }
    else
    {
      this.setState({ error_user_name:"" })
    } 

    if(this.state.path_row_id === '') 
    {
      this.setState({ error_path_row_id:"The Path Row ID field is required." })
      formIsValid = false
    }
    else
    {
      this.setState({ error_path_row_id:"" })
    }


    if(this.state.full_name === '') 
    {
      this.setState({ error_full_name:"The Full Name field is required." })
      formIsValid = false
    }
    else if(this.state.full_name.length < 4) 
    {
      this.setState({ error_full_name:"The Full Name field must be at least 4 characters in length." })
      formIsValid = false
    }
    else
    {
      this.setState({ error_full_name:"" })
    }

    if(this.state.country_id === '') 
    {
      this.setState({ error_country_id:"The Country ID field is required." })
      formIsValid = false
    }
    else
    {
      this.setState({ error_country_id:"" })
    }

    if(this.state.mobile_number === '') 
    {
        this.setState({
            error_mobile_number:"The Mobile Number field is required."
        })
        formIsValid = false
    }
    else if(this.state.mobile_number.length < 5) 
    {
        this.setState({
            error_mobile_number:"The Mobile Number field must be at least 5 characters in length."
        })
        formIsValid = false
    }
    else
    {
        this.setState({
            error_mobile_number:""
        })
    }

    if(this.state.email_id === '') 
    {
        this.setState({
            error_email_id:"The Email ID field is required."
        })
        formIsValid = false
    }
    else if(!this.state.email_id.includes('@')) 
    {
        this.setState({
            error_email_id:"The Email ID field must be contain valid email."
        })
        formIsValid = false
    }
    else
    {
        this.setState({
            error_email_id:""
        })
    }

    if(this.state.password === '') 
    {
                this.setState({
                    error_password:"The Password field is required."
            })
            formIsValid = false
    }
    else if(this.state.password.length < 6) 
    {
        this.setState({
            error_password:"The Password field must be at least 6 characters in length."
        })
        formIsValid = false
    }
    else
    {
        this.setState({
            error_password:""
        })
    }

    if(!formIsValid)
    {
        return true
    }
    //console.warn("state ",this.state)
  
    this.setState({
          loader:true
    })
    var data = {
      full_name: this.state.full_name
    }


  const register_data = {
    wallet_address: this.state.wallet_address,
    user_name : this.state.user_name,
    full_name : this.state.full_name,
    path_row_id : this.state.path_row_id,
    mobile_number: this.state.mobile_number,
    email_id: this.state.email_id,
    referral_id: this.state.referral_id,
    country_id: this.state.country_id,
    password: this.state.password,
  }
  var loginUserExpireTime = new Date()
  loginUserExpireTime.setMinutes(loginUserExpireTime.getMinutes() + (60*24))
  
  const config = get_config("")

  fetch(api_base_url+"register/create_new_user", {
     method:'POST',
     headers:config.headers,
     body:JSON.stringify(register_data)
  }).then(res => res.json())
  .then(
    (result) => {
      this.setState({
        loader:false
      })
    
     if(result.status === true) 
     {
         var var_alert_message = 'Dear user, Your Registration has been completed succesfully, Thank You!'
         loginCookies(result.message, loginUserExpireTime)
        
         //window.location.href = '/user/dashboard'
          if(result.message.email_verify_status == '1')
          {
            this.props.history.push('/user/dashboard') 
          }
          else
          {
            this.props.history.push('/auth/register-verify-email') 
          }
     }
     else
     {   
         //error_user_name
         if(result.message.user_name)
         {
          var error_user_name = result.message.user_name
         }
         else
         {
          var error_user_name = ""
         }

         //error_email_id
         if(result.message.email_id)
         {
          var error_email_id = result.message.email_id
         }
         else
         {
          var error_email_id = ""
         }

         //error_mobile_number
         if(result.message.mobile_number)
         {
          var error_mobile_number = result.message.mobile_number
         }
         else
         {
          var error_mobile_number = ""
         }

         //error_mobile_number
         if(result.message.referral_id)
         {
          var error_referral_id = result.message.referral_id
         }
         else
         {
          var error_referral_id = ""
         }

         if(result.message.alert_message)
         {
          var alert_message = result.message.alert_message
         }
         else
         {
          var alert_message = ""
         }
 
         this.setState({
              alert_message:alert_message,
              error_email_id:error_email_id,
              error_mobile_number:error_mobile_number,
              error_user_name:error_user_name,
              error_referral_id:error_referral_id
         })
      }
     
     });
}

getParamFun()
{
  if(typeof this.state.url_param.ref !== 'undefined')
   {  
     //alert(this.state.url_param.ref);
     if(this.state.url_param.ref !== '')
     {  
        //alert(this.state.url_param.ref);
        var data = {
          username: this.state.url_param.ref
        };
        
        const config = get_config("")
        fetch(api_base_url+"register/check_username", {
           method:'POST',
           headers: config.headers,
           body:JSON.stringify(data)
       }).then(res => res.json())
       .then(
         (result) => {
          // console.warn(result);
            if(result.status === false) 
            {
              this.props.history.push('/register')
            }
            else
            {
               this.setState({ 
                referral_id: this.state.url_param.ref,
                referred_username: this.state.url_param.ref
               })
            }
           }); 

        
     }
   }

   if(typeof this.state.url_param.contributor !== 'undefined')
   {
      if(this.state.url_param.contributor !== '')
      {
        this.setState({path_row_id: this.state.url_param.contributor})
        
      }
   }
}

componentDidMount() 
{
  this.getPathList()
  this.getCountryList()
  console.log(window.location.pathname)
 
  if(Cookies.get("token",{domain:cookieDomainExtension})){
    this.props.history.push('/auth/login')
  }
  else
  {
    this.getParamFun()
  }
  if(Cookies.get("temp_wallet_address", {domain:cookieDomainExtension}))
  {
      this.setState({wallet_address: Cookies.get("temp_wallet_address", {domain:cookieDomainExtension})})
  }
}

  render()
  {
    
   var { modal_alert_message,password_show_status } = this.state
    return (
     
      <> 
      <div className="auth">
      <MetaTags>
        <title>Register to Arrano Network Community | Create an Arrano Account.</title>
        <link rel="canonical" href="https://arrano.network/register" />
        <meta name="description" content="Arrano Network account is a new blockchain based DeFi product development platform. The Account allows users to be a part of Arrano Network community developments. Users can be a part of the Developers , media, Producers and traders community. " />
        <meta name="keyword" content="Arrano, Arrano token, arrano network, Register to arrano, arrano register, create arrano account, arrano project, ano token, ano token listed, Arrano exchange, decentralized exchange, Arrano DEX, DEX, Decentralized exchange, best decentralized exchange, what is Decentralized exchange, Arrano P2P, Arrano Network, Arrano Bounty, Bounty program, Arrano airdrop, airdrop, Arrano startups, Arrano launchpad program, Arrano team, Arrano account, DeFi, Smart contract, Uniswap, binance dex, 1inch." />
        <meta property="og:title" content="Register to Arrano Network Community | Create an Arrano Account." />
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>
      <section className="auth_section">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-5">
                <div className="row">
                
                  <div className="col-lg-12 col-md-12 auth_form">
                    <div className="submit_form">
                      <h5 className="watch_view_mobile"  data-toggle="modal" data-target="#myModal"><span>Watch Video</span></h5>
                      <div className="modal modal_main_popup popup_video" id="myModal">
                        <div className="modal-dialog claim_auto">
                          <div className="modal-content">
                            <div className="modal-body">
                              <button type="button" className="close" data-dismiss="modal">&times;</button>
                              <iframe src="https://player.vimeo.com/video/490171531" width="100%" height="250" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                      


                      <h1> <span>Join the Arrano Network Community</span></h1>
                      <p>Signup here with your details</p>

<div className={"modal custom-modal "+(modal_alert_message.status ? "show_modal":"")} id="myModal">
  <div className="modal-dialog modal-sm">
    <div className="modal-content"> 
      <div className="modal-body">
      <button type="button" className="close" onClick={()=>this.setState({modal_alert_message:{status:false, title:"", description:""}})}>&times;</button>
        <h4 className="modal-title">{modal_alert_message.title}</h4>
        <div className="modal-description">{modal_alert_message.description}</div>
      </div>
    </div>
  </div>
</div>



                    
                      
                      {/* <div className="border_bottom_auth"></div> */}
                      
                       {
                         this.state.referred_username ?
                         <div className="alert alert-info">
                            Dear user, <b>{this.state.referred_username}</b> has been invited to you!
                        </div>
                         :
                         null
                       }

                      { this.state.alert_message ? (
                          <div className="alert alert-primary">
                            {this.state.alert_message}
                          </div>
                          ) : (
                              null
                          )} 

                      {
                        this.state.wallet_address ?
                        <div className="form-group">
                          <input type="text" className="form-control"  value={this.state.wallet_address} autoComplete="off" readOnly/>
                        </div>
                        :
                        <div className="form-group">
                          <img src="/assets/images/connect-wallet.svg" width="22" height="22" className="connect-wallet-img"/>
                        <button onClick={()=>{this.connectToWallet()}} className="connect-wallet">Connect wallet</button>
                        </div>
                      }

                      <div className="form-group">
                        <select className="form-control" name="path_row_id" onChange={(e)=>{this.setState({path_row_id:e.target.value})}} useref="path_row_id">
                            <option value="">Contribute As</option>
                              {
                                this.state.pathList ?
                                  this.state.pathList.map((item, i) =>
                                    <option value={item.id}  key={item.id} selected={this.state.path_row_id == item.id}> {item.path_name}</option>
                                  )
                                  :null
                              }
                        </select>
                        <div className="error">
                            {this.state.error_path_row_id} 
                        </div>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Full Name" useref="full_name"  value={this.state.full_name}  onChange={(e)=>{this.setState({full_name:e.target.value})}} name="full_name" autoComplete="off"/>
                       <div className="error">
                            {this.state.error_full_name} 
                        </div>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Username" useref="user_name"  value={this.state.user_name}  onChange={(e)=>{this.setState({user_name:e.target.value})}} name="user_name" autoComplete="off" />
                       <div className="error">
                            {this.state.error_user_name} 
                        </div>
                      </div>
                      <div className="form-group">
                        <select className="form-control" name="country_id" onChange={(e)=>{this.setState({country_id:e.target.value})}}  useref="country_id">
                              <option value="">Select Country</option>
                              {
                                this.state.countryList ?
                                  this.state.countryList.map((item, i) =>
                                    <option value={item.country_id}  key={item.country_id} > {item.country_name} ({item.country_code} )</option>
                                  )
                                  :null
                              }
                        </select>
                       <div className="error">
                                {this.state.error_country_id} 
                        </div>
                      </div>

                      <div className="form-group">
                            <input type="number" className="form-control" placeholder="Mobile Number" useref="mobile_number"  value={this.state.mobile_number}  onChange={(e)=>{this.setState({mobile_number:e.target.value})}} name="mobile_number" autoComplete="off" />
                           <div className="error">
                                {this.state.error_mobile_number} 
                            </div>
                      </div>

                      <div className="form-group mb-0">
                          <input type="text" className="form-control" placeholder="Email ID" useref="email_id"  value={this.state.email_id}  onChange={(e)=>{this.setState({email_id:e.target.value})}} name="email_id" autoComplete="off" />
                         <div className="error">
                              {this.state.error_email_id} 
                          </div>
                      </div>
                     
                      <div className="form-group">
                      <div className="input-group">
                            <input type={password_show_status ? "text":"password"} className="form-control" placeholder="Password" useref="password"  value={this.state.password}  onChange={(e)=>{this.setState({password:e.target.value})}} name="password" autoComplete="off" />
                            <div className="input-group-append">
                                <span className="input-group-text password_view_span">
                                 {    
                                  password_show_status ?
                                  <img src="/assets/images/not-view.png" className="password_view" onClick={()=>{this.setState({password_show_status:!password_show_status})}} />
                                  :
                                  <img src="/assets/images/view-eye-icon.png"  className="password_view" onClick={()=>{this.setState({password_show_status:!password_show_status})}} />
                                  }
                                </span>
                              </div>
                              </div>
                           <div className="error">
                                {this.state.error_password} 
                            </div>
                            
                      </div>

                     
                      <div className="form-group">
                        <input className="form-control" name="referral_id" placeholder="Referral ID (Optional)" type="text"   value={this.state.referral_id}  onChange={(e)=>{this.setState({referral_id:e.target.value})}} autoComplete="off" />
                       <div className="error">
                            {this.state.error_referral_id} 
                        </div>     
                      </div>
                      {/* <button className="auth_button"  type="button" data-toggle="modal" data-target="#ComingSoonRegister" >
                          {this.state.loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                              <>Join Community</>
                          )}
                           </button> */}
                        <div className="text-center mt-4">
                          <button className="auth_button" type="submit" onClick={()=>{this.saveRegisteredUser()}} >
                          {this.state.loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                            <>Join Community</>
                          )}
                          </button>
                        </div>
                      <h5 className="auth_below_links">Want to <Link to="/auth/login">Sign In?</Link></h5>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-12 auth_content auth_register">
                    <h3><img src="/assets/images/a-logo.svg" className="a_logo_element" />ll you need is, one account! </h3>
                    <h5>Its great to see you here, its time to learn, simplify and normalise crypto, and we are building a community of such minded folks. </h5>
                    {/* <iframe src="https://player.vimeo.com/video/490171531" width="100%" height="250"></iframe> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="modal" id="ComingSoonRegister">
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" type="button" className="close" data-dismiss="modal" >&times;</button>
                  <h2>Maintenance Mode</h2>
                  <p>Website is under maintenance. please check back again soon. Follow our twitter handle for latest updates</p>
                  <p className="text-center"><a target="_blank" href="https://twitter.com/arranonetwork"><i className="fa fa-twitter" aria-hidden="true" style={{color:'#fff'}}></i></a></p>
                </div>
              </div>
            </div>
          </div> */}
      </div>
      </>
    );
  }
}

export default Register;

