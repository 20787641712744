import React from 'react';
import {Switch, Route} from 'react-router-dom';

import List from './academy_list';
import academy_crypto_basics_you_should_know from './academy-crypto-basics-you-should-know';
import academy_how_to_get_started_with_cryptocurrency from './academy-how-to-get-started-with-cryptocurrency';
import academy_why_invest_in_cryptocurrencies from './academy-why-invest-in-cryptocurrencies';
 
import Footer from "../../layouts/Footer";

 
class Academy_Routing extends React.Component 
{   

  render() { 
    return (  
     <>
        <Switch>
              <Route path="/academy/all" component={List} />   
              <Route path="/academy/academy-crypto-basics-you-should-know" component={academy_crypto_basics_you_should_know} />    
              <Route path="/academy/academy-how-to-get-started-with-cryptocurrency" component={academy_how_to_get_started_with_cryptocurrency} />   
              <Route path="/academy/academy-why-invest-in-cryptocurrencies" component={academy_why_invest_in_cryptocurrencies} />   
        </Switch>
        <Footer />
        </>
    )
  }
}

export default Academy_Routing;
 

