import React from 'react'
import {Switch, Route} from 'react-router-dom'

import Index from './index'
import Dummy from './dummy'
import Buy_order from './Buy_order'
import Sell_order from './Sell_order'
import Index_dummy from './Index_dummy'
import { BrowserRouter as Router} from 'react-router-dom'

class P2p_routing extends React.Component 
{    
  render() { 
    return (  
     <>  
        <Switch> 
               <Route exact path="/p2p/" component={Index} />  
               <Route exact path="/p2p/buy-order/:id" component={Buy_order} />  
               <Route exact path="/p2p/dummy" component={Dummy} />  
               <Route exact path="/p2p/sell-order/:id" component={Sell_order} />
               <Route exact path="/p2p/index-dummy" component={Index_dummy} />  
        </Switch> 
     </> 
    )}
}

export default P2p_routing