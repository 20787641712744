import React from 'react';
import {Switch, Route} from 'react-router-dom';
 
import Protected from '../auth/Protected';  
import Dashboard from './Dashboard';
import Ano_funds from './Ano_funds';
import Converted_funds from './Converted_funds';
import Profile from './Settings';
import Connections from './Connections';
import Rewards from './Rewards'; 
import Bounty_program from './Bounty_program';  

import Footer from "../../layouts/Footer";

 
class Dashboard_Routing extends React.Component 
{   

  render() { 
    return (  
     <> 
        <Switch> 
          <Route path="/user/dashboard" > <Protected cmp={Dashboard}/> </Route>
          <Route path="/user/ano-funds">  <Protected cmp={Ano_funds}/> </Route> 
          <Route path="/user/profile" > <Protected cmp={Profile}/> </Route>
          <Route path="/user/connections" > <Protected cmp={Connections}/> </Route>
          <Route path="/user/rewards" > <Protected cmp={Rewards}/> </Route>
          <Route path="/user/bounty-program" > <Protected cmp={Bounty_program}/> </Route>   
          <Route path="/user/converted-funds" > <Protected cmp={Converted_funds}/> </Route>  
        </Switch>  
        <Footer /> 
     </>
    )
  }
}

export default Dashboard_Routing;
 

