import React from 'react';
import { withRouter,Link } from 'react-router-dom'; 
import * as myConstClass from '../../Constants';  
import Axios from 'axios';
import Web3 from 'web3'
import * as marketConstants from '../../constants/market_details'; 
import MetaTags from 'react-meta-tags';   
import Footer from '../../layouts/Footer';

 
class SwapStake extends React.Component {

constructor() {
    super(); 
      this.state = { 
        api_base_url:myConstClass.api_base_url,
        metaMaskNetworkAccpeted:myConstClass.metaMaskNetworkAccpeted,
        availabel_balance: "", 
        inordered_balance: "",
        trx_inordered_balance: "",
        handleModalConnection:false,
        handleModalMainNetwork: false,  
        connectFullWalletAddress : "", 
        valid_connectFullWalletAddress : "",
        selected_network : true,
        block_number: "1", 
        copy_address: false,
        bep20_wallet: false,
        andx_value: "",
        error_andx_value: "",
        error_from_address: "",
        transaction_hash: "",
        valid_transaction_hash: "",
        valid_transaction_details: "",
        user_receiving_address: "",
        valid_user_receiving_address: "", 
        marketRecords:marketConstants.records,
        selected_balance: "1",
        trxWalletBalance: "",

        eth_address: "",
        trx_address: "",  
  
        modalTrxConnErr:false,

        availabel_info_block: false,
        inorder_info_block: false,
 
        block_content: "",
        block_heading: "",

        proceed_next_step: false,

        headers: {
          "Accept":"Application/json",
          "Content-Type":"Application/json"
        },

        display_model: false,
      }

      this.gettronweb()

      this.getETHAccountDetails = this.getETHAccountDetails.bind(this)
      this.OtherAddressBal = this.OtherAddressBal.bind(this) 
      this.availabelhandleModalInfoBlock = this.availabelhandleModalInfoBlock.bind(this)  
    }  

    gettronweb()
    {    
        var parentThis = this
        if(window.tronWeb && window.tronWeb.defaultAddress.base58)
        {  
          parentThis.setState({
            trx_address:window.tronWeb.defaultAddress.base58
          })  
          this.getTrxWalletBalance()
           this.gettrcbalance(window.tronWeb.defaultAddress.base58)
        }
    }

    getTrxWalletBalance()
    {
      var thisParent = this
      if(window.tronWeb && window.tronWeb.defaultAddress.base58)
      {
          var fromAddress = window.tronWeb.defaultAddress.base58
          var tronWeb = window.tronWeb
          tronWeb.trx.getAccount(fromAddress).then(function(response)
          {
              if(response.balance != undefined)
              {
                  var realBalance = response.balance/1000000; 
                  thisParent.setState({
                    trxWalletBalance:realBalance
                  }) 
              }
          })
      }
    }
 
    componentDidMount()
    {
       this.gettronweb()  
       this.getETHAccountDetails() 
    }  

    gettrcbalance(address){  
  
      const trc_address_data = {
        trx_address: address
      }    

      Axios.post(this.state.api_base_url+"swap_fund/trx_in_order_fund" , trc_address_data, this.state.headers) 
      .then(response =>{  
        console.log(response)   
        if(response.data.status == true){  
              this.setState({trx_inordered_balance: response.data.message.trx_in_order_andx_value})
        } 
      })
    }

    getethbalance(eth_address) {

      const eth_address_data = {
        eth_address: eth_address
      } 
      
        Axios.post(this.state.api_base_url+"swap_fund/eth_in_order_fund" , eth_address_data, this.state.headers) 
        .then(response =>{  
          console.log(response) 
          if(response.data.status == true){ 
                this.setState({inordered_balance: response.data.message.eth_in_order_andx_value})
          } 
        })
    }
 
    async OtherAddressBal(eth_address)
    {
        
       this.getethbalance(eth_address)

       this.setState({eth_address: eth_address})
 
       this.setState({connectFullWalletAddress: eth_address})

       this.setState({user_receiving_address: eth_address})

      var tokenAddress = '0xeE08F393F4Cc9eA0508246fFc0D693944deE6036'
      
      var web3 =  new Web3(Web3.givenProvider)
      let abi = [ 
        {
          "constant":true,
          "inputs":[{"name":"_owner","type":"address"}],
          "name":"balanceOf",
          "outputs":[{"name":"balance","type":"uint256"}],
          "type":"function"
        },
        {
          "constant":true,
          "inputs":[],
          "name":"decimals",
          "outputs":[{"name":"","type":"uint8"}],
          "type":"function"
        }
      ]
      var contract = await new web3.eth.Contract(abi, tokenAddress)
      try {
        var getBalance = await contract.methods.balanceOf(eth_address).call()  

        this.setState({availabel_balance: getBalance/1000000000000000000}) 

        if(this.state.availabel_balance > 0){
          this.setState({andx_value: getBalance/1000000000000000000})
        } 
        return getBalance
      } catch (e) {
          console.log('Error :',e);
      }
    }

    getETHAccountDetails()
    {   
      let parentThis = this 
      if(window.web3)
      {
        let web3 = new Web3(Web3.givenProvider || parentThis.state.givenProvider)
        web3.eth.net.getNetworkType().then(function(networkName)
        { 
          if(networkName === parentThis.state.metaMaskNetworkAccpeted)
          {
            web3.eth.getAccounts().then(function(accounts)
            {   
              var first_address = accounts[0]   
              if((typeof first_address != 'undefined'))
              {  
                parentThis.OtherAddressBal(first_address)
              }
              return true
            })
          }
        })
      }
      else if(window.ethereum)
      {
        let web3 = new Web3(window.ethereum)
        web3.eth.net.getNetworkType().then(function(networkName)
        {
          if(networkName === parentThis.state.metaMaskNetworkAccpeted)
          {
            web3.eth.getAccounts().then(function(accounts)
            {  
              var first_address = accounts[0] 
              if((typeof first_address != 'undefined'))
              {   
                parentThis.OtherAddressBal(first_address)
              }
              return true
            })
          }
        })
      }  
    } 

  handleModalConnection() 
  {
    this.setState({ handleModalConnection:!this.state.handleModalConnection});
  }

  handleModalMainNetwork() 
  {
    this.setState({ handleModalMainNetwork:!this.state.handleModalMainNetwork});
  } 

  availabelhandleModalInfoBlock(data){  

    if(data == "1"){
      this.setState({block_heading: "Available balance"})
      this.setState({block_content: "This is the ANDX ERC20 balance that is already sent your Metamask / Trustwallet after purchase. (make sure you connect the same wallet you used to purchase ANDX)"}) 
    }
    else if(data == "2"){
      this.setState({block_heading: "Pending balance"})
      this.setState({block_content: "This is the ANDX ERC20 balance that is not yet sent to your wallet after purchase. (make sure you connect the same wallet you used to purchase ANDX)"}) 
    }
    else if(data == "3"){
      this.setState({block_heading: "Available balance"})
      this.setState({block_content: "This is the ANDX TRC20 balance that is already sent your Klever / Tron link wallet after purchase. (make sure you connect the same wallet you used to purchase ANDX)"}) 
    } 
    else if(data == "4"){
      this.setState({block_heading: "Pending balance"})
      this.setState({block_content: "This is the ANDX TRC20 balance that is not yet sent to your wallet after purchase. (make sure you connect the same wallet you used to purchase ANDX)"}) 
    }   
    this.setState({ availabel_info_block:!this.state.availabel_info_block});
  } 
    
  connectToWallet()
  { 
    let parentThis = this; 
    if(window.web3)
    { 
      let web3 = new Web3(Web3.givenProvider)
      web3.eth.net.getNetworkType().then(function(networkName)
      {   
        if(networkName === parentThis.state.metaMaskNetworkAccpeted)
        {
          try 
          { 
            window.ethereum.enable().then(function(res) {
              parentThis.getETHAccountDetails()
            })
          } 
          catch(e)
          {
            parentThis.handleModalConnection();
          } 
        }
        else
        {
          parentThis.handleModalMainNetwork()
        }
       
      })
    }
    else if(window.ethereum)
    {
      let web3 = new Web3(window.ethereum)
      try {
        web3.eth.net.getNetworkType().then(function(networkName)
        {
          if(networkName === parentThis.state.metaMaskNetworkAccpeted)
          {
            try 
            { 
              window.ethereum.enable().then(function(res) {
                parentThis.getETHAccountDetails()
              })
            } 
            catch(e)
            {
              parentThis.handleModalConnection();
            } 
          }
          else
          {
            parentThis.handleModalMainNetwork()
          } 
        })
      }
      catch(error) {
        console.log('ethereum error, ', error)
      } 
    }
    else
    {
      this.handleModalConnection()
    }
  }

  connecttotrc(){
 
    if(this.state.trx_address == ""){
      this.setState({ modalTrxConnErr: !this.state.modalTrxConnErr});
      this.setState({connectFullWalletAddress: ""})
    }
    else{
      this.setState({ modalTrxConnErr: false }); 
      this.setState({connectFullWalletAddress: this.state.trx_address})
    }
  }

  // DisplayModel(){ 
  //     this.setState({display_model: !this.state.display_model}) 
  // }

  proceedwitherc(){ 
     
    this.setState({error_from_address: ""})
    this.setState({error_andx_value: ""}) 

    if(this.state.connectFullWalletAddress === ""){
      this.setState({valid_connectFullWalletAddress: "Please connect the wallet to start the swap"})
    }  
    else if(this.state.selected_balance == 2){  
      if(this.state.andx_value == ""){
          this.setState({error_andx_value: "Please enter ANDX value"})
      } 
      else if(this.state.user_receiving_address == "") {
        this.setState({valid_user_receiving_address: "Please Enter the valid receiving address"})
      } 
      else {
        this.submitwitherc()
      }
    }
    else{ 

      if(this.state.andx_value == ""){
        this.setState({error_andx_value: "Please enter ANDX value"})
      } 
      else if(this.state.user_receiving_address == "") {
        this.setState({valid_user_receiving_address: "Please Enter the valid receiving address"})
      } 
      else {
        this.setState({block_number: "2"})
      }
 
    } 
  } 

  submitERCData(erc_data){ 

    this.setState({valid_transaction_hash : ""}) 
    this.setState({valid_transaction_details: ""}) 

    console.log(erc_data)

    Axios.post(this.state.api_base_url+"swap_fund/save_and_submit_eth_request", erc_data, this.state.headers) 
    .then(response =>{
       
      if(response.data.status == true){ 
        this.setState({valid_transaction_details : ""})
        this.setState({error_andx_value : ""}) 
        this.setState({valid_user_receiving_address : ""}) 
  
        this.setState({block_number: "3"}) 
      }
      else{ 
        
        this.setState({valid_transaction_details: ""})

          if(response.data.message.alert_message){
            this.setState({valid_transaction_details: response.data.message.alert_message}) 
          }
          else if(response.data.message.andx_value){
            this.setState({valid_transaction_details: response.data.message.andx_value})
          }
          else if(response.data.message.from_address){
            this.setState({valid_transaction_details: response.data.message.from_address})
          }
          else if(response.data.message.trans_hash) {
            this.setState({valid_transaction_details: response.data.message.trans_hash})
          }
      }
    })

  }

  submitwitherc(){  
    if(this.state.selected_balance == "1" ){
          const erc_data = {
            andx_balance_type: this.state.selected_balance,
            andx_value: this.state.andx_value,
            user_receive_address: this.state.user_receiving_address,
            trans_hash: this.state.transaction_hash,
            from_address: this.state.connectFullWalletAddress
          } 

          if(this.state.transaction_hash.length > 3){
                this.submitERCData(erc_data)
          }
          else{
            this.setState({valid_transaction_hash : "Please enter your minimum 4 charecters transaction hash"})
          }
        }
    else {
      const erc_data = { 
        andx_balance_type: this.state.selected_balance, 
        user_receive_address: this.state.user_receiving_address, 
        from_address: this.state.connectFullWalletAddress
      }   
      this.submitERCData(erc_data)
    } 
  } 
  
  selectederc()
  { 
    
    this.setState({valid_connectFullWalletAddress: ""})

    this.setState({
      selected_network: true, 
      block_number: "1", 
      andx_value: "", 
      transaction_hash: "", 
      error_andx_value: "", 
      valid_transaction_details: "",
      proceed_next_step: false,
      selected_balance: "1"
    });

      this.setState({valid_transaction_details : ""})
      this.setState({error_andx_value : ""}) 
      this.setState({valid_user_receiving_address : ""})

    this.connectToWallet();
  } 

 
  selectedtrc(){    

    this.setState({valid_connectFullWalletAddress: ""})
  
    this.setState({user_receiving_address: ""}) 

    this.setState({andx_value: this.state.trx_inordered_balance})
  
    this.setState({
      selected_network: false, 
      block_number: "1", 
      andx_value: this.state.availabel_balance, 
      transaction_hash: "", error_andx_value: "", 
      valid_transaction_details: "", 
      selected_balance: "2", 
      proceed_next_step: false,  
    })   
 
 
      this.setState({valid_transaction_details : ""})
      this.setState({error_andx_value : ""}) 
      this.setState({valid_user_receiving_address : ""}) 


      this.connecttotrc()
  } 

  proceedwithtrc(){    
    this.setState({valid_user_receiving_address: ""})
    this.setState({error_andx_value: ""}) 

    if(this.state.trx_address == ""){
      this.setState({valid_connectFullWalletAddress: "Please connect the wallet to start the swap"})
    }
    else{ 
     if(this.state.trx_inordered_balance == "" || this.state.trx_inordered_balance == 0){
        this.setState({error_andx_value: "You don't have enough ANDX balance to swap"})
      } 
      else if(this.state.user_receiving_address == ""){
        this.setState({valid_user_receiving_address: "Please enter the valid receiving address"})
      } 
      else{ 
        this.setState({block_number: "2"}) 
      } 
    }  
  }
 
  submitwithtrc(){   
    const erc_data = { 
      andx_balance_type: this.state.selected_balance,
      andx_value: this.state.trx_inordered_balance,
      from_address: this.state.trx_address,
      user_receive_address: this.state.user_receiving_address 
    }  
    this.setState({valid_transaction_details: ""})
    this.setState({valid_user_receiving_address: ""}) 
    this.setState({error_andx_value: ""}) 
    this.setState({valid_connectFullWalletAddress: ""})

    Axios.post(this.state.api_base_url+"swap_fund/save_and_submit_trx_request", erc_data, this.state.headers) 
    .then(response =>{  
      if(response.data.status == true){ 
        this.setState({block_number: "3"}) 
      }
      else{   
          if(response.data.message.alert_message){
            this.setState({valid_transaction_details: response.data.message.alert_message}) 
          }
          else if(response.data.message.andx_value){
            this.setState({valid_transaction_details: response.data.message.andx_value})
          }
          else if(response.data.message.from_address){
            this.setState({valid_transaction_details: response.data.message.from_address})
          }
          else if(response.data.message.trans_hash) {
            this.setState({valid_transaction_details: response.data.message.trans_hash})
          }
      }
    })  
  }
 
  CopyAddress() { 
 
    var copyText = document.getElementById("copy_swap_address");
    copyText.select();
    document.execCommand("Copy");
    this.setState({copy_address : true})
  }
 
  render()
  {       

    var parentThis = this
    if(window.tronWeb && window.tronWeb.defaultAddress.base58)
    { 
      if(this.state.trx_address != window.tronWeb.defaultAddress.base58)
      {
        parentThis.setState({
          trx_address:window.tronWeb.defaultAddress.base58
        }) 
        this.getTrxWalletBalance()
           this.gettrcbalance(window.tronWeb.defaultAddress.base58)
      }
    } 

    return (
      <> 
      
      <MetaTags>
        <title>ANDX bridge to BEP20 and TRC20 from ERC20 | 81800 total supply </title>
        <link rel="canonical" href="https://arrano.network/swap-andx" />
        <meta name="description" content="andx token is issued in BEP20 and TRC20 and this page will allow ANDX ERC20 holders to place the swap request." />
        <meta name="keyword" content="ANDX, ERC20, BEP20, TRC20, binance smart chain, BSC scan, tron scan, Arrano, ANO token, Ethereum ETH, BNB, TRX, NFT" />
        <meta property="og:title" content="ANDX bridge to BEP20 and TRC20 from ERC20 | 81800 total supply" />
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>

      <div className="stake-swap-block">
        <div className="col-md-12"> 
            <div className="row"> 
            <div className="col-md-4"></div>
                <div className="col-md-4"> 

                <div className="swap_block_model">  
                  <h2 className="main_heading">ANDX Bridge to BEP20</h2>  
                  
                    <h5 className="first_heading">Swap your ANDX from ERC20 to TRC20 and BEP20 (Binance smart chain)<br /> <br /><i><span className="blink_info">Note for holders / buyers through ETH and TRX:</span> Please connect ONLY with the same trust wallet, Metamask wallet or Tron wallet that was used to place order or holds ANDX. </i></h5> 
     
                    <div className="row">
                      <div className="col-md-6 col-6" style={{textAlign:"center", marginTop:"1px"}}>
                        <button className={"btn btn-info"+ ( this.state.selected_network ? " activetedToken swap_buttons" : " deactivetedToken swap_buttons")} type="button" onClick={()=> this.selectederc()}>{this.state.eth_address ? <>Swap from ERC</> : <>Connect ERC</>}</button>
                      </div>
                      <div className="col-md-6 col-6" style={{textAlign:"center", marginTop:"1px" }}>
                      <button className={"btn btn-info"+ ( this.state.selected_network ? " deactivetedToken swap_buttons" : " swap_trc_20_button swap_buttons")} type="button" onClick={()=> this.selectedtrc()}>{this.state.trx_address ? <>Swap from TRC</> : <>Connect TRC</>}</button>
                      </div>
                    </div> 
  
                  {
                    this.state.connectFullWalletAddress != ""
                    ?  
                    <>
                      {
                        this.state.selected_network == true && this.state.eth_address
                        ? 
                        <>
                         <h6 className="connected-address"><u>Connected ERC20 Address </u></h6>  
                          <h6 className="swap-connected-address">{this.state.eth_address}</h6>   

                        </>
                            
                        :
                        this.state.selected_network == false && this.state.trx_address
                        ? 
                        <>

                          <h6 className="connected-address"><u>Connected TRC20 Address </u></h6>  
                          <h6 className="swap-connected-address">{this.state.trx_address}</h6>       

                        </>
                        :
                        null  
                      }
                    </> 
                    :  
                    null 
                  }

                  {
                    this.state.block_number == "1" && this.state.selected_network == true
                    ?
                    <div className={this.state.selected_network ? "main-swap-block selected_token_erc" : "main-swap-block selected_token_trc"}> 
 
                        <div className="swap_block">  
                            <div className="col-md-12">
                              <div className="row"> 
                                  <div className="col-md-6"> 
                                  <label>
                                    {
                                      this.state.availabel_balance == "" ||  this.state.availabel_balance == 0
                                      ?
                                      <input  style={{marginRight: "5px"}} type="radio" className="swaped_details"  name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "1"})} disabled/>
                                      :
                                      <input  style={{marginRight: "5px"}} type="radio" className="swaped_details" defaultChecked name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "1", andx_value : this.state.availabel_balance})} />
                                    }
                                  Available balance  <i className="fa fa-info-circle" onClick={()=> this.availabelhandleModalInfoBlock("1")} title="avilabel balance" aria-hidden="true"></i> 
                                  <br />  
                                  <span className="swap_wallet_balance">{this.state.availabel_balance ? <> {this.state.availabel_balance}  </>: "0"} ANDX</span>
                                  </label>
  
                                 
                                  </div>
                                    <div className="col-md-6">
                                    <label>
                                    {
                                      this.state.inordered_balance == "" ||  this.state.inordered_balance == 0
                                      ?
                                      <input style={{marginRight: "5px"}} type="radio" className="swaped_details" name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "2"})} disabled/>
                                      :
                                      <input style={{marginRight: "5px"}} type="radio" className="swaped_details" name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "2", andx_value : this.state.inordered_balance})} />
                                    }
                                  In order balance  <i className="fa fa-info-circle" onClick={()=> this.availabelhandleModalInfoBlock("2")} title="in order balance" aria-hidden="true"></i>
                                  
                                  <br />  
                                  <span className="swap_wallet_balance">{this.state.inordered_balance ? <> {this.state.inordered_balance}  </>: "0"} ANDX</span>

                                  </label> 
                                  </div>
  
                              </div>    
                            </div>  
                        </div>

                        <div className="mt-2 text-center">
                          <div className="error swap-error-msg">
                          {this.state.valid_connectFullWalletAddress ? this.state.valid_connectFullWalletAddress : null}
                          </div> 
                        </div>
                          
                        <div className="swap_block">
                            <h5 className="swaping_assets">Swap Value</h5>
                            <div className="input-group mb-2">
                              {
                                this.state.selected_balance == "1"
                                ?
                                <input type="number" className="form-control wallet_type" placeholder="Enter ANDX value" value={this.state.andx_value ? this.state.andx_value : ""} id="andx_value" name="andx_value" readOnly/>
                                :
                                <input type="number" className="form-control wallet_type" placeholder="Enter ANDX value" value={this.state.andx_value ? this.state.andx_value : ""} id="andx_value" name="andx_value" readOnly/>
                              } 
                              <div className="input-group-append">
                                <span className="input-group-text wallet_img">ANDX</span>
                              </div>
                            </div>

                            <div className="input-group mb-1">
                              <div className="error">
                                {this.state.error_andx_value} 
                              </div> 
                            </div>  

                            <h5 className="swaping_assets">Enter Receiving BEP20 Address </h5> 

                            <div className="input-group mb-1"> 
                              <input type="text" onChange={(e)=> this.setState({user_receiving_address: e.target.value})} value={this.state.user_receiving_address ? this.state.user_receiving_address : ""} className="form-control wallet_type" placeholder="Please enter your receiving address" id="user_receiving_address" name="user_receiving_address"/> 
                            </div> 

                            <div className="input-group mb-1">
                              <div className="error"> 
                                    {this.state.valid_user_receiving_address} 
                              </div> 
                            </div>  

                            <h6 className="stake_condition"><span style={{fontSize: "14px"}}>Does this wallet address support BEP20 tokens ? </span></h6>
                            <h6 className="stake_condition">
                              <span>Please provide ONLY BEP20 token supported wallet address. we recommend trust wallet, Metamask or any BEP20 supported wallets. You will lose the token  if unsupported wallet address is provided</span> <br />
                            </h6>
                            <h6 className="how_to_block" >
                              {
                                this.state.proceed_next_step
                                ?
                                <>
                                <input type="checkbox" checked  onClick={()=> this.setState({proceed_next_step: false})} /> I have added the ANDX BEP20 address to my wallet. <a href="https://arrano.medium.com/how-to-add-the-andx-binance-chain-token-to-the-wallet-131d86745b5" target="_blank" className="how_to_content">HOW TO?</a>
                                </>
                                :
                                <>
                                <input type="checkbox" onClick={()=> this.setState({proceed_next_step: true})} /> I have added the ANDX BEP20 address to my wallet. <a href="https://arrano.medium.com/how-to-add-the-andx-binance-chain-token-to-the-wallet-131d86745b5" target="_blank" className="how_to_content">HOW TO?</a>
                                </>
                              }
                            </h6> 
                        </div>

                        <div className="mb-1">
                              <div className="error">
                                  {this.state.valid_transaction_details} 
                              </div>   
                            </div> 
                            {
                                this.state.proceed_next_step
                                ?
                                <button className="btn btn-primary swap_stake_button" name="update_personal"  type="button" onClick={()=>{this.proceedwitherc()}} > {this.state.selected_balance == "2" ? <>I Have Sent ANDX</> : <>Proceed Next</> } </button>
                                :
                                <button style={{opacity: 0.5}} className="btn btn-primary swap_stake_button" name="update_personal"  type="button" > {this.state.selected_balance == "2" ? <>I Have Sent ANDX</> : <>Proceed Next</> } </button>
                            }

                            {
                              this.state.valid_connectFullWalletAddress 
                              ?
                              <div className="mt-3 text-center">
                                <div className="error swap-error-msg">
                                {this.state.valid_connectFullWalletAddress}
                                </div> 
                              </div>
                              :
                              null 
                            }

                            {
                              this.state.connectFullWalletAddress != "" || this.state.trx_address != ""
                              ?
                              <div> <Link to="/swap-transactions" className="how_to_content"><u>View my transaction history</u></Link> </div>
                              :
                              null
                            } 
                      </div> 
                      :
                      this.state.block_number == "1" && this.state.selected_network == false
                    ?
                      <div className={this.state.selected_network ? "main-swap-block selected_token_erc" : "main-swap-block selected_token_trc"}> 
                           <div className="swap_block">  
                            <div className="col-md-12">
                              <div className="row"> 
                                  {/* <div className="col-md-6"> 
                                  <label>
                                    {
                                      this.state.trxWalletBalance == "" ||  this.state.trxWalletBalance == 0
                                      ?
                                      <input type="radio" className="swaped_details"  name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "1"})} disabled/>
                                      :
                                      <input type="radio" className="swaped_details" defaultChecked name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "1", andx_value : this.state.trxWalletBalance})}  disabled/>
                                    }
                                  Available balance  <i className="fa fa-info-circle" onClick={()=> this.availabelhandleModalInfoBlock("3")} title="avilabel balance" aria-hidden="true"></i>
                                  </label>
  
                                  <h5 className="swap_wallet_balance">{this.state.trxWalletBalance ?  this.state.trxWalletBalance : "0"} ANDX </h5>
                                  </div> */}
                                    {/* <div className="col-md-6"> */}

                                    <div className="col-md-12">
                                    <label>
                                    {
                                      this.state.trx_inordered_balance == "" ||  this.state.trx_inordered_balance == 0
                                      ?
                                      <input style={{marginRight: "5px"}} type="radio" className="swaped_details" name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "2"})} disabled/>
                                      :
                                      <input style={{marginRight: "5px"}} type="radio" className="swaped_details" checked name="selected_balance" value={this.state.selected_balance} onClick={()=> this.setState({selected_balance: "2", andx_value : this.state.trx_inordered_balance})} />
                                    }
                                    In order balance  <i className="fa fa-info-circle" onClick={()=> this.availabelhandleModalInfoBlock("4")} title="in order balance" aria-hidden="true"></i>
                                  </label>
                                  <br />

                                  <h5 className="swap_wallet_balance">
                                    {this.state.trx_inordered_balance == "" ||  this.state.trx_inordered_balance == 0  ? "0" : this.state.trx_inordered_balance} ANDX
                                    </h5>
                                  </div>
  
                              </div>    
                            </div>  
                        </div>
                        <div className="mt-2 text-center">
                          <div className="error swap-error-msg">
                          {this.state.valid_connectFullWalletAddress ? this.state.valid_connectFullWalletAddress : null}
                          </div> 
                        </div>
                          
                        <div className="swap_block">
                            <h5 className="swaping_assets">Swap Value</h5>
                            <div className="input-group mb-1"> 
                              <input type="number" className="form-control wallet_type" placeholder="Enter ANDX value" value={this.state.trx_inordered_balance == "" ||  this.state.trx_inordered_balance == 0  ? "" : this.state.trx_inordered_balance} id="andx_value" name="andx_value" readOnly/>
                              <div className="input-group-append">
                                <span className="input-group-text wallet_img">ANDX</span>
                              </div>
                            </div>  

                            <div className="input-group mb-1">
                              <div className="error">
                                {this.state.error_andx_value} 
                              </div> 
                            </div>  

                            <h5 className="swaping_assets">Receiving Address</h5>
                            <div className="input-group mb-1"> 
                            {
                              this.state.trx_inordered_balance == "" ||  this.state.trx_inordered_balance == 0
                              ?
                              <input type="text" onChange={(e)=> this.setState({user_receiving_address: e.target.value})} value={this.state.user_receiving_address ? this.state.user_receiving_address : ""} className="form-control wallet_type" placeholder="Please enter your receiving address" id="user_receiving_address" name="user_receiving_address" readOnly/> 
                              :
                              <input type="text" onChange={(e)=> this.setState({user_receiving_address: e.target.value})} value={this.state.user_receiving_address ? this.state.user_receiving_address : ""} className="form-control wallet_type" placeholder="Please enter your receiving address" id="user_receiving_address" name="user_receiving_address"/> 
 
                            }
                            </div> 

                            <div className="input-group mb-1">
                              <div className="error"> 
                                    {this.state.valid_user_receiving_address} 
                              </div> 
                            </div>  
                        </div> 
                           
                        <div className="input-group mb-1">
                              <div className="error">
                                  {this.state.valid_transaction_details} 
                              </div>   
                            </div> 

                            <h6 className="stake_condition"><span style={{fontSize: "14px"}}> Important : Use only TronLink Pro Wallet Address as 'receiving' address. </span></h6>
                            <h6 className="stake_condition">
                              <span>You will loose the ANDX funds if you provide klever wallet or other tronwallet address as they do not support ANDX TRC20 now.</span> <br />
                            </h6> 
                            <button className="btn btn-primary swap_stake_button" name="update_personal"  type="button" onClick={()=>{this.proceedwithtrc()}}> Proceed Next  </button>
  
                        <div className="mt-2 text-center">
                          <div className="error swap-error-msg">
                          {this.state.valid_connectFullWalletAddress ? this.state.valid_connectFullWalletAddress : null}
                          </div> 
                        </div> 
                            {
                              this.state.connectFullWalletAddress != "" || this.state.trx_address != ""
                              ?
                              <div> <Link to="/swap-transactions" className="how_to_content"><u>View my transaction history</u></Link> </div>
                              :
                              null
                            } 
                      </div> 
                      : 
                      this.state.block_number == "2" && this.state.selected_network == true
                      ? 
                      <div className={this.state.selected_network ? "main-swap-block selected_token_erc" : "main-swap-block selected_token_trc"}> 

                        <h6 style={{textAlign: "right", paddingRight: "6px"}} onClick={()=> this.setState({block_number: "1"})} ><i className="fas fa-arrow-left"></i> &nbsp; Back </h6>
 
                          <div className="swap_block">


                            <h5 className="swaped_details">Send ERC20 ANDX to below address : </h5>
                              <img className="mb-4" src="/assets/images/QR code.png" width="50%" />

                              <h5 className="swaped_details swaped_second_details">0x11667A7dEC0545d18F513091092528535cD38E77</h5> 
                              <h5><u><a className="swap_copy_link" onClick={()=>{this.CopyAddress()}}>{this.state.copy_address ? "Copied" : "Copy"}</a></u></h5>

                              <input type="text" id="copy_swap_address" style={{height:'0', padding: '0', border: '0'}} value="0x11667A7dEC0545d18F513091092528535cD38E77" />
                          </div>
                          
                            <div className="swap_block">
                                <h5 className="swaping_assets">Enter the Transaction hash</h5>
                                <div className="input-group mb-1"> 
                                  <input type="text" className="form-control wallet_type" placeholder="Enter the Transaction hash" onChange={(e)=> this.setState({transaction_hash: e.target.value})} value={this.state.transaction_hash ? this.state.transaction_hash : ""} id="transaction_hash" name="transaction_hash" /> 
                                </div> 

                                <div className="input-group mb-1">
                                  <div className="error">
                                      {this.state.valid_transaction_hash} 
                                  </div>   
                                </div>    
                            </div>
 
                           <div className="input-group mb-1">
                              <div className="error">
                                  {this.state.valid_transaction_details} 
                              </div>   
                            </div>  
                            

                           <button className="btn btn-primary swap_stake_button" name="update_personal"  type="button" onClick={()=> this.submitwitherc()} > I Have Sent ANDX </button>
                           
 
                      </div> 
                      :
                      this.state.block_number == "3" && this.state.selected_network == true
                      ?
                      <div className={this.state.selected_network ? "main-swap-block selected_token_erc" : "main-swap-block selected_token_trc"}> 
 
                          <div className="swap_block">

                              <h5><i style={{fontSize: "40px"}} className="far fa-check-circle" aria-hidden="true"></i></h5>
 
                            <h5 className="mb-2 request_succees_text" >Swap request placed successfully <br /></h5> 
                            <h5 className="swaped_details swaped_second_details swap_success_conetnt">It will take 12 hours to verify and swap your ERC20 ANDX token to BEP20 token. For further queries please visit <span style={{color: "#0095eb"}}><a style={{color: "#0095eb"}} target="_blank" href="https://t.me/arranonetwork">here</a></span>  </h5> <br />
                           
                            <h6 className="integrated_bep swap_success_conetnt"> MAKE SURE you have added the ANDX BEP20 address to your Metamask / Trustwallet (address you have provided to receive BEP20 ANDX). you will receive swapped ANDX tokens only if you add the address to wallet. <a style={{color: "#0095eb"}} target="_blank" href="https://arrano.medium.com/how-to-add-the-andx-binance-chain-token-to-the-wallet-131d86745b5">how to add?</a> </h6>
                          
                          </div> 

                          <Link to="/swap-transactions"> <button className="btn btn-primary swap_stake_button" name="update_personal"  type="button" >View my transaction history </button></Link>
                      </div>   
                      :
                      this.state.block_number == "2" && this.state.selected_network == false
                      ? 
                      <div className={this.state.selected_network ? "main-swap-block selected_token_erc" : "main-swap-block selected_token_trc"}> 
                      <h5><i  style={{fontSize: "40px"}} className="fa fa-question-circle" aria-hidden="true"></i></h5>
                        <h6 className="swaped_details confirmation_block">Confirmation</h6>  
 
                          <div className="swap_block">  
                            
                            <h5 className="swaped_details confirmation_values">Available balance : {this.state.trx_inordered_balance ? this.state.trx_inordered_balance : 0} ANDX</h5>   
                            <h5 className="swaped_details confirmation_values">Issued on TRC20 <a href="https://tronscan.org/#/token20/TCdvet6k6PdtiNd2YDVPAexDP11T7PZowc" target="_blank" className="how_to_content">(TronScan)</a></h5>  
                            <h5 className="swaped_details confirmation_values">Swaping value :  {this.state.trx_inordered_balance ? this.state.trx_inordered_balance : 0} ANDX</h5>  
                            <h5 className="swaped_details confirmation_values">Receiving address :  {this.state.user_receiving_address ? this.state.user_receiving_address : ""}</h5> 

                            <h6 className="stake_condition"><span style={{fontSize: "14px"}}>Important : Use only TronLink Pro Wallet Address as 'receiving' address.</span></h6>
                            <h6 className="stake_condition">
                              <span>You will loose the ANDX funds if you provide klever wallet or other tronwallet address as they do not support ANDX TRC20 now.</span> <br />
                            </h6>
                            <h6 className="how_to_block" >
                              {
                                this.state.proceed_next_step
                                ?
                                <>
                                <input type="checkbox" checked  onClick={()=> this.setState({proceed_next_step: false})} /> I have added the ANDX TRC20 address to my wallet <a href="https://arrano.medium.com/tutorial-swap-andx-to-trc20-andx-token-daa7d72721e0" target="_blank" className="how_to_content">HOW TO?</a>
                                </>
                                :
                                <>
                                <input type="checkbox" onClick={()=> this.setState({proceed_next_step: true})} /> I have added the ANDX TRC20 address to my wallet. <a href="https://arrano.medium.com/tutorial-swap-andx-to-trc20-andx-token-daa7d72721e0" target="_blank" className="how_to_content">HOW TO?</a>
                                </>
                              }
                            </h6> 
 
                          </div>  
                          
                          <div className="input-group mb-1">
                              <div className="error">
                                  {this.state.valid_transaction_details} 
                              </div>   
                            </div>  

                            {
                                this.state.proceed_next_step
                                ?
                                <button className="btn btn-primary swap_stake_button" name="update_personal"  type="button" onClick={()=> this.submitwithtrc()} > Submit </button>
                                :
                                <button style={{opacity: 0.5}} className="btn btn-primary swap_stake_button" name="update_personal"  type="button" > Submit </button>
                            } 
                          
                      </div> 
                      :
                      this.state.block_number == "3" && this.state.selected_network == false
                      ?
                      <div className={this.state.selected_network ? "main-swap-block selected_token_erc" : "main-swap-block selected_token_trc"}> 
 
                          <div className="swap_block">

                              <h5><i style={{fontSize: "40px"}} className="far fa-check-circle" aria-hidden="true"></i></h5>

                              <h5 className="mb-2 request_succees_text" >Swap request placed successfully <br /></h5> 
                            <h5 className="swaped_details swaped_second_details swap_success_conetnt">It will take 12 hours to verify and send your TRC20 ANDX token to provided TronLink wallet address. For further queries please visit <span style={{color: "#0095eb"}}><a style={{color: "#0095eb"}} target="_blank" href="https://t.me/arranonetwork">here</a></span>  </h5> <br />
                           
                            <h6 className="integrated_bep swap_success_conetnt"> MAKE SURE you have added the ANDX TRC20 address to TronLink wallet (address you have provided to receive ANDX). you will receive swapped ANDX tokens only if you add the address to wallet. <a style={{color: "#0095eb"}} target="_blank" href="https://arrano.medium.com/how-to-add-the-andx-binance-chain-token-to-the-wallet-131d86745b5">how to add?</a> </h6>
                          
                            
                            </div> 
 
                           <Link to="/swap-transactions"> <button className="btn btn-primary swap_stake_button" name="update_personal"  type="button" >View my transaction history </button></Link>

                      </div>  
                      :
                      null 
                  } 
                </div> 
                </div>
            </div> 

        </div>

        <button id="ComingSoonTRC20button" style={{display: "none"}} data-toggle="modal" data-backdrop="static" data-keyboard="false"  data-target="#ComingSoonTRC20"></button>

         <div className="modal" id="ComingSoonTRC20">
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" className="close" data-dismiss="modal" >&times;</button>
                  <h2>Maintenance Mode</h2>
                  <p>Website is under maintenance. please check back again soon. Follow our twitter handle for latest updates</p>
                  <p className="text-center"><a target="_blank" href="https://twitter.com/arranonetwork"><i className="fa fa-twitter" aria-hidden="true" style={{color:'#fff'}}></i></a></p>
                </div>
              </div>
            </div>
          </div>

        <div className={"modal  modal_main_popup" + (this.state.availabel_info_block ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-sm">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button" className="close" data-dismiss="modal"  onClick={()=> this.availabelhandleModalInfoBlock()}>&times;</button>
                <h2 style={{fontSize: '40px'}}><i className="fa fa-info-circle" aria-hidden="true"></i> </h2> 
                  <h2>{this.state.block_heading}</h2>
                   <p>{this.state.block_content}</p>
              </div>
            </div>
          </div>
        </div>   

        <div className={"modal  modal_main_popup" + (this.state.modalTrxConnErr ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-sm">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button" className="close" data-dismiss="modal" onClick={()=> {this.connecttotrc()}}>&times;</button>
                <h2 style={{fontSize: '40px'}}><i className="far fa-times-circle"></i></h2>
                <h2>Please connect same Tron Wallet!</h2>
                <p>To place this order connect the same 'Klever wallet' or 'TronLink wallet' that was used to purchase the ANDX token.</p>
              </div>  
            </div>
          </div>
        </div> 

                   {/* Install Metamask - Starts here*/}
          <div className={"modal  modal_main_popup" + (this.state.handleModalConnection ? " show d-block" : " d-none")}>
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" className="close" data-dismiss="modal" onClick={()=> {this.handleModalConnection()}}>&times;</button>
                  <h2 style={{fontSize:'40px'}} ><i className="far fa-times-circle" aria-hidden="true"></i></h2>
                  <h2>Connection Error!</h2>
                  <p>Please connect to the same ETH / TRX wallet that was use to buy ANDX in order to place swap request.</p>
                </div>
              </div>
            </div>
          </div>

          <div className={"modal  modal_main_popup" + (this.state.handleModalMainNetwork ? " show d-block" : " d-none")}>
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" className="close" data-dismiss="modal" onClick={()=>{this.handleModalMainNetwork()}}>&times;</button>
                  <h2 style={{fontSize:'40px'}} ><i className="far fa-times-circle" aria-hidden="true"></i></h2>
                  <h2>Connection Error!</h2>
                  <p>You are connected to an unsupported network.</p>
                </div>
              </div>
            </div>
          </div> 
 
          <div className={"modal  modal_main_popup" + (this.state.display_model ? " show d-block" : " d-none")}>
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" className="close" data-dismiss="modal" onClick={()=>{this.DisplayModel()}}>&times;</button>
                  <h2 style={{fontSize:'40px'}} ><i className="fa fa-info-circle" aria-hidden="true"></i></h2> 
                  <p>We are Updating the data into System to perform swap operations, Currently the Interface ( design ) is live, please wait for the activation announcement to start Swapping. </p>
                </div>
              </div>
            </div>
          </div>


    </div> 

    <Footer />
    </>
    );
  }
}

export default withRouter(SwapStake);
