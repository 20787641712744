import React from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import {api_base_url, get_config, cookieDomainExtension} from '../../Constants';
import MetaTags from 'react-meta-tags';

class Forgot_password extends React.Component {

  constructor(props) 
  {
      super(props);
      this.state = {
          alert_message:null,
          login_id:"",
          error_login_id:"",
          forgot_password_sess_id:"",
          forgot_password_sess_email_id:"",
          forgot_password_date_n_time:"",
          headers: {
            "Accept":"Application/json",
            "Content-Type":"Application/json"
          },
          loader:false
      }

      Cookies.remove('forgot_unique_id');
      Cookies.remove('forgot_verify_code');
      Cookies.remove('forgot_alert_message');
      Cookies.remove('forgot_alert_message');
      Cookies.remove('forgot_password_sess_id');
      Cookies.remove('forgot_password_sess_email_id');
      Cookies.remove('forgot_password_date_n_time');
  }
 
 
  forgotFun()
  { 
    // return false;
      let formIsValid = true;

      if(!this.state.login_id) 
      {
          this.setState({
            error_login_id:"The Login ID field is required."
          });
          formIsValid = false;
      }
      else
      {
          this.setState({
              error_login_id:""
          });
      }

      if(!formIsValid)
      {
          return true;
      }
    
    this.setState({
        loader:true
    });

    const user_data = {
      login_id: this.state.login_id
    }
    const config = get_config("")  
    fetch(api_base_url+"forgot_password/start_forgot", {
        method:'POST',
        headers: config.headers,
        body:JSON.stringify(user_data)
    }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
            loader:false
        });

        if(result.status === true) 
        {   
              Cookies.set('forgot_password_sess_id', result.message.forgot_password_sess_id,{domain:cookieDomainExtension});
              Cookies.set('forgot_password_sess_email_id', result.message.forgot_password_sess_email_id,{domain:cookieDomainExtension});
              Cookies.set('forgot_password_date_n_time', result.message.forgot_password_date_n_time,{domain:cookieDomainExtension});
              Cookies.set('forgot_alert_message', result.message.alert_message,{domain:cookieDomainExtension});
           
              this.props.history.push('/auth/forgot-password-otp');
        }
        else
        {   
            
            if(result.message.alert_message)
            {
              var var_alert_message = result.message.alert_message;
            }
            else
            {
              var var_alert_message = "";
            }

            if(result.message.login_id)
            {
              var var_error_login_id = result.message.login_id;
            }
            else
            {
              var var_error_login_id = "";
            }

           



            this.setState({
                alert_message:var_alert_message,
                error_login_id:var_error_login_id,
                login_id:""
            });
        }
        
        });
  }

  componentDidMount(){
    if(Cookies.get('token'))
    {
        this.props.history.push('/user/dashboard');
    } 
  }

  
  render()
  {
    return (
      <div> 
        <MetaTags>
          <title>Reset Login Password | Arrano Network </title>
           <link rel="canonical" href="https://arrano.network/forgot-password" />
          <meta name="description" content="Reset your password on arrano - Find out more on how you can recover your password and get further help." />
          <meta name="keyword" content="arrano password reset , ANO token password reset , Arrano network password reset" />
          <meta property="og:title" content="Reset Login Password | Arrano Network  " />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        <section className="auth_section">
          
          <div className="container">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-12 auth_form">
                        <div className="submit_form">
                        <h1>Forgot Password ?</h1>
                        <p>Please enter your registered email ID and we will send you the Password recovery link or OTP .</p>
                        <div className="border_bottom_auth"></div>
                          {/* {this.state.alert_message ? (
                          <div className="alert alert-primary">
                            {this.state.alert_message}
                          </div>
                          ) : (
                              null
                          )}  */}
                        <div className="form-group">
                          <input type="email" className="form-control" placeholder="Username or Email"  value={this.state.login_id}  onChange={(e)=>{this.setState({login_id:e.target.value})}} name="login_id"  />
                          <div className="error">
                          {this.state.error_login_id}  {this.state.alert_message}
                          </div>
                        </div> 
                       
                        
                        {/* <button className="auth_button"  type="button" data-toggle="modal" data-target="#ComingSoonForgotPassword" >
                          {this.state.loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                              <>Receive OTP</>
                          )}
                           </button> */}
                        <div className="text-center mt-4">
                          <button className="auth_button"  type="submit" onClick={()=>{this.forgotFun()}} >
                          {this.state.loader ? (
                            <span className="spinner-border spinner-border-sm mr-1"></span> 
                            ) : (
                              <>Receive OTP</>
                          )} 
                          </button>
                        </div>
                        <h5 className="mt-3 auth_below_links">
                          <Link to="/auth/login"  >Log In?</Link>
                        </h5>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-9 auth_content mt-3">
                      <h5>The new impact of blockchain</h5>
                      <h3>Arrano Projects will make a Difference. </h3>
                      <h5>Turning the real world ideas into decentralized reality of blockchain </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="modal" id="ComingSoonForgotPassword">
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" type="button" className="close" data-dismiss="modal" >&times;</button>
                  <h2>Maintenance Mode</h2>
                  <p>Website is under maintenance. please check back again soon. Follow our twitter handle for latest updates</p>
                  <p className="text-center"><a target="_blank" href="https://twitter.com/arranonetwork"><i class="fa fa-twitter" aria-hidden="true" style={{color:'#fff'}}></i></a></p>
                </div>
              </div>
            </div>
          </div> */}
      </div> 
    );
  }
}

export default Forgot_password;
