import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Link, withRouter } from 'react-router-dom'
import Moment from 'react-moment'
import {api_base_url, get_config,p2p_currency, equi_p2p_currency,cookieDomainExtension,getShortWalletAddress} from '../../Constants'
import Axios from 'axios';
import Popupmodal from '../../../components/layouts/popupmodal'
import Timer from 'react-compound-timer';
import JsCookie from 'js-cookie';
import moment from 'moment'
import { useHistory } from "react-router-dom"


// buy_request_row_id
// txn_trading_row_id
// deposited_bank_ref_id
// p2p_trading/buy_order_deposit_detail

export default function Index()
{
  const router = useHistory()
  var post_slug
  post_slug = window.location.pathname.substring(1)
  post_slug = post_slug.substring(post_slug.lastIndexOf("/"))
  post_slug = post_slug.replace(/\//,"")
  const [buy_usdt_value, set_buy_usdt_value] = useState("")
  const [buy_request_row_id, set_buy_request_row_id] = useState(post_slug)
  const [buy_request_loader, set_buy_request_loader] = useState(false)
  const [canel_order_loader, set_canel_order_loader] = useState(false)
  const [buy_request_id, set_buy_request_id] = useState("")
  const [buy_status, set_buy_status] = useState("")
  const [receiving_amount, set_receiving_amount] = useState("")
  const [rate_for_one_usdt, set_rate_for_one_usdt] = useState("")
  const [date_n_time, set_date_n_time] = useState("")
  const [request_status, set_request_status] = useState("")
  const [completed_percentage, set_completed_percentage] = useState(0)
  const [assigned_usdt_value, set_assigned_usdt_value] = useState("")
  
  const [buy_request, set_buy_request] = useState([])
  const [referral_reward_value, set_referral_reward_valuee] = useState(null)
  
  const [recieve_payment_type, set_recieve_payment_type] = useState("")
  const [bank_name, set_bank_name] = useState("")
  const [bank_holder_name, set_bank_holder_name] = useState("")
  const [bank_acc_number, set_bank_acc_number] = useState("")
  const [bank_branch, set_bank_branch] = useState("")
  const [bank_ifsc_code, set_bank_ifsc_code] = useState("")
  const [upi_id, set_upi_id] = useState("")
  const [present_date_n_time, set_present_date_n_time] = useState("")
  const [added_date_n_time, set_added_date_n_time] = useState("")
  const [completed_buy_usdt_value, set_completed_buy_usdt_value] = useState("")
  const [cancelled_buy_usdt_value, set_cancelled_buy_usdt_value] = useState("")
  const [pending_buy_usdt_value, set_pending_buy_usdt_value] = useState("")
  const [total_usdt_value, set_total_usdt_value] = useState("")
  const [total_amount, set_total_amount] = useState("")
  const [transactions_list, set_transactions_list] = useState([])
  const [contract_copy_status, set_contract_copy_status] = useState("")
  const [modalState, set_modalState] = useState("")
  const [deposited_bank_ref_id, set_deposited_bank_ref_id] = useState("")
  const [display_model, set_display_model] = useState(false)
  const [alert_message, set_alert_message] = useState(false)
  const [cancel_status, set_cancel_status] = useState(false)
  const [transaction_status, set_transaction_status] = useState(false)
  const [modal_data, setModalData] = useState({ icon: "", title: "", content: "" })
  const [timer_expire_on, set_timer_expire_on] = useState("")
  const [wallet_address] = useState(JsCookie.get('local_wallet_address',{domain:cookieDomainExtension}))
  const [all_assigned_cancelled, set_all_assigned_cancelled] = useState("")

  const [payment_details_modal_status, set_payment_details_modal_status] = useState("")
  const [payment_details, set_payment_details] = useState("")
  const [copy_status, set_copy_status] = useState("")
  
  
  // submit transaction id
  const [transaction_id, set_transaction_id] = useState("")

  const [err_deposited_bank_ref_id, set_err_deposited_bank_ref_id] = useState("")
  
  
  useEffect(() => 
  { 
    individualDetails()
  }, [])

 const copyContract=(data)=>
  {
    var copyText = document.createElement("input")
    copyText.value = data
    document.body.appendChild(copyText)
    copyText.select()
    document.execCommand("Copy")
    copyText.remove()
    setTimeout(() => set_contract_copy_status(""), 3000)
  }


  const individualDetails = async ()=>
  {
    const res_data = await Axios.get(api_base_url+"p2p_trading/buy_individual_view/"+buy_request_row_id, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_data.data.status)
    {
      console.log(res_data)
      set_buy_request(res_data.data.message.buy_request)
      set_assigned_usdt_value(res_data.data.message.buy_request.assigned_usdt_value)
      set_buy_status(res_data.data.message.buy_request.buy_status)
      set_buy_usdt_value(res_data.data.message.buy_request.buy_request_usdt_value)
      set_buy_request_id(res_data.data.message.buy_request.buy_request_id)
      set_rate_for_one_usdt(res_data.data.message.buy_request.buy_rate_for_one_usdt)
      set_date_n_time(res_data.data.message.buy_request.date_n_time)
      set_completed_buy_usdt_value(res_data.data.message.buy_request.completed_buy_usdt_value)
      set_cancelled_buy_usdt_value(res_data.data.message.buy_request.cancelled_buy_usdt_value)
      set_pending_buy_usdt_value(res_data.data.message.buy_request.pending_buy_usdt_value)
      set_request_status(res_data.data.message.buy_request.request_status)
      set_completed_percentage(res_data.data.message.buy_request.completed_percentage)
      set_total_usdt_value(res_data.data.message.buy_request.total_usdt_value)
      set_total_amount(res_data.data.message.buy_request.total_amount)
      set_present_date_n_time(res_data.data.message.buy_request.present_date_n_time)
      set_all_assigned_cancelled(res_data.data.message.buy_request.all_assigned_cancelled)
      
      
      if(res_data.data.message.transactions)
      {
        set_transaction_status(true)
        set_transactions_list(res_data.data.message.transactions)

        set_recieve_payment_type(res_data.data.message.transactions.payment_type)
        set_bank_name(res_data.data.message.transactions.bank_name)
        set_bank_holder_name(res_data.data.message.transactions.bank_holder_name)
        set_bank_acc_number(res_data.data.message.transactions.bank_acc_number)
        set_bank_ifsc_code(res_data.data.message.transactions.bank_ifsc_code)
        set_upi_id(res_data.data.message.transactions.upi_id)
      }
      
    }
    else
    {
      router.push("/p2p")
    }
  }

  const paymentDetails = async (item)=>
  {
    set_payment_details_modal_status(true)
    set_payment_details(item)
  }

  const clearPaymentDetails = async ()=>
  {
    set_payment_details_modal_status(false)
    set_payment_details("")
  }

  const copyValue = (copy_status, data)=>
  {
    set_copy_status(copy_status)
    var copyText = document.createElement("input")
    copyText.value = data
    document.body.appendChild(copyText)
    copyText.select()
    document.execCommand("Copy")
    copyText.remove()
    // setTimeout(3000)
    setTimeout(() => set_copy_status(""), 3000)
  }
  

  const CancelOrder = async ()=>
  {
    
    set_display_model(false)
    const res_data = await Axios.get(api_base_url+"p2p_trading/cancel_your_buy_order/"+buy_request_row_id, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_data.data.status)
    {
      console.log(res_data)
      setModalData({icon:true, title:"Order Cancelled!", content:res_data.data.message.alert_message})
      set_cancel_status(true)
      individualDetails()
    }
    else
    {
      setModalData({icon:true, title:"Oops!", content:res_data.data.message.alert_message})
    }
  }
  
  const submitDepositDetails= async (txn_trading_row_id)=>
  {
    set_transaction_id(txn_trading_row_id)
      let formIsValid = true
      set_err_deposited_bank_ref_id("")
      if(!deposited_bank_ref_id) 
      {
        set_err_deposited_bank_ref_id("The Deposited Ref ID field is required.")
        formIsValid = false
      }
     
      if(!formIsValid)
      {
        return true
      }
      set_buy_request_loader(true)

      const reqObj = {
        buy_request_row_id:buy_request_row_id,
        txn_trading_row_id: txn_trading_row_id,
        deposited_bank_ref_id:deposited_bank_ref_id
      }
      
      const res_body = await Axios.post(api_base_url+"p2p_trading/buy_order_deposit_detail", reqObj, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
      if(res_body.data)
      { 
        set_buy_request_loader(false)
        set_buy_usdt_value("")
        console.log("res_body", res_body.data)
        if(res_body.data.status)
        {
          setModalData({icon:true, title:"Transfer Details", content:"Your transfer details have been submitted successfully, please wait while we receive confirmation from the seller. Your "+getUsdSymbol(parseInt(buy_request.network_type_row_id))+" will be credited after seller confirmation."})
          individualDetails()
        }
        else
        {
          setModalData({icon:true, title:"Oops", content:res_body.data.message.alert_message})
        }
      }
  }
  
  const getUsdSymbol = (pass_network) =>
  {
    if(pass_network === 1)
    {
      return "USDT"
    }
    else
    {
      return "BUSD"
    }
  }


    return(
    <>
    <MetaTags>
      <title>P2P Buy Order Details</title>
      <meta property="og:image" content="/favicon.png" />
    </MetaTags>
      <div id="Content" className="p2p_orders_details profile p2p_orders">
        <div className='row'>
        <div className="container">
          <div className="row">
            {/* <div className="col-md-4">
                
                <div className="order_quick_links">
                  <div className="order_quick_header">
                    <h6>Buy Order Details</h6>
                    <p>Total Buying Order</p>
                  </div>
                  <div className="order-details">
                    <h6>1.  {completed_buy_usdt_value} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} - <span className="completed">Completed</span></h6>
                  </div>
                  <div className="order-details">
                    <h6>2.  {parseFloat(assigned_usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} - <span className="pending">Assigned</span></h6>
                  </div>
                  <div className="order-details">
                    <h6>3.  {pending_buy_usdt_value} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} - <span className="pending">Not Assigned</span></h6>
                  </div>
                  <div className="order-details">
                    <h6>4.  {cancelled_buy_usdt_value} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} - <span className="cancelled">Cancelled</span></h6>
                  </div>
                </div>
              </div>
             */}
            
            <div className="col-md-12">
              <div className="buy_sell_orders_back">
                <Link to={((buy_status == 2) || (buy_status == 3)) ? '/p2p?active_tab=2' : '/p2p?active_tab=1'} >
                  <img src="/assets/images/left-arrow.png" width="27px"/>  Back to Order's
                </Link>
              </div>
            </div>

            <div className="col-md-8 mx-auto">
              <div className="">
              <h3 className="buy-sell-title"> <img src="/assets/images/buy-order.svg"/>  Buy order details : {buy_request_id} </h3>
                <div className="single_order_details">
                 
                  <h5>You are buying <span>{buy_usdt_value} {getUsdSymbol(parseInt(buy_request.network_type_row_id))}</span>  <span>for {parseFloat(total_amount)} INR </span></h5>
                  <h6>Placed on {moment(date_n_time).format("LLLL")} (1 {getUsdSymbol(parseInt(buy_request.network_type_row_id))} = {parseFloat(buy_request.buy_rate_for_one_usdt)} INR)</h6>
                  {/* <h6 className="order_id_details">Order ID : <span>{buy_request_id}</span></h6> */}
                  <h6 className="receiving_address">{getUsdSymbol(parseInt(buy_request.network_type_row_id))} Receiving Address: {wallet_address}</h6>
                  
                  
                  <div className="row">
                    <div className="col-md-3 col-6">
                      <h4>{completed_buy_usdt_value} {getUsdSymbol(parseInt(buy_request.network_type_row_id))}</h4>
                      <p>Completed</p>
                    </div>
                    <div className="col-md-3 col-6">
                      <h4>{parseFloat(pending_buy_usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))}</h4>
                      <p>Not Assigned</p>
                    </div>
                    <div className="col-md-3 col-6">
                      <h4>{parseFloat(assigned_usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))}</h4>
                      <p>Assigned</p>
                    </div>
                    <div className="col-md-3 col-6">
                      <h4>{cancelled_buy_usdt_value} {getUsdSymbol(parseInt(buy_request.network_type_row_id))}</h4>
                      <p>Cancelled</p>
                    </div>
                    <div className="col-md-12">
                      {
                        parseInt(pending_buy_usdt_value) > 0 ?
                        <button type="button" className="btn btn-primary" onClick={()=>set_display_model(!display_model)}>Cancel Order</button>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7 mx-auto">
                    { 
                  
                      transactions_list ?
                      <>
                        {
                          transactions_list.map((item, i) =>
                          <div>
                            {  
                              parseInt(item.assinged_status) === 0 ? 
                                  <div className="order_transaction">
                                    
                                    <div className="transaction_pay_with">
                                      <div className="transaction_header pb-1">
                                        <h4>#{i+1} Seller found, Pay {parseFloat(item.total_amount)} INR & get {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))}</h4>
                                        <h5>Txn ID: {item.assinged_unique_id}</h5>
                                        <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                                      </div>
                                      {/* <i className="fas fa-exclamation-triangle"></i> */}

                                      <div className="paywith-txn">
                                        <img src="/assets/images/timer-img.svg" />

                                        <h6>Pay with  
                                          {
                                            parseInt(item.payment_type) == 1 ?
                                            <span onClick={()=>paymentDetails(item)} >&nbsp;IMPS Details&nbsp;</span>
                                            :
                                            <span onClick={()=>paymentDetails(item)} >&nbsp;UPI Details&nbsp;</span> 
                                          }

                                           within  <Timer
                                            initialTime={new Date(item.added_date_n_time).getTime()-new Date(present_date_n_time).getTime()}
                                            direction="backward"
                                            checkpoints={[
                                              {
                                                 time: 0,
                                                 callback:() =>individualDetails(),
                                              }
                                          ]}
                                          >
                                          {() => (
                                                <React.Fragment>
                                                    
                                                    <Timer.Minutes />:
                                                    <Timer.Seconds /> 
                                                </React.Fragment>
                                            )} 
                                          </Timer> Min</h6>
                                      </div>
                                     
                                    <div className=" mb-3 input-group-sm transaction_id_buyer">
                                      <input autoComplete="off" type="text" className="form-control" placeholder="Enter Transaction ID" name="transaction_id" value={item.deposited_bank_ref_id==deposited_bank_ref_id?deposited_bank_ref_id:null} onChange={(e) => set_deposited_bank_ref_id(e.target.value)}/>
                                      <div className="error text-left">{err_deposited_bank_ref_id}</div>
                                      <div className="submit-txn">
                                        <button className="btn btn-submit" type="button" onClick={() =>{submitDepositDetails(item.id)}} >Submit</button>
                                        {/* <h6>Cancel this Order</h6> */}
                                      </div>
                                    </div>
                                    
                                    <p>Order will go to dispute, if the seller rejects the payment confirmation. </p><p> Order will cancelled automatically if payment not deposited within time limit. </p>
                                    
                                  </div>
                                </div>
                              :
                              parseInt(item.assinged_status) === 1 ? 
                                <div className="order_transaction">
                                  <div className="transaction_deposited">
                                    <div className="transaction_header">
                                      <h4>#{i+1} Payment Deposited of {parseFloat(item.total_amount)} INR ({parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))})</h4>
                                      <h6>Txn ID: {item.assinged_unique_id}</h6>
                                      <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                                      <h4 className="mt-4 mb-4">Payment Deposited</h4>
                                      <img src="/assets/images/timer-img.svg"/>
                                      <h4 className="mt-4">
                                      <Timer
                                            initialTime={new Date(item.deposited_date_n_time_added_hour).getTime()-new Date(present_date_n_time).getTime()}
                                            direction="backward"
                                            checkpoints={[
                                              {
                                                 time: 0,
                                                 callback:() =>individualDetails(),
                                              }
                                          ]}
                                          >
                                          {() => (
                                                <React.Fragment>
                                                    
                                                    <Timer.Minutes />:
                                                    <Timer.Seconds /> 
                                                </React.Fragment>
                                            )} 
                                        </Timer> Min</h4>
                                    </div>
                                    {/* <i className="fas fa-exclamation-triangle"></i> */}
                                    
                                  
                                    <p className="seller-confirmation">Waiting for seller confirmation</p>
                                 
                                    <p>Deposited  {
                                            parseInt(item.payment_type) == 1 ?
                                            <span className="payment_type_view" onClick={()=>paymentDetails(item)} >IMPS</span>
                                            :
                                            <span className="payment_type_view" onClick={()=>paymentDetails(item)} >UPI</span> 
                                          }&nbsp; payment on: {moment(item.deposited_date_n_time).format("LLLL")}</p>
                                    {/* <p>Deposited Ref ID: {item.deposited_bank_ref_id}</p> */}
                                  </div>
                                </div>
                              :
                              parseInt(item.assinged_status) === 2 ?
                              <div className="order_transaction">
                                <div className="transaction_completed">
                                    <div className="transaction_header">
                                      <h4>#{i+1} Payment Accepted of {parseFloat(item.total_amount)} INR ({parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))})</h4>
                                     
                                      
                                      <h5>Txn ID: {item.assinged_unique_id}</h5>
                                      <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                                    </div>
                                    
                                      {
                                        item.ad_deposited_buyer_trans_hash ?
                                        <img src="/assets/images/completed.svg" />
                                        :
                                        <div className=''>
                                         
                                        </div> 
                                      }

                                   
                                      {
                                        item.ad_deposited_buyer_trans_hash ?
                                        <h5 style={{color:"white", fontSize:"13px", fontWeight:"400"}}>
                                        {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} deposited to your wallet. To view &nbsp; 
                                        {
                                          parseInt(item.payment_type) === 1 ?
                                          <a href={"https://etherscan.io/tx/"+item.ad_deposited_buyer_trans_hash} target="_blank" style={{color:"#39d87d", fontWeight:"600", fontSize:"14px"}}>
                                             click here  
                                          </a>
                                          :
                                          parseInt(item.payment_type) === 2 ?
                                          <a href={"https://bscscan.com/tx/"+item.ad_deposited_buyer_trans_hash} target="_blank" style={{color:"#39d87d", fontWeight:"600", fontSize:"14px"}}>
                                           click here  
                                          </a>
                                          :
                                          "asdf"
                                        }
                                        </h5>
                                        :
                                        " "
                                      }
                                      {
                                        item.ad_deposited_buyer_trans_hash ?
                                        <h6 class="yes_confirmed">Transaction Completed</h6>
                                        :
                                        <h6 class="payment_progress"> payment is processing</h6>
                                      }

                                    
                                    
                                    <p>Yes confirmed the { parseInt(item.payment_type) == 1 ?
                                            <span className="payment_type_view" onClick={()=>paymentDetails(item)} >IMPS</span>
                                            :
                                            <span className="payment_type_view" onClick={()=>paymentDetails(item)} >UPI</span> 
                                          } payment from seller</p>
                                   {
                                      !item.ad_deposited_buyer_trans_hash ?
                                      <p>Please wait your {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} payment is processing.</p>
                                      :
                                      ""
                                    }
                                </div>
                              </div>
                              :
                              parseInt(item.assinged_status) === 3 ?
                                <div className="order_transaction">
                                  
                                  <div className="transaction_dispute">
                                    <div className="transaction_header">
                                        <h4>#{i+1} Payment Dispute of {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} ({parseFloat(item.total_amount)} INR)</h4>
                                        <h6>Txn ID: {item.assinged_unique_id}</h6>
                                        <h6>Matched on {moment(item.date_n_time).format("LLLL")}</h6>
                                    </div>
                                    <img src="/assets/images/cancelled.svg" />
                                    <h5>Order in Dispute</h5>
                                    <p>Seller not confirmed payment in time.</p>
                                    <h6 className="mt-2 mb-3">
                                          { 
                                            parseInt(item.payment_type) == 1 ?
                                            <span className="payment_type_view" onClick={()=>paymentDetails(item)} >Bank Details</span>
                                            :
                                            <span className="payment_type_view" onClick={()=>paymentDetails(item)} >UPI Details</span> 
                                          }
                                    </h6>
                                    <h6>Please wait for some time, the team will look into the dispute.</h6>
                                  </div>
                                </div>
                              :
                              parseInt(item.assinged_status) === 4 ?
                                <div className="order_transaction">
                                
                                  <div className="transaction_cancelled">
                                  <div className="transaction_header">
                                      <h4>#{i+1} Payment Cancelled of {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} ({parseFloat(item.total_amount)} INR)</h4>
                                      <h5>Txn ID: {item.assinged_unique_id}</h5>
                                      <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                                  </div>
                                    <img src="/assets/images/buyer-cancel.svg" />
                                    <h5 className="order-cancel">Order Cancelled</h5>
                                    <p>Payment details not deposited.</p>
                                    
                                  </div>
                                </div>
                              :
                              parseInt(item.assinged_status) === 5 ?
                                <div className="order_transaction"> 
                                  <div className="transaction_cancelled">
                                  <div className="transaction_header">
                                    <h4>#{i+1} Payment Cancelled of {parseFloat(item.usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} ({parseFloat(item.total_amount)} INR)</h4>
                                    <h5>Txn ID: {item.assinged_unique_id}</h5>
                                    <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5>
                                  </div>
                                    <img src="/assets/images/buyer-cancel.svg" />
                                    <h5 className="order-cancel">Order Cancelled</h5>
                                    <p>Dispute response/System failure try again.</p>
                                    
                                  </div>
                                </div>
                              :
                              null
                            }
                            </div>
                          )
                        }
                      </>
                      :
                      null
                    }

                    {
                      parseFloat(all_assigned_cancelled) < parseFloat(buy_usdt_value) ?
                      <div className="matching_direct_seller">
                          {/* <h2>Finding Sellers for your Order</h2> */}
                          <h2>Looking for Seller</h2>
                          {/* <h6>Please hold while we match your order </h6> */}
                          <img src="/assets/images/orders-loader.svg"  />
                          {/* <h3>You are buying 800 USD for 72.5$</h3> */}
                          {/* Place on June 30 2022, 11:30 AM,  */}
                          <h4>You will receive email when seller matched.</h4>
                          {/* <h5>Current demand and supply affect the time delay.</h5> */}
                        
                      </div>
                      :
                      parseFloat(cancelled_buy_usdt_value) == parseFloat(buy_usdt_value) ?
                      <div className="order_transaction"> 
                        <div className="transaction_cancelled">
                        <div className="transaction_header">
                          <h4>Buy Order {parseFloat(cancelled_buy_usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} of {parseFloat(total_amount)} INR</h4>
                        </div>
                        <img src="/assets/images/buyer-cancel.svg" />
                        <h6 className="order-cancel">Order Cancelled</h6>
                        <p>Your buy Order successfully Cancelled</p>
                        </div>
                      </div> 
                      :
                      null
                  }


                    

                  </div>
                </div>
              </div>
              
            </div>
            
            
                   {/* parseInt(buy_status)==1?
                       <div className="order_pending">
                        <h2>Buying USDT in Process</h2>
                     <h5>({completed_percentage}% complete) {completed_buy_usdt_value} of {buy_usdt_value}</h5>
                         <h6>Placed on {moment(date_n_time).format("LLLL")}</h6>
                         { 
                            parseInt(pending_buy_usdt_value) > 0  
                            ?
                            <button type="button" className="cancel_order btn btn-primary text-light" name="cancel_order" onClick={()=>set_display_model(!display_model)}>Cancel Order</button>
                            :
                            buy_usdt_value == cancelled_buy_usdt_value?
                            <span className="red"> Order Cancelled</span>
                            :
                            null
                          } 
                        </div>
                          :
                          parseInt(buy_status)==2 ?
                          <div className="order_complete">
                            <h2>Buying USDT Completed</h2>
                            <h5>({completed_percentage}% complete) {completed_buy_usdt_value} of {buy_usdt_value}</h5>
                            <h6>Placed on {moment(date_n_time).format("LLLL")}</h6>
                        </div>
                        :
                      parseInt(buy_status) ===  3 ?
                        <div className="order_cancelled">
                          <h2>Order Cancelled</h2>
                          <h5>(100% cancelled) {parseFloat(cancelled_buy_usdt_value)} {getUsdSymbol(parseInt(buy_request.network_type_row_id))} of {parseFloat(total_amount)} INR</h5>
                          <h6>Placed on {moment(date_n_time).format("LLLL")}</h6>
                          <h6>Order ID : {buy_request_id}</h6>
                        </div>
                        :
                        null
                        <div className="order_transaction">
                        <div className="transaction_cancelled">
                          <div className="transaction_header">
                            <h4>Payment #{i+1} for {item.total_amount} {equi_p2p_currency}</h4>
                            <h6>Transaction ID : {item.assinged_unique_id}</h6>
                            <h5>Matched on {moment(item.date_n_time).format("LLLL")}</h5> 
                            <p>You requested <b>{buy_usdt_value} USDT</b> for <b>{parseFloat(total_amount)} INR is Cancelled</b></p>
                            <h4>Placed on {moment(date_n_time).format("LLLL")} </h4>
                            <h4>Order ID : {buy_request_id}</h4>
                          </div>
                          <i class="fas fa-times"></i>
                          <h5>Order Cancelled</h5>
                          <p>Order is cancelled successfully</p>
                        </div>
                      </div> */}

            {/* <div className="col-md-4 text-right">
              <Link to={((buy_status == 2) || (buy_status == 3)) ? '/p2p?active_tab=2' : '/p2p?active_tab=1'} className="btn btn-primary mt-3">
                <i className="la la-arrow-left"></i>Back
              </Link>
            </div> */}
            </div>
           </div>
          </div>
         
        </div>
     
     
      <div className={"modal  modal-design modal_main_popup" + (display_model ? " show d-block" : " d-none")}>
        <div className="modal-dialog modal-md">
          <div className="modal-content ">
          <div className="modal-header">
          <h4 className="modal-title">Attention !</h4>
          <button type="button" className="close" data-dismiss="modal" onClick={()=>set_display_model(!display_model)}>&times;</button>
        </div> 

            <div className="modal-body ">
              
              {/* <h2 style={{fontSize:'40px'}} ><i className="fa fa-info-circle" aria-hidden="true"></i></h2>  */}
              <p className="wallet-btm-text"><b>All assigned transaction except the</b> onc you have submitted for seller information will be cancelled.</p>
              <p>Please Note: We may charge penalty for not executing transactions after placing the buy orders or not executing it multiple times may lead in block of ypur account/ funds.</p>
              <div className="modal-btns">
              <button type="button" className="btn goback-btn" data-dismiss="modal" onClick={()=>set_display_model(!display_model)}>Go Back</button>
              <button type="button" className="btn confirm-order" onClick={()=>CancelOrder()}>Yes, Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>

{modal_data.title ? <Popupmodal name={modal_data} /> : null}

{/* payment_details */}

   <div className={"modal modal-design "+(payment_details_modal_status ? " show d-block":" d-none")}>
    <div className="modal-dialog modal-md">
      <div className="modal-content">
      
      <div className="modal-header">
          <h4 className="modal-title">Payment Details</h4>
          <button type="button" class="close" data-dismiss="modal" onClick={()=>clearPaymentDetails()}>&times;</button>
        </div> 
        <div className="modal-body">
          <div className="impsdetails">
          
          {
            payment_details.payment_type == 1 ?
            <>
              <div className="impsdetails">
                <h4>IMPS</h4>
                <h5>Name : {payment_details.bank_holder_name} { copy_status != 1 ? <span onClick={()=>copyValue(1, payment_details.bank_holder_name)}><img src="/assets/images/copy-img.svg"/></span>:<span className="copy-text"> &nbsp;Copied</span> }</h5>
                <h5>Account Number : {payment_details.bank_acc_number} { copy_status != 2 ? <span onClick={()=>copyValue(2, payment_details.bank_acc_number)}><img src="/assets/images/copy-img.svg"/></span>:<span className="copy-text"> &nbsp;Copied</span> }</h5>
                <h5>IFSC : {payment_details.bank_ifsc_code} { copy_status != 3 ? <span onClick={()=>copyValue(3, payment_details.bank_ifsc_code)}><img src="/assets/images/copy-img.svg"/></span>:<span className="copy-text"> &nbsp;Copied</span> }</h5>
                <h5>Bank : {payment_details.bank_name} { copy_status != 4 ? <span onClick={()=>copyValue(4, payment_details.bank_name)}><img src="/assets/images/copy-img.svg"/></span>:<span className="copy-text"> &nbsp;Copied</span> }</h5>
              </div> 
            </>
            :
            <>
                <div className="impsdetails">
                  <h4>UPI</h4>
                  <h5>UPI : {payment_details.upi_id} { copy_status != 5 ? <span onClick={()=>copyValue(5, payment_details.upi_id)}><img src="/assets/images/copy-img.svg"/></span>:<span className="copy-text"> &nbsp;Copied</span> }</h5>
                </div>
            </>
          }
           
          </div>      
        </div>
        
        
      </div>
    </div>
  </div>
  </>
  )
}