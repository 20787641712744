import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { accountLogout, api_base_url, get_config} from '../../Constants'; 
import MetaTags from 'react-meta-tags'
import Popupmodal from '../../../components/layouts/popupmodal'

class Bounty_program extends React.Component 
{ 
  constructor() 
  {
      super();
      this.state = {
          token:Cookies.get('token'),
          email_verify_status:Cookies.get('local_email_verify_status'),
          alert_message:null,
          platform_row_id:"",
          twitter_link:"",
          error_twitter_link:"",
          twitter_loader:false,
          telegram_link:"",
          error_telegram_link:"",
          telegram_loader:false,
          reddit_link:"",
          error_reddit_link:"",
          reddit_loader:false,
          medium_link:"",
          error_medium_link:"",
          medium_loader:false,
          video_campaign:"",
          error_video_campaign:"",
          video_campaign_loader:false,
          bounty_article:"",
          error_bounty_article:"",
          bounty_article_loader:false,
          modalState: false,
          task_link:"",
          modal_data:{icon: "", title: "", content: ""},
          bountyShareContent:"",
          headers: {
            "Accept":"Application/json",
            "Content-Type":"Application/json"
          },
          loader:false
      }
      
  }

  //1:Twitter, 2:Telegram 3:Reddit 4:Medium 5:Video Campaign 6:Article Bounty
  twitterFormSubmit()
  {
    

    let formIsValid = true;

    this.setState({
      alert_message:""
    });
    
    if(!this.state.twitter_link) 
    {
        this.setState({
          error_twitter_link:"The Twitter Link field is required."
        });
        formIsValid = false;
    }
    else
    {
        this.setState({
          error_twitter_link:""
        });
    }

    if(!formIsValid)
    {
        return true;
    }
    
    this.setState({
      twitter_loader:true
    });

    this.callBountySubmitForm(this.state.twitter_link, 1);

  }

  callBountySubmitForm(task_link, platform_row_id)
  {
    //const token = Cookies.get('token');
    this.setState({
      modal_data: {icon:"", title:"", content:""}
    })
    
    var data = {
      platform_row_id:platform_row_id,
      task_link:task_link
    } 
    const reqHeader = get_config(Cookies.get('token'))
    if(data != 'undefined')
    {
      fetch(api_base_url+"bounty_campaigns/save_bounty_details", {
        method:'POST',
        headers: reqHeader.headers,
        body:JSON.stringify(data)
        }).then(res => res.json())
        .then(
          (result) => {
            this.setState({
                twitter_loader:false,
                telegram_loader:false,
                reddit_loader:false,
                medium_loader:false,
                video_campaign_loader:false,
                bounty_article_loader:false
            });
 
            if(result.tokenStatus){ 
            if(result.status === true) 
            {    
                this.setState({
                    modal_data: {icon:"",title:"Thank You!",content:result.message.alert_message},
                    twitter_link:"",
                    telegram_link:"",
                    reddit_link:"",
                    medium_link:"",
                    video_campaign:"",
                    bounty_article:""
                });
            }
            else
            {  
                if(result.message.alert_message)
                {
                  this.setState({
                    modal_data: {icon:"", title:"Oops!", content:result.message.alert_message}
                  })
                }
            }
          }
          else{
            this.logout()
          }
      });
    }
  }

  logout()
  {
    this.props.history.push('/auth/login');
  }


  telegramFormSubmit()
  {
    let formIsValid = true;
    this.setState({
      alert_message:""
    });

    if(!this.state.telegram_link) 
    {
        this.setState({
          error_telegram_link:"The Telegram Link field is required."
        });
        formIsValid = false;
    }
    else
    {
        this.setState({
          error_telegram_link:""
        });
    }

    if(!formIsValid)
    {
        return true;
    }
    
    this.setState({
      telegram_loader:true
    });
    this.callBountySubmitForm(this.state.telegram_link, 2);
  }

  redditFormSubmit()
  {
    let formIsValid = true;
    this.setState({
      alert_message:""
    });

    if(!this.state.reddit_link) 
    {
        this.setState({
          error_reddit_link:"The Reddit Link field is required."
        });
        formIsValid = false;
    }
    else
    {
        this.setState({
          error_reddit_link:""
        });
    }

    if(!formIsValid)
    {
        return true;
    }
    
    this.setState({
        reddit_loader:true
    });
    this.callBountySubmitForm(this.state.reddit_link, 3);
  }

  mediumFormSubmit()
  {
    let formIsValid = true;
    this.setState({
      alert_message:""
    });

    if(!this.state.medium_link) 
    {
        this.setState({
          error_medium_link:"The Medium Link field is required."
        });
        formIsValid = false;
    }
    else
    {
        this.setState({
          error_medium_link:""
        });
    }

    if(!formIsValid)
    {
        return true;
    }
    
    this.setState({
        medium_loader:true
    });
    this.callBountySubmitForm(this.state.medium_link, 4);
  }

  videoCampaignFormSubmit()
  {
    let formIsValid = true;
    this.setState({
      alert_message:""
    });

    if(!this.state.video_campaign) 
    {
        this.setState({
          error_video_campaign:"The Video Campaign Link field is required."
        });
        formIsValid = false;
    }
    else
    {
        this.setState({
          error_video_campaign:""
        });
    }

    if(!formIsValid)
    {
        return true;
    }
    
    this.setState({
        video_campaign_loader:true
    });
    this.callBountySubmitForm(this.state.video_campaign, 5);
  }

  bountyArticleFormSubmit()
  {
    let formIsValid = true;
    this.setState({
      alert_message:""
    });

    if(!this.state.bounty_article) 
    {
        this.setState({
          error_bounty_article:"The Bounty Article Link field is required."
        });
        formIsValid = false;
    }
    else
    {
        this.setState({
          error_bounty_article:""
        });
    }

    if(!formIsValid)
    {
        return true;
    }
    
    this.setState({
      bounty_article_loader:true
    });

    this.callBountySubmitForm(this.state.bounty_article, 6);
  }

  componentDidMount()
  { 

  }

  copytemplate () 
  {
    var copyText = document.getElementById("copy-text");
    copyText.select();
    document.execCommand("Copy");
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied";
  }
  
getBountyShareContent()
{
  var tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copy Now";
  const reqHeader = get_config(Cookies.get('token'))

  fetch(api_base_url+"bounty_campaigns/bounty_share_content", {
    method:'GET',
    headers: reqHeader.headers,
  }).then(res => res.json())
  .then(
    (result) => { 
      if(result.status === true) 
      {
        var bountyShareContent = result.message.content.replace('<br/>', '\n');
      this.setState({ 
          bountyShareContent: bountyShareContent
      });
      }
   });
}




render()
{
  const { modal_data } = this.state
  return (
    <> 
    <div id="Content" className="bounty">
      <MetaTags>
        <title>Arrano Bounty Program For ANDX Token | Crypto Bounty </title>
        <link rel="canonical" href="https://arrano.network/bounty-program" />
        <meta name="description" content="Participate in the Exciting Arrano Bounty Program. Get a chance to Earn ANDX tokens by completing simple and creative tasks." />
        <meta name="keyword" content="Arrano Bounty Program , Earn ANDX tokens , ANDX  Bounty , arrano airdrop , ANDX airdrop." />
        <meta property="og:title" content="Arrano Bounty Program For ANDX Token | Crypto Bounty " />
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>

      <div className="content_wrapper clearfix">
      
        <div id="bounty_page">
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <div className="bounty_details_block">
                  <div className="row">
                    <div className="col-md-6 bounty_two">
                      <img src="/assets/images/bounty_gramophone.png" alt="Bounty Gramophone" />
                      <h1>Arrano Network Bounty 2.0<br/>For ANDX Defi token </h1>
                      {/* <h5 className="claim_token andx_defi"><a href="/ANDX-sale" className="defi_announce"><span className="blink_me">ANDX Defi <span style={{fontSize: '12px'}}>Max Supply 81,800</span> </span></a><br/>Token Sale is Live</h5> */}
                    </div>
                    <div className="col-md-6">
                      <div className="bounty_details_yellow">
                        <img src="/assets/images/bounty_A.png" alt="Bounty" />
                        <h4><span>100</span> ANO <span className="bounty_per_referral">per referral</span></h4>
                        
                        <h5>Total Allocation: 0.5%</h5>
                        <div className="row">
                          <div className="col-md-5 col-6">
                            <h6>Bounty Starts on</h6>
                            <p>21 NOV 2020</p>
                          </div>
                          <div className="col-md-5 col-6">
                            {/* <h6>Ends on</h6> */}
                            {/* <p>30 JAN 2021</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* style={{display:'none'}} */}

                {
                  this.state.alert_message ? (
                    <div className="alert alert-info mt-3">
                      {this.state.alert_message}
                    </div>
                    ) : (
                    null
                )}

                <div className="social_media_token">
                <h6>Campaign 1</h6>
                  <h2><span><img src="/assets/images/bounty_play.png" alt="Social Media Bounty For ANDX Tokens" />Social Media Bounty For ANDX Tokens </span> - 20 ANO on completing all</h2>

                  <div className="main_social_media">
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_twitter.png" alt="Twitter" /> Twitter</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano on <a target="blank" href="https://twitter.com/arranonetwork">Twitter</a></li>
                              <li>RT/Tweet with this content <a href="" data-toggle="modal" data-target="#myModal" onClick={()=>this.getBountyShareContent()}>Click to Copy</a></li>
                              <li>Like all the Arrano Posts</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                             <input  placeholder="Submit Task Link/Profile Link" autoComplete="off" type="text"  value={this.state.twitter_link} onChange={(e)=>{this.setState({twitter_link:e.target.value})}} name="twitter_link"  />
                             <div className="error">
                               {this.state.error_twitter_link}
                             </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="" onClick={()=>this.twitterFormSubmit()}>
                          {this.state.twitter_loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  
                  

                  <div className="main_social_media">
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_telegram.png" alt="Telegram" /> Telegram</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano <a href="https://t.me/arranonetwork" target="blank" >Telegram</a> Group </li>
                              <li>Follow Arrano Telegram Channel</li>
                              <li>Talk about ANDX token in group</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <input className="" type="text" placeholder="Submit Telegram Profile Link" autoComplete="off"  value={this.state.telegram_link} onChange={(e)=>{this.setState({telegram_link:e.target.value})}} name="telegram_link"  useref="telegram_link" />
                            <div className="error">
                               {this.state.error_telegram_link}
                             </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="" onClick={()=>this.telegramFormSubmit()}>
                          {this.state.telegram_loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  

                  <div className="main_social_media">
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_reddit.png" alt="Reddit"/> Reddit</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano <a href="https://www.reddit.com/user/ArranoNetwork" target="blank" >Reddit</a> profile</li>
                              <li>Comment this content <a href="" data-toggle="modal" data-target="#myModal" onClick={()=>this.getBountyShareContent()}>Click to Copy</a></li>
                              <li>Upvote all posts</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <input className="" type="text" placeholder="Submit Reddit Task Link/Profile Link" autoComplete="off"  value={this.state.reddit_link} onChange={(e)=>{this.setState({reddit_link:e.target.value})}} name="reddit_link"  useref="reddit_link" />
                            <div className="error">
                               {this.state.error_reddit_link}
                             </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="" onClick={()=>this.redditFormSubmit()}>
                          {this.state.reddit_loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="main_social_media">
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_medium.png" alt="Medium" /> Medium</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano <a href="https://arrano.medium.com/" target="blank" >Medium</a> Profile</li>
                              <li>Clap all the articles</li>
                              <li>Comment #ANDX #ANO and your views on the article</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <input className="" type="text" placeholder="Submit Medium Task Link/Profile Link" autoComplete="off"  value={this.state.medium_link} onChange={(e)=>{this.setState({medium_link:e.target.value})}} name="medium_link"  useref="medium_link" />
                            <div className="error">
                               {this.state.error_medium_link}
                             </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                         <button className="" onClick={()=>this.mediumFormSubmit()}>
                          {this.state.medium_loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  

                  <div id="myModal" className="modal fade" role="dialog">
                    <div className="modal-dialog claim_auto modal-sm">
                      <div className="modal-content">
                        <div className="modal-body bounty_copy_template">
                          Post this text on your twitter
                          <button type="button" className="close" data-dismiss="modal">×</button>
                          <div className="form-group">
                            <textarea className="form-control" id="copy-text" value={this.state.bountyShareContent}  onChange={(e)=>{this.setState({bountyShareContent:e.target.value})}} rows="8" ></textarea>
                          </div>
                          <a className="claim_now_popup" id="myTooltip" onClick={()=>{this.copytemplate()}}>Copy Now</a>
                        </div>
                      </div> 
                    </div>
                  </div>

                  <div className="main_social_media" style={{display:'none'}}>
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_facebook.png" alt="Facebook" /> Facebook</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano Telegram Group </li>
                              <li>Follow Arrano Telegram Channel</li>
                              <li>Share your views on Project updates and ANDX  </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <input className="" type="text" placeholder="Submit Facebook Task Link/Profile Link" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="" > Submit </button>
                      </div>
                    </div>
                  </div>

                 

                  

                  <div className="main_social_media" style={{display:'none'}}>
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_btctalk.png" alt="Bitcoin Talk" /> Bitcoin talk</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano on Twitter</li>
                              <li>Like and Comment all Posts. </li>
                              <li>RT with #Arrano #ANO #ANDX and tag 2 friends </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <input className="" type="text" placeholder="Submit Task Link/Profile Link" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="">Submit</button>
                      </div>
                    </div>
                  </div>

                  <div className="main_social_media" style={{display:'none'}}>
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_discord.png" alt="Discord" /> Discord</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano on Twitter</li>
                              <li>Like and Comment all Posts. </li>
                              <li>RT with #Arrano #ANO #ANDX and tag 2 friends </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <input className="" type="text" placeholder="Submit Task Link/Profile Link" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="">Submit</button>
                      </div>
                    </div>
                  </div>

                  <div className="main_social_media" style={{display:'none'}}>
                    <div className="row">
                      <div className="col-md-2">
                        <p><img src="/assets/images/bounty_instagram.png" alt="Instagram" /> Instagram</p>
                      </div>
                      <div className="col-md-8 text-field-bounty">
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>Follow Arrano on Twitter</li>
                              <li>Like and Comment all Posts. </li>
                              <li>RT with #Arrano #ANO #ANDX and tag 2 friends </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <input className="" type="text" placeholder="Submit Task Link/Profile Link" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button className="">Submit</button>
                      </div>
                    </div>
                  </div>
                  <p><b  style={{color:'#fff', fontSize: '13px'}}>Note: You can participate in Bounty 2.0 even if you have participated in Bounty 1.0</b></p>
                </div>

                

                
                              
                <div className="social_media_token social_media_detail">
                  <h6>Campaign 2</h6>
                  <h2><span><img src="/assets/images/bounty_play.png" alt="Bounty Play" /> Youtube Bounty for ANDX Defi token  </span></h2>
                  
                  <p>Create and Upload your amazing video about ANDX token to YouTube. You may introduce our project, share your review, provide a guide to help others understand and earn ANO through our Products or campaigns, and … It’s your time to impress us!</p>
                  
                  <h5>What to do?</h5> 
                  <ul>
                    <li>Your video should be 30 seconds or longer</li>
                    <li>Please be original</li>
                    <li>Please be truthful, don't say anything out of our litepaper,medium content, roadmap or any official announcement . We will review if you provide information about Contentos correctly.</li>
                    <li>Provide a link to our website (https://arrano.network) and your UID at video description</li>
                    <li>If verified successful, you will receive your ANO tokens at the distribution stage. </li>
                  </ul>

                  <h5>Bonus ANO on views</h5>
                  <ul>
                    <li>Below 50 views: 10  ANO</li>
                    <li>50~100 views: 30 ANO</li>
                    <li>101~250 views: 50 ANO</li>
                    <li>251~500 views: 100 ANO</li>
                    <li>501~1000 views: 150 ANO</li>
                    <li>1001~2000 views: 300 ANO</li>
                    <li>2001~3000 views: 500 ANO</li>
                    <li>3001~5000 views: 1000 ANO</li>
                    <li>5001~10000 views: 2000 ANO</li>
                    <li>Above 10000 views: 50000 ANO</li>
                  </ul>
                  
                  <div className="row" >
                    <div className="col-md-5 bounty_campaign mb-3"> 
                      <h6><span>Submit your video link</span></h6>
                      <div className="input-group ">
                      <input className="form-control" type="text" placeholder="Submit your video link" autoComplete="off"  value={this.state.video_campaign} onChange={(e)=>{this.setState({video_campaign:e.target.value})}} name="video_campaign"  useref="video_campaign" />
                         <div className="input-group-append">
                        <button className="" onClick={()=>this.videoCampaignFormSubmit()}>
                          {this.state.video_campaign_loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                            <>Submit</>
                          )}
                        </button>
                        </div>
                      </div>
                      <div className="error">
                          {this.state.error_video_campaign}
                        </div>
                    </div>
                  </div>

                </div>

                <div className="social_media_token social_media_detail">
                  <h6>Campaign 3</h6>
                  <h2><span><img src="/assets/images/premium-academy-3.png" alt="Article Bounty for ANDX Defi token" /> Article Bounty for ANDX Defi token </span></h2>
                  
                  <p>Write and Publish articles about ANDX Defi token . You may introduce an ANDX token , share your review, provide a guide to help others understand the Project details and Arrano Project Vision. </p>
                  
                  <h5>What to do?</h5> 
                  <ul>
                    <li>Write article of at least 600 words </li>
                    <li>Please be original, Plagiarized content will be rejected </li>
                    <li>Please be positive and truthful, don't say anything out of our litepaper,medium content, roadmap or any official announcement . We will review if you provide information about Contentos correctly.</li>

                    <li>Provide a link to our website (https://arrano.network) and your UID at article description</li>
                    <li>If verified successful, you will receive your ANO tokens at the distribution stage.</li>

                  </ul>

                  <div className="row" >
                    <div className="col-md-5 bounty_campaign mb-3">
                      <h6><span>Submit your article link</span></h6>
                      <div className="input-group ">
                      <input className="form-control" type="text" placeholder="Submit your article link" autoComplete="off"  value={this.state.bounty_article} onChange={(e)=>{this.setState({bounty_article:e.target.value})}} name="bounty_article"  useref="bounty_article" />
                      
                        <div className="input-group-append">
                          <button className="" onClick={()=>this.bountyArticleFormSubmit()}>
                            {this.state.bounty_article_loader ? (
                              <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                              ) : (
                              <>Submit</>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="error">
                          {this.state.error_bounty_article}
                        </div>
                    </div>
                  </div>
                </div>

                <div className="social_media_token social_media_detail" style={{display:'none'}}>
                  <h6>Campaign 3</h6>
                  <h4><span><img src="/assets/images/bounty_twitter.png" alt="Twitter Bounty" /> Twitter Bounty </span></h4>
                  
                  <p>Create and Upload your amazing video about Arrano Network to YouTube. You may introduce our project, share your review, provide a guide to help others understand and earn ANO through our Products or campaigns, and … It’s your time to impress us!</p>
                  
                  <h5>What to do?</h5> 
                  <ul>
                    <li>Your video should be 30 seconds or longer</li>
                    <li>Please be original</li>
                    <li>Please be truthful, don't say anything out of our litepaper or medium content. We will  review if you provide information about Contentos correctly.</li>
                    <li>Provide a link to our website (https://arrano.network) and your UID at video description</li>
                    <li>If verified successful, you will receive your ANO tokens at the distribution stage. </li>
                  </ul>

                  <h5>Bonus ANO on views</h5>
                  <ul>
                    <li>Below 50 views: 10  ANO</li>
                    <li>50~100 views: 30 ANO</li>
                    <li>101~250 views: 50 ANO</li>
                    <li>251~500 views: 100 ANO</li>
                    <li>501~1000 views: 150 ANO</li>
                    <li>1001~2000 views: 300 ANO</li>
                    <li>2001~3000 views: 500 ANO</li>
                    <li>3001~5000 views: 1000 ANO</li>
                    <li>5001~10000 views: 2000 ANO</li>
                    <li>Above 10000 views: 50000 ANO</li>
                  </ul>
                </div>

                <div className="social_media_token social_media_detail" style={{display:'none'}}>
                  <h6>Campaign 4</h6>
                  <h4><span><img src="/assets/images/bounty_facebook.png" alt="Facebook Bounty" /> Facebook Bounty </span></h4>
                  
                  <p>Create and Upload your amazing video about Arrano Network to YouTube. You may introduce our project, share your review, provide a guide to help others understand and earn ANO through our Products or campaigns, and … It’s your time to impress us!</p>
                  
                  <h5>What to do?</h5> 
                  <ul>
                    <li>Your video should be 30 seconds or longer</li>
                    <li>Please be original</li>
                    <li>Please be truthful, don't say anything out of our litepaper or medium content. We will  review if you provide information about Contentos correctly.</li>
                    <li>Provide a link to our website (https://arrano.network) and your UID at video description</li>
                    <li>If verified successful, you will receive your ANO tokens at the distribution stage. </li>
                  </ul>

                  <h5>Bonus ANO on views</h5>
                  <ul>
                    <li>Below 50 views: 10  ANO</li>
                    <li>50~100 views: 30 ANO</li>
                    <li>101~250 views: 50 ANO</li>
                    <li>251~500 views: 100 ANO</li>
                    <li>501~1000 views: 150 ANO</li>
                    <li>1001~2000 views: 300 ANO</li>
                    <li>2001~3000 views: 500 ANO</li>
                    <li>3001~5000 views: 1000 ANO</li>
                    <li>5001~10000 views: 2000 ANO</li>
                    <li>Above 10000 views: 50000 ANO</li>
                  </ul>
                </div>

                <div className="social_media_token social_media_detail" style={{display:'none'}}>
                  <h6>Campaign 5</h6>
                  <h4><span><img src="/assets/images/bounty_reddit.png" alt="Reddit Bounty"/> Reddit Bounty </span></h4>
                  
                  <p>Write and Publish articles about ANDX Defi token . You may introduce an ANDX token , share your review, provide a guide to help others understand the Project details and Arrano Project Vision. </p>
                  
                  <h5>What to do?</h5> 
                  <ul>
                    <li>Your video should be 30 seconds or longer</li>
                    <li>Please be original</li>
                    <li>Please be truthful, don't say anything out of our litepaper or medium content. We will  review if you provide information about Contentos correctly.</li>
                    <li>Provide a link to our website (https://arrano.network) and your UID at video description</li>
                    <li>If verified successful, you will receive your ANO tokens at the distribution stage. </li>
                  </ul>

                  <h5>Bonus ANO on views</h5>
                  <ul>
                    <li>Below 50 views: 10  ANO</li>
                    <li>50~100 views: 30 ANO</li>
                    <li>101~250 views: 50 ANO</li>
                    <li>251~500 views: 100 ANO</li>
                    <li>501~1000 views: 150 ANO</li>
                    <li>1001~2000 views: 300 ANO</li>
                    <li>2001~3000 views: 500 ANO</li>
                    <li>3001~5000 views: 1000 ANO</li>
                    <li>5001~10000 views: 2000 ANO</li>
                    <li>Above 10000 views: 50000 ANO</li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </div> 
      </div>
    </div>

    {modal_data.title ? <Popupmodal name={modal_data} /> : null}

    </>
    );
  }
}

export default withRouter(Bounty_program);
