import React,{useEffect,useState} from 'react'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import MetaTags from 'react-meta-tags'  
import { api_base_url, cookieDomainExtension, get_config, loginCookies} from '../../Constants' 
import Popupmodal from '../../../components/layouts/popupmodal'
import { useHistory,Link} from "react-router-dom"
import Web3 from 'web3'
import Axios from 'axios';


export default function Login()
{
  const history = useHistory()
  const parsed = queryString.parse(history.location.search)
  const [prev_url] = useState(parsed.prev_url ? parsed.prev_url:"")
  console.log(prev_url)
  const [alert_message, set_alert_message] = useState("")
  const [error_login_id, set_error_login_id] = useState("")
  const [error_password, set_error_password] = useState("")
  const [login_id, set_login_id] = useState("")
  const [password, set_password] = useState("")
 
  const [loader, set_loader] = useState(false)
  const [metamask_loader, set_metamask_loader] = useState(false)
  const [modal_data, set_modal_data] = useState({ icon: "", title: "", content: "" })
  const [password_show_status, set_password_show_status] = useState("")
  const [walletAddress, setWalletAddress] = useState('')
 
  const loginFun=()=>
  { 
    set_modal_data({icon:"", title:"", content:""})
    let formIsValid = true
    if(!password) 
    {
      set_error_password("The Password field is required.")
      formIsValid = false
    }
    else if(password.length < 5) 
    {
     set_error_password("The Password field must be at least 6 characters in length.")
      formIsValid = false
    }
    else
    {
    set_error_password("" )
    }

    if(!login_id)  
    {
     set_error_login_id("The Login ID field is required.")
      formIsValid = false
    }
    else
    {
      set_error_login_id("")
    }

    if(!formIsValid)
    {
      return true
    }
  
    set_loader(true)

    var loginUserExpireTime = new Date()
    loginUserExpireTime.setMinutes(loginUserExpireTime.getMinutes() + (60*24))

    const login_data = {
      login_id: login_id,
      password: password
    }
    
    const config = get_config("")
    fetch(api_base_url+"login/start_login", {
      method:'POST',
      headers:config.headers,
      body:JSON.stringify(login_data)
    }).then(res => res.json()) .then( (result) => 
    {
      set_loader(false)
      if(result.status === true) 
      {   
        loginCookies(result.message, loginUserExpireTime)
        if(result.message.email_verify_status === '1')
        {
          if(prev_url)
          {
            history.push({pathname : prev_url})
          }
          else
          { 
            history.push({pathname : "/user/dashboard"})
          }
        }
        else
        {
          history.push('/auth/register-verify-email') 
        } 
      }
      else
      {    
        if(result.message.alert_message)
        { 
          set_modal_data({icon:true, title:"Login Failed", content:result.message.alert_message})
         // set_alert_message: result.message.alert_message})
        }
        else
        {
          set_modal_data({icon:"", title:"", content:""})
        }

        if(result.message.login_id)
        {
          var var_error_login_id = result.message.login_id
        }
        else
        {
          var var_error_login_id = ""
        }

        if(result.message.password)
        {
          var var_error_password = result.message.password
        }
        else
        {
          var var_error_password = ""
        }

        set_error_login_id(var_error_login_id)
        set_error_password(var_error_password)
        set_login_id("")
        set_password("")
      
      } 
    }) 
  }


  const connectToWallet=()=>
  { 
    console.log("dgfhgh")
    var checkNetworks = ["private", "main"]  
    set_modal_data({icon: "", title: "", content:""})
    if(window.ethereum)
    {   
      window.ethereum.enable().then(function(res) 
      { 
        let web3 = new Web3(Web3.givenProvider)
        web3.eth.net.getNetworkType().then(function(networkName) 
        {       
          if(checkNetworks.includes(networkName))
          { 
            web3.eth.requestAccounts().then(function(accounts)
            {   
              var first_address = accounts[0]   
              if((typeof first_address != 'undefined'))
              {   
                checkUserWalletAddress(first_address)
                return true
              }
              return true
            })
          }
          else
          {
            set_modal_data({icon: "/assets/img/close_error.png", title: "Connection Error", content: 'Please connect to Main or BNB wallet.'}) 
          }
        })
      })
    } 
    else
    {
      set_modal_data({icon: "/assets/img/close_error.png", title: "Connection Error", content: 'You are connected to an unsupported network.'})
    }
  }

  useEffect(() => 
  { 
    
    if(Cookies.get("token", {domain:cookieDomainExtension}))
     {
      history.push({pathname : "/user/dashboard"})
    }
  }, [])

  const checkUserWalletAddress = (connectedaddress) => 
  {
    set_metamask_loader(true)
      Axios.post(api_base_url+'login/login_with_wallet_address', {wallet_address:connectedaddress}, get_config(""))
      .then(res => {  
           console.log(res.data)
          if(res.data.status == true)
          {
             set_metamask_loader(false)
              loginCookies(res.data.message)
              if(prev_url)
              {
                 history.push({pathname : prev_url})
              }
              else
              {
                  
                 history.push({pathname : "/user/dashboard"})
              }
          }
          else
          {
              Cookies.set("temp_wallet_address", connectedaddress,{domain:cookieDomainExtension})
              history.push({pathname : "/register"})
          } 
      })
  }
  // getParamFun()
  // {
  //   if(typeof url_param.ref !== 'undefined' && url_param.ref === 'submit-bounty')
  //   {  
  //      set_alert_message("Please login to submit your bounty details")
  //   }
  // }

  


    return (
      <> 
      <div id="Content">
      <MetaTags>
        <title>Log in to Arrano network | Blockchain for new age financing. </title>
        <link rel="canonical" href="https://arrano.network/login" />
        <meta name="description" content="Login to Arrano Decentralized Exchange. Buy, sell, and trade Bitcoin (BTC), Ethereum (ETH), best altcoins and DeFi tokens on the market." />
        <meta name="keyword" content="Arrano Login , Arrano sign in , Arrano account" />
        <meta property="og:title" content="Log In | Arrano Decentralized Exchange" />
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>
        <section className="auth_section">
          <div className="container">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 auth_form">
                     <div className="submit_form">
                        <h1>Welcome to <span>Arrano Community</span></h1>
                        <p>Login to your Account.</p>

                        <button className="auth_button btn-block login-metamask"  type="button" onClick={()=>connectToWallet()} style={{ background:"#ee811a"}} >
                        {metamask_loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                              <>Login With Metamask</>
                          )} 
                        </button>

                        <div className="border_bottom_auth"></div>

                            {
                              alert_message ? 
                              <div className="alert alert-primary">
                                {alert_message}
                                </div> 
                                : 
                              null
                              }


                          {Cookies.get('alert_message', {domain:cookieDomainExtension}) ? (
                          <div className="alert alert-primary">
                            {Cookies.get('alert_message', {domain:cookieDomainExtension})}
                            {Cookies.remove('alert_message', {domain:cookieDomainExtension})}
                          </div>
                          ) : (
                              null
                          )}
             
                          <div className="form-group">
                            <input type="email" className="form-control" placeholder="Username or Email"  useref="login_id"  value={login_id}  onChange={(e)=>{set_login_id(e.target.value)}} name="login_id"  />
                            <div className="error">
                            {error_login_id} 
                            </div>
                          </div>  

                          <div className="form-group ">
                            <div className="input-group mb-3">
                              <input useref="password" value={password} onChange={(e)=>{set_password(e.target.value)}} type={password_show_status ? "text":"password"} name="password" className="form-control" placeholder="Password" />
                              <div className="input-group-append">
                                <span className="input-group-text password_view_span">
                                 {    
                                  password_show_status ?
                                  <img src="/assets/images/not-view.png" className="password_view" onClick={()=>{set_password_show_status(!password_show_status)}} />
                                  :
                                  <img src="/assets/images/view-eye-icon.png"  className="password_view" onClick={()=>{set_password_show_status(!password_show_status)}}/>
                                  }
                                </span>
                              </div>
                            </div>

                            <div className="error">
                            {error_password}
                            </div>
                          </div>

                          <h6 className="text-center"><Link to="/auth/forgot-password">Forgot Password?</Link></h6> 

                          {/* <button className="auth_button"  type="button" data-toggle="modal" data-target="#ComingSoonLogin" >
                          {loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                              <>Take Me In</>
                          )}
                           </button> */}
                          <div className="text-center">
                          <button className="auth_button mt-2"  type="button" onClick={()=>{loginFun()}} >
                          {loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                              <>Take Me In</>
                          )} 
                          </button>
                          </div>
                    
                      <h5 className="mt-3 auth_below_links">
                        Click here to <Link to="/register" >Create account?</Link>
                      </h5>
                     </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-12 auth_content">
                      <h3>You're a part of World's Fastest Growing Crypto Community.</h3>
                      <h5>Enjoy the Arrano Blockchain Services and Contribute the developments. </h5> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
        
      </div>
     
      {modal_data.title ? <Popupmodal name={modal_data} /> : null}
      
      {/* <div className="modal" id="ComingSoonLogin">
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" type="button" className="close" data-dismiss="modal" >&times;</button>
                  <h2>Maintenance Mode</h2>
                  <p>Website is under maintenance. please check back again soon. Follow our twitter handle for latest updates</p>
                  <p className="text-center"><a target="_blank" href="https://twitter.com/arranonetwork"><i class="fa fa-twitter" aria-hidden="true" style={{color:'#fff'}}></i></a></p>
                </div>
              </div>
            </div>
          </div> */}
      </>
    );
  }


