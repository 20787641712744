import React from 'react'
import $ from 'jquery'
import { Link, Route , withRouter} from 'react-router-dom'
import OtpInput from 'react-otp-input'
import Cookies from 'js-cookie'
import { countryList, api_base_url, get_config, cookieDomainExtension, accountLogout} from '../../Constants'
import Popupmodal from '../../../components/layouts/popupmodal'
import queryString from 'query-string' 
import MetaTags from 'react-meta-tags' 
import Axios from 'axios'
import Web3 from 'web3'
var md5 = require('md5')

class Settings extends React.Component {
  
  constructor() 
  {
    super();
    this.state = {
        pathList: null,
        token:Cookies.get('token'),
        getAPIOutput:false,
        path_row_id:"",
        country_id:"",
        user_name: "",
        full_name: "",
        email_id: "",
        mobile_number: "",
        password: "",
        referral_user_name:"",
        referral_full_name:"",
        country_name: "",
        path_name: "",
        date_n_time: "",
        old_password:"",
        new_password:"",
        confirm_new_password:"",
        error_user_name:"",
        error_full_name:"",
        error_email_id:"",
        error_mobile_number:"",
        error_old_password:"",
        error_new_password:"",
        error_confirm_new_password:"",
        alert_message:"",
        error_country_id:"",
        error_referral_id:"",
        password_alert_message:"",
        email_verify_otp:"",
        error_email_verify_otp:"",
        verify_email_loader:"",
        resend_alert_message:"",
        email_verify_status:1,
        bank_name:"",
        bank_holder_name:"",
        bank_acc_number:"",
        bank_ifsc_code:"",
        bank_branch:"",
        upi_id:"",
        modal_data:({icon: "", title: "", content: ""}),
        error_bank_name:"",
        error_bank_holder_name:"",
        error_bank_acc_number:"",
        error_bank_ifsc_code:"",
        wallet_address_status:false,
        wallet_address:"",
        error_bank_branch:"",
        error_upi_id:"",
        imps_loader:"",
        imps_alert_message:"",
        upi_loader:"",
        upi_alert_message:"",
        password_show_status:"",
        new_password_show_status:"",
        headers: {
          "Accept":"Application/json",
          "Content-Type":"Application/json"
        },
        loader:false,
        
        personal_loader:false,
        modalState: false,
        url_param:queryString.parse(window.location.search)
        }
        this.handleShow = this.handleShow.bind(this)
  }
 
  
  logout()
  {
    this.props.history.push('/auth/login')
  }

  handleShow() 
  {
    this.setState({ modalState: !this.state.modalState })
  }

  handleChange = email_verify_otp => this.setState({ email_verify_otp })

  reSendEmailFun()
  {   
    
    this.setState( { email_verify_otp:"" } )


    const config = get_config(this.state.token)
    fetch(api_base_url+"profile/resend_otp", {
      method:'POST',
      headers: config.headers,
    }).then(res => res.json())
    .then(
      (result) => { 
        
      if(result.tokenStatus){ 
        if(result.status === true) 
        {
          this.setState({ 
            resend_alert_message: result.message.alert_message
          })
        }
      }
      else{
        this.logout()
      }
    })
  }
 
  connectToWallet()
  {
    var parentThis = this
    var checkNetworks = ["private", "main"]
    if(window.ethereum) 
    {
      window.ethereum.enable().then(function (res) 
      {
        let web3 = new Web3(Web3.givenProvider || parentThis.state.state.givenProvider)
        web3.eth.net.getNetworkType().then(function (networkName) 
        {
          if (checkNetworks.includes(networkName)) 
          {
            web3.eth.requestAccounts().then(function (accounts) 
            {
              var first_address = accounts[0]
              if ((typeof first_address != 'undefined')) 
              {
                parentThis.setState({ wallet_address : first_address}) 
                parentThis.walletAddressUpdate(parentThis.state.wallet_address)
             }
              return true
            })
          }
          else 
          {
            console.log('Please connect to Main or BNB wallet.')
            parentThis.setState({modal_alert_message : { status:true, title: "Connection Error", description: 'Please connect to Main or BNB wallet.' }})
          }
        })
      })
    }
    else 
    {
      console.log('You are connected to an unsupported network.')
      parentThis.setState({modal_alert_message : { status:true, title: "Connection Error", description: 'You are connected to an unsupported network.' }})
    }
  }
 
  walletAddressUpdate(wallet_address)
  {   
    console.log("ddsf",wallet_address)
    const data = {
      wallet_address: wallet_address
  }
    const config = get_config(this.state.token)
    fetch(api_base_url+"profile/update_wallet_address", {
      method:'POST',
      headers: config.headers,
      body:JSON.stringify(data)
    }).then(res => res.json())
    .then(
      (result) => { 
        console.log("wallet_address",result)
        if(result.status === true) 
        {
          var var_alert_message = 'Wallet details has been updated successfully.'
          this.setState({modal_data:({icon:"",title:"Thank You!",content:var_alert_message})})
        }
      else{
        this.setState({modal_data:({icon:"",title:"Oops!",content:result.message.wallet_address})})
        this.setState({wallet_address:""})
      }
    
    })
  }
  verifyEmailFun()
  {
    let formIsValid = true
    this.setState( { resend_alert_message:"" } )
    
    if(this.state.email_verify_otp === '') 
    {
      this.setState({ error_email_verify_otp:"The OTP field is required." })
      formIsValid = false
    }
    else if(this.state.email_verify_otp.length != 6) 
    {
      this.setState({ error_email_verify_otp:"The OTP field must be at exactly 6 characters in length." })
      formIsValid = false
    }
    else
    {
      this.setState({ error_email_verify_otp:"" })
    }


    if(!formIsValid)
    {
      return true;
    }

    this.setState({
      verify_email_loader:true
    });

    var data = {
        email_verify_otp: this.state.email_verify_otp
    }

    const config = get_config(this.state.token)
      
    fetch(api_base_url+"profile/verify_email_using_otp", {
        method:'POST',
        headers: config.headers,
        body:JSON.stringify(data)
    }).then(res => res.json()) .then( (result) => 
      { 
        this.setState({ verify_email_loader:false })
        
        if(result.tokenStatus){ 
        if(result.status === true) 
        {
          this.setState({
          alert_message:result.message.alert_message,
          email_verify_otp:result.message.email_verify_otp
          })
          this.dashboardMe()
          this.handleShow()
        }
        else
        {   
          this.setState({
            resend_alert_message:result.message.alert_message,
            email_verify_otp:result.message.email_verify_otp
          })
        }
      }
      else
      {
        this.logout()
      }
    })

  }



  componentDidMount()
  {   
    this.dashboardMe()
    this.modalShowFun()
  }

  modalShowFun()
  {
    if(typeof this.state.url_param.ref !== 'undefined')
    {  
      this.handleShow()
    }
  }

    
    
    dashboardMe()
    { 
       const config = get_config(this.state.token)
        fetch(api_base_url+"dashboard", {
           method:'GET',
           headers: config.headers,
       }).then(res => res.json()).then((result) => 
       {
         console.log("result",result) 
          if(result.tokenStatus === true) 
          {
            this.setState({ 
                getAPIOutput:true,
                path_row_id: result.message.path_row_id,
                user_name: result.message.user_name,
                full_name: result.message.full_name,
                email_id: result.message.email_id,
                mobile_number: result.message.mobile_number,
                date_n_time: result.message.date_n_time,
                country_id: result.message.country_id,
                referral_user_name: result.message.referral_user_name,
                country_name: result.message.country_name,
                path_name: result.message.path_name,
                email_verify_status:result.message.email_verify_status,
                bank_name : result.message.bank_name,
                bank_holder_name : result.message.bank_holder_name,
                bank_acc_number : result.message.acc_number,
                bank_ifsc_code : result.message.ifsc_code,
                bank_branch: result.message.bank_branch,
                upi_id: result.message.upi_id,
                wallet_address:result.message.wallet_address
            })
          }
          else
          {
            accountLogout()
            this.props.history.push('/auth/login');
          }
        })
    }


    getPathList()
    {
      const config = get_config("")
      fetch(api_base_url+"register/path_list", {
        method:'GET',
        headers: config.headers,
      }).then(res => res.json())
      .then((result) => 
      {
        if(result.status === true) 
        {
          this.setState({  pathList: result.message })
        }
      })
    }

    getCountryList()
    {
      const config = get_config("")
      fetch(api_base_url+"register/country_list", {
        method:'GET',
        headers: config.headers,
      }).then(res => res.json()).then((result) => 
      {
        if(result.status === true) 
        {
          this.setState({ 
              countryList: result.message
          })
        }
      })
    }



    updatePersonalData()
    {
      let formIsValid = true
      this.setState( { alert_message:"" })
      this.setState({modal_data:({icon:"",title:"",content:""})})
      if(this.state.full_name === '') 
      {
        this.setState({ error_full_name:"The Full Name field is required." })
        formIsValid = false
      }
      else if(this.state.full_name.length < 4) 
      {
        this.setState({ error_full_name:"The Full Name field must be at least 4 characters in length." })
        formIsValid = false
      }
      else
      {
        this.setState({ error_full_name:"" })
      }

      
      if(this.state.mobile_number === '') 
      {
        this.setState({ error_mobile_number:"The Mobile Number field is required." })
        formIsValid = false
      }
      else if(this.state.mobile_number.length < 5) 
      {
        this.setState({ error_mobile_number:"The Mobile Number field must be at least 5 characters in length." })
        formIsValid = false
      }
      else
      {
        this.setState({ error_mobile_number:"" })
      } 

      if(this.state.country_id === '') 
      {
        this.setState({ error_country_id:"The Country ID field is required." })
        formIsValid = false
      }
      else
      {
        this.setState({ error_country_id:"" })
      }


      if(!formIsValid)
      {
        return true
      }

      const reqObj = {
        full_name: this.state.full_name,
        mobile_number: this.state.mobile_number,
        country_id: this.state.country_id,
        user_name: this.state.user_name,
        email_id: this.state.email_id,
      }

      this.setState({ personal_loader:true })
      const config = get_config(this.state.token)
      fetch(api_base_url+"profile/update_personal_detail", {
          method:'POST',
          headers: config.headers,
          body:JSON.stringify(reqObj)
      }).then(res => res.json()).then((result) => 
      {
          this.setState({ personal_loader:false })
           
          if(result.status === true) 
          {
              var var_alert_message = 'Profile details has been updated successfully.'
              // this.setState({
              //   alert_message:var_alert_message
              // })
              this.setState({modal_data:({icon:"",title:"Thank You!",content:var_alert_message})})
              Cookies.set('local_set_full_name', this.state.full_name, {domain:cookieDomainExtension})
              Cookies.set('local_set_email_id', this.state.full_name, {domain:cookieDomainExtension})
          }
          else
          {   
              //error_full_name
              if(result.message.full_name)
              {
                var error_full_name = result.message.full_name
              }
              else
              {
                var error_full_name = ""
              }

              //error_mobile_number
              if(result.message.mobile_number)
              {
                var error_mobile_number = result.message.mobile_number
              }
              else
              {
                var error_mobile_number = ""
              }

              this.setState({
                  error_full_name:error_full_name,
                  error_mobile_number: error_mobile_number
              });
            }
           
      })
    }

    updateSecurityData()
    {
      let formIsValid = true;
      this.setState({ password_alert_message:"" })
      this.setState({modal_data:({icon:"",title:"",content:""})})

      if(this.state.old_password === '') 
      {
        this.setState({ error_old_password:"The Old Password field is required." })
        formIsValid = false
      }
      else
      {
        this.setState({ error_old_password:"" })
      }
        
      if(this.state.new_password === '') 
      {
        this.setState({ error_new_password:"The New Password field is required." })
        formIsValid = false
      }
      else if(this.state.new_password.length < 6)
      {
        this.setState({ error_new_password:"The New password field must be at least 6 characters in length." })
        formIsValid = false
      }
      else
      {
        this.setState({ error_new_password:"" })
      }

      if(this.state.confirm_new_password === '') 
      {
        this.setState({ error_confirm_new_password:"The Confirm Password field is required." })
        formIsValid = false
      }
      else if(this.state.confirm_new_password !== this.state.new_password) 
      {
        this.setState({ error_confirm_new_password:"The Confirm password is not matching with New password." })
        formIsValid = false
      }
      else
      {
        this.setState({ error_confirm_new_password:"" })
      }
       
      if(!formIsValid)
      {
        return true
      }

      const reqObj = {
        old_password: this.state.old_password,
        new_password: this.state.new_password
      }

      this.setState({ loader:true })
        
      const config = get_config(this.state.token) 
      fetch(api_base_url+"profile/update_login_password", {
          method:'POST',
          headers: config.headers,
          body:JSON.stringify(reqObj)
       }).then(res => res.json()).then( (result) => 
       {
          this.setState({ loader:false })

          
          if(result.status === true) 
          {
              var var_alert_message = 'Security details has been updated successfully.';
              this.setState({
                  // password_alert_message:var_alert_message,
                  password:md5(this.state.new_password),
                  error_old_password:"",
                  error_new_password:"",
                  error_confirm_new_password:"",
                  old_password:"",
                  new_password:"",
                  confirm_new_password:""
                });
                this.setState({modal_data:({icon:"",title:"Thank You!",content:var_alert_message})})
          }
          else
          {   
              //error_old_password
              if(result.message.old_password)
              {
              var error_old_password = result.message.old_password
              }
              else
              {
              var error_old_password = ""
              }

              //error_new_password
              if(result.message.new_password)
              {
              var error_new_password = result.message.new_password
              }
              else
              {
              var error_new_password = ""
              }

              this.setState({
                  error_old_password:error_old_password,
                  error_new_password:error_new_password
              })
          }
        })
    }
    

    updateIMPSPayment()
    { 
      let formIsValid = true
      this.setState({modal_data:({icon:"",title:"",content:""})}) 

      if(!this.state.bank_name) 
      {
        formIsValid = false
        this.setState({ error_bank_name:"The Bank Name field is required." })
      }
      else
      {
        this.setState({ error_bank_name:"" })
      }
       
      if(!this.state.bank_holder_name) 
      {
        formIsValid = false
        this.setState({ error_bank_holder_name:"The Bank Holder Name field is required." })
      }
      else
      {
        this.setState({ error_bank_holder_name:"" })
      }

      if(!this.state.bank_acc_number) 
      {
        formIsValid = false
        this.setState({ error_bank_acc_number:"The Bank Account Number field is required." })
      }
      else if(!((/^(0|[1-9]\d*)$/).test(this.state.bank_acc_number)))
      {
        formIsValid = false
        this.setState({ error_bank_acc_number:"The Bank Account Number field must be numbers." })
      }
      else if (this.state.bank_acc_number.length < 8)
      {
        formIsValid = false
        this.setState({ error_bank_acc_number:"The Bank Account Number field must have atleast 8 digits." })
      }
      else
      {
        this.setState({ error_bank_acc_number:"" })
      }


      if(!this.state.bank_ifsc_code) 
      {
        formIsValid = false
        this.setState({ error_bank_ifsc_code:"The IFSC Code field is required." })
      }
      else
      {
        this.setState({ error_bank_ifsc_code:"" })
      }
      
      if(!formIsValid)
      {
        return true
      }

      this.setState({ imps_loader:true })
      
      const reqObj = {
        bank_name: this.state.bank_name,
        bank_holder_name: this.state.bank_holder_name,
        bank_acc_number: this.state.bank_acc_number,
        bank_ifsc_code: this.state.bank_ifsc_code,
        bank_branch: this.state.bank_branch
      }

      const config = get_config(this.state.token) 
      fetch(api_base_url+"profile/update_imps_payment", {
          method:'POST',
          headers: config.headers,
          body:JSON.stringify(reqObj)
      }).then(res => res.json()).then( (result) => 
        {
          this.setState({ imps_loader:false })
          
          if(result.status === true) 
          {
            var var_alert_message = 'IMPS details has been updated successfully.'
            this.setState({modal_data:({icon:"",title:"Thank You!",content:var_alert_message})})
          }
          else
          {   
            //bank_holder_name
            if(result.message.bank_name)
            {
              var error_bank_name = result.message.bank_name
            }
            else
            {
              var error_bank_name = ""
            }

            //bank_holder_name
            if(result.message.bank_holder_name)
            {
              var error_bank_holder_name = result.message.bank_holder_name
            }
            else
            {
              var error_bank_holder_name = ""
            }

            //bank_acc_number
            if(result.message.bank_acc_number)
            {
              var error_bank_acc_number = result.message.bank_acc_number
            }
            else
            {
              var error_bank_acc_number = ""
            }

            //bank_ifsc_code
            if(result.message.bank_ifsc_code)
            {
              var error_bank_ifsc_code = result.message.bank_ifsc_code
            }
            else
            {
              var error_bank_ifsc_code = ""
            }

            this.setState({
              error_bank_name:error_bank_name,
              error_bank_holder_name:error_bank_holder_name,
              error_bank_acc_number:error_bank_acc_number,
              error_bank_ifsc_code:error_bank_ifsc_code
            })
        }
      })
    } 

  updateUPIPayment()
  {
    let formIsValid = true
    this.setState({modal_data:({icon:"",title:"",content:""})})
    if(!this.state.upi_id) 
    {
      this.setState({ error_upi_id:"The UPI ID field is required." })
      formIsValid = false
    }
    else
    {
      this.setState({ error_upi_id:"" })
    }


    if(!formIsValid)
    {
      return true
    }

    this.setState({ upi_loader:true })
    
    const reqObj = {
      upi_id: this.state.upi_id
    }

    const config = get_config(this.state.token) 
      fetch(api_base_url+"profile/update_upi_payment", {
          method:'POST',
          headers: config.headers,
          body:JSON.stringify(reqObj)
      }).then(res => res.json())
      .then(
        (result) => {
          
          this.setState({
            upi_loader:false
          })
          
          if(result.status === true) 
          {
            var var_alert_message = 'UPI details has been updated successfully.'
            this.setState({modal_data:({icon:"",title:"Thank You!",content:var_alert_message})})
           // this.setState({ upi_alert_message:var_alert_message })
          }
          else
          {   
              //upi_id
              if(result.message.upi_id)
              {
               var error_upi_id = result.message.upi_id
              }
              else
              {
               var error_upi_id = ""
              }

              this.setState({
                error_upi_id:error_upi_id
              })
          }
        
        })
  }


  render()
  {
    return (
      <div>  
      <div id="Content" className="profile">
      <MetaTags>
          <title>Manage profile details and Security.</title>
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 mt-4">
                <fieldset className="form-group user_edit_profile">
                  <h5>Personal Details</h5>
                  <p>Update your personal details.</p>
                  {
                    this.state.alert_message ? (
                      <div className="alert alert-info">
                        {this.state.alert_message}
                      </div>
                    ) : (
                      null
                   )}

                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true">Username</label>
                          <input autoComplete="off" type="text" className="form-control"  placeholder="Username" value={this.state.user_name || ''} name="user_name"  readOnly/>
                        </div>
                      </div>
                      
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true">Registered On</label>
                          <input className="form-control" name="date_n_time" type="text" value={this.state.date_n_time || ''} readOnly />
                          <div className="help-block form-text with-errors form-control-feedback">
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true">Referral User ID</label>
                          <input className="form-control" type="text" value={this.state.referral_user_name || ''}  name="referral_user_name" readOnly />
                        </div>
                      </div>
                    
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true">Path Name</label>
                          <input autoComplete="off" type="text" className="form-control" placeholder="path name" value={this.state.path_name || ''} onChange={(e)=>{this.setState({path_name:e.target.value})}} name="path_name"  useref="path_name" readOnly/>
                         </div>
                      </div>
                      
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true" className="email_label_width">Email ID 
                          {
                            this.state.getAPIOutput ?
                            <>
                              {
                                this.state.email_verify_status == 0 ?
                                <span  onClick={this.handleShow} >( Verify Email )</span>
                                :
                                <span><i className="fas fa-check-circle"></i> Verified</span>
                              }
                            </>
                            :
                            null
                          }
                          </label>
                          <input autoComplete="off" type="text" className="form-control" placeholder="Email ID" value={this.state.email_id || ''} name="email_id" readOnly/>
                        </div>
                      </div>
                      <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="true">Select Country</label>
                        <select className="form-control" name="country_id" value={this.state.country_name} onChange={(e)=>{this.setState({country_id:e.target.value})}} >
                              <option value="">Select Country</option>
                              {
                                countryList ?
                                  countryList.map((item, i) => {
                                        return( <option key={i} value={item.country_id} selected={this.state.country_id == item.country_id} > {item.country_name} ({item.country_code} )</option>)
                                  }
                                  )
                                  :null
                              }
                        </select>
                        <div style={{color:"red"}}>
                                {this.state.error_country_id} 
                        </div>
                      </div>
                      </div>
                    
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true"> Full Name</label>
                          <input autoComplete="off" type="text" className="form-control"  placeholder="Full Name" value={this.state.full_name || ''} onChange={(e)=>{this.setState({full_name:e.target.value})}} name="full_name"  useref="full_name" />
                          <div className="help-block form-text with-errors form-control-feedback">
                            {this.state.error_full_name} 
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true">Phone Number</label>
                          <input autoComplete="off" type="text" className="form-control" placeholder="Mobile Number" value={this.state.mobile_number || ''} onChange={(e)=>{this.setState({mobile_number:e.target.value})}} name="mobile_number"  useref="mobile_number" />
                          <div className="help-block form-text with-errors form-control-feedback">
                          {this.state.error_mobile_number} 
                          </div>
                        </div>
                      </div>


                      <div className="col-sm-4">
                      <div className="form-group">
                      <label htmlFor="true">Wallet Address</label>
                         {
                            this.state.wallet_address
                            ?
                            <input type="text" className="form-control" title={this.state.wallet_address? this.state.wallet_address : ""} value={this.state.wallet_address} readOnly />
                             :
                            <button type="button" className="btn btn-primary" onClick={() => this.connectToWallet()}><span>Click here to Connect wallet</span></button>
                         }
                       </div>
                       </div>
                    </div>
                    <button className="btn btn-primary" name="update_personal" type="submit" onClick={()=>{this.updatePersonalData()}}>
                      {this.state.personal_loader ? (
                        <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                        ) : (
                        <>Update</>
                      )}
                    </button>
                 
                </fieldset>

		<div className={"modal" + (this.state.modalState ? " show d-block" : " d-none")}>
          <div className="modal-dialog">
            <div className="modal-content" >
              <div className="auth_form">
				<div className="submit_form">
				  <h2 className="heading-title" />
				  <div className="row">
					<div className="col-md-12">
					  <h4>Enter OTP</h4>
            {
                this.state.resend_alert_message ? (
                <div className="alert alert-info">
                  {this.state.resend_alert_message}
                </div>
              ) : (
                  null
            )}
            
					  <p>We’ve sent an OTP to {this.state.email_id} , please enter to verify and reset a new password for your Arrano Community account.</p>
					</div>
					<div className="col-md-12 text-center">
					<div style={{display: 'inline-flex', margin: '0 auto'}}>
					<OtpInput  className="otp-form-control" numInputs={6} value={this.state.email_verify_otp} onChange={this.handleChange} separator={<span>-</span>}  name="email_verify_otp" />
					 </div>
					</div>
					<div className="error col-md-12 text-center">
					  {this.state.error_email_verify_otp} 
					  <h6 className="text-center"><a href="#" onClick={()=>{this.reSendEmailFun()}}>Resend OTP?</a></h6>
					 </div>
					<div className=" col-md-12 mt-3">
					<div className="row">
						<div className="col-md-6 col-6 text-right">
							<button type="button" className="auth_button" onClick={this.handleShow}>Close</button>
						</div>
						<div className="col-md-6 col-6">
							<button type="submit" className="auth_button" onClick={()=>{this.verifyEmailFun()}}>
						  {this.state.verify_email_loader ? (
								  <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
								  ) : (
								  <> Verify OTP</>
						  )}
						</button>
						</div>
					</div>
						
					</div>
				
					</div>
            </div>
          </div>
            </div>
          </div>
        </div>

 

                  <fieldset className="form-group mt-4 user_edit_profile">
                    <h5>Personal Settings</h5>
                    <p>Set your new login password by confirming current password. </p>
                    {
                      this.state.password_alert_message ? (
                        <div className="alert alert-info">
                          {this.state.password_alert_message}
                        </div>
                        ) : (
                        null
                      )
                    }
                    <div className="row">
                      <div className="col-sm-4">
                        <label htmlFor="true">Current Password</label>
                        <div className="form-group">
                        <div className="input-group">
                          <input autoComplete="off" type={this.state.password_show_status ? "text":"password"} className="form-control" value={this.state.old_password || ''} placeholder="Old Password" onChange={(e)=>{this.setState({old_password:e.target.value})}} name="old_password"  useref="old_password" style={{borderRadius: '5px 0 0 5px !important'}}/>
                          <div className="input-group-append">
                                <span className="input-group-text password_view_span" style={{borderRadius: '0 5px 5px 0'}}>
                                 {    
                                  this.state.password_show_status ?
                                  <img src="/assets/images/not-view.png" className="password_view" onClick={()=>{this.setState({password_show_status:!this.state.password_show_status})}} />
                                  :
                                  <img src="/assets/images/view-eye-icon.png"  className="password_view" onClick={()=>{this.setState({password_show_status:!this.state.password_show_status})}} />
                                  }
                                </span>
                          </div>
                        
                        </div>
                        <div className="help-block form-text with-errors form-control-feedback">
                          {this.state.error_old_password} 
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="true">Enter New Password</label>
                        <div className="form-group">
                        <div className="input-group">
                          <input autoComplete="off" type={this.state.new_password_show_status ? "text":"password"} className="form-control" value={this.state.new_password || ''}  placeholder="New Password" onChange={(e)=>{this.setState({new_password:e.target.value})}} name="new_password"  useref="new_password" style={{borderRadius: '5px 0 0 5px !important'}} />
                          <div className="input-group-append">
                                <span className="input-group-text password_view_span" style={{borderRadius: '0 5px 5px 0'}}>
                                 {    
                                  this.state.new_password_show_status ?
                                  <img src="/assets/images/not-view.png" className="password_view" onClick={()=>{this.setState({new_password_show_status:!this.state.new_password_show_status})}} />
                                  :
                                  <img src="/assets/images/view-eye-icon.png"  className="password_view" onClick={()=>{this.setState({new_password_show_status:!this.state.new_password_show_status})}} />
                                  }
                                </span>
                          </div>
                       
                        </div>
                        <div className="help-block form-text with-errors form-control-feedback">
                          {this.state.error_new_password} 
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="true">Confirm New Password</label>
                          <input autoComplete="off" type="password" className="form-control" value={this.state.confirm_new_password || ''}  placeholder="Confirm New Password" onChange={(e)=>{this.setState({confirm_new_password:e.target.value})}} name="confirm_new_password"  useref="confirm_new_password" />
                          <div className="help-block form-text with-errors form-control-feedback">
                          {this.state.error_confirm_new_password} 
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary" type="submit" name="update_password" onClick={()=>{this.updateSecurityData()}}>
                      {this.state.loader ? (
                        <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                        ) : (
                        <>Update</>
                      )}
                    </button>
                  </fieldset>

                  <fieldset className="form-group mt-4 user_edit_profile" >
                    <h5>Bank Details</h5>
                    <p>Receive payments to this details, You can edit it later.</p>
                    
                    <ul className="nav nav-tabs profile-pg-tabs">
                      <li className="active"><a data-toggle="tab" href="#home" className="active">IMPS</a></li>
                      <li><a data-toggle="tab" href="#menu1">UPI</a></li>
                    </ul>

                    <div className="tab-content">
                      <div id="home" className="tab-pane fade in active show">
                        {
                          this.state.imps_alert_message ? 
                          ( <div className="alert alert-info">
                              {this.state.imps_alert_message}
                            </div> 
                          ) 
                          : 
                          ( null )
                        }
                      
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="true">Bank Name</label>
                              <input autoComplete="off" type="text" className="form-control" value={this.state.bank_name || ''}  placeholder="Bank Name" onChange={(e)=>{this.setState({bank_name:e.target.value})}} name="bank_name" useref="bank_name" />
                              <div className="help-block form-text with-errors form-control-feedback">
                                {this.state.error_bank_name} 
                              </div>
                            </div>
                          </div>
                          





                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="true">Account Holder Name </label>
                              <input autoComplete="off" type="text" className="form-control" value={this.state.bank_holder_name || ''}  placeholder="Account Holder Name" onChange={(e)=>{this.setState({bank_holder_name:e.target.value})}} name="bank_holder_name" useref="bank_holder_name"/>
                              <div className="help-block form-text with-errors form-control-feedback">
                              {this.state.error_bank_holder_name} 
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="true">Account Number</label>
                              <input autoComplete="off" type="text" className="form-control" value={this.state.bank_acc_number || ''}  placeholder="Account Number" onChange={(e)=>{this.setState({bank_acc_number:e.target.value})}} name="bank_acc_number" useref="bank_acc_number"/>
                              <div className="help-block form-text with-errors form-control-feedback">
                              {this.state.error_bank_acc_number} 
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="true">IFSC Code</label>
                              <input autoComplete="off" type="text" className="form-control" value={this.state.bank_ifsc_code || ''}  placeholder="IFSC Code" onChange={(e)=>{this.setState({bank_ifsc_code:e.target.value})}} name="bank_ifsc_code" useref="bank_ifsc_code"/>
                              <div className="help-block form-text with-errors form-control-feedback">
                              {this.state.error_bank_ifsc_code} 
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="true">Branch</label>
                              <input autoComplete="off" type="text" className="form-control" value={this.state.bank_branch || ''}  placeholder="Branch" onChange={(e)=>{this.setState({bank_branch:e.target.value})}} name="bank_branch" useref="bank_branch"/>
                              <div className="help-block form-text with-errors form-control-feedback">
                              {this.state.error_bank_branch} 
                              </div>
                            </div>
                          </div>
                        </div>

                        <button className="btn btn-primary" type="submit" onClick={() => {this.updateIMPSPayment()}}>
                          {this.state.imps_loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                            ) : (
                            <>Update</>
                          )}
                        </button>
                      </div>
                      <div id="menu1" className="tab-pane fade">
                       {
                            this.state.upi_alert_message ? (
                            <div className="alert alert-info">
                                {this.state.upi_alert_message}
                            </div>
                            ) : (
                                null
                            )
                       }

                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="true">UPI ID</label>
                              <input autoComplete="off" type="text" className="form-control" value={this.state.upi_id} placeholder="UPI ID"  onChange={(e)=>{this.setState({upi_id:e.target.value})}} name="upi_id" />
                              <div className="help-block form-text with-errors form-control-feedback">
                              {this.state.error_upi_id} 
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary" type="submit"  onClick={() => {this.updateUPIPayment()}}>
                        {
                          this.state.upi_loader 
                          ? 
                          ( <span className="spinner-border spinner-border-sm mr-1 text-light"></span>  ) 
                          : 
                          ( <>Update</> )
                        }
                        </button>
                      </div>
                    </div>

                    

                  </fieldset>
              </div>
             
            </div>
          </div>
        </div>
      </div> 
      {this.state.modal_data.title ? <Popupmodal name={this.state.modal_data} /> : null}
    </div>
    
    );
  }
}

export default withRouter(Settings);
