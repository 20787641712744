import React from 'react';
import { Link } from 'react-router-dom';
import Highcharts from 'highcharts'
import MetaTags from 'react-meta-tags'
import HighchartsReact from 'highcharts-react-official'
import NumberFormat from 'react-number-format' 
import Cookies from 'js-cookie';
import * as myConstClass from '../../Constants';
import * as marketConstants from '../../constants/market_details';
import SearchInput, {createFilter} from 'react-search-input';
// import {connect} from 'react-redux'
// import {connectWalletData,removeWalletData} from '../../redux/Actions'

// const mapStateToProps=state=>({
//   data:state
// })

// const mapDispatchToProps=dispatch=>({
//   addETHWalletData:data=>dispatch(connectWalletData(data)),
//   removeToCartHandler: () => dispatch(removeWalletData()), 
// })

const KEYS_TO_FILTERS = ['name', 'symbol']
class market_details extends React.Component
{ 
    constructor (props) {  
    super(props);
    var post_slug;
    post_slug = window.location.pathname.substring(1);
    post_slug = post_slug.substring(post_slug.lastIndexOf("/"));
    post_slug = post_slug.replace(/\//,"");
    
    var path_name;
    path_name =  window.location.pathname;
    var path_name_array = path_name.split("/");
    var second_path_name = '';
    var first_path_name = '';
    var secondTokenAddress = '';
    
    if(path_name_array[3])
    {
      first_path_name =  path_name_array[2];
      second_path_name =  path_name_array[3];
      var secondTokenAddress = marketConstants.tokenAddresses[second_path_name];
    }
    else
    {
      first_path_name = post_slug;
      second_path_name = '';
    }
    
   
    var tokenAddresses = marketConstants.tokenAddresses[first_path_name];
    

    this.state = {
      page2Class: "", 
      fixedExchangesObject:marketConstants.fixed_exchanges_object,
      tokenAddresses:marketConstants.tokenAddresses,
      tokenNames:marketConstants.tokenNames,
      first_token_symbol:first_path_name,
      firstTokenAddress:tokenAddresses,
      secondTokenAddress:secondTokenAddress,
      tokenExchanges:marketConstants.tokenExchanges,
      formSwapStatus:false,
      current_market_price:'',
      blockchainSite:'',
      second_token_symbol:second_path_name,
      connect_wallet: false,
      reviewOrder: false,
      order_settings: false, 
      swapcoins:false,
      connectWalletStatus:false,
      connectShortWalletAddress:"",
      connectFullWalletAddress:"",
      handleModalConnection:false,
      getSwapExchangeCoins:false,
      website_base_url:myConstClass.website_base_url,
      api_base_url:myConstClass.api_base_url,
      defulatModalState: false,
      getMarketsDetails:"",
      getsymbolname:"",
      getusdvolume:"",
      getmarketcapusd:"",
      ethstation:"",
      get_value:"",
      searchTerm: "",
      searchTermSwapMarket: "",
      getexchangelist:[],
      gettokenlist:[],
      getSwapMarketsList:[],
      getSwapLiveTxnsData:"",
      tokenReceiveRate:"",
      crytoCurrencySymbol:marketConstants.currency_object,
      tokenDecimals:marketConstants.tokenDecimals,
      filter: "",
      receiveTokenValue:"",
      sendTokenValue:"",
      getGraphData:"",
      tokenName:"",
      slippagePercentage:"0.02",
      exchangesDataStatus:false,
      get_symbols_data:"",
      headers: {
        "Accept":"application/json"
      }  
    }

  } 
  // getSwapLiveTxnsData()
  // {
  //   var firstTokenAddress = this.state.firstTokenAddress
  //   var secondTokenAddress = this.state.secondTokenAddress
  // }

  componentDidMount()
  { 
    
      this.getMarketsDetails();
      this.getGraphData(1);
      // this.getMarketsList();
      //this.getSwapMarketsList();
      //this.getgasethstation();
      // this.getexchangelist();
      // this.getSingleTokenGraph();
      this.getSymbolContents();

    // this.state.api_base_url+"/markets/exchanges_list
    // console.log(this.state);
  }

  getGraphData(datetime)
  { 
    var crytoCurrencySymbol = this.state.crytoCurrencySymbol;
    
    var first_token_symbol = this.state.first_token_symbol;
    var first_token_symbol = first_token_symbol.toLowerCase();
    if(crytoCurrencySymbol[first_token_symbol] != 'undefined')
    {  
        var symbolName = crytoCurrencySymbol[first_token_symbol];

        
        //1 Day ago
        var from_date = new Date();
        var from_date =  from_date.setDate(from_date.getDate() - 1);
        var from_date =  Date.parse(new Date(from_date))/1000;
        var to_date = Date.parse(new Date())/1000;

        if(datetime === 1)
        { 
          //1 Day ago
          var from_date = new Date();
          var from_date =  from_date.setDate(from_date.getDate() - 1);
          var from_date =  Date.parse(new Date(from_date))/1000;
          var to_date = Date.parse(new Date())/1000;
        }
        else if(datetime === 2)
        {
          //7 Days ago
          var from_date = new Date();
          var from_date =  from_date.setDate(from_date.getDate() - 7);
          var from_date =  Date.parse(new Date(from_date))/1000;
          var to_date = Date.parse(new Date())/1000;
        }
        else if(datetime === 3)
        {
          //30 Days ago
          var from_date = new Date();
          var from_date =  from_date.setDate(from_date.getDate() - 20);
          var from_date =  Date.parse(new Date(from_date))/1000;
          var to_date = Date.parse(new Date())/1000;
        }

        fetch("https://api.coingecko.com/api/v3/coins/"+symbolName+"/market_chart/range?vs_currency=usd&from="+from_date+"&to="+to_date, {
          method:'GET',
          headers: this.state.headers
        }).then(res => res.json())
        .then(
          
          (result) => {
            console.log(result)
              this.setState({
                getGraphData:result.prices
              })
            //console.log(result);
        });
        } 
    
      
  }

  Capitalize(str)
  {
     return str.charAt(0).toUpperCase() + str.slice(1);
  }



  reviewTokenOrder()
  {
    var connectWalletStatus = Cookies.get('connectWalletStatus'); 
    if(this.state.connectWalletStatus && connectWalletStatus)
    {
      this.setState({reviewOrder:!this.state.reviewOrder})
      
    }
    else
    {
      this.setState({connect_wallet:!this.state.connect_wallet})
    }
  }

  getSingleTokenGraph()
  {
    
    const houroptions = {
      chart:  {
            zoomType: 'x',
            height: '320'
          },
    
          title: {
              text: this.Capitalize(this.state.tokenName)+" ("+this.state.first_token_symbol+")",
              align: 'left'
          },
    
          subtitle: {
            text: document.ontouchstart === undefined ?
            '' : ' '
          },
    
          tooltip: {
            
            fontStyle: "oblique",
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'white',
            valueDecimals: 2
          },
    
          xAxis: {
            type: 'datetime',
            lineWidth: 1,
            minorGridLineWidth: 1,
            lineColor: '#ddd',      
            labels: {
              enabled: true
            },
            minorTickLength: 1,
            tickLength: 0,
            gridLineWidth: 1,
            dateTimeLabelFormats: {
              day: "%e. %b",
              month: "%b '%y",
              year: "%Y"
            }
          },
    
          yAxis: {
              title: {
                  text: ''
              },
              lineWidth: 1,
              minorGridLineWidth: 1,
              lineColor: 'transparent',      
              labels: {
                enabled: true
              },
            minorTickLength: 1,
            tickLength: 0,
            gridLineWidth: 1,
          },
    
          colors: ['#000'],
    
          legend: {
              enabled: false
          },
    
          plotOptions: {
            
            area: {
              fillColor: {
                  linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1
                  },
                  stops: [
                      [0, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                      [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                  ]
              },
              marker: {
                  radius: 0
              },
              lineWidth: 2,
              states: {
                  hover: {
                      lineWidth: 2
                  }
              },
              threshold: null
            }
          },
          
          series: [{
            name:'<strong>Price in $</strong>',
            data: this.state.getGraphData
          }]
    }
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={houroptions}
      />
    )
  }

  getMarketsList()
  {
    fetch("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum%2Cdai%2Cwrapped-bitcoin%2Cwrapped-ethereum%2Cchainlink%2Cyearn-finance%2Cuniswap%2Ctether%2Cusd-coin%2Caave%2Cbinance-usd%2Csynthetix-network-token%2Cbalancer%2Comg-network%2Cmaker%2Ccompound%2Cuma%2Cpaxos-standard%2Crenbtc%2Cbasic-attention-token%2C0x%2Cren%2Ctrue-usd%2Ckyber-network%2Csushi%2Campleforth%2Cband-protocol%2Cnumeraire%2Cenjin-coin%2Caragon%2Cstatus%2Cdecentraland%2Ccurve-dao-token%2Caave-old%2Cbancor-network%2Cpax-gold%2Csusd%2Cbzx-protocol%2Cmstable-usd%2Cxdai-stake%2Clivepeer%2Ccream%2Ctbtc%2Ckeep-network%2Cseth%2Cpickle-finance%2Cakropolis%2Cmeta%2Cgemini-dollar%2Cfoam%2Cbased-money%2Cswerve%2Cbarnbridge%2Crenzec%2Cdonut%2Caugur&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=24h", {
      method:'GET',
      headers: this.state.headers
    }).then(res => res.json())
    .then(
      (result) => {
          this.setState({
            getMarketsList:result
          })
        //console.log(result);
     });
  }

  
  getMarketsDetails()
  {
    var crytoCurrencySymbol = this.state.crytoCurrencySymbol;
    
    var first_token_symbol = this.state.first_token_symbol;
    var first_token_symbol = first_token_symbol.toLowerCase();
    if(crytoCurrencySymbol[first_token_symbol] != 'undefined')
    {  
        var symbolName = crytoCurrencySymbol[first_token_symbol];

        // console.log('Contants symbols: ', crytoCurrencySymbol);
        // console.log('token: ', first_token_symbol);
        // console.log('first symbols: ', crytoCurrencySymbol[first_token_symbol]);

        fetch("https://api.coingecko.com/api/v3/coins/"+symbolName, {
          method:'GET',
          headers: this.state.headers
        }).then(res => res.json())
        .then(
          (result) => {
            if(!result.error)
            {
              //console.log(result);
              var innnerResult = [];
              if(result.links.blockchain_site)
              {
                result.links.blockchain_site.map((item) => 
                {
                  if(item !== '')
                  {
                    var res = item.replace("https://", "")
                    var res2 = res.split(".")
                    var createObj = {}
                    createObj['url'] = item;
                    createObj['name'] = res2[0];
                    innnerResult.push(createObj);
                  }
                 
                });
              }

              var innerResult2 = [];
              if(result.tickers)
              {
               
                  result.tickers.map((item) => {
                    if(item.target === 'USD')
                    { 
                      var identifier = item.market.identifier
                      var exchangeImage = this.state.tokenExchanges[identifier]
                      if(exchangeImage === undefined)
                      {
                        exchangeImage = this.state.website_base_url+'assets/images/exchange.png';
                      }
                      var objCreate2 = {}
                      objCreate2['base'] = item.base
                      objCreate2['image'] = exchangeImage
                      objCreate2['identifier'] = item.market.identifier
                      objCreate2['market_name'] = item.market.name
                      objCreate2['last_price'] = item.last
                      objCreate2['volume'] = item.volume
                      objCreate2['trust_score'] = item.trust_score
                      objCreate2['bid_ask_spread_percentage'] = item.bid_ask_spread_percentage
                      objCreate2['last_traded_at'] = item.last_traded_at
                      objCreate2['trade_url'] = item.trade_url
                      innerResult2.push(objCreate2)
                    }
                  })
                
               
              }
              
              this.setState({
                getMarketsDetails:result,
                tokenName:result.id,
                blockchainSite:innnerResult,
                marketData:innerResult2,
                current_market_price:result.market_data.current_price.usd
              })
              //console.log(innerResult2);
            }
            else
            {
             this.props.history.push('/market');
            }
        });
    }
    else
    {
      this.props.history.push('/market');
    }
  }

  


  

  orderSettings = () => {
    this.setState({order_settings:!this.state.order_settings})
  }

  swapCoins = () => {
    this.setState({swapcoins:!this.state.swapcoins})
  };

  addDefaultSrc(ev)
  {
    ev.target.src = 'http://192.168.1.100:81/aro/assets/currency_images/DAI.svg'
  }

  getSwapExchangeCoins = () => {
    this.setState({getSwapExchangeCoins:!this.state.getSwapExchangeCoins})
  };

  getSwapMarketsList()
  {
    var tokenAddresses = this.state.tokenAddresses;
    var tokenNames = this.state.tokenNames;
    let myArray = [];
    
    fetch("https://api.0x.org/swap/v1/prices?buyToken="+this.state.first_token_symbol, {
      method:'GET',
      headers: this.state.headers
    }).then(res => res.json())
    .then(
      (result) => {
        //console.log("sellTOken" ,result);
        if(result.records)
        {
          result.records.map((item, i) =>
          {
            var createObj = {};
            createObj['address'] = tokenAddresses[item.symbol];
            createObj['price'] = item.price;
            createObj['symbol'] = item.symbol;
            createObj['name'] = tokenNames[item.symbol];
  
            myArray.push(createObj);
          });
  
          this.setState({
            getSwapMarketsList:myArray
          });
          //console.log("sellTOken" ,myArray);
        }


        
       
        // this.setState({
        //   getSwapMarketsList:result
        // })
      
    });
    this.blockchainSite();
  }
  

  getExchagesList()
  {
    return (
      <React.Fragment>
         {         
                  this.state.fixedExchangesObject ?
                    <React.Fragment>
                    <ul>
                    {
                      this.state.fixedExchangesObject.map((item, i) => {
                        return this.state.first_token_symbol !== item.symbol 
                                  ?
                                <li key={i}>
                                <Link to={"/market/"+this.state.first_token_symbol+'/'+item.symbol}>
                                    <h5>
                                      <img src={this.state.api_base_url+'assets/currency_images/'+item.symbol+'.svg'}  /> {item.symbol.toUpperCase()}
                                    </h5>
                                  </Link>
                                </li>
                                :
                                null
                      } 
                    )
                    
                    }
                    </ul>
                    </React.Fragment>
                  :
                  null    
              }
      </React.Fragment>
    );
  }

 
  

  convertvalue(labelValue) {
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? Math.trunc(Math.abs(Number(labelValue)) / 1.0e+9*100)/100 + "B"

    : Math.abs(Number(labelValue)) >= 1.0e+6
    
    ? Math.trunc(Math.abs(Number(labelValue)) / 1.0e+6*100)/100 + "M"

    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? Math.trunc(Math.abs(Number(labelValue)) / 1.0e+3*100)/100 + "K"

    : Math.abs(Number(labelValue));

}

getgasvalue(getvalue) {
  this.setState ({
    get_value:getvalue
  })
}

getexchangelist() 
{ 
  this.setState({
    exchangesDataStatus:false
  })
  fetch(this.state.api_base_url+"/markets/exchanges_list", {
      method:'GET',
      headers: this.state.headers
    }).then(res => res.json())
    .then(
    (result) => {
      this.setState({
        getexchangelist:result.message,
        exchangesDataStatus:true
      })
      //console.log(result.message);
  });
}

getSymbolContents() 
{ 
  var data = {
    symbol: this.state.first_token_symbol
  };
  
  fetch(this.state.api_base_url+"/markets/symbol_contents", {
      method:'POST',
      headers: this.state.headers,
      body:JSON.stringify(data)
    }).then(res => res.json())
    .then(
    (result) => {
      console.log(result);
      if(result.status === true) 
      {
        this.setState({ 
          get_symbols_data:result.alert_message
        })
      //console.log(this.state.get_symbols_data);
      }
  });
  
}

gettokenlist() 
{
  fetch("https://api.0x.org/swap/v1/tokens", {
    method:'GET',
    headers: this.state.headers
  }).then(res => res.json())
  .then(
    (result) => {
      this.setState({
        gettokenlist:result.records,
      }) 
  });
}

getMarketsList()
{
  fetch("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum%2Cdai%2Cwrapped-bitcoin%2Cwrapped-ethereum%2Cchainlink%2Cyearn-finance%2Cuniswap%2Ctether%2Cusd-coin%2Caave%2Cbinance-usd%2Csynthetix-network-token%2Cbalancer%2Comg-network%2Cmaker%2Ccompound%2Cuma%2Cpaxos-standard%2Crenbtc%2Cbasic-attention-token%2C0x%2Cren%2Ctrue-usd%2Ckyber-network%2Csushi%2Campleforth%2Cband-protocol%2Cnumeraire%2Cenjin-coin%2Caragon%2Cstatus%2Cdecentraland%2Ccurve-dao-token%2Caave-old%2Cbancor-network%2Cpax-gold%2Csusd%2Cbzx-protocol%2Cmstable-usd%2Cxdai-stake%2Clivepeer%2Ccream%2Ctbtc%2Ckeep-network%2Cseth%2Cpickle-finance%2Cakropolis%2Cmeta%2Cgemini-dollar%2Cfoam%2Cbased-money%2Cswerve%2Cbarnbridge%2Crenzec%2Cdonut%2Caugur&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=24h", {
    method:'GET',
    headers: this.state.headers
  }).then(res => res.json())
  .then(
    (result) => {
        this.setState({
          gettokenlist:result
        })
      //console.log(result);
   });

}

blockchainSite()
{ 
  var result = [];
  
   if(this.state.getMarketsDetails)
   {
    this.state.getMarketsDetails.links.blockchain_site.map((item) => 
    {
      var res = item.replace("https://", "")
      var res2 = res.split(".")
      var createObj = {}
      createObj['url'] = item;
      createObj['name'] = res2[0];
      result.push(createObj);
    });
   }

   this.setState({
    blockchainSite:result
   }); 
}



onChangeValue(event) {
  console.log(event.target.value);
}

callSwapForm()
{
  this.setState({formSwapStatus:!this.state.formSwapStatus, active: !this.state.active});
}
 
  render()
  {
   
    const filterednames = this.state.gettokenlist.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
    var filteredNamesSwapMarkets = this.state.getSwapMarketsList.filter(createFilter(this.state.searchTermSwapMarket, KEYS_TO_FILTERS))
    if(this.state.getMarketsDetails)
    {
      var asdfsdaf = this.state.getMarketsDetails.market_data.current_price.usd
    } 
    else 
    {
      var asdfsdaf = ''
    }
    return (
      <div id="Content" style={{background: '#e9edf2'}}>
         <MetaTags>
          <title>
          {this.state.first_token_symbol} - ${
          this.state.getMarketsDetails ?
          this.state.getMarketsDetails.market_data.current_price.usd
          :
          null
          } | Arrano.network </title>
          <link rel="canonical" href="https://arrano.network/" />
          {
              this.state.get_symbols_data ?
              <React.Fragment>
                 <meta name="description" content={this.state.meta_description} />
                  <meta name="keyword" content={this.state.meta_keywords} />
              </React.Fragment>
              :
              null
          }

         
          <meta property="og:title" content={this.state.first_token_symbol+' '+this.state.current_market_price+' | Arrano.network'} />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        
        <div className="main_info_page_fixed">
          <ul>
            <li><a href="#details_overview"><i className="fas fa-info-circle"></i></a></li>
          </ul>
        </div> 
        <div className="container">

          {
            this.state.getMarketsDetails ?
            <div className="row">
              <div className="col-md-4 coins_value_symbol">
                <h5 style={{ textTransform:"capitalize" }}>{this.state.getMarketsDetails.id} ({this.state.first_token_symbol})</h5>
                <h3><NumberFormat className="h3-tag" value={this.state.getMarketsDetails.market_data.current_price.usd} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                  {
                    this.state.getMarketsDetails.market_data.price_change_percentage_24h >= 0 ?
                    <span className="green_up"><i className="fas fa-caret-up"></i> {this.state.getMarketsDetails.market_data.price_change_percentage_24h.toFixed(2)}%</span>
                    :
                    <span className="red_down"><i className="fas fa-caret-down"></i> {this.state.getMarketsDetails.market_data.price_change_percentage_24h.toFixed(2)}%</span>
                  }
                </h3>
              </div>
            
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="market_values">
                      <h4>Market Cap</h4>
                      <h5><NumberFormat value={this.state.getMarketsDetails.market_data.market_cap.usd} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="market_values">
                      <h4>24 Hour Trading Vol</h4>
                      <h5><NumberFormat value={this.state.getMarketsDetails.market_data.total_volume.usd} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="market_values">
                      <h4>24h Low-24h High</h4>
                      <h5>${this.state.getMarketsDetails.market_data.low_24h.usd}-{this.state.getMarketsDetails.market_data.low_24h.usd}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
          

          <div className="row">
            <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <div className="trading_form mt-3 market_form" >
                  <div className="trading_form_header">
                    <h4>Market Order <span  data-toggle="modal" data-target="#ComingSoon"><i className="fas fa-sliders-h"></i></span></h4>
                  </div>
                  
                  <div className="trading_form_body">
                    <div className="col-md-12">
                      <div className="top_main_block">
                        <div className="row">
                          <div className="col-md-4 col-6">
                            <h5>You Pay</h5>
                          </div>
                          <div className="col-md-8 col-6">
                            <h6>Max 0 ETH</h6>
                          </div>
                        </div>
                        {
                          this.state.formSwapStatus === false ?
                          <React.Fragment>
                             {
                                  this.state.second_token_symbol ?
                                  <div className="row">
                                  <div className="col-md-5 col-6">
                                    <div className="choose_token_select" data-toggle="modal" data-target="#ComingSoon" >
                                    {this.state.second_token_symbol} <i className="fas fa-chevron-down"></i> 
                                    </div>
                                  </div>
                                  <div className="col-md-7 col-6">
                                    <div className="form-group">
                                      <input type="text" className="form-control" readOnly autoComplete="off" step="0.0001" min="0.01" value={this.state.sendTokenValue} onChange={this.handleKeyUp2}  name="sendTokenValue"  />
                                    </div>
                                  </div>
                                </div>
                                  :
                                  <div className="row">
                                  <div className="col-md-12 col-12">
                                    <div className="choose_token_select" data-toggle="modal" data-target="#ComingSoon"  style={{paddingBottom:"30px"}}>
                                    Choose Token <i className="fas fa-chevron-down"></i> 
                                    </div>
                                  </div>
                                  </div>
                             }
                           
                          </React.Fragment>
                          
                          :
                          <div className="row">
                            <div className="col-md-5 col-6">
                              <div className="choose_token_select" data-toggle="modal" data-target="#ComingSoon" >
                              {this.state.first_token_symbol.toUpperCase()}
                              <i className="fas fa-chevron-down"></i>
                              </div>
                            </div>
                            <div className="col-md-7 col-6">
                              <div className="form-group">
                                <input autoComplete="off" type="text" readOnly className="form-control"  step="0.0001" min="0.01" value={this.state.receiveTokenValue} onKeyUp={this.handleKeyUp}  name="receiveTokenValue" />
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <div className="col-md-12 swap_icon_display">
                      <img src="https://cdn.iconscout.com/icon/free/png-512/swap-1768053-1502226.png" className={this.state.active && 'swapicontoken'} onClick={()=> this.callSwapForm()}/>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="choose_token">
                      <div className="row">
                      <div className="col-md-6 col-6">
                        <h4>You Receive</h4>
                      </div>
                       
                      {
                        this.state.second_token_symbol && (this.state.receiveTokenValue > 0) ?
                      <div className="col-md-6 col-6">
                            <div>
                              <p className="exchange_types" 
                              data-toggle="popover" 
                              data-placement="bottom"
                              data-html="true" 
                              data-content="
                                <h6>15 Exchanges Searched</h6>
                                <h5>We got you the best price
                                from 0x & SushiSwap!</h5>
                                <div class='row'>
                                  <div class='col-md-6'>
                                    <div><span class='uniswap'>68%</span> Uniswap</div>
                                  </div>
                                  <div class='col-md-6'>
                                    <div><span class='sushiswap'>32%</span> SushiSwap</div>
                                  </div>
                                </div>
                                
                                <div class='row'>
                                  <div class='col-md-6'>
                                    <div>Expected Rate</div>
                                  </div>
                                  <div class='col-md-6'>
                                    <div>4,895,239.0653 DAI</div>
                                  </div>
                                </div>
                                <div class='row'>
                                  <div class='col-md-6'>
                                    <div>Minimum Rate</div>
                                  </div>
                                  <div class='col-md-6'>
                                    <div>4,944,191.456 DAI</div>
                                  </div>
                                </div>
                                <div class='row'>
                                  <div class='col-md-6'>
                                    <div>Max Price Slippage</div>
                                  </div>
                                  <div class='col-md-6'>
                                    <div>1%</div>
                                  </div>
                                </div>  
                              ">1 {this.state.first_token_symbol} =  
                              {
                                this.state.tokenReceiveRate > 0 ?
                                 this.state.tokenReceiveRate
                                :
                                null
                              }  
                                {this.state.second_token_symbol} <i className="fas fa-info-circle"></i></p>
                              
                            </div>

                         

                          </div>
                            :
                            null
                        }
                        </div>

                       

                        {
                          this.state.formSwapStatus === false ?
                          <div className="row">
                            <div className="col-md-5 col-6">
                              <div className="choose_token_select"  data-toggle="modal" data-target="#ComingSoon">
                              {this.state.first_token_symbol.toUpperCase()} 
                                <i className="fas fa-chevron-down"></i>
                              </div>
                            </div>
                            <div className="col-md-7 col-6">
                              <div className="form-group">
                              <input  autoComplete="off" type="text" readOnly className="form-control"  step="0.0001" min="0.01" value={this.state.receiveTokenValue} onChange={this.handleKeyUp}  name="receiveTokenValue" />
                              </div>
                            </div>
                          </div>
                         :
                         <div className="row">
                         <div className="col-md-5 col-6">
                           <div className="choose_token_select" data-toggle="modal" data-target="#ComingSoon">
                               {
                                  this.state.second_token_symbol ?
                                  <React.Fragment>
                                    {this.state.second_token_symbol}
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    Choose Token
                                  </React.Fragment>
                                }
                            
                              <i className="fas fa-chevron-down"></i>
                           </div>
                         </div>
                         <div className="col-md-7 col-6">
                           <div className="form-group">
                            <input type="text" className="form-control" readOnly autoComplete="off" step="0.0001" min="0.01" value={this.state.sendTokenValue} onChange={this.handleKeyUp2}  name="sendTokenValue"  />
                           </div>
                         </div>
                       </div>
                        }

                         <button className="button_fill btn-disabled" data-toggle="modal" data-target="#ComingSoon">Review Order</button>
                       
                      </div>
                    </div>
                  </div>
                </div>

                {
                  this.state.swapcoins 
                  ?
                    <div className="connect_wallet display_main_block swap_coin_list">
                      <p className="swap_coin_header">You Receive <span  onClick={()=>this.setState({swapcoins:false})}><i className="fas fa-times"></i></span></p>

                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"></i></span>
                        </div>
                        <SearchInput type="text" onChange={this.searchUpdated}  placeholder="Search or Paste any token" aria-label="Username" aria-describedby="basic-addon1" />
                      </div>
                        <div className="token_list_display">
                        {
                          filterednames.map((item, i) => {
                            return (
                              <Link key={i} to={"/market/"+item.symbol.toUpperCase()+'/'+this.state.second_token_symbol}>
                              <div className="media" key={item.name}>
                                <img className="mr-1" src={this.state.api_base_url+'assets/currency_images/'+item.symbol+'.svg'} alt=""  />
                                <div className="media-body">
                                  <h5 className="mt-0">{item.name} - {item.symbol.toUpperCase()}</h5>
                                </div>
                              </div>
                              </Link>
                              )
                            })
                          } 
                        </div>
                        
                    </div>
                  :
                  null
                }



              {
                this.state.getSwapExchangeCoins 
                ?
                <div className="connect_wallet display_main_block swap_coin_list">
                  <p className="swap_coin_header">You Pay <span  onClick={()=>this.setState({getSwapExchangeCoins:false})}><i className="fas fa-times"></i></span></p>

                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"></i></span>
                    </div>
                    <SearchInput type="text" onChange={this.searchTermSwapMarket}  placeholder="Search or Paste any token" aria-label="Username" aria-describedby="basic-addon1" />
                  </div>
                    <div className="token_list_display">
                    {
                      filteredNamesSwapMarkets.map((item, i) => {
                        return (
                          <Link key={i} to={"/market/"+this.state.first_token_symbol+'/'+item.symbol} >
                          <div className="media" key={item.name}>
                            <img className="mr-1" src={this.state.api_base_url+'assets/currency_images/'+item.symbol+'.svg'} alt="" onError={this.addDefaultSrc} />
                            <div className="media-body">
                              <h5 className="mt-0">{item.name} - {item.symbol.toUpperCase()}</h5>
                            </div>
                          </div>
                          </Link>
                          )
                        })
                      } 
                    </div>
                  </div>
                :
                null
              }





  {
    this.state.connect_wallet
    ?

      <div className="connect_wallet display_main_block"  >
        <p className="connect_wallet_sub"><span onClick={()=>this.setState({connect_wallet:false})}><i className="fas fa-long-arrow-alt-left"></i></span>Connect Wallet</p>
        <h3>Connect your Wallet to Proceed</h3>
        <div className="media p-1">
          <img src="https://cdn.freebiesupply.com/logos/thumbs/2x/metamask-logo.png" alt="John Doe" />
          <div className="media-body">
            <h4 onClick={() =>this.connectToWallet()}>Connect to Metamask</h4>
          </div>
        </div>

        <div className="media p-1">
          <img src="https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/b0/64/0e/b0640ece-2cbd-493e-6d80-23c3c6df8564/AppIcon-0-1x_U007emarketing-0-10-0-85-220.png/320x0w.png" alt="John Doe" />
          <div className="media-body">
            <h4>Connect Trust Wallet(Coming Soon)</h4>
          </div>
        </div>

        <div className="media p-1">
          <img src="https://lh3.googleusercontent.com/3pwxYyiwivFCYflDJtyWDnJ3ZgYuN_wBQBHqCXbKh9tJTdTL1uOrY1VyxeC_yXLTNZk" alt="John Doe" />
          <div className="media-body">
            <h4>Connect Coinbase Wallet(Coming Soon)</h4>
          </div>
        </div>

        <div className="media p-1">
          <img src="https://pbs.twimg.com/profile_images/991423717304549376/82F8qlM1.jpg" alt="John Doe" />
          <div className="media-body">
            <h4>Connect Bitski Wallet(Coming Soon)</h4>
          </div>
        </div>
      </div>
    :
    null
  }

  {
    this.state.reviewOrder
    ?
      <div className="connect_wallet display_main_block"  >
        <p className="connect_wallet_sub"><span onClick={()=>this.setState({reviewOrder:false})}><i className="fas fa-long-arrow-alt-left"></i></span> Quote expires in 3 seconds</p>
          <div className="media">
            <div className="media-body">
              <div className="token_submit_order">
                  <div className="col-md-12">
                    <div className="top_main_block">
                        <h5>You Pay</h5>
                        <p>{this.state.sendTokenValue} {this.state.second_token_symbol}</p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          

          <div className="token_submit_order">
            <div className="col-md-12">
              <div className="top_main_block">
                <h5>You Receive</h5>
                <p>{this.state.receiveTokenValue} {this.state.first_token_symbol}</p>
              </div>
            </div>
          </div>
          <div className="market_details_rate">
            <div className="row">
              <div className="col-md-5">
                <p>Rate</p>
              </div>
              <div className="col-md-7">
                <p> 1 
                {this.state.first_token_symbol} @ {
                  this.state.tokenReceiveRate > 0 ?
                    this.state.tokenReceiveRate
                  :
                  null
                } {this.state.first_token_symbol}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5">
                <p>Estimated Fee</p>
              </div>
              <div className="col-md-7">
                <p> $ 3.22</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5">
                <p>You Save</p>
              </div>
              <div className="col-md-7">
                <p> $ 0.25</p>
              </div>
            </div>
          </div>
          <div>
            
          </div> 
          
          <div>
            {
              (this.state.sendTokenValue > 0) && (this.state.receiveTokenValue > 0) ?
              <React.Fragment>
                  <button className="btn btn-block btn-primary" onClick={()=>this.reviewTokenOrder()} >Place Order</button>
              </React.Fragment>
              :
              <React.Fragment>
                  <button className="btn btn-block btn-primary-outline" disabled>Refresh quote</button>
              </React.Fragment>
            }
          </div>
      </div>
    :
    null
  }


  

                {
                  this.state.order_settings 
                  ?
                    <div className="connect_wallet display_main_block advanced_settings" >
                      <h3>Advanced Settings <span onClick={()=>this.setState({order_settings:false})}>X</span></h3>
                      <div className="setting_body">
                        <div className="accordion" id="swap_settings">
                          <div className="card">
                              <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#maxslippage">
                                  <h2 className="mb-0">
                                      <button type="button" className="btn btn-link">Max Slippage <span>1% <i className="fas fa-chevron-down"></i></span></button>									
                                  </h2>
                              </div>
                              <div id="maxslippage" className="collapse" aria-labelledby="headingOne" data-parent="#swap_settings">
                                  <div className="card-body">
                                      
                                  </div>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-header" id="headingTwo">
                                  <h2 className="mb-0">
                                    <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo">Exchanges <span>{this.state.getexchangelist.length} <i className="fas fa-chevron-down"></i></span></button>
                                  </h2>
                              </div>
                              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#swap_settings">
                                <div className="card-body">
                                  <div className="row">
                                    {
                                      this.state.getexchangelist.map((item,i) => 
                                        <div key={i} className="col-md-6 col-6">
                                          <div className="">
                                            <label><input type="checkbox" value="" defaultChecked /> {item.exchange_name}</label>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </div>


                                </div>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                  <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree">Gas Price <span> {this.state.get_value} <i className="fas fa-chevron-down"></i></span></button>                     
                                </h2>
                              </div>
                              <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#swap_settings">
                                  <div className="card-body">
                                    <div onChange={this.onChangeValue}>
                                      <div className="main_gas_price">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="">
                                              <label onClick={() => {this.getgasvalue(this.state.ethstation.fast)}}><input type="radio" name="gasvalue"  defaultChecked/>Fast <span>{this.state.ethstation.fast}</span></label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="main_gas_price ">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="">
                                              <label onClick={() => {this.getgasvalue(this.state.ethstation.fastest)}}><input type="radio" name="gasvalue"  />Very fast <span>{this.state.ethstation.fastest}</span></label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="main_gas_price ">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="">
                                              <label onClick={() => {this.getgasvalue(this.state.ethstation.average)}}><input type="radio" name="gasvalue"  />Average <span>{this.state.ethstation.average}</span></label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="main_gas_price ">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="">
                                              <label onClick={() => {this.getgasvalue(this.state.ethstation.safeLow)}}><input type="radio" name="gasvalue"  />Safe Low <span>{this.state.ethstation.safeLow}</span></label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            </div>

              <div className="col-md-8">  
              <div className="chart_main">
                <div id="tooltip"></div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#home" onClick={() => {this.getGraphData(1)}}>24H</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#home" onClick={() => {this.getGraphData(2)}}>1W</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#home" onClick={() => {this.getGraphData(3)}}>1M</a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane container active" id="home">
                      {this.getSingleTokenGraph()}
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#Overview">Overview</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Exchange">Exchange</a>
                </li>
              </ul>
              
              <div className="tab-content main_tab_content_list">
                <div className="tab-pane container active show" id="Overview">
                {
                   this.state.getMarketsDetails ?
                  <div className="market_details">
                    <div className="row">
                      <div className="col-md-6">
                        <table style={{marginTop: '15px'}}>
                          <tbody>
                          <tr>
                            <td><h5><i className="fas fa-chart-line"></i> Market Cap Rank</h5></td>
                            <td>:</td>
                            <td>
                              <h6 className="main_span_types">#{this.state.getMarketsDetails.market_cap_rank}</h6>
                            </td>
                          </tr>
                          <tr>
                            <td><h5><i  className="fas fa-percentage"></i> Circulating Supply</h5></td>
                            <td>:</td>
                            <td style={{textAlign:'left'}}>
                              <NumberFormat className="h3-tag" value={this.state.getMarketsDetails.market_data.circulating_supply} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </td>
                          </tr>
                          <tr>
                            <td><h5><i className="fas fa-globe"></i> Website</h5></td>
                            <td>:</td>
                            <td>
                              <h6 className="main_span_types"><a href={this.state.getMarketsDetails.links.homepage[0]} target="_blank">{this.state.getMarketsDetails.links.homepage[0]}</a></h6>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-md-6 col-12">
                        <table className="table ">
                        <tbody>
                          <tr>
                            <td><h5> Tags</h5></td>
                            <td>:</td>
                            <td>
                              <h6 className="main_span_types">
                                {
                                  this.state.getMarketsDetails.categories.map((item, i) =>
                                  <span key={i}>{item}</span> 
                                  )
                                }
                              </h6>
                            </td>
                          </tr>
                          
                          <tr>
                            <td><h5> Community</h5></td>
                            <td>:</td>
                            <td>
                            <h6 className="main_span_types">
                              {
                                this.state.getMarketsDetails.links.official_forum_url && this.state.getMarketsDetails.links.official_forum_url !== '' ?
                                <span><a href={this.state.getMarketsDetails.links.official_forum_url+""}  target="_blank">{this.state.getMarketsDetails.links.official_forum_url}</a> </span>
                                :
                                null
                              }
                              
                              {
                                this.state.getMarketsDetails.links.twitter_screen_name && this.state.getMarketsDetails.links.twitter_screen_name !== '' ?
                                <span><a href={"https://twitter.com/"+this.state.getMarketsDetails.links.twitter_screen_name} target="_blank">Twitter</a></span>
                                :
                                null
                              }

                              {
                                this.state.getMarketsDetails.links.facebook_username && this.state.getMarketsDetails.links.facebook_username !== '' ?
                                <span><a href={"https://www.facebook.com/"+this.state.getMarketsDetails.links.facebook_username} target="_blank">Facebook</a></span>
                                :
                                null
                              }

                              {
                                this.state.getMarketsDetails.links.subreddit_url && this.state.getMarketsDetails.links.subreddit_url !== '' ?
                                <span><a href={this.state.getMarketsDetails.links.subreddit_url} target="_blank">Reddit</a> </span>
                                :
                                null
                              } 
                            </h6>
                            </td>
                          </tr>
                          <tr>
                            <td style={{width:'115px'}}><h5> Explorers</h5></td>
                            <td>:</td>
                            <td>
                              <h6 className="main_span_types">
                                {
                                  this.state.blockchainSite ?
                                  this.state.blockchainSite.map((item, i) => 
                                  <span key={i}><a href={item.url}  target="_blank">{item.name}</a> </span>
                                  )
                                  :
                                  null
                                }
                              </h6>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                     
                      
                    </div>
                  </div>
                  :
                  null
                }

                  <div className="overview_details" id="details_overview">
                  {
                      this.state.get_symbols_data.currency_contents ?
                      <React.Fragment>
                        {/* <h5 style={{ textTransform:"capitalize" }}> About {this.state.getMarketsDetails.id} ({this.state.first_token_symbol})</h5> */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.get_symbols_data.currency_contents }}> 
                        </div>
                      </React.Fragment>
                      :
                      null 
                  }
                    </div>
                </div>
                
                <div className="tab-pane container fade market_exchange" id="Exchange">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                          <tr>
                            <th>Exchange</th>
                            <th>Pair</th>
                            <th>Price</th>
                            <th  className="mobile_hide_col">Volume %</th>
                            <th  className="mobile_hide_col">Volume</th>
                            <th  className="mobile_hide_col">Last Traded</th>
                            <th  className="mobile_hide_col">Trust Score</th>
                          </tr>
                      </thead>
                      <tbody>
                        
               

                        {
                          this.state.marketData ?
                          this.state.marketData.map((item, i) => 
                          <tr key={i}>
                            <td>
                                <a href={item.trade_url} target="_blank">
                                  <div className="media">
                                      <div className="media-left">
                                        <img src={item.image} className="media-object" />
                                      </div>
                                      <div className="media-body">
                                        <h4 className="media-heading">{item.market_name} </h4>
                                      </div>
                                  </div>
                                </a>
                            </td>
                            <td>
                                <a href={item.trade_url} target="_blank">
                                  <h5>{item.base}/USDT</h5>
                                </a>
                            </td>
                            <td>
                                <a href={item.trade_url} target="_blank">
                                  <h5>
                                      <NumberFormat value={item.last_price.toFixed(4)} displayType={'text'} thousandSeparator={true} prefix={'$'} />               
                                  </h5>
                                </a>
                            </td>
                            <td className="mobile_hide_col">
                                <a href={item.trade_url} target="_blank">
                                  <h5>
                                    {
                                      item.bid_ask_spread_percentage ?
                                      item.bid_ask_spread_percentage > 0?
                                      <span className="status_green">{item.bid_ask_spread_percentage.toFixed(2)} % <i className="fa fa-caret-up" aria-hidden="true"></i></span>
                                      :
                                      <span className="status_red">{item.bid_ask_spread_percentage.toFixed(2)} % <i className="fa fa-caret-down" aria-hidden="true"></i></span>
                                      :
                                      null
                                    }
                                  </h5>
                                </a>
                            </td>
                            <td>
                                <a href={item.trade_url} target="_blank">
                                  <h5>
                                  <NumberFormat value={item.volume.toFixed(4)} displayType={'text'} thousandSeparator={true} prefix={''} />    
                                  
                                  </h5>
                                </a>
                            </td>
                            <td className="mobile_hide_col">
                                <a href={item.trade_url} target="_blank">
                                  Recently
                                </a>
                            </td>
                            <td className="mobile_hide_col">
                                <a href={item.trade_url} target="_blank">
                                  <div className={item.trust_score}></div>
                                </a>
                            </td>
                          </tr>
                          )
                          :
                          null
                          }
                       
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

         {/* Install Metamask - Starts here*/}
         <div className={"modal  modal_main_popup" + (this.state.handleModalConnection ? " show d-block" : " d-none")}>
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button"  className="close" data-dismiss="modal" onClick={()=> {this.handleModalConnection()}}>&times;</button>
                  <h2>Connection Error!</h2>
                  <p>Sorry, no ethereum wallet found.</p>
                </div>
              </div>
            </div>
          </div>
          {/* Install Metamask - Ends Here*/}  

        {/* coming soon popup starts      */}
        <div className="modal" id="ComingSoon">
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                <button type="button"  className="close" data-dismiss="modal" >&times;</button>
                  <h2>Coming Soon!</h2>
                  <p>The Decentralized will be coming soon</p>
                </div>
              </div>
            </div>
          </div>
          {/* coming soon popup ends here */} 
        </div>
    );
  }
}

// export default withRouter(connect(mapStateToProps,mapDispatchToProps)(market_details))


export default market_details;