import React from 'react';
import Topmenu from '../../layouts/Topmenu';
import MetaTags from 'react-meta-tags';
import Footer from '../../layouts/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class academy_crypto_basics_you_should_know extends React.Component 
{
  constructor() {
  super();
}

render(){
  return (
    <>

      <div id="Content">
        <MetaTags>
          <title>Cryptocurrency Insights</title> 
          <meta name="description" content="Discover the latest and events in the fintech and crypto eco-system" />
          <meta name="keyword" content=" Crypto insights, defi trends, defi markets , cryptocurrency market trends, decentralised  market insights" />
          <meta property="og:title" content="Cryptocurrency Insights" />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        <div className="content_wrapper clearfix">
          <div className="sections_group">
            <div className="entry-content">
              <div className="about_page ">
                <div className="container">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="academy_detail_lessons">
                        <div className="row">
                          <div className="col-md-2"></div>
                          <div className="col-md-8">
                            <div className="academy_details_page">
                              <div className="row">
                                <div className="col-md-9">
                                  <h4 className="course_title">Crypto Basics you should know!</h4>
                                  <p className="course_tag"><span>Course #1</span> </p>
                                </div>
                                <div className="col-md-3">
                                  <p className="back_button"><Link to="/academy/all"><i class="fas fa-arrow-circle-left"></i> Back to Courses</Link></p>
                                </div>
                              </div>
                                <h6 className="course_overview"><i>This course will help you get started with cryptocurrency by understanding the basics and frontline elements of this new age financing.</i></h6>
                                
                                <div className="course_cover">
                                  <h5>Cover </h5>
                                  <ul>
                                    <li><a href="#cryptocurrency">Cryptocurrency</a></li>
                                    <li><a href="#blockchain">Blockchain Technology </a></li>
                                    <li><a href="#wallet">Wallets</a></li>
                                    <li><a href="#exchage">Exchanges</a></li>
                                  </ul>
                                </div>

                                <div className="course_lesson_data" id="cryptocurrency">
                                  <h5 id="cryptocurrency">1. Cryptocurrency</h5>

                                  <p>Cryptocurrency is like digital money that can be used for any general purpose just like the fiat currencies dollars. euros....You can send or receive the currency with anyone, anywhere and anytime in the world without any bank or central authority. Existing payment system involves banks that hold your funds and you will need technical permissions from them everytime you need to spend. </p>

                                  <p>Cryptocurrency is a decentralized currency system, there is no bank involved and so the payments are made directly from person to person under the hood of the Blockchain technology network. A Technology that cannot be tampered, that is  faster and secure than existing systems. </p>

                                  <p>You just need a crypto wallet to store, send and receive cryptocurrencies that can be created in minutes. </p>

                                  <p>For Eg : To manage bitcoin you need a blockchain wallet . To Manage your Ethereum you will need an Ether wallet like Metamask, Trust Wallet etc. </p>

                                  <p>The name Cryptocurrency comes from its nature of work, that is <b>Cryptography</b> and <b>Currency</b>. Cryptography is a form of complex maths that executes and encrypts data that cannot be hacked or altered. </p>
                                  
                                  <p>Currently there are thousands of cryptocurrencies available with upgraded technical features with regards to security, scalability, privacy and speed.</p>

                                  <h6><b>1.1 Bitcoin </b></h6>

                                  <p>Bitcoin(BTC) is first ever introduced Cryptocurrency which served as foundation to all the cryptocurrencies created. Bitcoin was released in 2009 by an anonymous person named ‘Satoshi Nakomoto” ( a person or a group of people). </p>

                                  <p>In 2009, bitcoin’s whitepaper document was released which provides complete information of blockchain technology, purpose, tokenomics and use cases of bitcoin. Few months later the Coin itself was released. </p>


                                  <p>Bitcoin is also called the kIng of Cryptocurrencies and also holds market Dominance of above 70% Alone as of Jan 2021. </p>

                                  <p>With this amount of Dominance you can Trade BTC into 98% of exchanges with maximum pairs. Bitcoin is the only globally P2P traded cryptocurrency.</p>


                                  <h6><b>1.2  The Altcoins</b> </h6>

                                  <p>Altcoins are basically cryptocurrencies other than Bitcoin (BTC) like Ethereum, Litecoin, Ripple,etc  generally called alternative coins or alternate coins . Altcoins can be a Coin or Token, <b><i>Coin:</i></b> has its own blockchain like ETH on Ethereum blockchain, TRX on Tron Blockchain ..etc.  </p>

                                  <p><b><i>Token</i></b> is also a Crypto asset built on top of other blockchain networks, like ERC-20 tokens that are built on Ethereum blockchain. </p>

                                  <p>Some altcoins are simply the modified version of an existing asset, this is done by a process called <b><i>HardFork.</i></b> for ex : Bitcoin Cash ( BCH ) is the hard fork of bitcoin , Ethereum Classic ( ETC) is the hard fork Ethereum. </p>


                                  <p>With a common purpose to serve as a Cryptocurrency , each altcoin will have its own functionality differing from each other/ </p>

                                   </div>




                                   <div className="course_lesson_data" id="blockchain">
                                  <h5 >2. Blockchain</h5>

                                  <p>As you’ve heard it many times now, how complex a technological piece is blockchain, but blockchain simply a ledger or Database that stores data in blocks like a Spreadsheet. </p>

                                  <p>But Blockchain functions in a different way than the traditional database. </p>

                                  <ul className="contents-in-dots">
                                    <li>Blockchain works  as an “ Append only “ form , meaning you can only add information and cannot delete or alter the added information in any way. </li>
                                     <li>The combination of Block and Hash, whereas the block is an entry where data is stored and Hash is just like the signature of a previous block. Every Hash entry points back to the previous block and so it is called “ Block-Chain “: </li>
                                      <li>Blockchain is Immutable, tha means if you try to alter any block, the previous block will receive a different signature and thus change the signature of its previous block. Hence the transaction not reaching its destination. </li>
                                  </ul>

                               

                                  <p>Blockchain program works on an unique algorithm called <b>“ Consensus Algorithm’</b> that allows computers(miners) to coordinate with each other to validate the transactions with Proof of Work (Pow) concept “ </p>

                                  <p>Like blockchain typically works on PoW consensus algorithms, some crypto’s work on PoS ( proof of stake ) , PoA ( Proof of authority) Consensus algorithm. </p>

                                  <p>This Ledger technology that was first applied only to create  cryptocurrencies is now evolving and used for multi-purpose application development like smart contracts, supply chain, data management..etc. </p>
                                </div>

                                   <div className="course_lesson_data" id="wallet">
                                  <h5 >3. Wallets</h5> 

                                 
                        <p>Crypto wallet is basically a tool that interacts with the blockchain network to send, receive and store the crypto. The wallets are generally of 2 types , Software Wallet and Hardware wallet also known as Hot wallet and Cold wallet. </p>
                        <p><b>Software Wallet (Hot Wallet)</b> is an Online wallet that is connected to the Internet and so accessible remotely. Hot Wallets have a high preference because of the convenience offered, while <b>hardware wallets ( Cold wallets )</b> are said to be more secure and reliable, used by Long term traders or Holders. </p>
                        <p>Examples</p>
                        - for Hot Wallet is Metamask, TrustWallet, TronWallet … - for cold wallet is a Trezor . Ledger Nano ..
                        <h6>Crypto wallets working </h6>
                        <ul class="contents-in-dots">
                           <li>The Wallet does not really store any funds, the Coins never leave the blockchain network. Wallets are made of tools that can run the blockchain transactions to send and receive coins / tokens.</li>
                           <li>The Wallets are protected with one or pair of Private Keys that act like the unique Identifier of your wallet.</li>
                           <li>Each Wallet generates an “Address “ of the specific token ( wallet address ) which is the receive address of that token. </li>
                           <li>With having Private Keys and Passwords(Seed Phrase) you can keep your wallet safe and  access your wallet from any other device incase of any device compromises. </li>
                        </ul>
                        <h6>Some more Important things : </h6>
                        <ul class="contents-in-dots">
                           <li>Hot-Wallets also include Web-Wallets ( Browser wallets ), Desktop wallets and Mobile wallets</li>
                           <li>Web-Wallets are basically wallets offered by other Websites and exchanges, but these cases are risky as the Private Keys will be managed by the Wallet provider.</li>
                           <li>Desktop Wallets and Mobile Wallets are more safer, these are applications that will be downloaded into our device and perform transactions directly.</li>
                        </ul>
                        
                        <h6>Backup your phrase: </h6>
                        <p>Crypto Wallet is a very important tool for the Crypto domain that allows you to reserve, send and receive funds securely. But losing your Phrase code or Private keys is losing your password that cannot be recovered. Also, if you opted for password encryption, remember to back up your password as well.</p>
                        <p>It is also crucial you study about the Advantages and Disadvantages of the Wallet and read the security specifications before you opt to access funds through. </p>
                      </div>

                 <div className="course_lesson_data" id="exchage">
                         <h5>4. Exchanges</h5>

                                   <p>Cryptocurrency Exchange is a platform ( website or app)  where one can buy and sell cryptocurrencies for other crypto’s or traditional money like the Euro or US Dollar.</p>
                        <p>An exchange is a platform where buyers and sellers initiate or conduct their business. It helps the sellers and the buyers to meet at one platform and match their trade requirements or conditions.</p>
                        <p>Cryptocurrency exchanges are also known as Digital currency exchange (DCE) or online business where money or digital currencies are digitally exchanged and transferred.</p>
                        <p>Some exchanges convert digital currencies into fiat currencies or or anonymous prepaid cards that can be used to withdraw funds worldwide through an ATM.</p>
                        <p>Cryptocurrencies are the hot topic of this century and quite an intimidating and intrigued topic that everyone wants to learn about. Now if you are a beginner then it is strictly advised that do not perform any kind of trading or exchanges without having complete knowledge about the exchanges.</p>
                        <p><b>Rule 1: Never invest money that you can't afford to lose.</b></p>
                        <p><b>Types of Crypto Exchanges.</b></p>
                        <p>Cryptocurrencies work on the decentralized system of networks and today there are about more than 200 different exchange platforms in the world.</p>
                        <ul class="contents-in-dots">
                           <li><b>Fiat - to - Crypto Exchange:</b> - This exchange is very popular amongst the new beginners who are eager to learn about the cryptocurrency exchange. In this exchange, conventional fiat currencies like the US dollar or Euro can be used to buy Cryptocurrencies. <br/><b>Note: Not all exchange platforms serve fiat to crypto conversion.</b></li>
                           <li><b>Crypto - to - Crypto exchange:</b> As the name suggests, exchanges in this program are done between the cryptocurrency. All these currencies' values differ from one another and thus one currency can be used to buy other cryptocurrencies as per its estimated value. Before you initiate any exchange in this program, the first step is to convert your fiat currency into Cryptocurrency.</li>
                           <li><b>Peer-to-Peer Exchange:</b>  This is one of the interesting types of exchange wherein the seller can quote his desired value of price for the currency that he owns and the buyer on the other hand gets a choice to choose what's best for him in this kind of exchange. Both the parties are then matched when their exchange agreements meet. Here a financial agreement is made between the buyer and the seller by the third party who regulates this exchange between them.</li>
                           <li><b>Brokers:</b> here, the broker can be anyone and you can buy cryptocurrencies directly from him, he then charges certain commission/fees on every exchange that you make with him. The price or commission that he charges may be higher than the actual value of that particular currency exchange.</li>
                        </ul>
                        
                        <p><b>Criteria to check in before making an exchange.</b></p>
                        <p>Before making any exchange there is certain information that one must look into. </p>
                        <ul class="contents-in-number">
                           <li><b>Reputation:</b> Check the exchange platform details like any kind of scandal or scam or bankruptcy case have they been part of or not. Look for individual reviews from well-known industry sites and those reviews into consideration. Verify it by checking if any one of your trusted and experienced investors has invested their assets in that platform. Go for the official websites.</li>
                           <li><b>Payment Methods:</b> Check for the payment methods available on the exchange platform that you choose. Some exchanges might have limited payment options and can be inconvenient to use.( Payment options include - Credit/Debit cards, PayPal, Digital coins and tokens)</li>
                           <li><b>Exchange rate:</b> For trade, that deals with Fiat currencies, there's a fee charged for its conversion into cryptocurrencies. Check for reasonable exchange rates on its exchange platform or refer to other sites. As you know that there are so many exchange platforms on the internet today, all have different exchange rates. Compare it one other according;y to your suitability.</li>
                           <li><b>Restrictions:</b> Some countries have been banned for cryptocurrency exchange and its related business. Check whether any such geographical restrictions are cited in your country or not, if yes then avoid cryptocurrency transactions to avoid any issues.</li>
                           <li><b>Security:</b> Before making any exchange, the user has to go through an authenticated verification process requested by the exchange platform wherein he has to register his Id as a proof issued by the government. This helps avoid frauds and lets the user interact with the verified investor only.</li>
                           <li><b>Customer Support:</b> 24/7 customer support service availability is one of the biggest criteria that a person looks for any kind of business to invest in. It helps to clear your queries in a timeframe requested.</li>
                        </ul>
                        
                        <p><b>How to start trading?</b></p>
                        <p>Now that you have learned about the different exchange programs, it's time that we learn how to trade. Cryptocurrency exchanges are getting mainstream and are drawing attention from all over the world.</p>
                        <ul class="contents-in-dots">
                           <li>Choose the exchange platform that is the official website in which you are going to invest your money or trade-in.</li>
                           <li>Create an account on the website and sign up.</li>
                           <li>Fill in the personal/ professional details if requested by the website and do not reveal or release account details or passwords to any third party.</li>
                           <li>Always look for a two-factor authentication exchange platform and verify your account with the verification process.</li>
                           <li>Have a Crypto wallet before adding a payment method to your account.</li>
                        </ul>
                        
                        <p>Look into the factors listed above before initiating any exchange.</p>
                               </div>
                              </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default academy_crypto_basics_you_should_know;