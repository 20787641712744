import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Login from './Login';
import Forgot_password_otp from './Forgot_password_otp';
import Forgot_password_new_password from './Forgot_password_new_password';
import Forgot_password from  './Forgot_password';
import Register_verify_email from './Register_verify_email'

import { BrowserRouter as Router} from 'react-router-dom';


class AuthRouting extends React.Component 
{    
  render() { 
    return (  
     <>  
        <Switch> 
              <Route path="/auth/login" component={Login} />   
              <Route path="/auth/forgot-password" component={Forgot_password} />   
              <Route path="/auth/forgot-password-otp" component={Forgot_password_otp} />   
              <Route path="/auth/forgot-password-new-password" component={Forgot_password_new_password} />   
              <Route path="/auth/register-verify-email" component={Register_verify_email} />    
              <Route path="/" component={Login} /> 
        </Switch> 
     </> 
    )}
}

export default AuthRouting;