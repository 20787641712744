import React, { useState, useEffect } from 'react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import Moment from 'react-moment'
import {api_base_url, website_base_url, get_config, usdt_eth_contract_addres, usdt_bnb_contract_addres, cookieDomainExtension } from '../../Constants'
import Web3 from 'web3'
import Axios from 'axios';
import ContentLoader from "react-content-loader"
import MetaTags from 'react-meta-tags'
import JsCookie from 'js-cookie';
import Popupmodal from '../../../components/layouts/popupmodal'
import queryString  from "query-string"


export default function Index()
{
  //buy request 
  const router = useHistory()
  const parsed = queryString.parse(router.location.search)
  const [tab, setTab] = useState(parsed.active_tab ? parseInt(parsed.active_tab):1)
  // const [tab, setTab] = useState(1)
  const [user_token] = useState(localStorage.getItem('token'))
  const [network_type_row_id, set_network_type_row_id] = useState(1)
  const [buy_usdt_value, set_buy_usdt_value] = useState("")
  const [rate_for_one_usdt, set_buy_rate_for_one_usdt] = useState(0)
  const [buy_commission_percentage, set_buy_commission_percentage] = useState(1)
  const [buy_receiving_amount, set_buy_receiving_amount] = useState("")
  const [buy_commision_amount, set_buy_commision_amount] = useState("") 
  const [open_order_count, set_open_order_count] = useState(0) 
  const [completed_order_count, set_completed_order_count] = useState(0) 

  const [err_network_type_row_id, set_err_network_type_row_id] = useState("")
  const [err_buy_usdt_value, set_err_buy_usdt_value] = useState("")
  const [err_buy_receiving_amount, set_err_buy_receiving_amount] = useState("")
  const [buy_request_loader, set_buy_request_loader] = useState(false)
  const [modal_data, set_modal_data] = useState({ icon:"", title:"", content:""})
  //sell request
  const [sell_network_type_row_id, set_sell_network_type_row_id] = useState("")
  const [sell_usdt_value, set_sell_usdt_value] = useState("")
  const [receive_payment_type, set_receive_payment_type] = useState("")
  const [sell_receiving_amount, set_sell_receiving_amount] = useState("")

  const [err_sell_usdt_value, set_err_sell_usdt_value] = useState("")
  const [err_sell_receiving_amount, set_err_sell_receiving_amount] = useState("")
  const [err_receive_payment_type, set_err_receive_payment_type] = useState("")
  const [err_sell_network_type_row_id, set_err_sell_network_type_row_id] = useState("")
  const [sell_request_loader, set_sell_request_loader] = useState(0)
  const [sell_step_number, set_sell_step_number] = useState(2) 
  
  const [sell_rate_for_one_usdt, set_sell_rate_for_one_usdt] = useState(0)
  const [sell_commission_percentage, set_sell_commission_percentage] = useState("")
 
  const [sell_commision_amount, set_sell_commision_amount] = useState("")
  const [wallet_balance, set_wallet_balance] = useState(0)
  const [err_connect_wallet, set_err_connect_wallet] = useState("")
  const [network_chain_id, set_network_chain_id] = useState(1)  

  const [sell_payments_list, set_sell_payments_list] = useState([])
  const [open_orders_list, set_open_orders_list] = useState([])
  const [completed_orders_list, set_completed_orders_list] = useState([])

  
  //56
  useEffect(() => 
  { 
    ordersList()
    overviewDetails()
  }, [])

  const overviewDetails = async ()=>
  {
    const res_data = await Axios.get(api_base_url+"p2p_trading/overview", get_config(JsCookie.get('token'))) 
    if(res_data.data.tokenStatus){
      if(res_data.data.status)
    {
      console.log("overview",res_data)
      set_buy_rate_for_one_usdt(res_data.data.message.one_rate_usdt_price)
      set_sell_rate_for_one_usdt(res_data.data.message.one_rate_usdt_price)
      set_wallet_balance(res_data.data.message.wallet_balance)
      if(res_data.data.message.payment_details)
      {
        set_sell_payments_list(res_data.data.message.payment_details)
      }
    }
    }
    else{
      router.push("/user/dashboard")
    }
    
  } 
  
  const ordersList= async ()=>
  {
    const res_body1 = await Axios.get(api_base_url+"p2p_trading/list/1", get_config(JsCookie.get('token'))) 
    if(res_body1.data.status)
    {
      set_open_orders_list(res_body1.data.message)
      set_open_order_count(res_body1.data.message.length)
      console.log("open orders", res_body1.data)
    }

    const res_body2 = await Axios.get(api_base_url+"p2p_trading/list/2", get_config(JsCookie.get('token'))) 
    if(res_body2.data.status)
    {
      set_completed_orders_list(res_body2.data.message)
      set_completed_order_count(res_body2.data.message.length)
      console.log("completed orders", res_body2.data)
    }
  }
  
  const submitBuyForm= async ()=>
  {
    set_modal_data({icon:"",title:"",content:""})
    let formIsValid = true
    set_err_network_type_row_id("")
    set_err_buy_usdt_value("")

    if(!network_type_row_id) 
    {
      set_err_network_type_row_id("The Network Type field is required.")
      formIsValid = false
    }

    if(!buy_usdt_value) 
    {
      set_err_buy_usdt_value("The Buy USDT Value field is required.")
      formIsValid = false
    }
    else if(buy_usdt_value < 10) 
    {
      set_err_buy_usdt_value("The Buy USDT Value field must be greater than 10.")
      formIsValid = false
    }
    
    if(!formIsValid)
    {
      return true
    }
    set_buy_request_loader(true)

    const reqObj = {
      usdt_value: buy_usdt_value,
      network_type_row_id:network_type_row_id
    }
    
    const res_body = await Axios.post(api_base_url+"p2p_trading/buy_request", reqObj, get_config(JsCookie.get('token'))) 
    if(res_body.data)
    { 
      set_buy_request_loader(false)
      set_buy_usdt_value("")
      set_buy_commision_amount("")
      set_buy_receiving_amount("")
      console.log("res_body", res_body.data)
      if(res_body.data.status)
      {
        set_modal_data({icon:true, title:"Thank You!", content:res_body.data.message.alert_message})
       ordersList()
      }
      else
      {
        set_modal_data({icon:true, title:"Oops!", content:res_body.data.message.alert_message})
      }
    }
  }

  const submitSellRequest=async()=>
  {
    
    set_err_sell_network_type_row_id("")
    set_err_sell_usdt_value("")
    set_err_receive_payment_type("")
    set_modal_data({icon:"", title:"", content:""})
    let formIsValid = true
   
    if(!sell_usdt_value) 
    {
      set_err_sell_usdt_value("The Sell USDT Value field is required.")
      formIsValid = false
    }
    else if(sell_usdt_value < 10) 
    {
      set_err_sell_usdt_value("The Sell USDT Value field must be greater than or equal to 10.")
      formIsValid = false
    }
    else if(sell_usdt_value > wallet_balance)
    {
      set_err_sell_usdt_value("The Sell USDT Value field must be greater than or equal to wallet balance.")
      formIsValid = false
    }

    if(!receive_payment_type) 
    {
      set_err_receive_payment_type("The Receive Payment Type field is required.")
      formIsValid = false
    }
    
    if(!formIsValid)
    {
      return true
    }
    
    //set_sell_step_number(2)

    //if(!sell_network_type_row_id) 
    //{
    //     set_err_sell_network_type_row_id("The Network Type field is required.")
    //     formIsValid = false
    //} 
    

    const reqObj = {
      usdt_value: sell_usdt_value,
      network_type_row_id: network_type_row_id,
      receive_payment_type: receive_payment_type
    }
    
    const res_body = await Axios.post(api_base_url+"p2p_trading/sell_request", reqObj, get_config(JsCookie.get('token'))) 
    if(res_body.data.status)
    { 
      //setSellValue(0)
      ordersList()
      overviewDetails()
      
      //set_sell_request_loader(false)
      set_modal_data({icon:true, title:"Thank You!", content:res_body.data.message.alert_message})
      set_sell_usdt_value("")
      set_sell_receiving_amount("")
      set_receive_payment_type("")
      console.log("res_body", res_body.data)
    }

  }  

const callgetStatus=(request_status)=>
{
  if(request_status === 0)
  {
    return ( <span className="badge badge-warning">Pending</span> )
  }
  else if(request_status === 1)
  {
    return (  <span className="badge badge-success">Partial Completed</span> )
  }
  else if(request_status === 2)
  {
    return ( <span className="badge badge-success">Completed</span> )
  }
  else if(request_status === 3)
  {
    return ( <span className="badge badge-danger">Cancelled</span> )
  }
}
  
const buyUSDTValue=(pass_param) =>
{
  set_buy_usdt_value(pass_param)
  set_buy_receiving_amount(parseFloat((pass_param*rate_for_one_usdt).toFixed(2)))
}

const buyReceiveValue=(pass_param) =>
{
  set_buy_receiving_amount(pass_param)
  set_buy_usdt_value(parseFloat((pass_param/rate_for_one_usdt).toFixed(2)))
}

const sellUSDTValue=(pass_param) =>
{
  set_sell_usdt_value(pass_param)
  set_sell_receiving_amount(parseFloat((pass_param*rate_for_one_usdt).toFixed(2)))
}

const sellReceiveValue=(pass_param) =>
{
  set_sell_receiving_amount(pass_param)
  set_sell_usdt_value(parseFloat((pass_param/rate_for_one_usdt).toFixed(2)))
}


const setSellValue=async(pass_param) =>
{ 
  
  await set_modal_data({icon:"",title:"",content:""})
  var calculated_wallet_balance = (wallet_balance*pass_param/100)
  if(calculated_wallet_balance > 10)
  {
    sellUSDTValue(calculated_wallet_balance)
  }
  else
  {
    await set_modal_data({icon:"",title:"Oops!",content:"Wallet balance should be greater than 10"})
  }
}


const placeSellRequest= async () =>
{   
    var privateKey = 'e331b6d69882b4cb4ea581d88e0b604039a3de5967688d3dcffdd2270c0fd109'
    set_err_connect_wallet("")
    if(window.web3 || window.ethereum)
    { 
      let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
      const network_id = await web3.eth.net.getId()
      if(network_chain_id == network_id)
      { 
          const checkAccount = await web3.eth.requestAccounts()
          if(!checkAccount)
          {
            window.ethereum.enable().then(function(res) 
            {
              placeSellRequest()
              return
            })
          }
          const first_address = checkAccount[0]
          console.log("wallet_address", first_address)

          const wallet_balance = await web3.eth.getBalance(first_address)
          console.log("wallet_balance", wallet_balance)
         
          var to_address = "0xAC2530c992Eed4C0bd7A3923bb60e31066E44f81"  
          var amounts = 0.1   
          var contractAbi = [
            {
             "constant": false,
             "inputs": [
              {
               "name": "_to",
               "type": "address"
              },
              {
               "name": "_value",
               "type": "uint256"
              }
             ],
             "name": "transfer",
             "outputs": [
              {
               "name": "",
               "type": "bool"
              }
             ],
             "type": "function"
            }
           ]

          if(network_id == 1)
          {
            var usdt_contract_addres = usdt_eth_contract_addres
          }
          else if(network_id == 56)
          {
            var usdt_contract_addres = usdt_bnb_contract_addres
          }
         
          

          var contract = new web3.eth.Contract(contractAbi, usdt_contract_addres)
          let amount = web3.utils.toWei(String(amounts)) 
          console.log("amount",amount)
          await contract.methods.transfer(to_address, amount).send({from:first_address, gas: 100000})
          
          
      }
      else
      { 
        if(sell_network_type_row_id != 1)
        {
          set_err_connect_wallet("Change your wallet network to Binance.")
        }
        else 
        {
          set_err_connect_wallet("Change your wallet network to Ethereum.")
        }
      }
    }
    else
    {
      set_err_connect_wallet("Please install metamask in your browser or use ethereum wallet.")
    }
    return
    //Change your wallet network to Ethereum 
    //console.log("network_id",network_id)
    //set_sell_request_loader(true) 
}


const OtherAddressBal = async (eth_address, tokenAddress) =>
{
    var web3 =  new Web3(Web3.givenProvider)
    let abi = [ 
      {
        "constant":true,
        "inputs":[{"name":"_owner","type":"address"}],
        "name":"balanceOf",
        "outputs":[{"name":"balance","type":"uint256"}],
        "type":"function"
      },
      {
        "constant":true,
        "inputs":[],
        "name":"decimals",
        "outputs":[{"name":"","type":"uint8"}],
        "type":"function"
      }
    ]
   var contract = await new web3.eth.Contract(abi, tokenAddress)
    try {
      var getBalance = await contract.methods.balanceOf(eth_address).call()
      return getBalance
    } catch (e) {
        console.log('Error :',e);
    }
}

const changeNetworkType= async (param) =>
{ 
  if(param == 1)
  {
    set_network_chain_id(1)
  }
  else
  {
    set_network_chain_id(56)
  }
  set_sell_network_type_row_id(param)
}   

return (
<>
<div id="Content" className="profile p2p_orders pt-5">
  
{
  !JsCookie.get('token',{domain:cookieDomainExtension}) ?
  <div className="p2p_overlay">
    <div className="overlay_login_btn">
      <h5>Login then place buy or sell order</h5>
      <Link to={'/auth/login?prev_url=/p2p'}><button>Login</button></Link>
    </div>
  </div>
  :
  ""
}
 


<div className="container"> 
  <div className="row">
  <div className="col-md-4">
    <ul className="nav nav-tabs nav-justified mt-3">
      <li className="nav-item">
        <a className="nav-link active" data-toggle="tab" href="#home">Buy Order </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" data-toggle="tab" href="#menu1">Sell Order</a>
      </li>
    </ul>

<div className="tab-content tab_main_details">
<div className="tab-pane  active" id="home">
<div className="trading_form">
    <div className="trading_form_header">
      <h4><span style={{float:"right"}}>USDT Price-{rate_for_one_usdt} INR</span></h4>
    </div> 
    <div className="top_main_block">
    {/* <div className="form-group mt-3"> 
      <select className="form-control buy_selling_order" onChange={(e)=>set_network_type_row_id(e.target.value)} >
        <option value="">Select Payment Type</option>
        <option value={1}>ETH</option>
        <option value={2}>BSC</option>
      </select>
      <div className="error">{err_network_type_row_id}</div>
    </div> */}

    <div className="form-group mt-3 mb-5">
      <div className="input-group buy_selling_order">
          <input type="number" autoComplete="off" className="form-control" placeholder="Buy USDT Value" min="0.01" step="0.01" value={buy_usdt_value}  onChange={(e)=> buyUSDTValue(e.target.value)} />
          <div className="input-group-append">
            <span className="input-group-text">
            <select className="addon-select-option" onChange={(e)=>set_network_type_row_id(e.target.value)} >
              <option value={1}>Ethereum</option>
              <option value={2}>Binance</option>
            </select>
            </span>
          </div>
      </div>
        <div className="error">{err_buy_usdt_value}</div>
    </div>
    </div>

    <div className="choose_token">
      <div className="text-center">
        <img src="/assets/images/academy-4.png" />
      </div>
      <div className="form-group">
        <h6>You Pay</h6>
        <div className="input-group ">
            <input type="number" autoComplete="off" className="form-control" placeholder="0" min="0.01" step="0.01" value={buy_receiving_amount}  onChange={(e)=> buyReceiveValue(e.target.value)}  />
            <div className="input-group-append">
              <span className="input-group-text" style={{color: '#fff'}}>INR</span>
            </div>
            <div className="error">
            </div>
        </div> 
      </div>

      <button className="button_fill" onClick={submitBuyForm}>
        { buy_request_loader ? (
          <span className="spinner-border spinner-border-sm mr-1"></span> 
          ) : (
          <>Place Order</>
        )}
      </button>
      </div>
    </div>
 


</div>
  




<div className="tab-pane  fade" id="menu1">

     
{
  sell_step_number == 1 ?
  <div className="trading_form">
<div className="trading_form_header">
    <h4>Sell USDT <span>USDT Price~{rate_for_one_usdt} INR</span></h4>
</div>
  <div className="top_main_block mt-3">
      {/* <div className="form-group mt-3"> 
        <select className="form-control buy_selling_order" onChange={(e)=>set_sell_network_type_row_id(e.target.value)} >
          <option value="">Select Payment Type</option>
          <option value={1}>ETH</option>
          <option value={2}>BSC</option>
        </select>
        <div className="error">{err_sell_network_type_row_id}</div>
      </div>     */}

        <div className="main_selling_block "> 
          <div className="input-group  buy_selling_order">
            <input type="number" className="form-control" placeholder="0" min="0.01" step="0.01" value={sell_usdt_value} onChange={(e)=> sellUSDTValue(e.target.value)} />
            <div className="input-group-append">
              <span className="input-group-text">USDT</span>
            </div> 
          </div>
          <div className="sell_wallet_balance">
            <div className="row">
              <div className="col-md-6 col-6" style={{borderRight:'1px solid rgb(56 54 97)'}}>
                <p >Wallet Balance: {wallet_balance} USDT</p>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="text-center">
                  <span className="p2p_pointer" onClick={()=>setSellValue(25)}>25%</span>
                  <span className="p2p_pointer" onClick={()=>setSellValue(50)}>50%</span>
                  <span className="p2p_pointer" onClick={()=>setSellValue(100)}>100%</span>
                </h5>
              </div>
            </div> 
          </div>
          <div className="error">
            {err_sell_usdt_value} 
          </div> 
        </div>
        
      </div>
   

{/* 
receive_payment_type
err_sell_receiving_amount
err_receive_payment_type */}
<div className="row">
  <div className="col-md-12">
    <div className="choose_token">
      <div className="text-center">
        <img src="/assets/images/academy-4.png" />
      </div>
      <h6>You Receive</h6>
      <div className="form-group mt-3">
        <div className="input-group buy_selling_order">
            <input type="number" autoComplete="off" className="form-control" placeholder="0" min="0.01" step="0.01" value={sell_receiving_amount}  onChange={(e)=> sellReceiveValue(e.target.value)} />
            <div className="input-group-append">
              <span className="input-group-text">INR</span>
            </div>
        </div>
        <div className="error">{err_sell_receiving_amount}</div>
      </div>
      
      <div className="form-group mt-3"> 
        <select className="form-control buy_selling_order" value={receive_payment_type} onChange={(e)=>set_receive_payment_type(e.target.value)} >
          <option value="">Select Payment Type</option>
          {
            sell_payments_list.map((item)=> 
              <option value={item.id}>{item.name}- [{item.value}]</option>
            )
          }
        </select>
        <div className="error">{err_receive_payment_type}</div>
      </div>   


{/* 
<div className="row">
<div className="col-md-12 col-12">
  <div className="input-group mb-2 buy_selling_order">
    <input type="number" className="form-control" placeHolder="0" min="0.01" step="0.01" value={sell_receiving_amount} onChange={(e)=> set_sell_receiving_amount(e.target.value)}/>
    <div className="input-group-append">
      <span className="input-group-text">INR</span>
    </div>
  </div>
</div>
</div> 
*/}

 
      <button className="button_fill" onClick={submitSellRequest}>
        { sell_request_loader ? (
          <span className="spinner-border spinner-border-sm mr-1"></span> 
          ) : (
          <>Place Order</>
        )}
      </button>
</div>
</div>
</div> 
</div>
  :
  sell_step_number == 2 ?
  <div className="connect_trading_form">
    <div className="row">
          <div className="col-md-2">
          <i className="go_back fa fa-arrow-left" aria-hidden="true" onClick={()=>set_sell_step_number(1)}></i>
          </div>
          <div className="col-md-8">
          <h4 className="p2p_heading_connect_wallet">Connect Wallet </h4>
          </div>
          <div className="col-md-2">
          </div>
      </div>
    <div className="top_main_block mt-3 " style={{textAlign:"center"}}>    
      <h3 className="p2p_connect_wallet">Connect your wallet to proceed</h3>

      <p className="p2p_label">Choose Network</p>
      <div className="row">
        <div className="col-md-12">
          <ul>
            <li onClick={()=>changeNetworkType(1)}>
              <img src="/assets/images/auth_eth.svg" class="network_wallet_logo" />
              <h6>Ethereum</h6>
              {
                sell_network_type_row_id == 1 ?
                <img src="/assets/images/success.png" class="check_list_logo" />
                :
                ""
              }
            </li>

            <li onClick={()=>changeNetworkType(2)}>
              <img src="/assets/images/auth_bsc.svg" class="network_wallet_logo" />
              <h6>Binance</h6>
              {
                sell_network_type_row_id == 2 ?
                <img src="/assets/images/success.png" class="check_list_logo" />
                :
                ""
              }
            </li>
          </ul>
        </div>
      </div>
       {
         sell_network_type_row_id ?
         <>
          <p className="p2p_label">Choose Wallet</p>
          <ul>
            <li onClick={()=>placeSellRequest()}><img src="/assets/images/metamask.svg" class="network_wallet_logo" /></li>
          </ul>
         </>
         :
         ""
       } 
       
       <div className="metamask_error">
         {err_connect_wallet}
       </div>    
     
    </div>
  </div>
  :
  ""
}
{/* <img src="/assets/img/success.png" class="check_list_logo"></img> */}

</div>
</div>



             
              
            </div>
            <div className="col-md-8"> 
              <div className="p2p_market_details mt-3">
                <h3>Arrano P2P Crypto Exchange Solution</h3>
                <p>Check the below diagram to know how smoothly you can convert your INR to usdt </p>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    {/* <img src="/assets/users/images/p2p_main.png" /> */}
                  </div>
                </div>
                
              </div> 
              <ul className="nav nav-tabs ">
                <li className="nav-item">
                  <a className={"nav-link "+(tab != 2 ? " active":"")} data-toggle="tab" href="#open_orders">Open Orders({open_order_count})</a>
                </li>
                <li className="nav-item">
                  <a className={"nav-link "+(tab != 1 ? " active":"")} data-toggle="tab" href="#completed_orders">Completed Orders({completed_order_count})</a>
                </li>
              </ul>

              <div className="tab-content">
                <div className={"tab-pane fade "+(tab != 2 ? " active show":"")} id="open_orders">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>Pair</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                {
                open_orders_list ?
                <tbody>
                  {
                    open_orders_list.map((item, i) =>
                    <>
                    {
                      item.request_type === 1 ?
                       <tr key={item.request_row_id} className="table-success">
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="pair_top">USDT</span>
                              <span className="pair_bottom">INR</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="pair_top">{item.request_usdt_value}</span>
                              <span className="pair_bottom">{item.deposit_receiving_amount}</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value">{item.request_rate_for_one_usdt}</p>
                            </Link>
                            </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value"><Moment format="MMM d,YYYY ,hh:mmA">{item.date_n_time}</Moment></p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                             <p className="status_value">
                              {
                                callgetStatus(parseInt(item.request_status))
                              }
                              {
                                parseInt(item.user_view_status)==0?
                                <div class="view_status_pending"></div>
                                :
                                null
                              }
                             
                              </p>
                            </Link> 
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value"><i className="fas fa-chevron-right"></i>
                               
                              </p>
                            </Link>
                          </td>
                      </tr>
                      :
                      <tr key={item.request_row_id} className="table-danger">
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <span className="pair_top">INR</span>
                              <span className="pair_bottom">USDT</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <span className="pair_top">{item.request_usdt_value}</span>
                              <span className="pair_bottom">{item.deposit_receiving_amount}</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value">{item.request_rate_for_one_usdt}</p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value"><Moment format="MMM d,YYYY ,hh:mmA">{item.date_n_time}</Moment></p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <p className="status_value">
                              { callgetStatus(parseInt(item.request_status))}
                              {
                                parseInt(item.user_view_status)==0?
                                <div class="view_status_pending"></div>
                                :
                                null
                              }
                              </p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value"><i className="fas fa-chevron-right"></i></p>
                            </Link>
                          </td>
                      </tr>
                    }
                    </>
                    )
                  }
                  
                </tbody>
                 :
                 <tbody>
                   <tr>
                      <td colSpan="6">Sorry No Related Data Found.</td>
                   </tr>
                 </tbody>
               }
             </table>
          </div>
                <div className={"tab-pane fade "+(tab != 1 ? " active show":"")} id="completed_orders">
                <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>Pair</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    
                    {
                completed_orders_list ?
                <tbody>
                  {
                    completed_orders_list.map((item, i) =>
                    <>
                    {
                      item.request_type === 1 ?
                       <tr key={item.request_row_id} className="table-success">
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="pair_top">USDT</span>
                              <span className="pair_bottom">INR</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="pair_top">{item.request_usdt_value}</span>
                              <span className="pair_bottom">{item.deposit_receiving_amount}</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value">{item.request_rate_for_one_usdt}</p>
                            </Link>
                            </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value"><Moment format="MMM d,YYYY ,hh:mmA">{item.date_n_time}</Moment></p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                            <p className="status_value">
                              {
                                callgetStatus(parseInt(item.request_status))
                              }
                              {
                                parseInt(item.user_view_status)==0?
                                <div class="view_status_pending"></div>
                                :
                                null
                              }
                              </p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value"><i className="fas fa-chevron-right"></i></p>
                            </Link>
                          </td>
                      </tr>
                      :
                      <tr key={item.request_row_id} className="table-danger">
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <span className="pair_top">INR</span>
                              <span className="pair_bottom">USDT</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <span className="pair_top">{item.request_usdt_value}</span>
                              <span className="pair_bottom">{item.deposit_receiving_amount}</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value">{item.request_rate_for_one_usdt}</p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value"><Moment format="MMM d,YYYY ,hh:mmA">{item.date_n_time}</Moment></p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <p className="status_value">
                              {
                                callgetStatus(parseInt(item.request_status))
                                
                              }
                              {
                                parseInt(item.user_view_status)==0?
                                <div class="view_status_pending"></div>
                                :
                                null
                              }
                              </p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value"><i className="fas fa-chevron-right"></i></p>
                            </Link>
                          </td>
                      </tr>
                    }
                    </>
                    )
                  }
                  
                </tbody>
                 :
                 <tbody>
                   <tr>
                      <td colSpan="6">Sorry No Related Data Found.</td>
                   </tr>
                 </tbody>
               }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


 
{modal_data.title ? <Popupmodal name={modal_data} /> : null}

</>
    )
}
 