import React from 'react';
import { withRouter } from 'react-router-dom'; 
import Cookies from 'js-cookie';
import {website_base_url, api_base_url, metaMaskNetworkAccpeted, cookieDomainExtension, accountLogout, get_config} from '../../Constants'; 
import ContentLoader from "react-content-loader";
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags'; 
import $ from 'jquery';
import Moment from 'react-moment'; 
import OtpInput from 'react-otp-input';
import Pagination from "react-js-pagination";
 
import Web3 from 'web3'
import Axios from 'axios';

class Ano_funds extends React.Component {

constructor() {
    super();
    if(Cookies.get('reverify_sent_email_status'))
    {
      var reverify_sent_email_status = Cookies.get('reverify_sent_email_status')
    }
    else
    {
      var reverify_sent_email_status = 0
    }
 
    this.state = {
       ano_tab : "1",
       reverify_sent_email_status:reverify_sent_email_status,
       website_base_url:website_base_url,
        headers: {
          "Accept":"Application/json",
          "Content-Type":"Application/json"
        },
        total_locked_fund: 0,
        total_verified_fund: 0,
        total_available_fund: 0,
        ano_value: null,
        total_connections: null,
        perPage: 20,
        sl_no:0,
        page_number:1,
        currentPage: 0,
        description: null,
        date_n_time: null,
        api_base_url:api_base_url,
        verifiedTxnLoaderStatus:true,
        availableTxnLoaderStatus:false,
        getVerifiedTxnReferralsList:'',
        getVerifiedTxnLoginRegList:'',
        getAvailableTrasactionList:'',
        verifiedfunds:false,
        lockedfunds:false,
        availablefunds:false,
        device_unqiue_id: "",
        date_n_time: "",
        remaining_days_message:"", 
        added_date_n_time: "",
        getEmailReverifyData:"",
        reverify_email_verify_status: "",
        email_verify_otp:"", 
        error_email_verify_otp:"",
        verify_email_loader:"",
        reverifyActiveMessageStatus:false,
        emailverifysuccess:false, 
        ano_wallet_balance:0.00,
        Earnedfunds: false,
        
        emailverifyotp: false,

        
        metaMaskNetworkAccpeted:metaMaskNetworkAccpeted,
        availabel_balance: "", 
        eth_address: "", 
        handleModalConnection:false,
        handleModalMainNetwork: false, 

        trx_address: "", 
        modalTrxConnErr:false,
        trxWalletBalance: "",

        bep_address: "",

        // claim_eth: false,
        claim_trx: false,
        claim_bep: false,

        claim_wallet: true, 
        claim_wallet_address: "", 
        claim_wallet_balance: "",
        chain_type: "",
        day_difference: 0,

        claim_list: [],
        claim_list_status: false,
        checkclaimwallet: false,

        claim_ano_value: "",
        bep_address: "",
        submitclaimano: false,
        save_claim_wallet_details: false,
        invalid_claim_wallet_address: "",
        reverify_email_active_status: "",
        token:Cookies.get('token'),
    }
    
    this.handleReverifyActiveMessage = this.handleReverifyActiveMessage.bind(this)
    
    $(function() 
    {
      var taeb = $(".taeb-switch")
      taeb.find(".taeb").on("click", function() 
      {
        var $this = $(this)
        if ($this.hasClass("active")) return
        var direction = $this.attr("taeb-direction")
        taeb.removeClass("left right").addClass(direction)
        taeb.find(".taeb.active").removeClass("active")
        $this.addClass("active")
      })
    }) 
}

// async OtherAddressBal(eth_address)
// {   
//   this.setState({chain_type: "1"})

//   this.setState({claim_eth: true})
//   this.setState({claim_wallet: false})  
//   this.setState({claim_wallet_address: eth_address})
 
//   this.SubmitTokenDetails() 

//    this.setState({eth_address: eth_address}) 

//   var tokenAddress = '0xeE08F393F4Cc9eA0508246fFc0D693944deE6036'
  
//   var web3 =  new Web3(Web3.givenProvider)
//   let abi = [ 
//     {
//       "constant":true,
//       "inputs":[{"name":"_owner","type":"address"}],
//       "name":"balanceOf",
//       "outputs":[{"name":"balance","type":"uint256"}],
//       "type":"function"
//     },
//     {
//       "constant":true,
//       "inputs":[],
//       "name":"decimals",
//       "outputs":[{"name":"","type":"uint8"}],
//       "type":"function"
//     }
//   ]
//   var contract = await new web3.eth.Contract(abi, tokenAddress)
//   try {
//     var getBalance = await contract.methods.balanceOf(eth_address).call()  

//     this.setState({availabel_balance: getBalance/1000000000000000000}) 

//     this.setState({claim_wallet_balance: getBalance/1000000000000000000})
 
//     return getBalance
//   } catch (e) {
//       console.log('Error :',e);
//   }
// }

//   getETHAccountDetails() 
//   {  
      
//     let parentThis = this 
//     if(window.web3)
//     {
//       let web3 = new Web3(Web3.givenProvider || parentThis.state.givenProvider)
//       web3.eth.net.getNetworkType().then(function(networkName)
//       { 
//         if(networkName === parentThis.state.metaMaskNetworkAccpeted)
//         {
//           web3.eth.getAccounts().then(function(accounts)
//           {   
//             var first_address = accounts[0]   
//             if((typeof first_address != 'undefined'))
//             {  
//               parentThis.OtherAddressBal(first_address)
//             }
//             return true
//           })
//         }
//       })
//     }
//     else if(window.ethereum)
//     {
//       let web3 = new Web3(window.ethereum)
//       web3.eth.net.getNetworkType().then(function(networkName)
//       {
//         if(networkName === parentThis.state.metaMaskNetworkAccpeted)
//         {
//           web3.eth.getAccounts().then(function(accounts)
//           {  
//             var first_address = accounts[0] 
//             if((typeof first_address != 'undefined'))
//             {   
//               parentThis.OtherAddressBal(first_address)
//             }
//             return true
//           })
//         }
//       })
//     }  
//   }  

//   connectToWallet()
//   {  

//     let parentThis = this; 
//     if(window.web3)
//     { 
//       let web3 = new Web3(Web3.givenProvider)
//       web3.eth.net.getNetworkType().then(function(networkName)
//       {   
//         if(networkName === parentThis.state.metaMaskNetworkAccpeted)
//         {
//           try 
//           { 
//             window.ethereum.enable().then(function(res) {
//               parentThis.getETHAccountDetails()
//             })
//           } 
//           catch(e)
//           {
//             parentThis.handleModalConnection();
//           } 
//         }
//         else
//         {
//           parentThis.handleModalMainNetwork()
//         }
       
//       })
//     }
//     else if(window.ethereum)
//     {
//       let web3 = new Web3(window.ethereum)
//       try {
//         web3.eth.net.getNetworkType().then(function(networkName)
//         {
//           if(networkName === parentThis.state.metaMaskNetworkAccpeted)
//           {
//             try 
//             { 
//               window.ethereum.enable().then(function(res) {
//                 parentThis.getETHAccountDetails()
//               })
//             } 
//             catch(e)
//             {
//               parentThis.handleModalConnection();
//             } 
//           }
//           else
//           {
//             parentThis.handleModalMainNetwork()
//           } 
//         })
//       }
//       catch(error) {
//         console.log('ethereum error, ', error)
//       } 
//     }
//     else
//     {
//       this.handleModalConnection()
//     }
//   }

  connecttotrc(){ 
 
    if(this.state.trx_address == ""){
      this.setState({ modalTrxConnErr: !this.state.modalTrxConnErr}); 
    }
    else{
      this.setState({ modalTrxConnErr: false });  
    }
  }

  gettronweb()
  {    

      var parentThis = this
      if(window.tronWeb && window.tronWeb.defaultAddress.base58)
      {   
        
      // this.setState({chain_type: "2"})  
    this.SubmitTokenDetails("2" , window.tronWeb.defaultAddress.base58)
        
        parentThis.setState({
          trx_address:window.tronWeb.defaultAddress.base58
        })  
 
      }
      else{
        this.connecttotrc()
      }
  }

  async triggerSmartContract(tron_address) {
    

    const trc20ContractAddress = "TCdvet6k6PdtiNd2YDVPAexDP11T7PZowc";//contract address
    var address = tron_address;
  
    try {
        let contract = await window.tronWeb.contract().at(trc20ContractAddress);
        //Use call to execute a pure or view smart contract method.
        // These methods do not modify the blockchain, do not cost anything to execute and are also not broadcasted to the network.
        let result = await contract.balanceOf(address).call(); 
        let dec = parseInt(result._hex,16); 
  
        this.setState({trxWalletBalance: dec / 1000000}) 

        this.setState({claim_wallet_balance: dec / 1000000})  
        
    } catch(error) {
        console.error("trigger smart contract error",error)
    }
  } 

  handleModalConnection() 
  {
    this.setState({ handleModalConnection:!this.state.handleModalConnection});
  }

  handleModalMainNetwork() 
  {
    this.setState({ handleModalMainNetwork:!this.state.handleModalMainNetwork});
  } 


handleReverifyActiveMessage()
{
  this.setState({reverifyActiveMessageStatus:!this.state.reverifyActiveMessageStatus});
}

handleModelEmailVerifySuccessMsg()
{
this.setState({ emailverifysuccess: !this.state.emailverifysuccess });
}

handleChange = email_verify_otp => this.setState({ email_verify_otp });

anoFundsOverview()
{
  const config = get_config(this.state.token)
  fetch(api_base_url+"dashboard/divided_fund_wallet_overview", {
    method:'GET',
    headers: config.headers
  }).then(res => res.json()).then((result) => 
  { 
    if(result.tokenStatus  === true)
    { 
      if(result.status === true) 
      {
        this.setState({ 
          total_locked_fund: result.message.total_locked_fund,
          total_available_fund: result.message.total_available_fund,
          claim_ano_value: result.message.claim_ano_value,
          ano_wallet_balance: result.message.total_locked_fund+result.message.total_available_fund+result.message.claim_ano_value
        }) 
      }
    }
    else
    {
      accountLogout()
      this.props.history.push('/auth/login')
    }
  })
}


getVerifiedTxnLoginRegList()
{   
  const config = get_config(this.state.token)
  // this.setState({ verifiedTxnLoaderStatus: true });
    
  fetch(this.state.api_base_url+"ano_funds/users_verified_transactions_login_reg",
  {
    method:'get',
    headers: config.headers
  }).then(res => res.json()).then((result) => 
  {
    
    // this.setState({ verifiedTxnLoaderStatus: false });

    if(result.tokenStatus)
    { 
      if(result.status === true) 
      {
        this.setState({ getVerifiedTxnLoginRegList: result.message });
      }
    }
  });
}

logout(){
  this.props.history.push('/auth/login');
}

getVerifiedTxnReferralsList(page_number)
{   
  const { perPage } = this.state
  var cust_page = page_number ? (page_number-1):0
  console.log(cust_page)
  var asdfd = cust_page*perPage
  this.setState({page_number:page_number})
  this.setState({sl_no:cust_page*perPage})
   const config = get_config(this.state.token)
    this.setState({
      verifiedTxnLoaderStatus: true
    });
    
    fetch(this.state.api_base_url+"ano_funds/users_verified_transactions_referrals/"+asdfd+"/"+perPage,
    {
      method:'get',
      headers: config.headers
    }).then(res => res.json())
    .then(
      (result) => {
        console.log("Result",result)
        this.setState({
          verifiedTxnLoaderStatus: false
        });
 
        if(result.tokenStatus){ 
          if(result.status === true) 
          {
            this.setState({
              getVerifiedTxnReferralsList:result.message, total_connections:result.total_counts
            });
          }
        }
        else{
          this.logout()
        }
    });
}

getEmailReverifyData()
{  

    const config = get_config(this.state.token)
    fetch(this.state.api_base_url+"dashboard/email_reverify_data",
    {
      method:'GET',
      headers: config.headers
    }).then(res => res.json())
    .then(
      (result) => { 

        console.log(result);
        
        if(result.tokenStatus){ 
          if(result.status === true) 
          {
            this.setState({
              reverify_email_verify_status: result.message.email_verify_status,
              reverify_email_active_status: result.message.reverify_email_active_status,
              device_unqiue_id: result.message.device_unqiue_id
            });
          }
        }
        else{
          this.logout()
        }
    })
}
  
dashboardMe()
{
    const config = get_config(this.state.token)
    
    fetch(api_base_url+"dashboard", {
       method:'GET',
       headers: config.headers
   }).then(res => res.json())
   .then(
     (result) => {  
       
      if(result.tokenStatus){ 
       if(result.status === true) 
       {
         console.log(result.message.email_verify_status);
          this.setState({ 
            user_name: result.message.user_name,
            full_name: result.message.full_name,
            email_verify_status:result.message.email_verify_status,
            date_n_time: result.message.date_n_time
          })
       }
      }
      
      });
  }
 
  verifyEmailFun()
  {
    let formIsValid = true 
        
    if(this.state.email_verify_otp === '') 
    {
      this.setState({ error_email_verify_otp:"The OTP field is required." })
      formIsValid = false
    }
    else if(this.state.email_verify_otp.length != 6) 
    {
      this.setState({ error_email_verify_otp:"The OTP field must be at exactly 6 characters in length." })
      formIsValid = false
    }
    else
    {
      this.setState({ error_email_verify_otp:"" })
    }
  
  
    if(!formIsValid)
    {
        return true
    }

    this.setState({
      verify_email_loader:true
    })

    const config = get_config(this.state.token)
    var data = {
      otp_number: this.state.email_verify_otp
    }
          
    fetch(this.state.api_base_url+"profile/wallet_accessible_verify_otp", {
        method:'POST',
        headers: config.headers,
        body:JSON.stringify(data)
    }).then(res => res.json()).then( (result) => 
    { 
      this.setState({ verify_email_loader:false })
          
      if(result.tokenStatus)
      { 
        if(result.status === true) 
        {   
          this.getEmailReverifyData()
          this.handleModelEmailVerifySuccessMsg()
        }
        else
        {   
          this.setState({ error_email_verify_otp:result.message.otp_number })
        }
      }
      else{
        this.logout()
      }
    })
  } 

  //1:Normal send email, 2:Re-send Email
  sentOTPToEmail()
  { 
      // var parentThis = this
      const config = get_config(this.state.token)
    
      fetch(api_base_url+"profile/wallet_accessible_verify_email",
      {
        method:'GET',
        headers: config.headers
      }).then(res => res.json())
      .then(
        (result) => { 
          if(result.tokenStatus){ 
            if(result.status === true) 
            {
              this.setState({ emailverifyotp: true }) 
            }
          }
      })  
  }
 
getAvailableTrasactionList()
{   
  const config = get_config(this.state.token)
  this.setState({
    availableTxnLoaderStatus: true
  });
  fetch(api_base_url+"ano_funds/users_available_funds_transactions",
  {
    method:'GET',
    headers: config.headers
  }).then(res => res.json()).then((result) => 
  {
      this.setState({
        availableTxnLoaderStatus: false
      }); 
      
      if(result.tokenStatus){ 
        if(result.status === true) 
        {
          this.setState({
            getAvailableTrasactionList: result.message
          });
        }
      }
  })
}

handleModelVerifiedFunds() {
  this.setState({ verifiedfunds: !this.state.verifiedfunds });
}

handleModelEarnedFunds(){
  this.setState({ Earnedfunds: !this.state.Earnedfunds });
}

handleModelLockedFunds()
{
  this.setState({ lockedfunds: !this.state.lockedfunds });
}

handleModelAvailableFunds() {
  this.setState({ availablefunds: !this.state.availablefunds });
}

componentDidMount()
{     
  this.getcliamwallet()
  this.getEmailReverifyData()  
  this.getVerifiedTxnLoginRegList()
  this.getVerifiedTxnReferralsList()  
  this.getClaimWalletHistoryList() 
  this.anoFundsOverview()
}

getClaimWalletHistoryList()
{
  const config = get_config(this.state.token)
  Axios.get(this.state.api_base_url+"ano_claim_request/claim_wallet_history", config).then(res=>
  {
    this.setState({claim_list_status: true})
    if(res.data.status)
    { 
      this.setState({claim_list: res.data.message}) 
    }
  })

}


getcliamwallet()
{
  // const config = get_config(this.state.token)

  // Axios.post(this.state.api_base_url+"ano_claim_request/claim_wallet_address_status", config).then(response=>
  // {
  //   if(response.data.status == true)
  //   {
  //     if(response.data.message.chain_type == "2")
  //     {
  //       this.setState({day_difference: response.data.message.day_difference}) 
  //       this.setState({claim_trx: true}) 
  //       this.setState({trx_address: response.data.message.address})
  //       this.setState({claim_wallet: false})
  //       this.setState({claim_wallet_address: response.data.message.address})   
  //       this.triggerSmartContract(response.data.message.address) 
  //     }
  //     else if(response.data.message.chain_type == "3")
  //     {
  //       // this.setState({claim_bep: true})  
  //       // this.setState({bep_address: response.data.message.address})
  //       // this.setState({claim_wallet: false})

  //       this.setState({day_difference: response.data.message.day_difference})
  //       this.setState({claim_bep: true}) 
  //       this.setState({bep_address: response.data.message.address})
  //       this.setState({claim_wallet: false})
  //       this.setState({claim_wallet_address: response.data.message.address})
  //       this.BEPAddressBal(response.data.message.address)
  //     }  
  //   }
  //   else
  //   {
  //     console.log(response.data)
  //   }
  // })
}

SubmitTokenDetails(chain_type, address)
{

  const config = get_config(this.state.token)

  const Cliam_data = {
    chain_type: chain_type,
    address: address,
  } 
  //console.log(config)
  Axios.post(this.state.api_base_url+"ano_claim_request/save_claim_wallet_address",Cliam_data, config)
  .then(response=>{
    if(response.data.status === true){
      this.componentDidMount()  
      if(chain_type == "2"){
        this.setState({claim_trx: true})
        this.setState({claim_wallet: false})  
        this.setState({trx_address: address})
        this.setState({claim_wallet_address: address})
        this.triggerSmartContract(address) 
      }

      if(chain_type == "3"){
          this.BEPAddressBal(address)
      }

    }
    else{
      if(response.data.message.alert_message){
        this.setState({claim_wallet : true})
          this.setState({invalid_claim_wallet_address : response.data.message.alert_message})
          this.setState({save_claim_wallet_details : true})
      }
    }
  }) 
}

SubmitClaimwalletDetails(){

  const config = get_config(this.state.token)
  


  Axios.post(this.state.api_base_url+"ano_claim_request/submit_claim_request",config)
  .then(response=>{
    if(response.data.status === true){
      this.setState({submitclaimano: true})
    }
  }) 
} 

async BEPAddressBal(eth_address)
{   
  this.setState({chain_type: "3"}) 
  this.setState({claim_bep: true}) 

  // this.setState({claim_bep: true})
  this.setState({claim_wallet: false})  
  this.setState({claim_wallet_address: eth_address})
  this.setState({bep_address: eth_address}) 
 
  // this.SubmitTokenDetails("3", eth_address) 


  var tokenAddress = '0xb63a911ae7dc40510e7bb552b7fcb94c198bbe2d'
  
  var web3 =  new Web3(Web3.givenProvider)
  let abi = [ 
    {
      "constant":true,
      "inputs":[{"name":"_owner","type":"address"}],
      "name":"balanceOf",
      "outputs":[{"name":"balance","type":"uint256"}],
      "type":"function"
    },
    {
      "constant":true,
      "inputs":[],
      "name":"decimals",
      "outputs":[{"name":"","type":"uint8"}],
      "type":"function"
    }
  ]
  var contract = await new web3.eth.Contract(abi, tokenAddress)
  try {
    var getBalance = await contract.methods.balanceOf(eth_address).call()    

    this.setState({claim_wallet_balance: getBalance/100000})
 
    return getBalance
  } catch (e) {
      console.log('Error :',e);
  }
}

  getBEPAccountDetails() 
  {  
      
    let parentThis = this 
    if(window.web3)
    {
      let web3 = new Web3(Web3.givenProvider || parentThis.state.givenProvider)
      web3.eth.net.getNetworkType().then(function(networkName) 
      {   
        if(networkName === "private")
        { 
          web3.eth.getAccounts().then(function(accounts)
          {   
            var first_address = accounts[0]    
            if((typeof first_address != 'undefined'))
            {    
              parentThis.SubmitTokenDetails("3" , first_address)
            }
            return true
          })
        }
      })
    }
    else if(window.ethereum)
    {
      let web3 = new Web3(window.ethereum)
      web3.eth.net.getNetworkType().then(function(networkName)
      {
        if(networkName === "private")
        {
          web3.eth.getAccounts().then(function(accounts)
          {  
            var first_address = accounts[0] 
            if((typeof first_address != 'undefined'))
            {    
              parentThis.SubmitTokenDetails("3", first_address)
            }
            return true
          })
        }
      })
    }  
  }  

  connecttobep()
  {  

    let parentThis = this; 
    if(window.web3)
    { 
      let web3 = new Web3(Web3.givenProvider)
      web3.eth.net.getNetworkType().then(function(networkName)
      {    
        if(networkName === "private")
        {
          try 
          { 
            window.ethereum.enable().then(function(res) {
              parentThis.getBEPAccountDetails()
            })
          } 
          catch(e)
          {
            parentThis.handleModalConnection();
          } 
        }
        else
        {
          parentThis.handleModalMainNetwork()
        }
       
      })
    }
    else if(window.ethereum)
    {
      let web3 = new Web3(window.ethereum)
      try {
        web3.eth.net.getNetworkType().then(function(networkName)
        {
          if(networkName === "private")
          {
            try 
            { 
              window.ethereum.enable().then(function(res) {
                parentThis.getETHAccountDetails()
              })
            } 
            catch(e)
            {
              parentThis.handleModalConnection();
            } 
          }
          else
          {
            parentThis.handleModalMainNetwork()
          } 
        })
      }
      catch(error) {
        console.log('ethereum error, ', error)
      } 
    }
    else
    {
      this.handleModalConnection()
    }
  }

  checkclaimwallet(){
    this.setState({checkclaimwallet: !this.state.checkclaimwallet})
  }
 
  render()
  {  
    var page_number = 1
    var sl_number = 1
    return (
      <> 
      <div id="Content" className="ano_funds">
      <MetaTags>
        <title>Verify ANO Tokens</title>
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>
      <div className="ano_funds_banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ano_funds_banner_block">
                <h3>Verify and Claim ANO Funds</h3>
                <p><a href="https://arrano.medium.com/verify-your-ano-tokens-1462ac6deec0" target="_blank">Read More</a></p>
                {/* <div className="row">
                  <div className="col-md-4 col-4">
                    <div className="ano_funds_wallet">
                      <img src="/assets/images/total-rewards.png" />
                      <h4><span style={{color: "#fff", cursor:"pointer"}} onClick={()=> {this.handleModelLockedFunds()}}>Earned Funds <i style={{color: "#fff"}} className="fas fa-info-circle"></i></span></h4>
                      <h5>{this.state.total_locked_fund}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-4">
                    <div className="ano_funds_wallet">
                      <img src="/assets/images/verified-funds.png" />
                      <h4><span style={{color: "#fff", cursor:"pointer"}} onClick={()=> {this.handleModelVerifiedFunds()}}>Verified Funds <i style={{color: "#fff"}} className="fas fa-info-circle"></i></span>
                       <span onClick={()=> {this.handleModelVerifiedFunds()}}>Know More <i className="fas fa-info-circle"></i></span> 
                      </h4>
                      <h5>{this.state.total_verified_fund}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-4">
                    <div className="ano_funds_wallet">
                      <img src="/assets/images/premium-wallet.png" />
                      <h4>
                      <span style={{color: "#fff", cursor:"pointer"}} onClick={()=> {this.handleModelAvailableFunds()}}>Available Funds <i style={{color: "#fff"}} className="fas fa-info-circle"></i></span>
                      </h4>
                      <h5>{this.state.total_available_fund}</h5>
                    </div>
                  </div>
                </div> */}
 

                <div className="row">
                  <div className="col-md-4 wallet_mobile">
                    <div className="ano_funds_banner_wallet">
                      <h6>Total Arrano Wallet</h6> 
                      <h3>
                        {
                          this.state.ano_wallet_balance ?
                          this.state.ano_wallet_balance
                          :
                          0.00
                        }
                      ANO</h3>
                      <h5>Locked Funds <a onClick={()=> {this.handleModelLockedFunds()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.total_locked_fund} ANO</span></h5>
                      {/* <h5>Verified Funds <a onClick={()=> {this.handleModelVerifiedFunds()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.total_verified_fund} ANO</span></h5> */}
                      <h5>Available Funds <a onClick={()=> {this.handleModelAvailableFunds()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.total_available_fund} ANO</span></h5>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="steps_by_process">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="row">
                              <div className="col-md-12">
                                <h6 className="ano_steps_title"><span>Step 1: Verification</span></h6>
                                <div className="ano_block_allow">
                                {
                                this.state.reverify_email_active_status == "1"
                                 ?
                                <h6>User Email Verification status: Verification <i className="fas fa-check-circle success_check"></i></h6>
                                : 
                                this.state.reverify_email_verify_status == "0"
                                ?
                                <h6>User Email verification status: Send OTP to Email for reverification <i className="fas fa-exclamation-circle pending_check" onClick={()=>this.sentOTPToEmail()}></i></h6>
                                : 
                                this.state.reverify_email_active_status
                                ? 
                                <h6>User Email Verification status: Re-Verify Pending. <i className="fas fa-exclamation-circle pending_check"  onClick={()=>this.handleReverifyActiveMessage()}></i></h6> 
                                :
                                null
                                }
                                </div>
                              </div>
                          </div>
                          <h6 className="ano_steps_title"><span>Step 2: Connect Wallet</span></h6>
                          <div className="row">    

                            {/* <div className="col-md-4 col-4 ano-fund-connect-wallet">
                              <button><img src="/assets/images/eth-icon.png" /></button>
                              {this.state.claim_wallet ?  <p onClick={()=> this.connectToWallet()}>Connect</p> : null}
                            </div> */}
                            
                            <div className="col-md-4 col-4 ano-fund-connect-wallet">
                              <button><img src="/assets/images/bnb-icon.webp" /></button>
                              {
                                this.state.claim_bep
                                ?
                                <i class="fas fa-check-circle wallet_connected_allow"></i>
                                : 
                                null
                              }
                              {this.state.claim_wallet ?  <p onClick={()=> this.connecttobep()}>Connect</p> : null}
                            </div>
                            
                            <div className="col-md-4 col-4 ano-fund-connect-wallet">
                              <button><img src="/assets/images/trx-icon.png" /></button>
                              {
                                this.state.claim_trx
                                ?
                                <i class="fas fa-check-circle wallet_connected_allow"></i>
                                : 
                                null
                              }
                              {this.state.claim_wallet ?  <p onClick={()=> this.gettronweb()}>Connect</p> : null} 
                            </div>
                          </div>
                            {
                            this.state.claim_wallet
                            ?
                            null
                            :
                            <h6 className="connected_address">Connected {this.state.claim_eth ? "ETH" : this.state.claim_trx ? "TRX" : this.state.claim_bep ? "BEP" : null} Address: {this.state.claim_wallet_address}</h6>
                            }

                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="ano_steps_title"><span>Step 3: Activate ANO Funds</span></h6>
                              <div className="ano_block_allow">
                                <h5>Must have at least 0.10 ANDX in your connected wallet to authenticate your ANO funds. {this.state.claim_wallet_balance >= 0.10 ? <i className="fas fa-check-circle success_check"></i> :  <i className="fas fa-exclamation-circle pending_check"></i>} </h5>
                                
                                <h6>Your Balance: <span>{this.state.claim_wallet_balance ? this.state.claim_wallet_balance : 0 } ANDX</span></h6>
                                {
                                  this.state.claim_wallet_balance < 0.10 
                                  ? 
                                  <h6>You can buy ANDX from here.
                                    {
                                      // this.state.claim_eth 
                                      // ?
                                      // <Link><a href="">ERC20 Buy</a></Link>
                                      // :
                                      this.state.claim_trx
                                      ?
                                      <a href="https://arrano.medium.com/how-to-buy-andx-on-justswap-c6f31dd257a1" target="_blank">TRC20 Buy</a>
                                      : 
                                      this.state.claim_bep
                                      ?
                                      <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb63a911ae7dc40510e7bb552b7fcb94c198bbe2d" target="_blank">BEP20 Buy</a>
                                      :
                                      null 
                                    }
                                  </h6>
                                  :
                                  null
                                }
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="ano_steps_title"><span>Step 4: Verify purchase via DEX</span></h6>
                              <div className="ano_block_allow">
                                <h5>Must have purchased ANDX from PancakeSwap or JustSwap, 5 Days before. {this.state.day_difference > 5 ? <i className="fas fa-check-circle success_check"></i> : <i className="fas fa-exclamation-circle pending_check"></i>} </h5>

                                  {
                                    this.state.claim_wallet
                                    ?
                                    null
                                    :
                                    this.state.day_difference == 0
                                    ?
                                    <h6 className="purchase_history_condition">* No purchase history</h6>
                                    :
                                    this.state.day_difference < 5
                                    ?
                                    <h6 className="purchase_history_condition">* { 5 - this.state.day_difference} Day(s) remaining to claim</h6> 
                                    :
                                    null
                                  } 
                                

                                {
                                  this.state.claim_wallet
                                  ?
                                  null
                                  :
                                  <div className="mt-4">
                                    <p className="text-right" style={{marginBottom: '0', fontSize: '14px'}}>Available Balance: {this.state.total_available_fund } ANO</p>
                                    <div className="input-group mb-1">
                                      <input type="text" className="form-control" value={this.state.claim_wallet_address} />
                                      {
                                        this.state.day_difference < 5 || this.state.total_available_fund == 0
                                        ?
                                        <div className="input-group-prepend" onClick={()=> this.setState({submitclaimano: true})}>
                                          <span className="input-group-text">Claim ANO</span>
                                        </div>
                                        : 
                                        <div className="input-group-prepend" onClick={()=> this.SubmitClaimwalletDetails()}>
                                          <span className="input-group-text">Claim ANO</span>
                                        </div>   
                                      }
                                    </div> 

                                    <p>You cannot change the receiver address in future.</p>
                                  </div> 
                                }
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        
                      {/* <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="ano_funds_wallet">
                            <h4>Step 1</h4>
                            <img src="/assets/images/email_steps.png" />
                            <h5>Re-Verify Email</h5>
                            {
                                parseInt(this.state.reverify_email_active_status) === 1 ?
                                <React.Fragment>
                                  {
                                    parseInt(this.state.reverify_email_verify_status)  === 0 ?
                                      <React.Fragment>
                                        <h6><span className="send_otp_email" onClick={() => {this.sentOTPToEmail()}}>Send OTP <b className="blink_me"><i className="fas fa-info-circle"></i></b></span></h6>
                                        </React.Fragment>
                                      :
                                      <h6 className="email_verified">Verified <i className="fas fa-check-circle"></i></h6> 
                                  }
                                </React.Fragment>
                                :
                                <h6><span style={{color: "#fff", cursor:"pointer"}} onClick={()=> {this.handleReverifyActiveMessage()}}>Re-Verify Pending </span></h6>
                            }               
                          </div>
                        </div>
                        
                        <div className="col-md-6 col-12">
                          <div className="ano_funds_wallet">
                            <h4>Step 2</h4>
                            <img src="/assets/images/mobile_steps.png" />
                            {
                              this.state.device_unqiue_id == '' 
                              ?
                              <h5 data-toggle="modal" data-target="#ComingMobileApp">Wait for the New Version update</h5>
                              : 
                              <h5>Download Mobile APP</h5>
 
                            }
                            {
                              this.state.device_unqiue_id !== '' ?
                              <h6 className="email_verified">Authorized <i className="fas fa-check-circle"></i></h6> 
                              :
                              null
                              // <h6 className="download-links">Pending<a target="_blank" href="https://play.google.com/store/apps/details?id=network.arrano.app"><i className="fa fa-android download-links"></i></a> <a  data-toggle="modal" data-target="#ComingSoonIphonelink"><i className="fa fa-apple download-links"></i></a></h6>
                            }
                          </div>
                        </div> */}
                         {/* <div className="col-md-4 col-12">
                          <div className="ano_funds_wallet">
                            <h4>Step 3</h4>
                            <img src="/assets/images/connection_steps.png" />
                            <h5>Verify your Connections</h5>
                            <h6>Notify <span title="Coming soon"><i style={{color: '#fff', marginRight:'5px', marginLeft:'5px'}}  className="far fa-envelope" ></i></span> 
                            <a target="_blank" href="http://twitter.com/intent/tweet/?text=Hello Mates, its time to verify your $ANO funds. Login to your Arrano Account and re-verify Email + Download the app to authorize your funds.&url=http://arrano.network/"><i style={{color: '#fff'}} className="fab fa-twitter"></i></a></h6>
                          </div>

                        </div>  */}
                        {/* <p>Note: Your referrals must also reverify accounts to receive referral ANO rewards</p>
                      </div> */}
                      
                    </div>
                  </div>
                  <div className="col-md-4 wallet_desktop">
                    <div className="ano_funds_banner_wallet">
                      <h6>Total Arrano Wallet</h6>
                      <h3> {
                          this.state.ano_wallet_balance ?
                          this.state.ano_wallet_balance
                          :
                          0.00
                        } ANO</h3>
                      {/* <h5>Earned Funds <a onClick={()=> {this.handleModelEarnedFunds()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.ano_wallet_balance} ANO</span></h5>
                      <hr/>
                      <h6>Locked</h6> */}
                      <h5>Locked Funds <a onClick={()=> {this.handleModelLockedFunds()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.total_locked_fund} ANO</span></h5>
                      {/* <h5>Verified Funds <a onClick={()=> {this.handleModelVerifiedFunds()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.total_verified_fund} ANO</span></h5> */}
                      {/* <hr /> */}
                      {/* <h6>Released Funds</h6> */}
                      <h5>Available Funds <a onClick={()=> {this.handleModelAvailableFunds()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.total_available_fund} ANO</span></h5>
                      <h5>Total Claimed Funds <a onClick={()=> {this.checkclaimwallet()}}><i style={{color: "#fff"}} className="fas fa-info-circle"></i></a> <span>{this.state.claim_ano_value} ANO</span></h5>
                    </div>
                  </div>
                </div>
 
                {/* <p className="reverify_email_mobile">Re-Verify Email + <i className="fab fa-android"></i> Download Mobile App</p> */}
              </div>
            </div>
          </div>
        </div>
           
      </div>
      
      <div className="container"> 
        <ul class="nav nav-tabs main_block_tabs">
          <li class="active"><a data-toggle="tab" href="#Overview" onClick={()=> this.setState({ano_tab: "1"})} class="active">Available Funds</a></li>
          {/* <li><a data-toggle="tab" href="#Exchange" onClick={()=> this.setState({ano_tab: "2"})}>Available Funds</a></li> */}
          <li><a data-toggle="tab" href="#claimfund" onClick={()=> this.setState({ano_tab: "3"})}>Claim Funds</a></li>
        </ul>

        <div className="tab-content ano_funds_history">
          {
            this.state.ano_tab == "1"
            ?
            <div className="tab-pane active show" id="Overview"> 
            <h5>Available Funds History</h5>
            <div className="table-responsive">
              <table className="table table_connections">
                <thead>
                  <tr>
                  <th className="mobile_hide">Sl.No</th>
                  <th>Date </th>
                  <th>Description</th>
                  <th>Tokens</th>
                  </tr>
                </thead> 
                <tbody> 
                {
                     this.state.getVerifiedTxnLoginRegList ?
                      <>
                      {
                        this.state.getVerifiedTxnLoginRegList.map((item, i) =>
                        <tr key={'a'+i}>
                          {/* {sl_number++} */}
                            <td className="mobile_hide">-</td>
                            <td><Moment format="DD MMM YYYY">{item.date_only}</Moment>
                            </td>
                            <td>
                              {
                                parseInt(item.transaction_type) === 1 ?
                                <React.Fragment>
                                  Registration Reward
                                </React.Fragment>
                                :
                                parseInt(item.transaction_type) === 2 ?
                                <React.Fragment>
                                  Login Reward 
                                </React.Fragment>
                                :
                                null
                              }
                            </td>
                            <td>{parseFloat(item.ano_value)} ANO</td> 
                        </tr>
                        )
                      }
                    </>
                     :
                     null
                   }
              { 
                
                !this.state.verifiedTxnLoaderStatus ?
                 <React.Fragment>
                   {
                     this.state.getVerifiedTxnReferralsList ?
                     <>
                      {
                        this.state.getVerifiedTxnReferralsList.map((item, i) =>
                        <tr key={'b'+i}>
                            <td className="mobile_hide">{sl_number++}</td>
                            <td><Moment format="DD MMM YYYY">{item.date_only}</Moment>
                            </td>
                            <td>
                               Referral reward from {item.from_user_name}
                            </td>
                            <td>{parseFloat(item.ano_value)} ANO</td> 
                        </tr>
                        )
                      }
                    </>
                     :
                     null
                   } 
                   
                   {
                    ((this.state.getVerifiedTxnReferralsList == '') && (this.state.getVerifiedTxnLoginRegList == '')) ?
                    <tbody>
                      <tr >
                      <td colSpan="4">Sorry No Related Data Found.</td>
                      </tr>
                    </tbody>
                    :
                    null
                  }
                 </React.Fragment>
                :
                <React.Fragment>
                    <tr key={'1'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>  
                    <tr key={'2'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>  
                    <tr key={'3'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                    </tr>  
                    <tr key={'4'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                      
                    </tr>  
                    <tr key={'5'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>  
                    <tr key={'6'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>   
                
                </React.Fragment>
              }
               </tbody>
              </table> 
              
            </div>
            {
                    this.state.total_connections ?
                      <React.Fragment>
                        {
                          this.state.total_connections > this.state.perPage ?
                          <div style={{textAlign: 'center', marginBottom: '15px'}}>
                              <Pagination
                                prevPageText='« Prev'
                                nextPageText='Next »'
                                firstPageText='First'
                                lastPageText='Last'
                                itemclassName='page-item'
                                activePage={this.state.page_number}
                                itemsCountPerPage={this.state.perPage}
                                totalItemsCount={this.state.total_connections}
                                pageRangeDisplayed={5}
                                onChange={(pageNumber) => this.getVerifiedTxnReferralsList(pageNumber)}
                              />
                          </div>
                          :
                          null
                        }
                      </React.Fragment>   
                    :
                    null
                } 
          </div>
            : 
            
            <div className="tab-pane  active show" id="Exchange">
              <h5>Claim Funds History</h5>
              
              <div className="table-responsive">
                <table className="table table_connections">
                  <thead>
                  <tr>
                    <th className="mobile_hide">Sl.No</th>
                    <th>Date </th>
                    <th>Tokens</th> 
                    <th>status</th> 
                    </tr>
                  </thead>
                {
                this.state.claim_list_status || this.state.claim_list.length > 0 ?
                <>
                  {
                   this.state.claim_list.length > 0
                   ?
                   <tbody>
                     {
                       this.state.claim_list.map((item, i) =>
                       <tr key={'c'+i}>
                           <td className="mobile_hide">{page_number+i}</td>
                           <td>{item.date_n_time}</td>
                           <td>{item.ano_value} ANO</td> 
                           <td>
                           {
                             item.approval_status == "0"
                             ?
                             "Pending"
                             :
                             item.approval_status == "1"
                             ?
                             "Approved ("+item.admin_hash+")" 
                             :
                             "Rejected ("+ item.rejected_reason ? item.rejected_reason : "-" +")" 
                           } 
                           </td> 
                       </tr>
                       )
                     }
                     
                   </tbody>
                   : 
                   <tbody>
                     <tr >
                     <td colSpan="4">Sorry No Related Data Found.</td>
                     </tr> 
                   </tbody>
                 }
                </>
               :
               <>
                 <tbody>
                   <tr key={'1'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td> 
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td> 
                   </tr>  
                   <tr key={'2'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td> 
                   </tr>  
                   <tr key={'3'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td> 
                   </tr>  
                   <tr key={'4'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td> 
                   </tr>  
                   <tr key={'5'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td> 
                   </tr>  
                   <tr key={'6'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td> 
                   </tr>   
               </tbody>
               </>
             }
                </table>
              </div>
            </div>

          }
          
        </div> 
        
      </div>
    </div>
    {/* Verify Funds */}
    <div className={"modal  modal_main_popup" + (this.state.verifiedfunds ? " show d-block" : " d-none")}>
      <div className="modal-dialog claim_auto readmore_ano_tokens">
        <div className="modal-content ">
          <div className="modal-body ">
            <button type="button"  className="close" data-dismiss="modal" onClick={()=> {this.handleModelVerifiedFunds()}}>&times;</button>
            <h2>Verified ANO tokens</h2>
            <ul className="text-left">
              <li>Email Re-verify + Mobile app download must be completed </li>
              <li>The Verified fund will be released Directly to blockchain wallet monthly (to Available tokens) </li>
              <li>5% release every month.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
 
        {/* Available Funds */}
        <div className={"modal  modal_main_popup" + (this.state.availablefunds ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-xs readmore_ano_tokens">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button"  className="close" data-dismiss="modal" onClick={()=> {this.handleModelAvailableFunds()}}>&times;</button>
                <h2>Available ANO tokens</h2>
                <ul className="text-left">
                  <li>This tokens are sent directly to the blockchain wallet.</li>
                  <li>Accessible to trade and transact.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Locked Funds */}
        <div className={"modal  modal_main_popup" + (this.state.lockedfunds ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto readmore_ano_tokens">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button" className="close" data-dismiss="modal" onClick={()=> {this.handleModelLockedFunds()}}>&times;</button>
                <h2>Locked funds ANO</h2>
                <p style={{fontWeight:"100",color:"#aaa"}}>This is the total ANO funds earned from Airdrop and rewards. This funds are locked and you need to Re-verify your email and download the app to authenticate this funds.</p>
                <p style={{fontWeight:"100",color:"#aaa"}}>Once verified, tokens will be sent to Verified wallet and further it will be released directly to blockchain wallet with monthly ratio of. <br></br>5% release  monthly.</p>
              </div>
            </div>
          </div>
        </div>


        {/* <div className={"modal  modal_main_popup" + (this.state.Earnedfunds ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto readmore_ano_tokens">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button" type="button" className="close" data-dismiss="modal" onClick={()=> {this.handleModelEarnedFunds()}}>&times;</button>
                <h2>Earned funds ANO</h2>
                <p style={{fontWeight:"100",color:"#aaa"}}>This is the total ANO funds earned from Airdrop and rewards. This funds are locked and you need to Re-verify your email and download the app to authenticate this funds.</p>
                <p style={{fontWeight:"100",color:"#aaa"}}>Once verified, tokens will be sent to Verified wallet and further it will be released directly to blockchain wallet with monthly ratio of. <br></br>5% release  monthly.</p>
              </div>
            </div>
          </div>
        </div> */}

         {/* Email Re-verify after 3 Days */}
         <div className={"modal  modal_main_popup" + (this.state.reverifyActiveMessageStatus ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto readmore_ano_tokens">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button" className="close" data-dismiss="modal" onClick={()=> {this.handleReverifyActiveMessage()}}>&times;</button>
                <h2>Re-Verify your Email</h2>
                <p style={{fontWeight:"100",color:"#aaa"}}>For new users email re-verification will start after 3 days of registration.</p>
              </div>
            </div>
          </div>
        </div>     
      {/* popup modal ends here  */}   
 
      {/* Email Re-Verify Model - Starts here */}

      {/* Email Verify OTP */}
        <div className={"modal  modal_main_popup" + (this.state.emailverifyotp ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-md">
            <div className="modal-content">
              <div className="modal-body ">
                <button type="button" className="close" data-dismiss="modal"  onClick={()=> this.setState({emailverifyotp: false})}>&times;</button>
                <h2 style={{fontSize: '40px'}}><i className="far fa-envelope"></i></h2>
                <h2>Re-Verify your Email</h2>
                <p>Enter OTP sent to your Arrano Account email. You must download the mobile app after this.</p>
                <div className="otp_email_verify">
                <OtpInput  className="otp-form-control" numInputs={6} value={this.state.email_verify_otp} onChange={this.handleChange} separator={<span>-</span>}  name="email_verify_otp" />
                </div>
                <div className="reverify_email_id">
                  <div style={{fontSize:'12px', color:'red', marginBottom: '6px', fontWeight:'600'}}>
                    {this.state.error_email_verify_otp} 
                  </div>
                
                <button type="submit" className="btn-dashboard-small" onClick={()=>{this.verifyEmailFun()}}>
                  {this.state.verify_email_loader ? (
                    <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
                      ) : (
                    <> Verify OTP</>
                  )}
                </button>

                <p className="resend_otp_dashboard" onClick={()=>{this.sentOTPToEmail()}}><span>Resend OTP</span></p>     
                
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Email Verify OTP Success */}
        <div className={"modal  modal_main_popup" + (this.state.emailverifysuccess ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-sm">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button" className="close" data-dismiss="modal" onClick={()=> {this.handleModelEmailVerifySuccessMsg()}}>&times;</button>
                <h2 style={{fontSize: '40px'}}><i className="far fa-check-circle"></i></h2>
                <h2>Email Re-Verified Successfully!</h2>
                <p>Please Download the mobile app to authenticate the access to ANO tokens.</p>
              </div>
            </div>
          </div>
        </div>
  
            <div className="modal" id="ComingSoonIphonelink">
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button" className="close" data-dismiss="modal" >&times;</button>
                  <h2>Coming Soon</h2> 
                </div>
              </div>
            </div>
          </div>
 
          <div className="modal" id="ComingMobileApp">
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button"  className="close" data-dismiss="modal" >&times;</button>
                  <h2>App on update</h2>
                  <p>We are updating the mobile app with new features please wait for the announcement to download the latest version from playstore</p> 
                </div>
              </div>
            </div>
          </div>
  
       {/* Email Re-Verify Model - ends here */}

       <div className={"modal  modal_main_popup" + (this.state.modalTrxConnErr ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-sm">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button"  className="close" data-dismiss="modal" onClick={()=> {this.connecttotrc()}}>&times;</button>
                <h2 style={{fontSize: '40px'}}><i className="far fa-times-circle"></i></h2>
                <h2>Error</h2>
                <p>Please connect to Tron Wallet network </p>
              </div>  
            </div>
          </div>
        </div> 

        <div className={"modal  modal_main_popup" + (this.state.checkclaimwallet ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-md readmore_ano_tokens">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button"  className="close" data-dismiss="modal" onClick={()=> {this.checkclaimwallet()}}>&times;</button>
                <h2>Total Claimed Funds</h2>
                <p>Total ANO tokens sent to your provided blockchain wallet. </p>
              </div>  
            </div>
          </div>
        </div>
        
        <div className={"modal  modal_main_popup" + (this.state.submitclaimano ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-sm">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button"  className="close" data-dismiss="modal" onClick={()=> this.setState({submitclaimano: false})}>&times;</button>
                {
                  this.state.day_difference == 0
                  ?
                  <>
                  <h2 style={{fontSize: '40px'}}><i className="far fa-check-circle"></i></h2>
                  <h2>Error</h2>
                  <p>No purchase history</p>
                  </>
                  :
                  this.state.day_difference < 5
                  ?
                  <>
                  <h2 style={{fontSize: '40px'}}><i className="far fa-check-circle"></i></h2>
                  <h2>Error</h2>
                  <p>{ 5 - this.state.day_difference} Day(s) remaining to claim</p> 
                  </>
                  :
                  this.state.total_available_fund == 0
                  ?
                  <>
                    <h2 style={{fontSize: '40px'}}><i className="far fa-check-circle"></i></h2>
                    <h2>Error</h2>
                    <p>Sorry , Insufficient wallet balance</p> 
                  </>
                  :
                  <>
                    <h2 style={{fontSize: '40px'}}><i class="far fa-times-circle"></i></h2>
                    <h2>Submitted successfully</h2>
                    <p>ANO will be sent within 24Hrs.</p>
                  </>
                }
              </div>  
            </div>
          </div>
        </div>

       <div className={"modal  modal_main_popup" + (this.state.handleModalConnection ? " show d-block" : " d-none")}>
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button"  className="close" data-dismiss="modal" onClick={()=> {this.handleModalConnection()}}>&times;</button>
                  <h2 style={{fontSize:'40px'}} ><i className="far fa-times-circle" aria-hidden="true"></i></h2>
                  <h2>Connection Error!</h2>
                  <p>Please connect to the Binace smart chain network.</p>
                </div>
              </div>
            </div>
          </div>

          <div className={"modal  modal_main_popup" + (this.state.save_claim_wallet_details ? " show d-block" : " d-none")}>
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button"  className="close" data-dismiss="modal" onClick={()=> this.setState({save_claim_wallet_details : false})}>&times;</button>
                  <h2 style={{fontSize:'40px'}} ><i className="far fa-times-circle" aria-hidden="true"></i></h2>
                  <h2>Connection Error!</h2>
                  <p>{this.state.invalid_claim_wallet_address}</p>
                </div>
              </div>
            </div>
          </div>
  

          <div className={"modal  modal_main_popup" + (this.state.handleModalMainNetwork ? " show d-block" : " d-none")}>
            <div className="modal-dialog claim_auto modal-sm">
              <div className="modal-content ">
                <div className="modal-body ">
                  <button type="button"  className="close" data-dismiss="modal" onClick={()=>{this.handleModalMainNetwork()}}>&times;</button>
                  <h2 style={{fontSize:'40px'}} ><i className="far fa-times-circle" aria-hidden="true"></i></h2>
                  <h2>Connection Error!</h2>
                  <p>Switch your Metamask wallet network to Binace smart chain network.</p>
                </div>
              </div>
            </div>
          </div>
         
    </>
    );
  }
}

export default withRouter(Ano_funds);
