import React from 'react';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Cookies from 'js-cookie';
import {api_base_url, get_config, cookieDomainExtension} from '../../Constants'; 

class Forgot_password_otp extends React.Component {
           
  constructor(props) 
  {
      super(props);
      this.state = {
          alert_message:"",
          forgot_otp:"",
          error_forgot_otp:"",
          forgot_unique_id:Cookies.get('forgot_password_sess_id'),
          forgot_password_sess_email_id:Cookies.get('forgot_password_sess_email_id'),
          forgot_password_date_n_time:Cookies.get('forgot_password_date_n_time'),
          headers: {
            "Accept":"Application/json",
            "Content-Type":"Application/json"
          },
          loader:false
      }
      
  }
  
  handleChange = forgot_otp => this.setState({ forgot_otp });

  forgotOTPFun()
  { 
    
      let formIsValid = true;

      if(!this.state.forgot_otp) 
      {
          this.setState({
            error_forgot_otp:"The OTP field is required."
          });
          formIsValid = false;
      }
      else if(this.state.forgot_otp.length != 6) 
      {
          this.setState({
            error_forgot_otp:"The OTP field must be at exactly 6 characters in length."
          });
          formIsValid = false;
      }
      else
      {
          this.setState({
            error_forgot_otp:""
          });
      }

      if(!formIsValid)
      {
          return true;
      }
    
    this.setState({
        loader:true
    });
    
    const reqObj = 
    {
      forgot_otp: this.state.forgot_otp,
      forgot_unique_id:this.state.forgot_unique_id
    }
    const config = get_config("")
    fetch(api_base_url+"forgot_password/verify_otp", {
        method:'POST',
        headers: config.headers,
        body:JSON.stringify(reqObj)
    }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
            loader:false
        });

        if(result.status === true) 
        {     
              Cookies.remove('forgot_alert_message');
              Cookies.remove('forgot_password_sess_id');
              Cookies.remove('forgot_password_sess_email_id');
              Cookies.remove('forgot_password_date_n_time');

              Cookies.set('forgot_unique_id', result.message.forgot_unique_id,{domain:cookieDomainExtension});
              Cookies.set('forgot_verify_code', result.message.forgot_verify_code,{domain:cookieDomainExtension});
              Cookies.set('forgot_alert_message', result.message.alert_message,{domain:cookieDomainExtension});
              this.props.history.push('/auth/forgot-password-new-password');
        }
        else
        {   
            
            if(result.message.alert_message)
            {
              var var_alert_message = result.message.alert_message;
            }
            else
            {
              var var_alert_message = "";
            }



            this.setState({
                alert_message:var_alert_message
            });
        }
        
        });
  }

  componentDidMount(){
    // if(!Cookies.get('forgot_password_sess_id') || !Cookies.get('forgot_password_sess_email_id') || !Cookies.get('forgot_password_date_n_time'))
    // {
    //     this.props.history.push('/auth/forgot-password');
    // }

    // if(Cookies.get('token'))
    // {
    //     this.props.history.push('/user/dashboard');
    // } 
  }


  render()
  {
    return ( 
      <> 
      <section className="auth_section">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-5">
                <div className="row">
                  <div className="col-lg-12 col-md-12 auth_form">
                   <div className="submit_form">
                      <h2 className="heading-title" />
                      <div className="row">
                        <div className="col-md-12">
                          <h4>Enter OTP</h4>
                          <p>{Cookies.get('forgot_alert_message')}</p>

                          {this.state.alert_message ? (
                          <div className="alert alert-primary">
                            {this.state.alert_message}
                          </div>
                          ) : (
                              null
                          )}
                        </div>
                        <div className="col-md-12 text-center otpinput">
                          <OtpInput  className="otp-form-control" isInputNum={true} numInputs={6} value={this.state.forgot_otp} onChange={this.handleChange} separator={<span>-</span>}  name="forgot_otp" />
                          
                        </div>
                        <div className="col-md-12 text-center otpinput">
                        <div className="error">
                          {this.state.error_forgot_otp} 
                          </div>
                          <h6 className="text-center"><Link to="/auth/login"  >Log In?</Link></h6>
                       
                        <button className="auth_button"  type="submit" onClick={()=>{this.forgotOTPFun()}} >
                            {this.state.loader ? (
                              <span className="spinner-border spinner-border-sm mr-1"></span> 
                              ) : (
                                <>Verify OTP</>
                            )} 
                        </button>
                        </div>
                      </div>
                   </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-lg-9 col-md-12 mt-0 auth_content">
                    <p className="mt-0">The new impact of blockchain</p>
                    <h3>Arrano Projects will make a Difference. </h3>
                    <h5>Turning the real world ideas into decentralized reality of blockchain </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </> 
    );
  }
}

export default Forgot_password_otp;
