import React from 'react'; 
import MetaTags from 'react-meta-tags';  
import Footer from '../layouts/Footer';

class Roadmap extends React.Component 
{

render(){
  return (
    <>
      <div id="Content" className="claim_ano">
        <MetaTags>
          <title> Arrano Network Roadmap | Way to Decentralised World</title>
          <link rel="canonical" href="https://arrano.network/roadmap" />
          <meta name="description" content=" Arrano Network is a world class Decentralized exchange aimed to provide service and deliver our products faster and smarter." />
          <meta name="keyword" content="Arrano Network Roadmap , Arrano Network exchange , Arrano dex , Arrano Decentralized exchange." />
          <meta property="og:title" content="Arrano Network Roadmap | Way to Decentralised World" />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        <div className="content_wrapper clearfix">
          <div className="sections_group">
            <div className="entry-content">
              <div className="about_page ">
                <div className="container">
                  <div className="col-md-12">
                    <div className="roadmap_page">
                      <h1>Arrano Network Roadmap</h1>
                      <p>Our goal is to provide the world class Decentralized exchange service and deliver our products faster and smarter. We will be focusing on syndication workflow and product execution.</p>
                      <h6><b>Nov 2020</b> - 1st Summary draft | <b>Dec 2021</b> - Next update Draft </h6>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-roadmap">
                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-litepaper-release.png" />
                                  </div>
                                  <span className="year">June 2020</span>
                                  <div className="timeline-content">
                                    <h5 className="title">LitePaper Release</h5>
                                    <p className="description">Arrano Network’s first listepaper was released publicly as the project passed the Ideation round in May. The paper includes our approach towards Decentralized exchange, the Community and the products. </p>
                                  </div>
                                </div>
                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-arrano-account-njection.png" />
                                  </div>
                                  <span className="year">July 2020</span>
                                  <div className="timeline-content">
                                    <h5 className="title">Arrano account Injection</h5>
                                    <p className="description">Enrollment of users to join the Arrano Network Community began. The community was divided into 4 cases, the introducers, traders, Media and Developers. A Beta version that supports account, bounty submissions and much more.</p>
                                  </div>
                                </div>
                                
                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-arrano-decentralized-exchange-blueprint.png" />
                                  </div>
                                  <span className="year">August 2020</span>
                                  <div className="timeline-content">
                                    <h5 className="title">Arrano Decentralized Exchange Blueprint </h5>
                                    <p className="description">
                                    The first Step to begin the development of Arrano Decentralized exchange was initiated  with multiple surveys and market analysis. Post this study a blueprint was projected to develop an own blockchain platform for further Arrano Products.
                                    </p>
                                  </div>
                                </div>
                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-ano-token-bounty-program.png" />
                                  </div>
                                  <span className="year">October 2020</span>
                                  <div className="timeline-content">
                                    <h5 className="title">ANO token Bounty Program  </h5>
                                    <p className="description">
                                      As of October 22, the ANO token bounty program of 41 days was released. The Airdrop was in motive to encourage crypto members to join the Arrano Community. This campaign was a huge success in building a strong community. 
                                    </p>
                                  </div>
                                </div>
                                
                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-ano-scratch-card-rewards.png" />
                                  </div>
                                  <span className="year">November 2020</span>
                                  <div className="timeline-content">
                                    <h5 className="title">ANO Scratch card rewards </h5>
                                    <p className="description">
                                    This is a part of giveaway wherein users were rewarded with 5 to 20 ANO tokens per day for daily login sessions. Started on Nov 20, the campaign was set to 42 days but was extended with regards to community demands. 92% of Arrano users participated. 
                                    </p>
                                  </div>
                                </div>
                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-trade-analytics-model-(beta).png" />
                                  </div>
                                  <span className="year">November 2020</span>
                                  <div className="timeline-content">
                                    <h5 className="title">Trade Analytics Model ( beta) </h5>
                                    <p className="description">
                                    This is an initiative to one of Arrano Network's products. We aim to provide users a friendly trade analytics platform to perform insightful crypto trading. With demand, this is actively done on twitter handle and the same will be developed inline with the roadmap. 
                                    </p>
                                  </div>
                                </div>


                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-andx-token-pre-sale.png" />
                                  </div>
                                  <span className="year">December 2020</span>
                                  <div className="timeline-content">
                                    <h5 className="title">ANDX token Pre-Sale </h5>
                                    <p className="description">
                                    Another native token of Arrano Network Ecosystem. A Defi token with a supply of 81800 and released for the purpose of Funding the Development of Arrano Dex , P2P and other Products. Started on 14th Dec, the Plan of 3 Day sale was extended because of technical challenges ahead. 
                                    </p>
                                  </div>
                                </div>

                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-launch-of-bounty-2.0.png" />
                                  </div>
                                  <span className="year">December 2020 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Launch of Bounty 2.0 </h5>
                                    <p className="description">
                                    This is another ANO token giveaway campaign for Arrano Community. The motive was to promote the Pre-Sale of ANDX tokens. The Bounty 2.0 consists of Social media, youtube and article bounty that created a global awareness of Arrano Network and its tokens. 
                                    </p>
                                  </div>
                                </div>
                             
                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-arrano-mobile-app-launch.png" />
                                  </div>
                                  <span className="year">Feb 2021 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Arrano Mobile App Launch ( Test Version )</h5>
                                    <p className="description">
                                    Arrano Mobile App will be released with all the account features. This Version of the app is only for the test purpose, where we aim to understand the requirement, behaviour and usability of Crypto users. With feedback from users we will continue the app in next versions. 
                                    </p>
                                  </div>
                                </div>

                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-Implement-andx-on-tron-blockchain.png" />
                                  </div>
                                  <span className="year">Feb 2021 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Implement ANDX on Tron Blockchain </h5>
                                    <p className="description">
                                    ANDX token will be implemented also in Tron Blockchain Network. Users can buy ANDX with TRX in pre-sale, store tokens in Tron Wallet and trade on Tron based Decentralized exchanges. This is to support Tron Community and work on high gas fees 
                                    </p>
                                  </div>
                                </div>

                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-listing-of-andx-token.png" />
                                  </div>
                                  <span className="year">Feb 2021 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Listing of ANDX token </h5>
                                    <p className="description">
                                    The ANDX Defi token will be listed on Tron and Ethereum Blockchain Network based Decentralized exchanges. Users can experience cross chain ANDX trading. Further to this we also plan to implement other blockchains supported. 
                                    </p>
                                  </div>
                                </div>

                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-Arrano-network-WhitePaper-release.png" />
                                  </div>
                                  <span className="year">Feb 2021 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Arrano Network WhitePaper release  </h5>
                                    <p className="description">
                                    With all the great traction and market acquisition the Arrano Network team is all set with a visionary approach towards existing problems or hurdles in blockchain network. The whitepaper will be publicly released. 
                                    </p>
                                  </div>
                                </div>

                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-listing-ano-token.png" />
                                  </div>
                                  <span className="year">March 2021 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Listing ANO token  </h5>
                                    <p className="description">
                                    The ANO token  listed on exchanges with a limited token supply released to wallets. The token may also go into migration to its own blockchain network. ANO is aimed to have multiple use-cases. 
                                    </p>
                                  </div>
                                </div>

                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-mobile-app-update-beta-version.png" />
                                  </div>
                                  <span className="year">March 2021 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Mobile App Update ( Beta version)  </h5>
                                    <p className="description">
                                    The Arrano Network Mobile app is a motive to understand the User behaviour and market needs. With ongoing surveys and feedback the app will be updated with improved user experience and new features. 
                                    </p>
                                  </div>
                                </div>

                                <div className="timeline">
                                  <div className="timeline-icon">
                                    <img src="/assets/images/road-arrano-p2p-launch.png" />
                                  </div>
                                  <span className="year">April 2021 </span>
                                  <div className="timeline-content">
                                    <h5 className="title">Arrano P2P Launch  </h5>
                                    <p className="description">
                                    An User-friendly and Instant Peer to Peer to exchange platform that enables users to exchange their Crypto to fiat. We will begin with the country having the highest number of users from.
                                    </p>
                                  </div>
                                </div>
                                <div className="timeline">
                                  <div className="timeline-icon">
                                  <img src="/assets/images/road-development-of-arrano-chain.png" />
                                  </div>
                                  <span className="year">May 2021 </span>
                                  <div className="timeline-content">
                                  <h5 className="title">Development of Arrano Chain</h5>
                                  <p className="description">
                                  Arrano Network Ecosystem , the popular Crypto platform will have its own Blockchain Network that will power up the project to develop and deploy fair crypto products including Decentralized exchange. 
                                  </p>
                                  </div>
                              </div>

                              <div className="timeline">
                                  <div className="timeline-icon">
                                  <img src="/assets/images/road-decentralized-exchange.png" />
                                  </div>
                                  <span className="year">July 2021 </span>
                                  <div className="timeline-content">
                                  <h5 className="title">Decentralized Exchange</h5>
                                  <p className="description">
                                  With its own Blockchain Network, the development of Arrano Decentralized exchange will begin and aimed to accomplish the platform in 30 days of development. This will be an initiative to further developments.  
                                  </p>
                                  </div>
                              </div>

                              <div className="timeline">
                                  <div className="timeline-icon">
                                  <img src="/assets/images/road-arrano-blockchain-wallet.png" />
                                  </div>
                                  <span className="year">August 2021 </span>
                                  <div className="timeline-content">
                                  <h5 className="title">Arrano Blockchain Wallet</h5>
                                  <p className="description">
                                  A Crypto wallet to store and transact ANO , ANDX and other crypto tokens . The wallet is aimed to support a multi-chain network, a browser and a platform to access Arrano blockchain based decentralized apps.  
                                  </p>
                                  </div>
                              </div>

                              <div className="timeline">
                                  <div className="timeline-icon">
                                  <img src="/assets/images/road-arrano-launch-pad.png" />
                                  </div>
                                  <span className="year">October 2021 </span>
                                  <div className="timeline-content">
                                  <h5 className="title">Arrano Launch Pad</h5>
                                  <p className="description">
                                  A Decentralized startup funding platform, that allows users to participate and fund the crypto startups using ANO and Crypto. The product will support new tokenized projects uner the hood of Arrano blockchain Network.  
                                  </p>
                                  </div>
                              </div>

                              <div className="timeline">
                                  <div className="timeline-icon">
                                  <img src="/assets/images/road-arrano-talk.png" />
                                  </div>
                                  <span className="year">November 2021 </span>
                                  <div className="timeline-content">
                                  <h5 className="title">Arrano Talk</h5>
                                  <p className="description">
                                  This is a community based discussion platform, where users can create and join topics to discuss with the same interest. An approach to build a professional and collaborative space of community.  
                                  </p>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                      <div className="col-md-7">
                        <p><b>Note : </b></p>
                        <ul>
                          <li>The Arrano Network Roadmap is designed as per the Arrano Community resources standards and we urge to serve the best services to ourt Community</li>
                          <li>With regards to any natural or Technical hurles the delivery timeline may vary, but we will keep you posted with any update. </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
      </>
    );
  }
}

export default Roadmap;
