import React from 'react';
import {Switch, Route} from 'react-router-dom';  
import Market from './Market';
import market_details from "./Market_details";

import Footer from "../../layouts/Footer";
 
class Market_Routing extends React.Component 
{   

  render() { 
    return (  
     <> 
        <Switch>
              <Route path="/market/:id" component={market_details} />  
              <Route path="/" component={Market} />  
        </Switch>  
        <Footer />
        </>
    )
  }
}

export default Market_Routing;
 
