import React from 'react'
import { Redirect } from 'react-router-dom'
import Cookies from 'js-cookie';
import {cookieDomainExtension} from '../../Constants' 
function Protected(props)
{   
    const Cmp = props.cmp
    var userToken = Cookies.get('token',{domain:cookieDomainExtension})
    var local_email_verify_status = parseInt(Cookies.get('local_email_verify_status',{domain:cookieDomainExtension}))

    return <div> 
           
            {
              userToken ?
                  local_email_verify_status === 1 ?
                  <Cmp /> 
                  :
                  <Redirect to="/auth/register-verify-email" />
                : 
                <Redirect to="/auth/login" />
             }
           </div>
    
}

export default Protected;