import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'js-cookie';
import {get_config, accountLogout, website_base_url, api_base_url, cookieDomainExtension} from '../../Constants'; 
import MetaTags from 'react-meta-tags'; 
import ContentLoader from "react-content-loader"; 

class Rewards extends React.Component 
{
  constructor() {
    super();
    this.state = {
      showNav:false,
      user_name:"",
      full_name:"",
      email_verify_status:1,
      rewardsList:"",
      website_base_url:website_base_url,
      headers: {
        "Accept":"Application/json",
        "Content-Type":"Application/json"
      },
      api_base_url:api_base_url,
      number_of_claimed_reward:"",
      total_usd_reward:"",
      total_ano_reward:"",
      today_date_only:"",
      server_end_time_in_seconds:"",
      rewardsDataStatus: true,
      next_date_only:""
    }
  }
  

componentDidMount()
{ 
  this.rewardsOverview();
  this.getRewardsList(); 
}  

getRewardsList()
{  
    
    this.setState({
      rewardsDataStatus: false
    })
    const config = get_config(Cookies.get('token'))
    fetch(api_base_url+"rewards/rewards_list",
    {
      method:'GET',
      headers: config.headers
    }).then(res => res.json())
    .then(
      (result) => { 
          if(result.tokenStatus === true)
          {
            if(result.status === true) 
            {
              this.setState({
                rewardsList: result.message,
                rewardsDataStatus: true
              })
            }
          }
          else
          {
            accountLogout()
            //this.props.history.push('/auth/login');
          }
         
    })
}

rewardsOverview()
{
    const config = get_config(Cookies.get('token'))
    fetch(api_base_url+"rewards/rewards_overview_details",
    {
      method:'GET',
      headers:  config.headers
    }).then(res => res.json())
    .then(
      (result) => { 
          if(result.status === true) 
          {
            this.setState({
              number_of_claimed_reward: result.message.number_of_claimed_reward,
              total_usd_reward: result.message.total_usd_reward,
              total_ano_reward: result.message.total_ano_reward
            })
          }
    })
}


sharepophover() {
  $(function () {
    $('[data-toggle="popover"]').popover()
  })
}

render()
{
  

  return (
    <> 
      <div id="Content" className="rewards_page">
        <MetaTags>
          <title>Arrano Login Rewards and Scratch Cards </title>
          <link rel="canonical" href="https://arrano.network/rewards" />
          <meta name="description" content="Log-in into Arrano Network. Stay for at least 5 minutes to activate the scratch card everyday." />
          <meta name="keyword" content="Arrano Scratch Cards , Arrano Login Rewards , Arrano  Rewards , scratch and Claim ANO token." />
          <meta property="og:title" content="Arrano Login Rewards and Scratch Cards" />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        <div className="content_wrapper clearfix">
          <div className="sections_group">
            <div className="entry-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <div className="rewards_banner">
                      <div className="row">
                        <div className="col-md-7">
                          <h1>Arrano Scratch Cards. <span data-toggle="modal" data-target="#rewards">How it works?</span></h1>
                          
                          <div className="reward_insights">
                            <div className="row">
                              <div className="col-md-4 col-6">
                                <div className="media">
                                  <div className="media-left">
                                    <img src="/assets/images/total-rewards.png" className="media-object" />
                                  </div>
                                  <div className="media-body">
                                    <h4 className="media-heading">{this.state.number_of_claimed_reward}</h4>
                                    <p>Total Claimed</p>
                                  </div>
                                </div>
                              </div>
                              
                              <div className="col-md-4 col-6">
                                <div className="media">
                                  <div className="media-left">
                                    <img src="/assets/images/ano-rewards.png" className="media-object" />
                                  </div>
                                  <div className="media-body">
                                    <h4 className="media-heading">{this.state.total_ano_reward}</h4>
                                    <p>ANO Rewards</p>
                                  </div>
                                </div>
                              </div>
                           
                              <div className="col-md-4 col-6">
                                <div className="media">
                                  <div className="media-left">
                                    <img src="/assets/images/dollar-rewards.png" className="media-object" />
                                  </div>
                                  <div className="media-body">
                                    <h4 className="media-heading">{this.state.total_usd_reward}</h4>
                                    <p>Dollar Rewards</p>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                          
                        </div>
                        <div className="col-md-5 reward_info">
                          <h5><i>The Scratch Card Reward Program Successfully Concluded on 15/01/2021. <a href="https://arrano.medium.com/login-rewards-program-concluded-successfully-c8476d6fccdf" target="blank">Read More about it</a></i></h5>
                          <h5><i><a href="https://arrano.medium.com/earn-more-ano-tokens-c7d244102069" target="blank"><i className="fas fa-arrow-circle-right"></i> More rewards options</a></i></h5>
                        </div>
                      </div>
                      <div style={{display:"flex"}}>
                      <h1 style={{fontSize:"20px"}}><i className="fas fa-trophy"></i> Earn 4% ANDX on every sale through your referral.</h1> 
                      </div>
                    </div>
                    <div className="modal" id="rewards">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-body">
                            <div className="modal-header">
                              <h4 className="modal-title" id="timeDisplay">Arrano Login Rewards.</h4>
                              <button type="button" className="close" data-dismiss="modal" style={{zIndex: 999}}>&times;</button>
                            </div>
                            
                            <img src="/assets/images/share_a.png" className="position_a_img" />
                            
                            <h6>This is a limited time reward program that consists of 42 scratch cards for 42 days of login session.</h6>
                            <h6>Users have to login to the Arrano account and stay for at least 5 minutes to activate the scratch card of their respective day. </h6>
                            <ul>
                              <li>The rewards can be ANO tokens. Crypto or Coupons.</li>
                              <li>The rewards missed cannot be retrieved</li>
                              <li>You can get the reward only after "scratch and Claim" </li>
                              <li>Please provide your crypto address while claiming the dollar reward.</li>
                            </ul>  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="table-responsive">
                          <table className="table table-hover table_connections">
                            <thead>
                              <tr>
                                <th>Scratch Card Number</th>
                                <th>Reward</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            {
                              this.state.rewardsDataStatus ?
                              <React.Fragment>
                                {
                                    this.state.rewardsList ?
                                    
                                  <tbody>
                                    {
                                      this.state.rewardsList.map((item, i) => {
                                      return  (
                                        i<=20 
                                        ?
                                        <tr key={i}>
                                        <td>Day {item.day_number}</td>
                                        <td>
                                          {
                                            item.reward_value ? 
                                            <span>{parseFloat(item.reward_value)} ANO</span>
                                            :
                                            <span>-</span>
                                          }
                                        </td>
                                        <td>
                                        {
                                            item.reward_value ? 
                                            <span className="claimed_rewards">Claimed</span>
                                            :
                                            <span className="expired_rewards">Expired</span>
                                          }
                                        </td>
                                        
                                      </tr>
                                      :
                                      null 
                                      )
                                      })

                                    }
                                  
                                  </tbody>
                                  :
                                  <tbody>
                                    <tr >
                                    <td colSpan="4">Sorry No Related Data Found.</td>
                                    </tr>

                                  </tbody>
                                }
                              </React.Fragment>
                            :
                            <tbody>
                                <tr key={'1'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                
                                
                                </tr>  
                                <tr key={'2'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'3'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'4'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'5'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'6'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'7'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'8'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                            </tbody>
                            }
                          </table>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="table-responsive">
                          <table className="table table-hover table_connections">
                            <thead>
                              <tr>
                                <th>Scratch Card Number</th>
                                <th>Reward</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            {
                              this.state.rewardsDataStatus ?
                              <React.Fragment>
                                {
                                    this.state.rewardsList ?
                                    
                                  <tbody>
                                    {
                                      this.state.rewardsList.map((item, i) => {
                                      return (
                                        i>20 
                                        ?
                                        <tr key={i}>
                                        <td>Day {item.day_number}</td>
                                        <td>
                                          {
                                            item.reward_value ? 
                                            <span>{parseFloat(item.reward_value)} ANO</span>
                                            :
                                            <span>-</span>
                                          }
                                        </td>
                                        <td>
                                        {
                                            item.reward_value ? 
                                            <span className="claimed_rewards">Claimed</span>
                                            :
                                            <span className="expired_rewards">Expired</span>
                                          }
                                        </td>
                                        
                                      </tr>
                                      :
                                      null
                                      ) 
                                      })

                                    }
                                  
                                  </tbody>
                                  :
                                  <tbody>
                                    <tr >
                                    <td colSpan="4">Sorry No Related Data Found.</td>
                                    </tr>

                                  </tbody>
                                }
                              </React.Fragment>
                            :
                            <tbody>
                                <tr key={'1'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                
                                
                                </tr>  
                                <tr key={'2'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'3'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'4'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'5'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'6'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'7'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                                <tr key={'8'}>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                  <td>
                                    <ContentLoader speed={1}
                                      backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                                      <rect x="0" y="0" rx="0" width="100%" height="30px" />
                                    </ContentLoader>
                                  </td>
                                </tr>  
                            </tbody>
                            }
                          </table>
                        </div>
                      </div>
              </div>
                
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> 
      </>
    );
  }
}

export default withRouter(Rewards);
