import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom' 
import Cookies from 'js-cookie'
import Web3 from 'web3'
import {cookieDomainExtension, main_website_url, accountLogout, getShortWalletAddress} from '../../components/Constants'

class Topmenu extends Component {
  constructor(props) 
  {
    super(props)
    var local_wallet_address = "";
    if(Cookies.get('local_wallet_address',{domain:cookieDomainExtension}))
    {
       local_wallet_address = Cookies.get('local_wallet_address',{domain:cookieDomainExtension})
    }

    this.state = {
      userToken:Cookies.get('token',{domain:cookieDomainExtension}),
      user_name:Cookies.get('local_set_user_name',{domain:cookieDomainExtension}), 
      short_wallet_address: local_wallet_address ? getShortWalletAddress(local_wallet_address):"", 
      wallet_address: local_wallet_address, 
      connected_address:"",
      connected_call_status:false,
      valid_user: false
    }  
  } 
  
  componentDidMount()
  {  
    this.setLogoutTimeFun()   
    if(Cookies.get("token"))
    { 
      this.setState({valid_user: true})  
    }
    this.checkConnectedAddress()
  } 

  
  async checkConnectedAddress()
  {
      if(window.web3 || window.ethereum)
      {
        let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
        const network_id = await web3.eth.net.getId()
        console.log("network_id", network_id)
        const checkInArray = [1,56]
        if(checkInArray.includes(network_id))
        { 
          const checkAccount = await web3.eth.getAccounts()

          if(checkAccount)
          {
            if(checkAccount[0] != undefined)
            {
              this.setState({connected_call_status:true})
              this.setState({connected_address: (checkAccount[0]).toLowerCase()})
              console.log("wallet_address",checkAccount[0])
            }
          }
        }
      }
      else
      {
        this.setState({connected_call_status:true})
      }
  }

  componentDidUpdate()
  {
    if(window.location.pathname != this.state.url_path)
    { 
      this.setState({url_path: window.location.pathname})  
      this.setState({user_name: Cookies.get('local_set_user_name',{domain:cookieDomainExtension})}) 
      this.setLogoutTimeFun()  
      if(Cookies.get("token",{domain:cookieDomainExtension}))
      { 
        this.setState({valid_user: true})  
      }
      else
      { 
        this.setState({valid_user: false})  
        Cookies.remove('token',{domain:cookieDomainExtension})
      } 
    }
  }
  
  setLogoutTimeFun() 
  { 
    if(Cookies.get('local_user_expire_time',{domain:cookieDomainExtension}))
    {
      const local_user_expire_time = new Date(Cookies.get('local_user_expire_time',{domain:cookieDomainExtension}))
      const local_present_time = new Date()
      if(local_present_time > local_user_expire_time)
      {   
        accountLogout()
        this.props.history.push('/auth/login')
      } 
    }
  } 

  logout()
  {
    accountLogout()
    this.props.history.push('/auth/login')
    //window.location.reload()
  }
    
render()
{   
  const {short_wallet_address, user_name, connected_address, connected_call_status, wallet_address } = this.state
    return(
      <div id="Header_wrapper">
        <header id="Header">
          {/* <div id="Action_bar">
             
            <div className="container">
              <div className="column one">
                <ul className="social">
                  <li key={'a1'}><a href="https://twitter.com/arranonetwork" target="_blank"><i className="fa fa-twitter" aria-hidden="true" /></a></li>
                  <li key={'a2'}><a href="https://t.me/arranonetwork" target="_blank"><i className="fa fa-telegram" aria-hidden="true" /></a></li>
                  <li key={'a3'}><a href="https://medium.com/@Arrano" target="_blank"><i className="fa fa-medium" aria-hidden="true" /></a></li>
                  <li key={'a4'}><a href="https://www.reddit.com/user/ArranoNetwork" target="_blank"><i className="fa fa-reddit-alien" aria-hidden="true" /></a></li>
                </ul> 
              </div>
            </div>
          </div> */}
          <div className="header_placeholder" />
          <div id="Top_bar">
            <div className="container">
              <div className="column one"> 
                <nav className="navbar navbar-expand-md bg-transparent navbar_top_menu navbar-dark">
                <a className="navbar-brand" href={main_website_url}><img src="/assets/images/logo.svg" alt="Logo" /></a> 
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                  <span className="navbar-toggler-icon"></span>
                </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    {
                      this.state.valid_user ? 
                    <ul className="navbar-nav ml-auto">
                       <li className="nav-item">
                          <a href={main_website_url}><span>Home</span></a>
                      </li>
                      {/* <li>
                        <Link to="/swap-andx"><span>Swap ANDX</span></Link>
                      </li>  
                      <li className="nav-item" >
                          <Link to="/user/bounty-program"><span>Bounty 2.0</span></Link>
                      </li> */}
                      <li>
                        <a href={main_website_url+"market"}><span></span><span>Markets</span></a>
                      </li>
                      <li className="nav-item">
                      <span className="new_menu_label">New</span>
                      <Link to="/p2p/"><span>P2P</span></Link>
                      </li>
                      <li className="nav-item dropdown">
                      {
                          short_wallet_address ? 
                          <>
                          {
                            wallet_address == connected_address ?
                            <div className="switch_account"><span class="wallet_connect_green"></span> Connected</div>
                            :
                            <div className="switch_account"> <span class="wallet_connect_orange"></span> Not Connected</div>
                          }
                          </>
                          :
                          ""
                      }
                      
                         
                        <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                        
                          {user_name}&nbsp;
                          {
                            short_wallet_address ? 
                            <> 
                              :
                              {short_wallet_address}&nbsp;

                              
                            </>
                            :
                            ""
                         }

                          
                        {/* {
                          connected_call_status ?
                          <>
                           
                          </>
                          :
                          ""
                        } */}
                        
                        </a>
                        <div className="dropdown-menu">
                          {/* <button className="dropdown-item btn btn-primary">Connect Wallet</button>  */}
                          <Link className="dropdown-item" to="/user/dashboard"><span> Dashboard</span></Link>
                          <Link className="dropdown-item" to="/user/profile"><span> Profile</span></Link>
                          <Link className="dropdown-item" to="/user/connections"><span> Connections</span></Link>
                          <Link className="dropdown-item " to="/user/ano-funds"><span> ANO Funds</span></Link>
                          <Link className="dropdown-item" to="/user/rewards"><span><span> Rewards</span></span></Link>
                          <a className="dropdown-item" href="https://medium.com/@Arrano" target="_blank"> <span>Blog</span></a>
                          <a className="dropdown-item" href={main_website_url+"manage_tokens/"}><span> Manage Token</span></a>
                          <a className="dropdown-item" onClick={()=> this.logout()}> <span>Logout</span></a>
                        </div>
                      </li>
                    </ul>
                      :
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item"> 
                          <a href={main_website_url}><span>Home</span></a>
                        </li>
                        <li>
                          <Link to="/swap-andx"><span className="info_dot blink_me"></span><span>Swap ANDX</span></Link>
                        </li>  
                        <li className="nav-item" >
                          <Link to="/user/bounty-program"><span>Bounty 2.0</span></Link>
                        </li>
                        <li>
                        <a href={main_website_url+"market"}><span></span><span>Markets</span></a>
                        </li> 
                        <li className="nav-item">
                        <span className="new_menu_label">New</span>
                        <Link to="/p2p/"><span>P2P</span></Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/auth/login"><span>Login</span></Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/register" className="reg-account-btn"><span>Create Account</span></Link>
                      </li>
                  </ul>
              }
              </div>
              </nav> 
              </div>
            </div>
            
          </div>
        </header>  
      </div> 
    )
  }
} 
export default withRouter(Topmenu)