import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import {TOKEN_ADDR, CONTRACT_ADDR} from './config.js'

const DEFAULT_WALLET = "CONNECT";
const CHAIN_ID = '0x38';

export default function Staking() {
  const [walletAddress, setWalletAddress] = useState(DEFAULT_WALLET);
  const [allowance, setAllowance] = useState(-1);
  const [tokenCount, setTokenCount] = useState(0)

  const [totalStaked, setTotalStaked] = useState(0);
  const [totalExpected, setTotalExpected] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0)
  const [claimalbe, setClaimable] = useState(0);
  const [walletTokenBlance, setwalletTokenBlance] = useState(0);
  const [allowanceResult, setallowanceResult] = useState(0);
  
  const [increaseLimit, setincreaselimit] = useState(false);
  
  const [handleModalConnection, sethandleModalConnection] = useState(false);
  
  let web3 = new Web3(window.ethereum)

  const getChainId = async () => {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    return chainId;
  }

  const connectWallet = async () => {
    if (typeof window.ethereum === 'undefined') {
      alert('MetaMask is not installed!')
    }

    /*** check if it is on BSC network***/
    const chainId = await getChainId()
    if (chainId !== CHAIN_ID) {
//      alert('wrong network!')
      Fun_handleModalConnection()
      return
    }

    /*** metamask connecting ***/
    window.ethereum.request({
      method: 'eth_requestAccounts'
    }).then((accounts) => {
      console.log(accounts[0])
      setWalletAddress(accounts[0])
    }).catch(() => {

    })
  }

  const BalanceOf = () => {
    console.log(walletAddress)
    const balanceAbi = [
      {
        "constant": true,
        "inputs": [
        {
        "internalType": "address",
        "name": "account",
        "type": "address"
        }
        ],
        "name": "balanceOf",
        "outputs": [
        {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
        }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      }
    ]
    
    const tokenContract = new web3.eth.Contract(balanceAbi, TOKEN_ADDR);
    tokenContract.methods.balanceOf(walletAddress).call(
    ).then((res) => {
      setwalletTokenBlance(res)
    });
  }

  const getAllowance = () => {
    const allowanceAbi = [
      {
        "constant":true,
        "inputs":[
          {
            "internalType":"address",
            "name":"owner",
            "type":"address"
          },
          {
            "internalType":"address",
            "name":"spender",
            "type":"address"
          }
        ],
        "name":"allowance",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
      }
    ]
    
    const tokenContract = new web3.eth.Contract(allowanceAbi, TOKEN_ADDR);
    tokenContract.methods.allowance(walletAddress, CONTRACT_ADDR).call(
    ).then((res) => {
      setAllowance(Number(res));
      console.log(res)
      setallowanceResult(res/100000)
    });
  }

  const getStakeStatus = async () => {
    const stakeStatusAbi = [
      {
        "inputs":[],
        "name":"stakeStatus",
        "outputs":[
          {
            "internalType":"string",
            "name":"",
            "type":"string"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      }
    ]
    const stakeContract = new web3.eth.Contract(stakeStatusAbi, CONTRACT_ADDR);
    try {
      let res = await stakeContract.methods.stakeStatus().call(
        {from: walletAddress}
      )
      console.log(res)
      
  
      if (res.length > 0) {
        res = res.replace(/{/g, '[');
        res = res.replace(/}/g, ']');
        res = '{"stakeInfo": [' + res +']}';
        const obj = JSON.parse(res);
        console.log(res)
  
        if (obj.stakeInfo !== undefined) {
          let a= [0,0,0,0];
          console.log(obj.stakeInfo.length)
          for (let index = 0; index < obj.stakeInfo.length; index++) {
            a[0] = a[0] + obj.stakeInfo[index][0];
            a[1] = a[1] + obj.stakeInfo[index][1];
            a[2] = a[2] + obj.stakeInfo[index][4];
            a[3] = a[3] + obj.stakeInfo[index][5];
          }
          setTotalClaimed(a[0]);
          setTotalStaked(a[1]);
          setTotalExpected(a[2]);
          setClaimable(a[3]);
        }
      } else {
        setTotalClaimed(0);
        setTotalStaked(0);
        setTotalExpected(0);
        setClaimable(0);
      }
      
    } catch (error) {
      setTotalClaimed(0);
      setTotalStaked(0);
      setTotalExpected(0);
      setClaimable(0);
    }
    
  }

  const approve = async () => {
    const approveAbi = [
      {
        "constant":false,
        "inputs":[
          {
            "internalType":"address",
            "name":"spender","type":"address"
          },
          {
            "internalType":"uint256",
            "name":"amount","type":"uint256"
          }
        ],
        "name":"approve",
        "outputs":[
          {
            "internalType":"bool",
            "name":"","type":"bool"
          }
        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
      }
    ]
    const tokenContract = new web3.eth.Contract(approveAbi, TOKEN_ADDR);
    console.log(tokenCount+'00000');
    

    await tokenContract.methods.approve(CONTRACT_ADDR, tokenCount*100000).send(
      {from: walletAddress}
    )
    getAllowance();
  }

  const stake = async () => {
    const creatStakeAbi = [
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"_amount","type":"uint256"
          }
        ],
        "name":"createStake",
        "outputs":[],
        "stateMutability":"nonpayable",
        "type":"function"
      }
    ]
    const stakeContract = new web3.eth.Contract(creatStakeAbi, CONTRACT_ADDR);
    console.log(tokenCount*100000)
    await stakeContract.methods.createStake(tokenCount*100000).send(
      {from: walletAddress}
    )
    getAllowance();
    getStakeStatus();
    
  }

  const claimreward = async (claimvalue) => {
    console.log(claimvalue)
    const claimrewardAbi = [
      {
        "inputs": [ ],
        "name": "rewardDailyEarning",
        "outputs": [ ],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ]
    const stakeContract = new web3.eth.Contract(claimrewardAbi, CONTRACT_ADDR);
    await stakeContract.methods.rewardDailyEarning().send(
      {from: walletAddress}
    )
    getAllowance();
    getStakeStatus();
  }

  const Fun_handleModalConnection = () => {
    sethandleModalConnection(!handleModalConnection);
  }


  useEffect(() => {
    
    if (walletAddress === DEFAULT_WALLET) {
      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed!')
      }
  
      /***** when chain Network is changed *****/
      window.ethereum.on('chainChanged', (chainId) => {
        if (chainId !== CHAIN_ID) {
          Fun_handleModalConnection()
        } else {
        }
      });
  
      /***** when account is changed *****/
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts[0]) {
          setWalletAddress(accounts[0]);
        } else {
          setWalletAddress(DEFAULT_WALLET);
        }
      })
  
      window.ethereum.request({
        method: 'eth_accounts'
      }).then((accounts) => {
        const addr = (accounts.length <= 0) ? DEFAULT_WALLET : accounts[0];
        if (accounts.length > 0) {
          setWalletAddress(addr);
        } else {
          setWalletAddress(DEFAULT_WALLET);
        }
      })
    } 
    else if (walletAddress !== "") {
      // eslint-disable-next-line
      getAllowance(); 
      // eslint-disable-next-line
      getStakeStatus();
      // eslint-disable-next-line
      BalanceOf();
      // eslint-disable-next-line
    }

  }, [walletAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="Content" className="staking">
      <MetaTags>
        <title>Claim your ANO | Find ways to earn ANO tokens</title>
        <meta name="description" content="ANO tokens have a good enough supply and has a rapidly growing market demand., The token is not just about a currency, it's about fueling the project and DeFi projects for big earning opportunities." />
        <meta name="keyword" content="Earn ANO tokens. Ano token, arrano bounty, ANO bounty Program, Register to arrano, arrano register, create arrano account, arrano project, ano token, ano token listed, Arrano exchange, decentralized exchange, Arrano DEX, DEX, Decentralized exchange, best decentralized exchange, what is Decentralized exchange, Arrano P2P, Arrano Network, Arrano Bounty, Bounty program, Arrano" />
        <meta property="og:title" content="Claim your ANO | Find  ways to earn ANO tokens" />
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>
      <div className="content_wrapper clearfix">
        <div className="sections_group">
          <div className="container">
            <h1>Secure the Arrano network and earn rewards</h1>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className="staking_form">
                  {
                    walletAddress
                    ?
                    <h4>Connected Wallet: 
                       <span className="connect_btn_stake" variant="contained" color="primary" onClick={connectWallet}>{(walletAddress === 'CONNECT') ? walletAddress : (walletAddress.substring(0, 7) + "..." + walletAddress.slice(-4))}</span>
                    </h4>
                    :
                    <h4>Connect Wallet: <span onClick={()=>connectWallet()}>Connect Wallet</span></h4>
                  }

                  <h4>Your Current Allowance: {allowanceResult}</h4>
                  <h6 style={{textAlign:'left', fontSize: '14px'}}><a style={{borderBottom: '1px solid #333', cursor:'pointer'}} onClick={()=>setincreaselimit(true)} >Increase Limit?</a></h6>

                  <div className="form-group">
                    {(allowance === 0 || increaseLimit) && <label>Sign Approval</label>}
                    {(allowance > 0 && !increaseLimit) && <label>Stake tokens </label>}
                    <input type="number" className="form-control" placeholder="Tokens Of Planned to Stake" value={tokenCount} onChange={(e) => setTokenCount(Number(e.target.value))}/>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      {(allowance < 0) && <button className="staking_btn">Reading...</button>}
                      {(allowance === 0 || increaseLimit) && <button className="staking_btn" onClick={approve}>Approve</button>}
                      {(allowance > 0 && !increaseLimit) && <button className="staking_btn" onClick={stake} color="primary">Stake</button>}
                    </div>
                  </div>

                  <h5>
                    Running Contract : 
                    <a href={"https://bscscan.com/address/" + CONTRACT_ADDR} target="_blank" rel="noreferrer">{CONTRACT_ADDR.substring(0, 7) + "..." + CONTRACT_ADDR.slice(-4)}</a>
                  </h5>
                  <h5>Wallet Token Balance: {walletTokenBlance/100000}</h5>
                </div>
              </div>
            </div>
          
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="staking_details">
                  <div className="row">
                    <div className="col-md-3 col-6">
                      <h4>Total Staked Tokens</h4>
                      <h6>$ {totalStaked/100000}</h6>
                    </div>
                    <div className="col-md-3 col-6">
                      <h4>Total Expected Return</h4>
                      <h6>$ {totalExpected/100000}</h6>
                    </div>
                    <div className="col-md-3 col-6">
                      <h4>Total Claimed token</h4>
                      <h6>$ {totalClaimed/100000}</h6>
                    </div>
                    <div className="col-md-3 col-6">
                      <h4>Tokens to Claim</h4>
                      <h6>$ {claimalbe/100000} 
                        {(claimalbe > 0) && <button className="claim_now claim_btn" onClick={()=>{claimreward(claimalbe)}}>Claim Now</button>}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"modal  modal_main_popup" + (handleModalConnection ? " show d-block" : " d-none")}>
        <div className="modal-dialog claim_auto modal-sm">
          <div className="modal-content ">
            <div className="modal-body ">
              <button type="button" className="close" data-dismiss="modal" onClick={()=> {Fun_handleModalConnection()}}>&times;</button>
              <h2>Connection Error!</h2>
              <p>You have selected wrong network, please select BSC Network</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// class Staking extends React.Component {

//     render() 
//     { 
//         return(
//             <React.Fragment>
//                 <div id="Content" className="staking">
//                     <MetaTags>
//                     <title>Claim your ANO | Find ways to earn ANO tokens</title>
//                     <meta name="description" content="ANO tokens have a good enough supply and has a rapidly growing market demand., The token is not just about a currency, it's about fueling the project and DeFi projects for big earning opportunities." />
//                     <meta name="keyword" content="Earn ANO tokens. Ano token, arrano bounty, ANO bounty Program, Register to arrano, arrano register, create arrano account, arrano project, ano token, ano token listed, Arrano exchange, decentralized exchange, Arrano DEX, DEX, Decentralized exchange, best decentralized exchange, what is Decentralized exchange, Arrano P2P, Arrano Network, Arrano Bounty, Bounty program, Arrano" />
//                     <meta property="og:title" content="Claim your ANO | Find  ways to earn ANO tokens" />
//                     <meta property="og:image" content="/favicon.png" />
//                     </MetaTags>
//                     <div className="content_wrapper clearfix">
//                         <div className="sections_group">
//                             <div className="container">
//                             <h1>Secure the Arrano network and earn rewards</h1>
//                                 <div className="row">
//                                     <div className="col-md-4"></div>
//                                     <div className="col-md-4">
                                        
//                                         <div className="staking_form">
//                                             <h4>Your Current Allowance: 390</h4>
//                                             <h6><a href="">Increase Limit?</a></h6>
//                                             <div class="form-group">
//                                                 <label for="usr">Stake tokens</label>
//                                                 <input type="text" class="form-control" id="usr" />
//                                             </div>
                                            
//                                             <button className="staking_btn">Stake</button>

//                                             <h5>Running Contract: <a href="">0x7e8C3...A736</a></h5>
//                                             <h5>Total tokens in stake contract: <a href="">749.6551</a></h5>
//                                             <h5>Stakable Max tokens: <a href="">250.3449</a></h5>

//                                             <button className="staking_btn">Claim Rewards</button>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="row">
//                                     <div className="col-md-2"></div>
//                                     <div className="col-md-8">
//                                         <div className="staking_details">
//                                             <div className="row">
//                                                 <div className="col-md-3">
//                                                     <h4>Total Staked Tokens</h4>
//                                                     <h6>$ 0</h6>
//                                                 </div>
//                                                 <div className="col-md-3">
//                                                     <h4>Total Expected Return</h4>
//                                                     <h6>$ 0</h6>
//                                                 </div>
//                                                 <div className="col-md-3">
//                                                     <h4>Total Claimed token</h4>
//                                                     <h6>$ 0</h6>
//                                                 </div>
//                                                 <div className="col-md-3">
//                                                     <h4>Tokens to Claim</h4>
//                                                     <h6>$ 0</h6>
//                                                     <button className="claim_now">Claim Now</button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </React.Fragment>
//         )  
//     }
// }

