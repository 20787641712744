import React from 'react';
import { Link, withRouter } from 'react-router-dom';  
import $ from 'jquery';
import Cookies from 'js-cookie';
import {get_config, api_base_url, main_base_url, accountLogout, cookieDomainExtension} from '../../Constants';
import MetaTags from 'react-meta-tags';  

class Dashboard_demo extends React.Component 
{
  constructor(props) {
    super(props)
    this.state = { 
        token:Cookies.get('token'),
        full_name:Cookies.get('local_set_full_name'),  
        ano_wallet_balance: null,  
        purchase_andx_value:''   
    }
  }    
    
  componentDidMount()
  { 
    this.referralsOverview()  
  } 
  

  referralsOverview()
  {
    const config = get_config(this.state.token)

    fetch(api_base_url+"dashboard/overview", 
    {
      method:'GET', 
      headers:config.headers
    }).then(res => res.json()) .then((result) => 
    { 
      if(result.tokenStatus  === true)
      {
          if(result.status === true) 
          {
            this.setState({  
              ano_wallet_balance: result.message.ano_wallet_balance
            });
          }
      }
      else
      {
        accountLogout()
        this.props.history.push('/auth/login');
      } 
    })
  } 

  clearLocalAlert()
  {
    Cookies.remove('alert_message')
  }

  render()
  {
    var alert_message = Cookies.get('alert_message',{domain:cookieDomainExtension}) 
    return (
      <div>
        <div id="Content" className="dashboard_pages">
        <MetaTags>
          <title>Arrano Network Account | Blockchain secured </title>
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
          <div className="container">
            <div>


              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12 dashboard_main">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dashboard_main_blocks">
                            <div className="gold_border">
                            <div className="row">
                              <div className="col-md-7">
                                <h3>Arrano Network Account </h3>
                                <h6 className="mt-1 mb-2">Hi "<span>{this.state.full_name}</span>", Its great to see you today! </h6>
                                <p className="mb-2">Lets Learn, Earn and Explore Together.</p>
                                <p className="version_dashboard">Vol: 2.2</p>
                              </div>
                              <div className="col-md-5">
                                <div className="your_earning desktop_view dashboard_main_blocks temp_wallet">
                                  <div className="media">
                                   
                                      <div className="media-body">
                                        <img src="/assets/images/dashboard_wallet.svg" className="media-object" />
                                        <p>Total Balance</p>
                                        
                                        {
                                          this.state.ano_wallet_balance ? 
                                          <h4 className="media-heading">{this.state.ano_wallet_balance} ANO</h4>
                                          :
                                          <h4 className="media-heading">00 ANO</h4>
                                        }
                                        {/* <div className="text-center" style={{marginBottom: '5px', marginTop: '5px'}}>
                                          <Link to="/user/ano-funds" className="blink_me" style={{color: '#000'}}><button className="btn-dashboard-small">Access</button></Link>
                                          </div> */}
                                       
                                    </div>
                                  </div>
                                   
                                  {/* <Link className="btn-dashboard-roadmap" to="/roadmap"><span><img src="/assets/images/roadmap-1.png" />Checkout our Roadmap</span></Link> */}
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>      
                  </div>
                </div>
                     
                  <div className="row">
                    <div className="col-md-12">
                      <div className="earn_free_tokens">
                      <div className="gold_border">
                        <div className="row">
                          <div className="col-md-12 main_block_left">
                            <h5>Activity Update</h5>
                            <h3>Check what you can do now with your account </h3>
                            <table className="text-left">
                            <tbody>
                              <tr key={1}>
                                <td>1</td>
                                <td className="text-left " >
                                  <h6>Update your profile</h6>
                                  <p>Reverify email, connect address & join community</p>
                                  {/* <div className="modal" id="invite">
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <div className="modal-header">
                                            <h4 className="modal-title">Invite and Earn - 100 ANO/Ref</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                          </div>
                                          
                                          <img src="/assets/images/share_a.png" className="position_a_img" />
                                          
                                          <h6>You can refer friends to the platform to make ANO rewards.</h6>
                                          <ul>
                                            <li>Rewards allowed only to Verified users.</li>
                                            <li>Your referrals will also reward id they make any future achievements or purchase</li>
                                          </ul>     
                                          
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </td>
                                <td><Link to="/user/profile"><button className="btn-dashboard-small" style={{background: '#ddca02', color: '#333'}}>Update Profile</button></Link></td>
                              </tr>

                              <tr key={2}>
                                <td>2</td>
                                <td className="text-left">
                                  <h6>Use P2P Exchange</h6>
                                  <p>Conver your USDT to INR, more fiat to come </p>
                                  {/* <div className="modal" id="card">
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <div className="modal-header">
                                            <h4 className="modal-title">Arrano Login Rewards.</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                          </div>
                                          
                                          <img src="/assets/images/share_a.png" className="position_a_img" />
                                          
                                          <h6>This is a limited time reward program that consists of 42 scratch cards for 42 days of login session.</h6>
                                          <h6>Users have to login to the Arrano account and stay for at least 5 minutes to activate the scratch card of their respective day. </h6>
                                          <ul>
                                            <li>The rewards can be ANO tokens. Crypto or Coupons.</li>
                                            <li>The rewards missed cannot be retrieved</li>
                                            <li>You can get the reward only after "scratch and Claim" </li>
                                            <li>Please provide your crypto address while claiming the dollar reward.</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </td>
                                <td><Link to="/p2p" ><button className="btn-dashboard-small">Place Order</button></Link></td>
                              </tr>
                             
                              <tr key={3}>
                                <td>3</td>
                                <td className="text-left">
                                  <h6>Explore markets</h6>
                                  <p>Look up to your fav token performance & data</p>
                                  {/* <div className="modal" id="bounty">
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <div className="modal-header">
                                            <h4 className="modal-title">Bounty 2.0</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                          </div>
                                          
                                          <img src="/assets/images/share_a.png" className="position_a_img" />
                                          
                                          
                                          <ul>
                                            <li><b>Video :</b>  Create and Publish a 40 sec Video to introduce our project, share your review, provide a guide to help others understand. </li>
                                            <li><b>Article :</b> Write and Publish rich and informative articles to introduce our project, share your review, provide a guide to help others understand. </li>
                                            <li><b>Meme :</b> Create humorous and informative memes of trending and popular templates and publish on your platforms. </li>
                                            <li><b>Social Bounty :</b> Twitter, FB, Telegram, Reddit, Youtube, medium . Get rewarded to like, comment and share our posts.  </li>
                                          </ul>  
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </td>
                                <td><a href={main_base_url+"market"} ><button className="btn-dashboard-small">Live Market</button></a></td>
                              </tr>
                              
                              <tr key={4}>
                                <td>4</td>
                                <td className="text-left">
                                  <h6>Use Arrano Dex</h6>
                                  <p>Swap your BSC tokens</p>
                                  
                                </td>
                                <td><button className="btn-dashboard-small">Coming Soon</button></td>
                              </tr>

                              <tr key={5}>
                                <td>5</td>
                                <td className="text-left">
                                  <h6>Arrano NFT Cards</h6>
                                  <p>Hold limited collection of staking cards</p>
                                  <div className="modal" id="academy">
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-body">
                                          <div className="modal-header">
                                            <h4 className="modal-title">Academy credits</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                          </div>
                                          
                                          <img src="/assets/images/share_a.png" className="position_a_img" />
                                          
                                          <h6>Arrano Academy Credits</h6>
                                          <ul>
                                            <li>Get rewarded for attending blockchain and fintech courses by experts </li>
                                            <li>Get e-Certificates on completing courses </li>
                                          </ul>     
                                          
                                          <h6>Note</h6> 
                                          <ul>
                                            <li>The Program is active till Dec 15 2020</li>
                                            <li>You should not break the chain of 21 days in a row. </li>
                                            <li>The rewards will be credited if you have validity completed the sessions. </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td><button className="btn-dashboard-small">Coming Soon</button></td>
                              </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
 
                </div>
                <div className="col-md-4">
                  <div className="iframemaindiv">
                  <div className="iframevideo">
                <iframe src="https://player.vimeo.com/video/490171531" width="100%" height="200"  allow="autoplay; fullscreen"></iframe>
                </div>
                </div>
                  <div id="accordion" className="academy_course_detail">
                  <div className="gold_border">
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <h5>Arrano Academy Study</h5>
                        <h6>Simplified and short Blockchain</h6>
                      </div>
                      {/* <div className="col-md-4 col-4">
                        <Link to="/academy/all"><button className="btn-dashboard-small">Start Course</button></Link>
                        
                      </div> */}
                    </div>
                    
                    
                    <div className="main_block_academy">
                      <div className="academy_toggle">
                        <div className="card-heade" id="headingOne">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              <div className="media">
                                <img src="/assets/images/academy-1.svg" alt="What is Blockchain?" className="mr-2" />
                                <div className="media-body">
                                  <h4>What is Blockchain?</h4>
                                  <p>Future of Database management.</p>
                                </div>
                              </div>
                            </button>
                        </div>
                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                          <div className="card-body">
                            <p>Blockchain is Simply a ledger technology that stores information in a “blocks of information” powered by cryptography. The Blocks usually carry information like transaction Date, time, amount or any  inputs provided and these blocks form a chain to pass/store the information and hence called a Blockchain. </p>
                            <p>Blockchain technology revolutionized the banking and finance markets, cyber security space, supply chain management, artificial intelligence, etc. It has been seen that since after its launch it began disrupting many industries through its technological features.</p>
                            <p>It is widely accepted and popular for its features such as Privacy and security, Transparency during transactions and auditability of operations, faster execution of operations and delivery of data with lower costs.</p>
                          </div>
                        </div>
                      </div>
                      <div className="academy_toggle">
                        <div className="card-heade" id="headingTwo">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <div className="media">
                                <img src="/assets/images/academy-2.svg" alt="What is Blockchain?" className="mr-2" />
                                <div className="media-body">
                                  <h4>What is Defi?</h4>
                                  <p>New Age Financing</p>
                                </div>
                              </div>
                            </button>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                          <div className="card-body">
                            <p>Cryptocurrency’s agenda to take currency to every corner with ease of access, transfer and security is now practically possible. </p>
                            <p>Decentralized Finance (DeFi) is the Open finance system where all the financial services can be accessed in a decentralized format under the smart contract surveillance . Users can simply have access to lending, staking loan, insurance..etc from their phone without worrying about the security or funds. </p>
                            <p>This is all possible because of powerful smart contracts that enable to have more sophisticated functioning apps also called Dapp ( Decentralized Apps) that just do more than sending and receiving digital assets. </p>
                            <p>Defi apps ( projects) completely Upholds the Idea of Decentralization, No Central Authority that also influences automation, high security and accuracy of working of a system/service. </p>
                            <p>DeFi today is a rapidly growing sector and most of its use cases are with Cryptocurrency sectors. This can also be evaluated by looking at the millions of holding in Ethereum currency in DeFi applications. </p>
                          </div>
                        </div>
                      </div>
                      <div className="academy_toggle">
                        <div className="card-heade" id="headingThree">
                        
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <div className="media">
                                <img src="/assets/images/academy-3.svg" alt="What is Blockchain?" className="mr-2" />
                                <div className="media-body">
                                  <h4>What are Smart Contracts?</h4>
                                  <p>Best of Blockchain</p>
                                </div>
                              </div>
                            </button>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                          <div className="card-body">
                            <p>Built on Blockchain technology, Smart Contracts are digitally secured protocols that are coded to execute on their own and perform the actions between two parties. </p>
                            <p>The contracts are between 2 parties or two digital entities to exchange currency, property, shares or anything of a value in a transparent, untampered and secure form. </p>
                            <p>For Ex : Smart Contract is used to Build a lending application that will receive deposits and automatically release the Interest funds to the provided wallet. This can happen without any middleman to alter or figures in code. </p>
                            <p>Nick Szabo was the first person to propose the idea of Smart Contracts and lot if his predictions on Cryptography have gone well today.</p>
                            <p>Ethereum was the first to introduce a Decentralized Smart Contract, which is today on rapid growth. Most of the blockchain products are built on this frame.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>

                  <div className="community_block">
                    <div className="gold_border">
                      <div className="community_main_block">
                        <h3>Arrano Staking NFT</h3>
                        <p>Hold Arrano staking cards for 20% monthly royalty</p>
                        <button className="">Coming Soon</button>
                        <img src="/assets/images/arrano-talk.svg" data-toggle="modal" data-target="#letschat" className="hide-image-mb"/>

                      {/* <div className="text-right">
                       
                        <div className="modal" id="letschat">
                          <div className="modal-dialog modal-md dashboard_modal ">
                          <div className="modal-content">
                            <div className="modal-header">
                             
                              <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                              <div className="modal-body ">
                              
                                <img src="/assets/images/popup_chat.png"  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>             
 
      </div>
    );
  }
}

export default withRouter(Dashboard_demo)
