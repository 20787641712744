import React, { useState, useEffect } from 'react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import {api_base_url, getShortWalletAddress, website_base_url, get_config, usdt_eth_contract_addres, usdt_bnb_contract_addres, cookieDomainExtension, bnb_to_wallet_address, getUsdSymbol} from '../../Constants'
import Web3 from 'web3'
import Axios from 'axios';
import MetaTags from 'react-meta-tags'
import JsCookie from 'js-cookie';
import Popupmodal from '../../../components/layouts/popupmodal'
import queryString  from "query-string"
import moment from 'moment'

export default function Index()
{
  //buy request 
  const router = useHistory()
  const parsed = queryString.parse(router.location.search)
  const [tab, setTab] = useState(parsed.active_tab ? parseInt(parsed.active_tab):1)
  // const [tab, setTab] = useState(1)
  const [user_token] = useState("")
  const [buy_network_type_row_id, set_buy_network_type_row_id] = useState("")
  const [buy_usdt_value, set_buy_usdt_value] = useState("")
  const [rate_for_one_usdt, set_buy_rate_for_one_usdt] = useState(0)
  const [buy_commission_percentage, set_buy_commission_percentage] = useState(1)
  const [buy_receiving_amount, set_buy_receiving_amount] = useState("")
  const [buy_commision_amount, set_buy_commision_amount] = useState("") 
  const [open_order_count, set_open_order_count] = useState(0) 
  const [completed_order_count, set_completed_order_count] = useState(0) 
  const [short_wallet_balance, set_short_wallet_balance] = useState("")
  
  const [err_buy_network_type_row_id, set_err_buy_network_type_row_id] = useState("")
  const [err_buy_usdt_value, set_err_buy_usdt_value] = useState("")
  const [err_buy_receiving_amount, set_err_buy_receiving_amount] = useState("")
  const [buy_request_loader, set_buy_request_loader] = useState(false)
  const [modal_data, set_modal_data] = useState({ icon:"", title:"", content:""})
  
  //sell request
  const [sell_network_type_row_id, set_sell_network_type_row_id] = useState(1)
  const [sell_usdt_value, set_sell_usdt_value] = useState("")
  const [receive_payment_type, set_receive_payment_type] = useState("")
  const [sell_receiving_amount, set_sell_receiving_amount] = useState("")

  const [err_sell_usdt_value, set_err_sell_usdt_value] = useState("")
  const [err_sell_receiving_amount, set_err_sell_receiving_amount] = useState("")
  const [err_receive_payment_type, set_err_receive_payment_type] = useState("")
  const [err_sell_network_type_row_id, set_err_sell_network_type_row_id] = useState("")
  const [sell_request_loader, set_sell_request_loader] = useState(0)
  const [sell_step_number, set_sell_step_number] = useState(2) 
  
  const [sell_rate_for_one_usdt, set_sell_rate_for_one_usdt] = useState(0)
  const [sell_commission_percentage, set_sell_commission_percentage] = useState("")
 
  const [sell_commision_amount, set_sell_commision_amount] = useState("")
  const [wallet_balance, set_wallet_balance] = useState(0)
  const [err_connect_wallet, set_err_connect_wallet] = useState("")
  const [network_chain_id, set_network_chain_id] = useState(1)  
  const [wallet_updated_status, set_wallet_updated_status] = useState(false)  
  const [wallet_address, set_wallet_address] = useState("")  
  const [sell_payments_list, set_sell_payments_list] = useState([])
  const [open_orders_list, set_open_orders_list] = useState([])
  const [completed_orders_list, set_completed_orders_list] = useState([])
  const [overview_api_loader_status, set_overview_api_loader_status] = useState(false) 
  const [connect_wallet_loader_status, set_connect_wallet_loader_status] = useState(false)
  const [receive_payment_details, set_receive_payment_details] = useState("")
  
  const [connected_status, set_connected_status] = useState(false)
  const [connected_address, set_connected_address] = useState("") 
  
  //56
  useEffect(() => 
  { 
    overviewDetails()
    ordersList()
  }, [])

  const overviewDetails = async ()=>
  {
    if(window.web3 || window.ethereum)
    {
      let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
      const network_id = await web3.eth.net.getId()
      const checkInArray = [1,56]
      if(checkInArray.includes(network_id))
      { 
        const checkAccount = await web3.eth.getAccounts()
        if(checkAccount)
        {
          if(checkAccount[0] != undefined)
          {
            set_connected_status(true)
            set_connected_address((checkAccount[0]).toLowerCase())
          }
        }
      }
    }

    const res_data = await Axios.get(api_base_url+"p2p_trading/overview", get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_data.data.tokenStatus)
    {
      set_overview_api_loader_status(true)
      if(res_data.data.status)
      {
        console.log("overview",res_data)
        console.log("asdf", res_data.data.message.payment_details)
        set_buy_rate_for_one_usdt(res_data.data.message.one_rate_usdt_price)
        set_sell_rate_for_one_usdt(res_data.data.message.one_rate_usdt_price)
        //set_wallet_balance(res_data.data.message.wallet_balance)
        set_wallet_updated_status(res_data.data.message.wallet_updated_status)
        set_wallet_address(res_data.data.message.wallet_address)
        set_receive_payment_details(res_data.data.message.receive_payment_details)
        console.log("asdf", res_data.data.message.receive_payment_details)
        if(res_data.data.message.payment_details)
        {
          set_sell_payments_list(res_data.data.message.payment_details)
        }
        
        if(res_data.data.message.wallet_address)
        {
          var usdt_contract_addres = usdt_eth_contract_addres
          var returnValue = await getBalance(res_data.data.message.wallet_address, usdt_contract_addres)
          set_wallet_balance(returnValue)
          var shortAddress =  await getShortWalletAddress(res_data.data.message.wallet_address)
          set_short_wallet_balance(shortAddress) 
        }
        
      }
    }
    else
    {
      router.push("/user/dashboard")
    }
  } 
  
  
  const ordersList= async ()=>
  {
    const res_body1 = await Axios.get(api_base_url+"p2p_trading/list/1", get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_body1.data.status)
    {
      set_open_orders_list(res_body1.data.message)
      set_open_order_count(res_body1.data.message.length)
      console.log("open orders", res_body1.data)
    }

    const res_body2 = await Axios.get(api_base_url+"p2p_trading/list/2", get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_body2.data.status)
    {
      set_completed_orders_list(res_body2.data.message)
      set_completed_order_count(res_body2.data.message.length)
      console.log("completed orders", res_body2.data)
    }
  }
  
  

  const submitBuyForm= async ()=>
  {
    await set_modal_data({icon:"", title:"", content:""})
    let formIsValid = true
    set_err_buy_usdt_value("") 
    set_err_buy_network_type_row_id("")

    if(!buy_network_type_row_id) 
    {
      set_err_buy_network_type_row_id("The Network field is required.")
      formIsValid = false
    }
    if(!buy_usdt_value) 
    {
      set_err_buy_usdt_value("The Buy USDT Value field is required.")
      formIsValid = false
    }
    else if(buy_usdt_value < 10) 
    {
      set_err_buy_usdt_value("The Buy USDT Value field must be greater than 10.")
      formIsValid = false
    }
    
    if(!formIsValid)
    {
      return true
    }
    set_buy_request_loader(true)
    const reqObj = {
      usdt_value: buy_usdt_value,
      network_type_row_id:buy_network_type_row_id
    }
    
    const res_body = await Axios.post(api_base_url+"p2p_trading/buy_request", reqObj, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) 
    if(res_body.data)
    { 
      set_buy_request_loader(false)
      set_buy_usdt_value("")
      set_buy_commision_amount("")
      set_buy_receiving_amount("")
      set_buy_network_type_row_id("")
      console.log("res_body", res_body.data)
      if(res_body.data.status)
      {
        await set_modal_data({icon:true, title:"Thank You!", content:res_body.data.message.alert_message})
       ordersList()
       
      }
      else
      {
        await set_modal_data({icon:true, title:"Oops!", content:res_body.data.message.alert_message})
      }
    }
  }

  const sellNetworkChange=async(param)=>
  {
    set_sell_network_type_row_id(param)
    if(param)
    { 
      if(param == 1)
      {
        var usdt_contract_addres = usdt_eth_contract_addres
      }
      else if(param == 2)
      {
        var usdt_contract_addres = usdt_bnb_contract_addres
      }
      if(wallet_address)
      {
        var returnValue = await getBalance(wallet_address, usdt_contract_addres)
        // console.log("Wallet Balance", returnValue)
        set_wallet_balance(returnValue)
      }
     

      // if(window.web3 || window.ethereum)
      // { 
      //   let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
      //   const network_id = await web3.eth.net.getId()
      // }
    }
  }
  
  

const getBalance = async (eth_address, tokenAddress) =>
{
      var web3 =  new Web3(Web3.givenProvider)
      let abi = [ 
        {
          "constant":true,
          "inputs":[{"name":"_owner","type":"address"}],
          "name":"balanceOf",
          "outputs":[{"name":"balance","type":"uint256"}],
          "type":"function"
        },
        {
          "constant":true,
          "inputs":[],
          "name":"decimals",
          "outputs":[{"name":"","type":"uint8"}],
          "type":"function"
        }
      ]
    var contract = await new web3.eth.Contract(abi, tokenAddress) 
    try 
    {
      var balance = await contract.methods.balanceOf(eth_address).call() 
      var balancefromWei = await web3.utils.fromWei(balance)
      return (parseFloat(balancefromWei)).toFixed(2)
    } 
    catch (e) 
    {
      return 0
    }
}


  const submitSellRequest=async()=>
  {
    set_err_sell_network_type_row_id("")
    set_err_sell_usdt_value("")
    set_err_receive_payment_type("")
    set_modal_data({icon:"", title:"", content:""})
    let formIsValid = true
   
    if(!sell_network_type_row_id) 
    {
      set_err_sell_network_type_row_id("The Network field is required.")
      formIsValid = false
    }

    if(!sell_usdt_value) 
    {
      set_err_sell_usdt_value("The Sell USDT Value field is required.")
      formIsValid = false
    }
    else if(sell_usdt_value < 0.1) 
    {
      set_err_sell_usdt_value("The Sell USDT Value field must be greater than or equal to 10.")
      formIsValid = false
    }
    else if(sell_usdt_value > wallet_balance)
    {
      set_err_sell_usdt_value("Sell USDT value field greater than or equal to wallet balance.")
      formIsValid = false
    }

    if(!receive_payment_type) 
    {
      set_err_receive_payment_type("The Receive Payment Type field is required.")
      formIsValid = false
    }
    
    if(!formIsValid)
    {
      return true
    }
    set_sell_request_loader(true)
    if(window.web3 || window.ethereum)
    { 
      let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
      const network_id = await web3.eth.net.getId()
      if(sell_network_type_row_id == 2)
      {
        var network_type_row_id = 56
      }

      if(network_type_row_id == network_id)
      {  
          var contractAbi = [
            {
             "constant": false,
             "inputs": [
              {
               "name": "_to",
               "type": "address"
              },
              {
               "name": "_value",
               "type": "uint256"
              }
             ],
             "name": "transfer",
             "outputs": [
              {
               "name": "",
               "type": "bool"
              }
             ],
             "type": "function"
            }
           ]
         

          if(network_id == 1)
          {
            var usdt_contract_addres = usdt_eth_contract_addres
          }
          else if(network_id == 56)
          {
            var usdt_contract_addres = usdt_bnb_contract_addres
          }
          set_sell_request_loader(false)
          
          var contract = new web3.eth.Contract(contractAbi, usdt_contract_addres)
          let amount = web3.utils.toWei(String(sell_usdt_value)) 
          console.log("amount",amount)
          await contract.methods.transfer(bnb_to_wallet_address, amount).send({from:wallet_address, gas: 100000})
          .on('transactionHash',  function(transactionHash)
          {
            const reqObj = {
              usdt_value: sell_usdt_value,
              network_type_row_id: sell_network_type_row_id,
              receive_payment_type: receive_payment_type,
              transaction_hash:transactionHash
            }

            Axios.post(api_base_url+"p2p_trading/sell_request", reqObj, 
            get_config(JsCookie.get('token',{domain:cookieDomainExtension}))).then(function(response)
            {
              console.log(response);
              if(response.data.status)
              { 
                //setSellValue(0)
                ordersList()
                overviewDetails()
               
                set_modal_data({icon:true, title:"Thank You!", content:response.data.message.alert_message})
                set_sell_usdt_value("")
                set_sell_receiving_amount("")
                set_receive_payment_type("")
              } 
            }) 
            console.log('transactionHash', transactionHash)
          })
          .on('receipt',  function(receipt)
          { 
            console.log('receipt', receipt)
          })
          .on('error',  function(error)
          {
            console.log('error', error)
          }) 
      }
      else
      { 
        set_sell_request_loader(false)
        if(sell_network_type_row_id != 1)
        {
          set_modal_data({icon:true, title:"Oops!", content:"Change your wallet network to Binance."})
          return
        }
        else 
        {
          set_modal_data({icon:true, title:"Oops!", content:"Change your wallet network to Ethereum."})
          return
        }
      }
    }
    else
    {
      set_sell_request_loader(false)
      set_modal_data({icon:true, title:"Oops!", content:"Please install metamask in your browser or use ethereum wallet."})
      
    }
    return 
}  



const callgetStatus=(request_status)=>
{
  if(request_status === 0)
  {
    return ( <span className="badge badge-warning">Pending</span> )
  }
  else if(request_status === 1)
  {
    return (  <span className="badge badge-success">Partial Completed</span> )
  }
  else if(request_status === 2)
  {
    return ( <span className="badge badge-success">Completed</span> )
  }
  else if(request_status === 3)
  {
    return ( <span className="badge badge-danger">Cancelled</span> )
  }
}
  
const buyUSDTValue=(pass_param) =>
{
  set_buy_usdt_value(pass_param)
  set_buy_receiving_amount(parseFloat((pass_param*rate_for_one_usdt).toFixed(2)))
}

const buyReceiveValue=(pass_param) =>
{
  set_buy_receiving_amount(pass_param)
  set_buy_usdt_value(parseFloat((pass_param/rate_for_one_usdt).toFixed(2)))
}

const sellUSDTValue=(pass_param) =>
{
  set_sell_usdt_value(pass_param)
  set_sell_receiving_amount(parseFloat((pass_param*rate_for_one_usdt).toFixed(2)))
}

const sellReceiveValue=(pass_param) =>
{
  set_sell_receiving_amount(pass_param)
  set_sell_usdt_value(parseFloat((pass_param/rate_for_one_usdt).toFixed(2)))
}


const setSellValue=async(pass_param) =>
{ 
  
  await set_modal_data({icon:"",title:"",content:""})
  var calculated_wallet_balance = (wallet_balance*pass_param/100)
  if(calculated_wallet_balance > 10)
  {
    sellUSDTValue(calculated_wallet_balance)
  }
  else
  {
    await set_modal_data({icon:"",title:"Oops!",content:"Wallet balance should be greater than 10"})
  }
}


const placeSellRequest= async () =>
{   
    var privateKey = 'e331b6d69882b4cb4ea581d88e0b604039a3de5967688d3dcffdd2270c0fd109'
    set_err_connect_wallet("")
    if(window.web3 || window.ethereum)
    { 
      let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
      const network_id = await web3.eth.net.getId()
      if(network_chain_id == network_id)
      { 
          const checkAccount = await web3.eth.requestAccounts()
          if(!checkAccount)
          {
            window.ethereum.enable().then(function(res) 
            {
              placeSellRequest()
              return
            })
          }
          const first_address = checkAccount[0]
          console.log("wallet_address", first_address)

          // const wallet_balance = await web3.eth.getBalance(first_address)
          // console.log("wallet_balance", wallet_balance)
         
          var to_address = "0xAC2530c992Eed4C0bd7A3923bb60e31066E44f81"  
          var amounts = 0.1   
          var contractAbi = [
            {
             "constant": false,
             "inputs": [
              {
               "name": "_to",
               "type": "address"
              },
              {
               "name": "_value",
               "type": "uint256"
              }
             ],
             "name": "transfer",
             "outputs": [
              {
               "name": "",
               "type": "bool"
              }
             ],
             "type": "function"
            }
           ]
         

          if(network_id == 1)
          {
            var usdt_contract_addres = usdt_eth_contract_addres
          }
          else if(network_id == 56)
          {
            var usdt_contract_addres = usdt_bnb_contract_addres
          }
          var balance = await getBalance(first_address, usdt_contract_addres)
          var wallet_balance = await web3.utils.fromWei(balance)
          console.log("balance", wallet_balance)

          var contract = new web3.eth.Contract(contractAbi, usdt_contract_addres)
          let amount = web3.utils.toWei(String(amounts)) 
          console.log("amount",amount)
          await contract.methods.transfer(to_address, amount).send({from:first_address, gas: 100000})
      }
      else
      { 
        if(sell_network_type_row_id != 1)
        {
          set_err_connect_wallet("Change your wallet network to Binance.")
        }
        else 
        {
          set_err_connect_wallet("Change your wallet network to Ethereum.")
        }
      }
    }
    else
    {
      set_err_connect_wallet("Please install metamask in your browser or use ethereum wallet.")
    }
    return
    //Change your wallet network to Ethereum 
    //console.log("network_id",network_id)
    //set_sell_request_loader(true) 
}
const changeNetworkType= async (param) =>
{ 
  if(param == 1)
  {
    set_network_chain_id(1)
  }
  else
  {
    set_network_chain_id(56)
  }
  set_sell_network_type_row_id(param)
} 


const checkConnectedAddress = async ()=>
{
    if(window.web3 || window.ethereum)
    {
      let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
      const network_id = await web3.eth.net.getId()
      const checkInArray = [1,56]
      if(checkInArray.includes(network_id))
      { 
        const checkAccount = await web3.eth.getAccounts()
        overviewDetails() 
        if(checkAccount)
        {
          if(checkAccount[0] != undefined)
          {
            set_connected_status(true)
            set_connected_address((checkAccount[0]).toLowerCase())
            console.log("wallet_address",checkAccount[0])
          }
        }
      }
    }
    else
    {
      overviewDetails() 
    }
     
}

const connectToWallet= async () =>
{ 
  await set_modal_data({icon:false, title:"", content:""})
  if(window.web3 || window.ethereum)
  { 
    let web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
    const network_id = await web3.eth.net.getId()
    const checkInArray = [1,56]
    if(checkInArray.includes(network_id))
    { 
      window.ethereum.enable().then(function(address) 
      {
            set_connect_wallet_loader_status(true)
            if(address[0] != undefined)
            {
              const wallet_address_val = address[0]
              console.log("wallet_address", wallet_address_val)
              if(wallet_address=="")
              {
                Axios.post(api_base_url+"profile/update_wallet_address", {wallet_address:wallet_address_val}, get_config(JsCookie.get('token',{domain:cookieDomainExtension}))) .then(function (res_body) {
                  if(res_body.data.status)
                  {
                    set_connect_wallet_loader_status(false) 
                    set_modal_data({icon:true, title:"Thank You!", content:res_body.data.message.alert_message})
                    set_connected_status(true)
                    set_connected_address(wallet_address_val)
                    overviewDetails()
                  }
                  else
                  {
                    set_connect_wallet_loader_status(false)
                    set_modal_data({icon:true, title:"Oops!", content:res_body.data.message.wallet_address})
                  }
                })
              }
              else if(wallet_address_val == wallet_address)
              {
                set_connected_status(true)
                set_connected_address(wallet_address_val)
                set_connect_wallet_loader_status(false)
              }
              else
              {
                set_modal_data({icon:"",title:"Oops!",content:"This wallet address isn't matching to your registered wallet address. Please connect your registered wallet address. "+short_wallet_balance})  
                set_connected_status(true)
                set_connect_wallet_loader_status(false)
              }
            } 
      })
    }
    else
    {
      set_connect_wallet_loader_status(false)
      await set_modal_data({icon:true, title:"Oops!", content:"Switch your wallet to binance or ethereu network."})
    }
  }
  else
  {
    set_connect_wallet_loader_status(false)
    await set_modal_data({icon:true, title:"Oops!", content:"Please install metamask in your browser or use ethereum wallet."})
  }
}


return (
<>
<MetaTags>
  <title>P2P Buy/Sell Request</title>
  <meta property="og:image" content="/favicon.png" />
</MetaTags>
<div id="Content" className="profile p2p_orders">
  
  {
    !JsCookie.get('token',{domain:cookieDomainExtension}) ?
    <div className="p2p_overlay">
      <div className="overlay_login_btn">
        <h5>Login then place buy or sell order</h5>
        <Link to={'/auth/login?prev_url=/p2p'}><button>Login</button></Link>
      </div>
    </div>
    :
    ""
  }
  
  <div className="container"> 
    <div className="row">
      <div className="col-md-4">
        <div className="main_form_buy_sell">
{
overview_api_loader_status ?
<>
  {
    wallet_updated_status === true  ?
    <> 
      {
        connected_status ?
        <> 
          {
            connected_address === wallet_address ?
            <>
            <div className="text-center">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#home">Buy Order </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#menu1">Sell Order</a>
              </li>
            </ul>
          </div>

        <div className="tab-content tab_main_details">
        <div className="tab-pane  active" id="home">  
            <div className="trading_form">
                <div className="trading_form_header">
                  <h4><span><i className="fa fa-wallet " style={{color:"#ffc107"}}></i> {short_wallet_balance}</span> <span style={{float:"right"}}>1 USDT = {rate_for_one_usdt} INR</span></h4>
                </div> 
                <div className="top_main_block mt-3">
                  <div className="form-group">
                    <div className="input-group buy_selling_order">
                      <input type="number" autoComplete="off" className="form-control" placeholder="Select Network" disabled />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <select className="addon-select-option" value={buy_network_type_row_id} onChange={(e)=>set_buy_network_type_row_id(e.target.value)} >
                            <option value="">Select Network</option> 
                            <option value={1}>Ethereum</option>
                            <option value={2}>Binance</option>
                          </select>
                          </span>
                      </div>
                    </div>
                    <div className="error">{err_buy_network_type_row_id}</div>
                  </div>
                </div>
                

                <div className="form-group">
                  <input type="number" autoComplete="off" className="form-control" placeholder="Buy USDT Value" min="0.01" step="0.01" value={buy_usdt_value}  onChange={(e)=> buyUSDTValue(e.target.value)} />
                  <div className="error">{err_buy_usdt_value}</div>
                </div>

                <div className="form-group">
                  <div className="input-group ">
                    <div className="input-group-append">
                      <span className="input-group-text">You will pay</span>
                    </div>
                    <input type="number" autoComplete="off" className="form-control" placeholder="0" min="0.01" step="0.01" value={buy_receiving_amount}  onChange={(e)=> buyReceiveValue(e.target.value)}  />
                    <div className="input-group-append">
                      <span className="input-group-text">INR</span>
                    </div>
                    
                  </div> 
                </div>

                <div className="text-center">
                  <button className="btn_place_order" onClick={submitBuyForm}>
                    { buy_request_loader ? (
                      <span className="spinner-border spinner-border-sm mr-1"></span> 
                      ) : (
                      <>Place Order</>
                    )}
                  </button>
                </div>
            </div>
            </div> 
        <div className="tab-pane  fade" id="menu1">
        <div className="trading_form">
          <div className="trading_form_header">
            <h4><span><i className="fa fa-wallet " style={{color:"#ffc107"}}></i> {short_wallet_balance}</span> <span style={{float:"right"}}> 1 USDT = {rate_for_one_usdt} INR</span></h4>
          </div>
        <div className="top_main_block mt-3">
          <div className="main_selling_block"> 
            <div className="top_main_block">
              <div className="form-group">
                <div className="input-group buy_selling_order">
                  <input type="number" autoComplete="off" className="form-control" placeholder="Select Network" disabled />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <select className="addon-select-option" onChange={(e)=>sellNetworkChange(e.target.value)} >
                      <option value="">Select Network</option> 
                        <option value={1}>Ethereum</option>
                        <option value={2}>Binance</option>
                      </select>
                      </span>
                  </div>
                </div>
                
              </div>
            </div>
            {/* set_err_sell_network_type_row_id */}
            <div className="form-group" style={{marginBottom: 0}}>
              <input type="number" autoComplete="off" className="form-control" placeholder="Sell USDT Value" min="0.01" step="0.01" value={sell_usdt_value} onChange={(e)=> sellUSDTValue(e.target.value)} />
              <div className="error">{err_sell_usdt_value}</div>
            </div>
          

            {/* <div className="input-group buy_selling_order">
              <span className="input-group-text">
                <select className="addon-select-option" onChange={(e)=>sellNetworkChange(e.target.value)} >
                  <option value="">Select Network</option>
                  <option value={1}>Ethereum</option>
                  <option value={2}>Binance</option>
                </select>
                </span>
              <input type="number" autoComplete="off" className="form-control" placeholder="Sell USDT Value" min="0.01" step="0.01" value={sell_usdt_value} onChange={(e)=> sellUSDTValue(e.target.value)} />
              <div className="input-group-append">
                
              </div>
            </div> */}

            <div className="sell_wallet_balance">
              <div className="row">
                <div className="col-md-6 col-6">
                  <p >Wallet Bal:{wallet_balance}{getUsdSymbol(parseInt(sell_network_type_row_id))}</p>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="text-center">
                    <span className="p2p_pointer" onClick={()=>setSellValue(25)}>25%</span>
                    <span className="p2p_pointer" onClick={()=>setSellValue(50)}>50%</span>
                    <span className="p2p_pointer" onClick={()=>setSellValue(100)}>100%</span>
                  </h5>
                </div>
              </div> 
            </div>
          </div>
          {/* <div className="error">
            {err_sell_usdt_value} 
          </div> */}
        </div>

        {/* <h6>You Receive</h6> */}

        <div className="form-group">
          <div className="input-group ">
            <div className="input-group-append">
              <span className="input-group-text">You Receive</span>
            </div>
            <input type="number" autoComplete="off" className="form-control" placeholder="0" min="0.01" step="0.01" value={sell_receiving_amount}  onChange={(e)=> sellReceiveValue(e.target.value)} />
            <div className="input-group-append">
              <span className="input-group-text">INR</span>
            </div>
            <div className="error">{err_sell_receiving_amount}</div>
          </div> 
        </div>

        {/* <div className="form-group mt-3">
          <div className="input-group buy_selling_order">
              <input type="number" autoComplete="off" className="form-control" placeholder="0" min="0.01" step="0.01" value={sell_receiving_amount}  onChange={(e)=> sellReceiveValue(e.target.value)} />
              <div className="input-group-append">
                <span className="input-group-text">INR</span>
              </div>
          </div>
          
        </div> */}
        
        <div className="top_main_block">
          <div className="form-group">
            <div className=" buy_selling_order">
              {/* <input type="number" autoComplete="off" className="form-control" placeholder="Select Payment Type" disabled /> */}
              <div className="">
                <span className="input-group-text">
                  <select className="form-control buy_selling_order" value={receive_payment_type} onChange={(e)=>set_receive_payment_type(e.target.value)} >
                    <option value="" disabled>Select Payment Type</option>
                    {
                      sell_payments_list.map((item)=> 
                        <option value={item.id}>{item.name}- [{item.value}]</option>
                      )
                    }
                  </select>
                  </span>
              </div>
            </div>
            <div className="error">{err_receive_payment_type}</div>
          </div>
          <span className="p2p_pointer" style={{fontSize:"12px", color:"#fff", float:"right"}} data-toggle="modal" data-target="#bankdetails">View {receive_payment_type == 1 ? 'IMPS' : receive_payment_type == 2 ? 'UPI' : null} Details</span>
        </div>

        {/* <div className="form-group mt-3"> 
          <select className="form-control buy_selling_order" value={receive_payment_type} onChange={(e)=>set_receive_payment_type(e.target.value)} >
            <option value="">Select Payment Type</option>
            {
              sell_payments_list.map((item)=> 
                <option value={item.id}>{item.name}- [{item.value}]</option>
              )
            }
          </select>
          <div className="error">{err_receive_payment_type}</div>
        </div>  */}
        <div className="text-center">
          <button className="btn_place_order" onClick={submitSellRequest}>
            { sell_request_loader ? (
              <span className="spinner-border spinner-border-sm mr-1"></span> 
              ) : (
              <>Place Order</>
            )}
          </button>
        </div>
        </div>
          
        {/* <img src="/assets/img/success.png" class="check_list_logo"></img> */}

        </div>
       
      </div> 
            </> 
            :
            <>
                <div style={{minHeight:"375px"}}>
                    <div className="top_main_block mt-5 " style={{textAlign:"center"}}>    
                    <h3 className="p2p_connect_wallet">This wallet address isn't matching to your registered wallet address.</h3>
                    <p style={{color:"#414194"}}>Please connect your registered wallet address</p>
                        <p style={{color:"#414194"}}>{short_wallet_balance}</p>
                    <div className="metamask_error">
                        {err_connect_wallet}
                    </div> 
                    </div>
                </div> 
            </>
            
          }


       
        </>
        :
        <div className="text-center">
            <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#home">Buy Order </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#menu1">Sell Order</a>
            </li>
          </ul>

        <button className="btn_place_order" onClick={connectToWallet}>
          { connect_wallet_loader_status ? (
            <span className="spinner-border spinner-border-sm mr-1"></span> 
            ) : (
            <>Connect Wallet</>
          )}
        </button>
      </div>
      } 
    </>
    :
    <>
     <div className="text-center">
            <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#home">Buy Order </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#menu1">Sell Order</a>
            </li>
          </ul>
      </div>
          <div style={{minHeight:"375px"}}>
          <div className="top_main_block mt-5 " style={{textAlign:"center"}}>    
            <h3 className="p2p_connect_wallet">Connect Your Wallet</h3>
            <p style={{color:"#414194"}}>Connecting wallet will add address to your account.</p>
            <div className="metamask_error">
              {err_connect_wallet}
            </div> 
          </div>
          
          <div className="text-center">
            <button className="btn_place_order" onClick={connectToWallet}>
              { connect_wallet_loader_status ? (
                <span className="spinner-border spinner-border-sm mr-1"></span> 
                ) : (
                <>Connect Wallet</>
              )}
            </button>
          </div>
      </div>
    </>
  }                  
</>
:
""
}
              </div> 
            </div>
            <div className="col-md-8"> 
            <div className="p2p-modal-block">
              <div className="p2p_market_details">
                <h3>Arrano P2P Exchange : Decentralized</h3>
                <p>Convert USDT/BUSD to FIAT, instant and safe. </p>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    {/* <img src="/assets/users/images/p2p_main.png" /> */}
                  </div>
                </div>
                
              </div> 
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className={"nav-link "+(tab != 2 ? " active":"")} data-toggle="tab" href="#open_orders">Open Orders({open_order_count})</a>
                </li>
                <li className="nav-item">
                  <a className={"nav-link "+(tab != 1 ? " active":"")} data-toggle="tab" href="#completed_orders">Completed Orders({completed_order_count})</a>
                </li>
              </ul>

              <div className="tab-content">
                <div className={"tab-pane fade "+(tab != 2 ? " active show":"")} id="open_orders">
                  <table className="table table-borderless crypto-exchanges">
                    <thead className="">
                      <tr>
                        <th>Network</th>
                        <th>Pair</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                {
                open_orders_list ?
                <tbody>
                  
                  {
                    open_orders_list.map((item, i) =>
                    <>
                    {
                      item.request_type === 1 ?
                       <tr key={item.request_row_id} style={{background: "linear-gradient(to right, #202040 30%, #14142a 0)"}}>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="buy_sell_green">   {parseInt(item.network_type_row_id) === 1 ? 'Ethereum' : 'Binance'} </span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="buy_sell_green"><img src={"/assets/images/"+(parseInt(item.network_type_row_id) === 1 ? 'buy-orders.svg' : 'buy-busd-orders.svg')} />  {getUsdSymbol(parseInt(item.network_type_row_id))} <i class="fa fa-greater-than"></i> INR</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="receiving_orders">{parseFloat(item.request_usdt_value)} <span>{getUsdSymbol(parseInt(item.network_type_row_id))}</span> <i class="fa fa-greater-than"></i> {parseFloat(item.deposit_receiving_amount)} <span>INR</span></span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value">{parseFloat(item.request_rate_for_one_usdt)} INR</p>
                            </Link>
                            </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value"> {moment(item.date_n_time).format("lll")}</p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                             <p className="status_value">
                              {
                                callgetStatus(parseInt(item.request_status))
                              }
                              {
                                parseInt(item.user_view_status)==0?
                                <div class="view_status_pending"></div>
                                :
                                null
                              }
                             
                              </p>
                            </Link> 
                          </td>
                          
                      </tr>
                      :
                      <tr key={item.request_row_id}>
                        <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <span className="buy_sell_red">  {parseInt(item.network_type_row_id) === 1 ? 'Ethereum' : 'Binance'}</span>
                          </Link>
                        </td>
                        <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <span className="buy_sell_red"><img src={"/assets/images/"+(parseInt(item.network_type_row_id) === 1 ? 'sell-orders.svg' : 'sell-busd-orders.svg')}/>  INR <i class="fa fa-greater-than"></i> {getUsdSymbol(parseInt(item.network_type_row_id))}</span>
                          </Link>
                        </td>
                        <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <span className="receiving_orders">{parseFloat(item.deposit_receiving_amount)} <span>INR</span> <i class="fa fa-greater-than"></i> {parseFloat(item.request_usdt_value)} <span>{getUsdSymbol(parseInt(item.network_type_row_id))}</span></span>
                          </Link>
                        </td>
                        <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <p className="table_value">{parseFloat(item.request_rate_for_one_usdt)} INR</p>
                          </Link>
                        </td>
                        <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <p className="table_value">{moment(item.date_n_time).format("lll")}</p>
                          </Link>
                        </td>
                        <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                          <p className="status_value">
                            { callgetStatus(parseInt(item.request_status))}
                            {
                              parseInt(item.user_view_status)==0?
                              <div class="view_status_pending"></div>
                              :
                              null
                            }
                            </p>
                          </Link>
                        </td>
                      </tr>
                    }
                    </>
                    )
                  }
                  
                </tbody>
                 :
                 <tbody>
                   <tr>
                      <td colSpan="6">Sorry No Related Data Found.</td>
                   </tr>
                 </tbody>
               }
             </table>
          </div>
                <div className={"tab-pane fade "+(tab != 1 ? " active show":"")} id="completed_orders">
                <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>Network</th>
                        <th>Pair</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    
                    {
                completed_orders_list ?
                <tbody>
                  {
                    completed_orders_list.map((item, i) =>
                    <>
                    {
                      item.request_type === 1 ?
                      
                       <tr key={item.request_row_id}>
                         <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="buy_sell_green"> {parseInt(item.network_type_row_id) === 1 ? 'Ethereum' : 'Binance'} </span>
                            </Link>
                          </td>
                          <td>
                              <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="buy_sell_green"><img src={"/assets/images/"+(parseInt(item.network_type_row_id) === 1 ? 'buy-orders.svg' : 'buy-busd-orders.svg')} /> {getUsdSymbol(parseInt(item.network_type_row_id))} <i class="fa fa-greater-than"></i> INR</span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <span className="receiving_orders">{parseFloat(item.request_usdt_value)} <span>{getUsdSymbol(parseInt(item.network_type_row_id))}</span> <i class="fa fa-greater-than"></i> {parseFloat(item.deposit_receiving_amount)} <span>INR</span></span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value">{parseFloat(item.request_rate_for_one_usdt)} INR</p>
                            </Link>
                            </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                              <p className="table_value">   {moment(item.date_n_time).format("lll")}</p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/buy-order/'+item.request_row_id}>
                            <p className="status_value">
                              {
                                callgetStatus(parseInt(item.request_status))
                              }
                              {
                                parseInt(item.user_view_status)==0?
                                <div class="view_status_pending"></div>
                                :
                                null
                              }
                              </p>
                            </Link>
                          </td>
                      </tr>
                      :
                      <tr key={item.request_row_id}>
                           <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <span className="buy_sell_red"> {parseInt(item.network_type_row_id) === 1 ? 'Ethereum' : 'Binance'}</span>
                          </Link>
                          </td> 
                          <td>
                          <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <span className="buy_sell_red"><img src={"/assets/images/"+(parseInt(item.network_type_row_id) === 1 ? 'sell-orders.svg' : 'sell-busd-orders.svg')} />  INR <i class="fa fa-greater-than"></i> {getUsdSymbol(parseInt(item.network_type_row_id))}</span>
                          </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <span className="receiving_orders">{parseFloat(item.deposit_receiving_amount)} <span>INR</span> <i class="fa fa-greater-than"></i> {parseFloat(item.request_usdt_value)} <span>{getUsdSymbol(parseInt(item.network_type_row_id))}</span></span>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value">{parseFloat(item.request_rate_for_one_usdt)} INR</p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                              <p className="table_value">   {moment(item.date_n_time).format("lll")}</p>
                            </Link>
                          </td>
                          <td>
                            <Link to={'/p2p/sell-order/'+item.request_row_id}>
                            <p className="status_value">
                              {
                                callgetStatus(parseInt(item.request_status))
                              }
                              {
                                parseInt(item.user_view_status)==0?
                                <div class="view_status_pending"></div>
                                :
                                null
                              }
                              </p>
                            </Link>
                          </td>
                      </tr>
                    }
                    </>
                    )
                  }
                  
                </tbody>
                 :
                 <tbody>
                   <tr>
                      <td colSpan="6">Sorry No Related Data Found.</td>
                   </tr>
                 </tbody>
               }
                  </table>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="bankdetails">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
      
      <div class="modal-header">
      
          <h4 class="modal-title">{receive_payment_type == 1 ? 'IMPS' : receive_payment_type == 2 ? 'UPI' : 'Receive Payment Details'} Details</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div> 
        <div class="modal-body">
        {
            receive_payment_type == 1 ?

            <div className="impsdetails">
                <h4>IMPS</h4>
                <h5>Name : {receive_payment_details.bank_holder_name} <img src="/assets/images/copy-img.svg"/></h5>
                <h5>Account Number : {receive_payment_details.acc_number} <img src="/assets/images/copy-img.svg"/></h5>
                <h5>IFSC : {receive_payment_details.ifsc_code} <img src="/assets/images/copy-img.svg"/></h5>
                <h5>Bank : {receive_payment_details.bank_name} <img src="/assets/images/copy-img.svg"/></h5>
            </div>
            :
            receive_payment_type == 2 ?

            <div className="impsdetails">
                <h4>UPI</h4>
                <h5>UPI : {receive_payment_details.upi_id} <img src="/assets/images/copy-img.svg"/></h5>
            </div>
            :
            null
        }
          
        </div>
        
        
      </div>
    </div>
  </div>
{
  modal_data.title ? 
  <Popupmodal name={modal_data} /> 
  : 
  null
}

</>
    )
}
 