import React from 'react';

class LazyComponent extends React.Component {
  
    constructor()
    {
      super();
    }

    render()
    {
        return (
            <div className="main_block_lazy">
                <div class="centering">
                <h1>Please Hold On, We are fetching your data</h1>
                    <div class="loaderContainer"> 
                        <div class="loaderBG">
                        <span class="loaderCircle"></span>
                        </div>
                    </div>
                </div>
            </div>
        )
        
    }

}

export default LazyComponent;