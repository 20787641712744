import React from 'react';
import { withRouter } from 'react-router-dom'; 
import * as myConstClass from '../../Constants';  
import Axios from 'axios';
import Web3 from 'web3'
import $ from 'jquery'; 
import ReactPaginate from 'react-paginate'; 
import MetaTags from 'react-meta-tags';   
import Footer from '../../layouts/Footer';

class Swaptransactions extends React.Component {
  
    constructor(props) {
      super(props);  
          
    $(function() {
        var taeb = $(".taeb-switch");
        taeb.find(".taeb").on("click", function() {
          var $this = $(this);
      
          if ($this.hasClass("active")) return;
      
          var direction = $this.attr("taeb-direction");
      
          taeb.removeClass("left right").addClass(direction);
          taeb.find(".taeb.active").removeClass("active");
          $this.addClass("active");
        });
      });

      this.state = {
        api_base_url:myConstClass.api_base_url, 
        arc_transactions_list : [],
        trc_transactions_list: [],
        tabs: true, 
        erc_address: "",
        trc_address: "", 
        metaMaskNetworkAccpeted:myConstClass.metaMaskNetworkAccpeted,
 
        offset: 0,
        data: [],
        elements: [],
        perPage: 10,
        currentPage: 0, 

        trc_offset: 0,
        trc_data: [],
        trc_elements: [],
        trc_perPage: 10,
        trc_currentPage: 0,

      }

      this.getETHAccountDetails = this.getETHAccountDetails.bind(this)
      this.Geterctransaction = this.Geterctransaction.bind(this)
      this.Gettrctransaction = this.Gettrctransaction.bind(this)
    }

    componentDidMount(){    
        this.getETHAccountDetails()
    } 

    getETHAccountDetails()
    {      

      let parentThis = this 
      if(window.web3)
      { 
        let web3 = new Web3(Web3.givenProvider || parentThis.state.givenProvider)
        web3.eth.net.getNetworkType().then(function(networkName)
        {  
          if(networkName === parentThis.state.metaMaskNetworkAccpeted)
          {
            web3.eth.getAccounts().then(function(accounts)
            {   
              var first_address = accounts[0]    
              if((typeof first_address != 'undefined'))
              {  
                parentThis.Geterctransaction(first_address)
              }
              return true
            })
          }
        })
       
      }
      else if(window.ethereum)
      { 
        let web3 = new Web3(window.ethereum)
        web3.eth.net.getNetworkType().then(function(networkName)
        {
          if(networkName === parentThis.state.metaMaskNetworkAccpeted)
          {
            web3.eth.getAccounts().then(function(accounts)
            {  
              var first_address = accounts[0]  
              if((typeof first_address != 'undefined'))
              {   
                parentThis.Geterctransaction(first_address)
              }
              return true
            })
          }
        }) 
      }   
   
    } 
 

    Geterctransaction(data){     
        const erc_address = {
            from_address : data
        }

        Axios.post(this.state.api_base_url+"swap_fund/eth_request_list", erc_address)
        .then(response=>{
            if(response.data.status === true){ 
                this.setState({arc_transactions_list: response.data.message}) 
                this.setState({data: response.data.message, 
                    pageCount: Math.ceil(response.data.message.length / this.state.perPage)}, () => this.setElementsForCurrentPage());
            } 
        }) 
    } 

    Gettrctransaction(data){ 
        const trc_address = {
            from_address : data
        }

        Axios.post(this.state.api_base_url+"swap_fund/trx_request_list", trc_address)
        .then(response=>{
            if(response.data.status === true){ 
                this.setState({trc_transactions_list: response.data.message}) 
                this.setState({trc_data: response.data.message, 
                  trc_pageCount: Math.ceil(response.data.message.length / this.state.trc_perPage)}, () => this.gettrcElementsForCurrentPage());
            }  
        }) 
    }  
 
    setElementsForCurrentPage() {   
        
        let elements = this.state.data
                      .slice(this.state.offset, this.state.offset + this.state.perPage)
                      .map((e,i) =>
          ( 
                <tr key={i}>
                <td className="mobile_hide">{this.state.offset + i + 1} </td>
                <td title={e.date_n_time}>{e.date_n_time}</td>  
                <td title={e.andx_value}>{e.andx_value}</td>
                <td title={e.from_address} >{e.from_address.substr(0, 4)+'...'+e.from_address.substr(e.from_address.length - 4)}</td>
                <td title={e.deposited_address} >{e.deposited_address ? e.deposited_address.substr(0, 4)+'...'+e.deposited_address.substr(e.deposited_address.length - 4) : null}</td>
                <td title={e.user_receive_address} >{e.user_receive_address ? e.user_receive_address.substr(0, 4)+'...'+e.user_receive_address.substr(e.user_receive_address.length - 4) : null}</td>
                <td>{
                        e.admin_submitted_status == "0" 
                        ? 
                        <sapn className="badge badge-warning">Pending</sapn>
                        :
                        e.approved_date_time 
                        ? 
                        <sapn className="badge badge-success" title={e.admin_hash}>Approved ({e.admin_hash ? e.admin_hash.substr(0, 4)+'...'+e.admin_hash.substr(e.admin_hash.length - 4) : null})</sapn> 
                        :  
                        <sapn className="badge badge-danger" title={e.rejected_reason}>Rejected</sapn>
                        
                    }
                </td> 
                
                <td><a href={'https://etherscan.io/tx/'+e.admin_hash} target="_blank">View</a></td> 
            </tr>
              
            )
        );
        this.setState({ elements: elements });
      }

      gettrcElementsForCurrentPage() {  
        
        let elements = this.state.trc_data
                      .slice(this.state.trc_offset, this.state.trc_offset + this.state.trc_perPage)
                      .map((e,i) =>
          ( 
                <tr key={i}> 
                <td className="mobile_hide">{this.state.trc_offset + i + 1}</td>
                <td title={e.date_n_time}>{e.date_n_time}</td>   
                <td title={e.andx_value}>{e.andx_value}</td>
                <td title={e.from_address} >{e.from_address.substr(0, 4)+'...'+e.from_address.substr(e.from_address.length - 4)}</td>
                <td title={e.user_receive_address} >{e.user_receive_address ? e.user_receive_address.substr(0, 4)+'...'+e.user_receive_address.substr(e.user_receive_address.length - 4) : null}</td>
                <td>{
                        e.admin_submitted_status == "0" 
                        ? 
                        <sapn className="badge badge-warning">Pending</sapn>
                        :
                        e.approved_date_time 
                        ? 
                        <sapn className="badge badge-success" title={e.admin_hash}>Approved ({e.admin_hash ? e.admin_hash.substr(0, 4)+'...'+e.admin_hash.substr(e.admin_hash.length - 4) : null})</sapn> 
                        :  
                        <sapn className="badge badge-danger" title={e.rejected_reason}>Rejected</sapn>
                        
                    }
                </td> 
                
                <td><a href={'https://etherscan.io/tx/'+e.admin_hash} target="_blank">View</a></td> 
            </tr>
              
            )
        );
        this.setState({ trcelements: elements });
      }


    handlePageClick = (data) => {   
        const selectedPage = data.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({ currentPage: selectedPage, offset: offset }, () => {
          this.setElementsForCurrentPage();
        });
      }

      trchandlePageClick = (data) => {  
        const trc_selectedPage = data.selected;
        const offset = trc_selectedPage * this.state.trc_perPage;
        this.setState({ trc_currentPage: trc_selectedPage, trc_offset: offset }, () => {
          this.gettrcElementsForCurrentPage();
        });
      }
      

    render(){ 

        var parentThis = this
        if(window.tronWeb && window.tronWeb.defaultAddress.base58)
        { 
          if(this.state.trx_address != window.tronWeb.defaultAddress.base58)
          {
            parentThis.setState({
              trx_address:window.tronWeb.defaultAddress.base58
            })  
            this.Gettrctransaction(window.tronWeb.defaultAddress.base58)
          }
        } 
 
        let paginationElement;
        if (this.state.pageCount > 1) {
          paginationElement = (
            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              breakLabel={<span className="gap">...</span>}
              pageCount={this.state.pageCount}
              onPageChange={this.handlePageClick}
              forcePage={this.state.currentPage}
              containerClassName={"pagination"}
              previousLinkClassName={"previous_page"}
              nextLinkClassName={"next_page"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
            />
          );
        }

        var trcpaginationElement;
         
        if (this.state.trc_pageCount > 1) {
          trcpaginationElement = (
            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              breakLabel={<span className="gap">...</span>}
              pageCount={this.state.trc_pageCount}
              onPageChange={this.trchandlePageClick}
              forcePage={this.state.trc_currentPage}
              containerClassName={"pagination"}
              previousLinkClassName={"previous_page"}
              nextLinkClassName={"next_page"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
            />
          );
        } 
 
        return(
            <> 
            
          <MetaTags>
            <title>ANDX swap transactions list | 81800 total supply </title>
            <link rel="canonical" href="https://arrano.network/swap-transactions" />
            <meta name="description" content="andx token is issued in BEP20 and TRC20 and this page will allow ANDX ERC20 holders to place the swap request." />
            <meta name="keyword" content="ANDX, ERC20, BEP20, TRC20, binance smart chain, BSC scan, tron scan, Arrano, ANO token, Ethereum ETH, BNB, TRX, NFT" />
            <meta property="og:title" content="ANDX bridge to BEP20 and TRC20 from ERC20 | 81800 total supply" />
            <meta property="og:image" content="/favicon.png" />
          </MetaTags>

            <div className="swap-transactions"> 
                    <div className="col-md-12">
                        <div className="wrapper"> 
                            <div className="taeb-switch left text-center">
                                <div className="taeb active" taeb-direction="left" data-toggle="tab" href="#ERC20" onClick={()=> this.setState({tabs: true}) }>ERC20</div>
                                <div className="taeb" taeb-direction="right" data-toggle="tab" href="#TRC20" onClick={()=> this.setState({tabs: false})}>TRC20</div>
                            </div>
                        </div>
                <div className="swap-transactions-list">
                        <div className="tab-content ano_funds_history"> 
                            {
                                this.state.tabs
                                ? 
                                <div className="tab-pane active show" id="ERC20"> 
                                    <h2>Swap ERC20 Transactions</h2>
                                    <div className="table-responsive connections ">
                                        <table className="table table_connections">
                                            <thead>
                                                <tr>
                                                <th className="mobile_hide" style={{width: '10px'}}>Sl.No</th>
                                                <th>Date and time</th>
                                                <th>Request funds</th>
                                                <th>From address</th>
                                                <th>Deposited address</th>
                                                <th>ANDX BEP20 receive address</th>
                                                <th>Status</th> 
                                                <th>Transaction hash</th>
                                                </tr>
                                            </thead>
                                            {
                                                    this.state.arc_transactions_list.length > 0
                                                    ?
                                                    <tbody>  
                                                        {this.state.elements} 
                                                    </tbody>
                                                    : 
                                                    <tbody>
                                                        <tr >
                                                        <td colSpan="8">Sorry No Related Data Found.</td>
                                                        </tr>
                                                    </tbody> 
                                            } 
                                        </table>
                                    </div>  


                                    <div style={{textAlign: 'center', marginBottom: '15px'}}>
                                     {paginationElement} 
                                     </div>

                                </div>
                                :
                                <div className="tab-pane active show" id="TRC20">
                                <h2>Swap TRC20 Transactions</h2>
                                <div className="table-responsive connections ">
                                        <table className="table table-hover table_connections">
                                            <thead>
                                                <tr>
                                                <th className="mobile_hide" style={{width: '10px'}}>Sl.No</th> 
                                                <th>Date and time</th>
                                                <th>Request funds</th>
                                                <th>From address</th> 
                                                <th>ANDX TRC20 receive address</th> 
                                                <th>Status</th>  
                                                <th>Transaction hash</th>
                                                </tr>
                                            </thead>
                                            {
                                                    this.state.trc_transactions_list.length > 0
                                                    ?
                                                    <tbody>  
                                                        {this.state.trcelements} 
                                                    </tbody>
                                                    : 
                                                    <tbody>
                                                        <tr >
                                                        <td colSpan="7">Sorry No Related Data Found.</td>
                                                        </tr>
                                                    </tbody> 
                                            } 
                                        </table>
                                    </div>

                                    <div style={{textAlign: 'center', marginBottom: '15px'}}>
                                     {trcpaginationElement} 
                                     </div>
                                </div> 
                            }
 
                        </div>
                    </div>
                </div>
            </div> 
            
    <Footer />
            </>
        )
    } 
}

export default withRouter(Swaptransactions); 