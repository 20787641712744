import React from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import {api_base_url, get_config, cookieDomainExtension} from '../../Constants'; 

class Forgot_password_new_password extends React.Component {

  constructor() 
  {
    super();
    this.state = {
      alert_message:"",
      forgot_otp:"",
      error_forgot_otp:"",
      forgot_unique_id:Cookies.get('forgot_unique_id'),
      forgot_verify_code:Cookies.get('forgot_verify_code'),
      forgot_alert_message:Cookies.get('forgot_alert_message'),
      new_password:"",
      confirm_new_password:"",
      error_new_password:"",
      error_confirm_new_password:"",
      password_show_status:"",
      headers: {
        "Accept":"Application/json",
        "Content-Type":"Application/json"
      },
      loader:false
    }
  }
  

  forgotSetPasswordFun()
  { 
      let formIsValid = true;

      if(this.state.new_password == '') 
       {
                this.setState({
                    error_new_password:"The New Password field is required."
                });
            formIsValid = false;
       }
       else if(this.state.new_password.length < 6)
       {
           this.setState({
               error_new_password:"The New password field must be at least 6 characters in length."
           });
           formIsValid = false;
       }
       else
       {
           this.setState({
               error_new_password:""
           });
       }
       


       if(this.state.confirm_new_password === '') 
       {
              this.setState({
                  error_confirm_new_password:"The Confirm Password field is required."
              });
            formIsValid = false;
       }
       else if(this.state.confirm_new_password !== this.state.new_password) 
       {
           this.setState({
               error_confirm_new_password:"The Confirm password is not matching with New password."
           });
           formIsValid = false;
       }
       else
       {
           this.setState({
               error_confirm_new_password:""
           });
       }

      if(!formIsValid)
      {
          return true;
      }
    
      this.setState({
          loader:true
      });
      
      const reqObj = 
      {
        new_password: this.state.new_password,
        forgot_unique_id:this.state.forgot_unique_id,
        forgot_verify_code: this.state.forgot_verify_code
      }
      const config = get_config("")
    fetch(api_base_url+"forgot_password/set_new_password", {
        method:'POST',
        headers: config.headers,
        body:JSON.stringify(reqObj)
    }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
            loader:false
        });

          if(result.status === true) 
          {     
                Cookies.remove('forgot_unique_id');
                Cookies.remove('forgot_verify_code');
                Cookies.remove('forgot_alert_message');

                Cookies.set('alert_message', result.message.alert_message,{domain:cookieDomainExtension});
                this.props.history.push('/auth/login');
          }
          else
          {  
              if(result.message.alert_message)
              {
                var var_alert_message = result.message.alert_message;
              }
              else
              {
                var var_alert_message = "";
              }
              this.setState({
                  alert_message:var_alert_message
              });
          }
    });
  }

  componentDidMount(){
    // if(!Cookies.get('forgot_unique_id') || !Cookies.get('forgot_verify_code'))
    // {
    //     this.props.history.push('/auth/forgot-password');
    // }

    // if(Cookies.get('token'))
    // {
    //     this.props.history.push('/user/dashboard');
    // }
  }
 
  render()
  { 
    
    return (
      <> 
      <section className="auth_section">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-5">
                <div className="row">
                  <div className="col-lg-12 col-md-12 auth_form">
                      <div className="submit_form">
                      <h4>Set a new password</h4>
                      <p>Enter a new password for your account.</p>
                      <div className="form-group">
                      <div className="input-group">
                        <input className="form-control" placeholder="New Password" type={this.state.password_show_status ? "text":"password"}  value={this.state.new_password}  onChange={(e)=>{this.setState({new_password:e.target.value})}} name="new_password" />
                        <div className="input-group-append">
                                <span className="input-group-text password_view_span">
                                 {    
                                  this.state.password_show_status ?
                                  <img src="/assets/images/not-view.png" className="password_view" onClick={()=>{this.setState({password_show_status:!this.state.password_show_status})}} />
                                  :
                                  <img src="/assets/images/view-eye-icon.png"  className="password_view" onClick={()=>{this.setState({password_show_status:!this.state.password_show_status})}} />
                                  }
                                </span>
                          </div>
                    
                      </div>
                      <div className="error">
                          {this.state.error_new_password} 
                          </div>
                    </div>
                      <div className="form-group">
                        <input className="form-control" placeholder="Confirm New Password" type="password" value={this.state.confirm_new_password}  onChange={(e)=>{this.setState({confirm_new_password:e.target.value})}} name="confirm_new_password" />
                        <div className="error">
                          {this.state.error_confirm_new_password} 
                        </div>
                      </div>
                      <button className="auth_button" type="submit" onClick={()=>{this.forgotSetPasswordFun()}}>
                      {this.state.loader ? (
                        <span class="spinner-border spinner-border-sm mr-1"></span> 
                        ) : (
                        <>Set New Password</>
                       )}
                      </button>
                      <h5 className="auth_below_links">Want to <Link to="/auth/login">Log In?</Link></h5>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-8 auth_content mt-5">
                    <h5 className="claim_token"></h5>
                    <p>Global tech &amp; media teams joining the </p>
                    <h3>Arrano Network Community</h3>
                    <h5>An open-source project for DeFi developments.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </> 
    );
  }
}

export default Forgot_password_new_password;