import { connectWallet, removeWallet } from './Constants';

var initialState = {
    ETHWalletData:[]
}

export default function reducer(state=initialState, action) {

    switch(action.type)
    {
        case connectWallet:
            // console.log('Reducer', action.data);
            return {
                ...state,
                ETHWalletData: action.data
            }
            break;
        case removeWallet:
            return {
                ...state,
                ETHWalletData: action.data
            }
            break;
         default:
         return state   
    }
}
