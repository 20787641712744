import React from 'react';
import { withRouter } from 'react-router-dom'; 
import Cookies from 'js-cookie';
import {website_base_url,api_base_url,nodejs_api_base_url, accountLogout, cookieDomainExtension} from '../../Constants'; 
import ContentLoader from "react-content-loader";
import MetaTags from 'react-meta-tags'; 
import $ from 'jquery';
import Moment from 'react-moment';  
import Axios from 'axios';

class Converted_funds extends React.Component {

constructor() {
    super();
    if(Cookies.get('reverify_sent_email_status'))
    {
      var reverify_sent_email_status = Cookies.get('reverify_sent_email_status')
    }
    else
    {
      var reverify_sent_email_status = 0
    }
 
    this.state = {
       ano_tab : true,
       reverify_sent_email_status:reverify_sent_email_status,
       website_base_url:website_base_url, 

        Earnedfunds: false,
        getVerifiedTxnReferralsList:'',
        getVerifiedTxnLoginRegList:'',
        getAvailableTrasactionList:'',
        api_base_url:api_base_url,
        nodejs_api_base_url:nodejs_api_base_url,

        payments_type_list: [],
        payment_receive_type: "",
        coin_symbol: "",
        one_ano_rate: "",
        payment_address: '',
        coin_image: "",
        entered_ano_value: "",

        valid_receive_address: "",
        valid_ano_value: "",
        convert_transaction_success: false

        
    } 
    
    $(function() {
      var taeb = $(".taeb-switch");
      taeb.find(".taeb").on("click", function() {
        var $this = $(this);
    
        if ($this.hasClass("active")) return;
    
        var direction = $this.attr("taeb-direction");
    
        taeb.removeClass("left right").addClass(direction);
        taeb.find(".taeb.active").removeClass("active");
        $this.addClass("active");
      });
    });

    this.SubmitConvertedvalue = this.SubmitConvertedvalue.bind(this)
}
 
handleChange = email_verify_otp => this.setState({ email_verify_otp });

anoFundsOverview()
{
  const token = Cookies.get('token');
  var data = {
      token: token
  };
  fetch(this.state.api_base_url+"dashboard/divided_fund_wallet_overview", {
    method:'POST',
    headers: this.state.headers,
    body:JSON.stringify(data)
  }).then(res => res.json())
  .then(
    (result) => { 
      if(result.tokenStatus  === true)
      {
          if(result.status === true) 
          {
            this.setState({ 
              total_locked_fund: result.message.total_locked_fund,
              total_verified_fund: result.message.total_verified_fund,
              total_available_fund: result.message.total_available_fund,
              ano_wallet_balance: result.message.total_locked_fund+result.message.total_verified_fund+result.message.total_available_fund
            });
          }
      }
      else
      {
        accountLogout()
        this.props.history.push('/auth/login');
      }
  })
}

logout(){
  this.props.history.push('/auth/login');
}

getVerifiedTxnLoginRegList()
{   
  const token = Cookies.get('token')
  var data = {
      token: token
  }
  this.setState({
    verifiedTxnLoaderStatus: true
  })
  
  fetch(this.state.api_base_url+"ano_funds/users_verified_transactions_login_reg",
  {
    method:'POST',
    headers: this.state.headers,
    body:JSON.stringify(data)
  }).then(res => res.json()).then((result) => 
  {
    this.setState({
      verifiedTxnLoaderStatus: false
    })

    if(result.tokenStatus)
    {  
      if(result.status === true) 
      {
        this.setState({
          getVerifiedTxnLoginRegList: result.message
        })
      }
    }
    else
    {
      this.logout()
    }
  })
}

getVerifiedTxnReferralsList()
{   
  const token = Cookies.get('token');
  var data = {
      token: token
  };
    this.setState({
      verifiedTxnLoaderStatus: true
    });
    
    fetch(this.state.api_base_url+"ano_funds/users_verified_transactions_referrals",
    {
      method:'POST',
      headers: this.state.headers,
      body:JSON.stringify(data)
    }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
          verifiedTxnLoaderStatus: false
        });
        
        if(result.tokenStatus){ 
 
          if(result.status === true) 
          {
            this.setState({
              getVerifiedTxnReferralsList: result.message
            });
          }
        }
        else{
          this.logout();
        }
    });
}
  
dashboardMe()
{
    const token = Cookies.get('token')
    var data = {
        token: token
    }
    
    fetch(this.state.api_base_url+"dashboard", {
       method:'POST',
       headers: this.state.headers,
       body:JSON.stringify(data)
    }).then(res => res.json()).then((result) => 
    { 
      if(result.tokenStatus)
      { 
       if(result.status === true) 
       {
          this.setState({ 
              user_name: result.message.user_name,
              full_name: result.message.full_name,
              email_verify_status:result.message.email_verify_status,
              date_n_time: result.message.date_n_time,
          })
       }  
      }
      else
      {
        accountLogout()
        this.props.history.push('/auth/login')
      }
    })
  }
 

getAvailableTrasactionList()
{   
  const token = Cookies.get('token');
  var data = {
      token: token
  };
    this.setState({
      availableTxnLoaderStatus: true
    });
    fetch(this.state.api_base_url+"ano_funds/users_available_funds_transactions",
    {
      method:'POST',
      headers: this.state.headers,
      body:JSON.stringify(data)
    }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
          availableTxnLoaderStatus: false
        }); 
        
        if(result.tokenStatus){ 
          if(result.status === true) 
          {
            this.setState({
              getAvailableTrasactionList: result.message
            });
          }
        }
        else{
          this.logout();
        }
    });
} 

componentDidMount()
{    
  this.anoFundsOverview()
  this.getVerifiedTxnLoginRegList()
  this.getVerifiedTxnReferralsList() 

  this.getpaymentlist()  
}

getpaymentlist(){
  Axios.get(this.state.api_base_url+"buy_ano/payment_types")
  .then(response=>{     

    if(response.data.status){ 
      this.setState({payments_type_list: response.data.message})
      
      response.data.message.map((item)=>{
          if(item.id == '3'){ 
            this.setState({payment_receive_type: item.id})
            this.setState({coin_symbol: item.payment_name})
            this.setState({one_ano_rate: item.one_ano_rate})
            this.setState({payment_address: item.payment_address})
            this.setState({coin_image: item.image_url}) 
          }
      })
  } 
  })
}

componentDidUpdate(){
  if(this.state.one_ano_rate == ''){
    this.getpaymentlist()
  }
}

selectedpayment(id){ 
    this.setState({payment_receive_type: id})

    this.state.payments_type_list.map((item)=>{
      if(item.id == id){ 
        this.setState({payment_receive_type: item.id})
        this.setState({coin_symbol: item.payment_name})
        this.setState({one_ano_rate: item.one_ano_rate})
        this.setState({payment_address: item.payment_address})
        this.setState({coin_image: item.image_url}) 
      }
  })
}

  OnanovalueChange(data){ 
    var entered_ano_value = this.state.entered_ano_value
    entered_ano_value = data;
    this.setState({entered_ano_value: entered_ano_value}) 
  
    var receive_amount = this.state.receive_amount;
    receive_amount = this.state.entered_ano_value * this.state.one_ano_rate
    this.setState({receive_amount: receive_amount}) 

}

OnChangeReceiveAddress(data){ 
  this.setState({receive_address: data})
}

SubmitConvertedvalue(){
  this.setState({valid_ano_value: ""}) 
  this.setState({valid_receive_address: ""})

  const converted_data = {
    token: Cookies.get("token"),
    ano_value: this.state.entered_ano_value,
    one_ano_rate: this.state.one_ano_rate,
    payment_type: this.state.payment_receive_type,
    receive_address: this.state.receive_address,
    receive_amount: this.state.receive_amount
  }  
  if(this.state.entered_ano_value == undefined || this.state.entered_ano_value == ""){
    this.setState({valid_ano_value: "Enter valid ANO values"})  
    this.setState({valid_receive_address: ""}) 
  }
  else if(this.state.receive_address == undefined || this.state.receive_address == ""){ 
    this.setState({valid_ano_value: ""}) 
    this.setState({valid_receive_address: "Enter receive address"})  
  }
  else{
    
  this.setState({valid_ano_value: ""}) 
  this.setState({valid_receive_address: ""}) 
    Axios.post(this.state.api_base_url+"convert_fund/save_n_submit_convert_fund_details", converted_data)
    .then(response=>{ 
        
        if(response.tokenStatus){ 
        if(response.data.status === true){
          this.setState({convert_transaction_success: true})
        }
      }
      else{
        this.logout();
      }
    })

  }
} 


  render()
  {  
    var page_number = 1
    var sl_number = 1
    return (
      <> 
      <div id="Content" >
      <MetaTags>
        <title>Verify ANO Tokens</title>
        <meta property="og:image" content="/favicon.png" />
      </MetaTags>
      <div className="ano_funds_banner convert-selected-network">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ano_funds_banner_block convert-selected-network-block">
                <h3>Convert ANO Funds </h3> 
               

                <p>Re-Verify email and Download Mobile app to authenticate your account and use your ANO tokens.</p> 

                    <div className="row">
                        <div className="col-md-12">    
                        
                        <div className="col-sm-4 offset-sm-4 converted-top-form"> 
                         
                        <div className="input-group mb-3">
                              <input type="text" className="form-control" value={this.state.receive_amount} placeholder="Select Network" name="receive_amount" />
                              <div className="input-group-append">
                                <span className="input-group-text"><img src={this.state.coin_image} /></span> 
                                <select name="payment_receive_type" value={this.state.payment_receive_type} onChange={(e)=> this.selectedpayment(e.target.value)} >
                                    { 
                                        this.state.payments_type_list.map((item, i) => {
                                              return <option key={i} value={item.id} > {item.payment_name}</option>
                                        }) 
                                    }
                              </select>
                              </div> 
                            </div> 

                            <div className="input-group mb-2">
                              <input type="text" className="form-control"  name="receive_address" placeholder="Enter your payment recieve address" value={this.state.receive_address || ''} onChange={(e)=> this.OnChangeReceiveAddress(e.target.value)} /> 
                            </div>
                            
                            <div className="input-group mb-2">
                              <div className="error">
                                  {this.state.valid_receive_address}
                              </div>            
                            </div> 

                            <div className="input-group mb-1">
                              <input type="number" className="form-control" placeholder="Enter ANO" value={this.state.entered_ano_value || ''} onChange={(e)=> this.setState({entered_ano_value: e.target.value})} name="entered_ano_value" /> 
                            </div> 
                             
                            <div className="input-group mb-2">
                                <div className="error">
                                    {this.state.valid_ano_value}
                                </div>             
                            </div> 
    
                            <button className="convert-selected-network" type="button" onClick={this.SubmitConvertedvalue}>Convert Now</button>  
                        </div>
                        
                        <div className="col-md-4"></div>  
                        </div>
                    </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container"> 
        <div className="wrapper"> 
          <div className="taeb-switch left text-center">
            <div className="taeb active" taeb-direction="left" data-toggle="tab" href="#Overview" onClick={()=> this.setState({ano_tab: true})}>Verified Funds</div>
            <div className="taeb" taeb-direction="right" data-toggle="tab" href="#Exchange" onClick={()=> this.setState({ano_tab: false})}>Available Funds</div>
          </div>
        </div>

        <div className="tab-content ano_funds_history">
          {
            this.state.ano_tab
            ?
            <div className="tab-pane active show" id="Overview"> 
            <h5>Verified Funds History</h5>
            <div className="table-responsive">
              <table className="table table-hover table_connections table-striped">
                <thead>
                  <tr>
                  <th className="mobile_hide">Sl.No</th>
                  <th>Date </th>
                  <th>Description</th>
                  <th>Tokens</th>
                  </tr>
                </thead>
                
                
              { 
                
                !this.state.verifiedTxnLoaderStatus ?
                 <React.Fragment>
                   {
                     this.state.getVerifiedTxnLoginRegList ?
                    <tbody>
                      {
                        this.state.getVerifiedTxnLoginRegList.map((item, i) =>
                        <tr key={'a'+i}>
                            <td className="mobile_hide">{sl_number++}</td>
                            <td><Moment format="DD MMM YYYY">{item.date_only}</Moment>
                            </td>
                            <td>
                              {
                                parseInt(item.transaction_type) === 1 ?
                                <React.Fragment>
                                  Registration Reward
                                </React.Fragment>
                                :
                                parseInt(item.transaction_type) === 2 ?
                                <React.Fragment>
                                  Login Reward 
                                </React.Fragment>
                                :
                                null
                              }
                            </td>
                            <td>{parseFloat(item.ano_value)} ANO</td> 
                        </tr>
                        )
                      }
                    </tbody>
                     :
                     null
                   }
                   {
                     this.state.getVerifiedTxnReferralsList ?
                    <tbody>
                      {
                        this.state.getVerifiedTxnReferralsList.map((item, i) =>
                        <tr key={'b'+i}>
                            <td className="mobile_hide">{sl_number++}</td>
                            <td><Moment format="DD MMM YYYY">{item.date_only}</Moment>
                            </td>
                            <td>
                               Referral reward from {item.from_user_name}
                            </td>
                            <td>{parseFloat(item.ano_value)} ANO</td> 
                        </tr>
                        )
                      }
                    </tbody>
                     :
                     null
                   } 
                   {
                    ((this.state.getVerifiedTxnReferralsList == '') && (this.state.getVerifiedTxnLoginRegList == '')) ?
                    <tbody>
                      <tr >
                      <td colSpan="4">Sorry No Related Data Found.</td>
                      </tr>
                    </tbody>
                    :
                    null
                  }
                 </React.Fragment>
                :
                <React.Fragment>
                  <tbody>
                    <tr key={'1'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>  
                    <tr key={'2'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>  
                    <tr key={'3'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>  
                    <tr key={'4'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                      
                    </tr>  
                    <tr key={'5'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>  
                    <tr key={'6'}>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader speed={1}
                          backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                          <rect x="0" y="0" rx="0" width="100%" height="30px" />
                        </ContentLoader>
                      </td>
                      
                    </tr>   
                </tbody>
                </React.Fragment>
              }
              </table> 
            </div>
          </div>
            : 
            <div className="tab-pane  active show" id="Exchange">
              <h5>Available Funds History</h5>
              
              <div className="table-responsive">
                <table className="table table-hover table_connections table-striped">
                  <thead>
                  <tr>
                    <th className="mobile_hide">Sl.No</th>
                    <th>Date </th>
                    <th>Description</th>
                    <th>Tokens</th>
                    </tr>
                  </thead>
                  {
                     
                !this.state.availableTxnLoaderStatus ?
                <React.Fragment>
                  {
                   this.state.getAvailableTrasactionList ?
                   <tbody>
                     {
                       this.state.getAvailableTrasactionList.map((item, i) =>
                       <tr key={'c'+i}>
                           <td className="mobile_hide">{page_number+i}</td>
                           <td><Moment format="MMM YYYY">{item.date_only}</Moment> 
                            </td>
                           <td>5% release of verified fund</td>
                           <td>{parseFloat(item.ano_value)} ANO</td> 
                       </tr>
                       )
                     }
                     
                   </tbody>
                   :
                   
                   <tbody>
                     <tr >
                     <td colSpan="4">Sorry No Related Data Found.</td>
                     </tr>
  
                   </tbody>
                 }
                </React.Fragment>
               :
               <React.Fragment>
                 <tbody>
                   <tr key={'1'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                   </tr>  
                   <tr key={'2'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                   </tr>  
                   <tr key={'3'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                   </tr>  
                   <tr key={'4'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     
                   </tr>  
                   <tr key={'5'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     
                   </tr>  
                   <tr key={'6'}>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                     <td>
                       <ContentLoader speed={1}
                         backgroundColor="#ABB0B2" foregroundColor="#fff"  width="100%" height="20px" >
                         <rect x="0" y="0" rx="0" width="100%" height="30px" />
                       </ContentLoader>
                     </td>
                   </tr>   
               </tbody>
               </React.Fragment>
             }
                </table>
              </div>
            </div>
          }
          
        </div>

        
      </div>
    </div>

    <div className={"modal  modal_main_popup" + (this.state.convert_transaction_success ? " show d-block" : " d-none")}>
          <div className="modal-dialog claim_auto modal-sm">
            <div className="modal-content ">
              <div className="modal-body ">
                <button type="button" className="close" data-dismiss="modal" onClick={()=> {this.handleModelEmailVerifySuccessMsg()}}>&times;</button>
                <h2 style={{fontSize: '40px'}}><i className="far fa-check-circle"></i></h2>
                <h2>Email Re-Verified Successfully!</h2>
                <p>Please Download the mobile app to authenticate the access to ANO tokens.</p>
              </div>
            </div>
          </div>
        </div>
     
    </>
    );
  }
}

export default withRouter(Converted_funds);
