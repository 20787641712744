import React from 'react';
import Topmenu from '../../layouts/Topmenu';
import MetaTags from 'react-meta-tags';
import Footer from '../../layouts/Footer';
import { Link } from 'react-router-dom'
class List extends React.Component 
{
  constructor() {
  super();
}

render(){
  return (
    <>
      <div id="Content">
        <MetaTags>
          <title>Arrano Academy</title> 
          <meta name="description" content="Discover the latest and events in the fintech and crypto eco-system" />
          <meta name="keyword" content=" Crypto insights, defi trends, defi markets , cryptocurrency market trends, decentralised  market insights" />
          <meta property="og:title" content="Cryptocurrency Insights" />
          <meta property="og:image" content="/favicon.png" />
        </MetaTags>
        <div className="content_wrapper clearfix">
          <div className="sections_group">
            <div className="entry-content">
                <div className="arrano_academy_banner">
                  <div className="container">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <div className="main_list_banner">
                            <img src="/assets/images/bounty_gramophone.png" />
                            <img src="/assets/images/bounty_A.png" className="academy_ano" />
                            
                            <h3>Arrano Academy</h3>
                            <h5>What will you get once you pursue our Courses</h5>
                            <ul>
                              <li>Crypto Skills for the future </li>
                              <li>End to End Understanding of practicing cryptocurrency </li>
                              <li>Tools, Analytics and ideas to apply in daily trading activities </li>
                              <li>References, case studies, Certificates </li>
                              <li>Lifetime Access to our Academy Alumni Community( Build relationships and engage with your peers) </li>
                            </ul>
                            <button>Start Course</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container pt-4">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <h3 className="text-center">Course for you</h3>
                        <div className="row">
                          <div className="col-md-1"></div>
                          <div className="col-md-10">
                            <ul className="academy_course_list">
                              <li>
                                <Link to="/academy/academy-crypto-basics-you-should-know" >
                                  <div className="academy_block" style={{background: 'url(/assets/images/academy-course-1.png)'}}> 
                                  
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link to="/academy/academy-why-invest-in-cryptocurrencies">
                                  <div className="academy_block"  style={{background: 'url(/assets/images/academy-course-2.png)'}}>
                                    
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link to="/academy/academy-how-to-get-started-with-cryptocurrency" >
                                  <div className="academy_block" style={{background: 'url(/assets/images/academy-course-3.png)'}}>
                                    
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default List;