import React from 'react'; 
import OtpInput from 'react-otp-input';
import Cookies from 'js-cookie';
import { api_base_url, get_config, cookieDomainExtension, loginCookies, businessModal} from '../../Constants' 
import MetaTags from 'react-meta-tags';

class Register_verify_email extends React.Component {
        
  constructor() 
  {
      super();
      this.state = {
          alert_message:"",
          email_verify_otp:"",
          error_email_verify_otp:"",
          verify_email_loader:"",
          resend_alert_message:"",
          forgot_otp:"",
          error_forgot_otp:"",
          full_name:Cookies.get('local_set_full_name'),
          email_id:Cookies.get('local_set_email_id'),
          headers: {
            "Accept":"Application/json",
            "Content-Type":"Application/json"
          },
          api_base_url:api_base_url,
          loader:false
      }
      
  }
  
  handleChange = email_verify_otp => this.setState({ email_verify_otp });

  reSendEmailFun()
  {   
      this.setState(
        {
          email_verify_otp:""
        }
      )
      var data = {
          token: Cookies.get('token')
      }
      const config = get_config(Cookies.get('token'))
      fetch(api_base_url+"profile/resend_otp", {
        method:'GET',
        headers: config.headers,
      }).then(res => res.json())
      .then(
        (result) => {
          if(result.status === true) 
          {
            this.setState({ 
              resend_alert_message: result.message.alert_message
            })
          }
      });
  }


    verifyEmailFun()
    {
      let formIsValid = true;
      this.setState(
        {
          resend_alert_message:""
        }
      )
      
      if(this.state.email_verify_otp === '') 
      {
          this.setState({
            error_email_verify_otp:"The OTP field is required."
          });
          formIsValid = false;
      }
      else if(this.state.email_verify_otp.length != 6) 
      {
          this.setState({
            error_email_verify_otp:"The OTP field must be at exactly 6 characters in length."
          });
          formIsValid = false;
      }
      else
      {
          this.setState({
            error_email_verify_otp:""
          });
      }


        if(!formIsValid)
        {
            return true;
        }

        this.setState({
          verify_email_loader:true
        });

        var data = {
            email_verify_otp: this.state.email_verify_otp
        }

       const config = get_config(Cookies.get('token'))  
        fetch(api_base_url+"profile/verify_email_using_otp", {
           method:'POST',
           headers: config.headers,
           body:JSON.stringify(data)
        }).then(res => res.json())
        .then(
         (result) => { 
            this.setState({
              verify_email_loader:false
            });
           if(result.status === true) 
           {  
              Cookies.set('local_email_verify_status', 1,{domain:cookieDomainExtension});
              Cookies.set('alert_message', 'Your email has been verified successfully, Thank You!',{domain:cookieDomainExtension});
              
              this.props.history.push('/user/dashboard');  
           }
           else
           {   
              this.setState({
                resend_alert_message:result.message.alert_message,
                email_verify_otp:result.message.email_verify_otp
              });
           }
           
        });

    }


  render()
  {
    if((Cookies.get('token') !== '') && (Cookies.get('local_email_verify_status') == 1))
    {
       this.props.history.push('/user/dashboard');
    }
    return (
      <>
       <MetaTags>
        <title>Verify Email | Arrano Decentralized Exchange </title>
        <link rel="canonical" href="https://arrano.network/register-verify-email" />
        <meta property="og:title" content="Verify Email | Arrano Decentralized Exchange" />
        <meta property="og:image" content="/favicon.png" />
      </MetaTags> 
     
      <section className="auth_section">
        
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-lg-9 col-md-12 auth_form">
                   <div className="submit_form">
                      <h2 className="heading-title" />
                      <div className="row">
                        <div className="col-md-12">
                          <h4>Enter OTP</h4>
                          <p>We’ve sent an OTP to {this.state.email_id} , please enter the OTP to verify for your Arrano Network account.</p>
                          {
                              this.state.resend_alert_message ? (
                              <div className="alert alert-info">
                                {this.state.resend_alert_message}
                              </div>
                            ) : (
                                null
                          )}

                        </div>

<div className="col-md-12 text-center">
<div style={{display: 'inline-flex', margin: '0 auto'}}>
<OtpInput  className="otp-form-control" isInputNum={true} numInputs={6} value={this.state.email_verify_otp} onChange={this.handleChange} separator={<span>-</span>}  name="email_verify_otp" />
  </div>
</div>
<div className="error col-md-12 text-center">
  {this.state.error_email_verify_otp} 
  <h6 className="text-center"><a href="#" onClick={()=>{this.reSendEmailFun()}}>Resend OTP?</a></h6>
</div>
                       
  <button type="submit" className="auth_button" onClick={()=>{this.verifyEmailFun()}}>
  {this.state.verify_email_loader ? (
      <span className="spinner-border spinner-border-sm mr-1 text-light"></span> 
      ) : (
      <> Verify OTP</>
  )}
</button>
                      </div>
                   </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-lg-8 col-md-12 auth_content mt-0">
                    <p>The new impact of blockchain</p>
                    <h3>Arrano Projects will make a Difference. </h3>
                    <h5>Turning the real world ideas into decentralized reality of blockchain </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
  }
}

export default Register_verify_email;